<template>
  <div class="container-fluid">
    <div class="row mt-5">
      <nav 
        v-bind:class="{ 'sidebar-hidden': isActive}"
        class="col-sm-3 col-md-2 d-sm-block bg-light sidebar"
        >
         <router-link class="navbar-brand mt-n5" to="/user">
            <b-img :src="logo" class="logo" alt="AFR logo"></b-img>
        </router-link>
        <ul class="nav nav-pills text-left flex-column mt-3 text-white">
          <li class="nav-item">
              <button @click="sideMenuActions" class="btn text-white float-right btn-humburger mt-3">
                <i  class="bx bx-x fa-2x"></i>
              </button>
          </li>
          <li class="nav-item">
            <router-link
              exact-active-class="vm-link"
              :class="{ 'vm-link': subIsActive('/user/admin/main') }"
              class="nav-link nav-link2 ml-n2"
              to="/user/admin/main"
            >
              <div class="cont pl-1">
                <div class="d-flex justify-content-start link-item">
                  <span>
                    <i class="bx bx-grid-alt fa-2x"></i>
                  </span>
                  <span class="mt-1 ml-3">Dashboard</span>
                </div>
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              exact-active-class="vm-link"
              class="nav-link nav-link2 ml-n2"
              to="/user/admin/users"
              :class="{ 'vm-link': subIsActive('/user/admin/users') }"
            >
              <div class="cont pl-1">
                <div class="d-flex justify-content-start link-item">
                  <span>
                    <i class="bx bx-group fa-2x"></i>
                  </span>
                  <span class="mt-1 ml-3">Users</span>
                </div>
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              exact-active-class="vm-link"
              class="nav-link nav-link2 ml-n2"
              to="/user/admin/roles"
              :class="{ 'vm-link': subIsActive('/user/admin/roles') }"
            >
              <div class="cont pl-1">
                <div class="d-flex justify-content-start link-item">
                  <span>
                    <i class="bx bx-cog fa-2x"></i>
                  </span>
                  <span class="mt-1 ml-3">Roles</span>
                </div>
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              exact-active-class="vm-link"
              class="nav-link nav-link2 ml-n2"
              to="/user/admin/audit_trail"
              :class="{ 'vm-link': subIsActive('/user/admin/audit_trail') }"
            >
              <div class="cont pl-1">
                <div class="d-flex justify-content-start link-item">
                  <span>
                    <i class="bx bx-notepad fa-2x"></i>
                  </span>
                  <span class="mt-1 ml-3">Audit Trail</span>
                </div>
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              exact-active-class="vm-link"
              class="nav-link nav-link2 ml-n2"
              to="/user/admin/workflows"
              :class="{ 'vm-link': subIsActive('/user/admin/workflows') }"
            >
              <div class="cont pl-1">
                <div class="d-flex justify-content-start link-item">
                  <span>
                    <i class='bx bxs-file-export fa-2x'></i>
                  </span>
                  <span class="mt-1 ml-3">Workflows</span>
                </div>
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              exact-active-class="vm-link"
              class="nav-link nav-link2 ml-n2"
              to="/user/admin/divisions"
              :class="{ 'vm-link': subIsActive('/user/admin/divisions') }"
            >
              <div class="cont pl-1">
                <div class="d-flex justify-content-start link-item">
                  <span>
                    <i class='bx bx-list-ul fa-2x'></i>
                  </span>
                  <span class="mt-1 ml-3">Departments</span>
                </div>
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              exact-active-class="vm-link"
              class="nav-link nav-link2 ml-n2"
              to="/user/admin/institutions"
              :class="{ 'vm-link': subIsActive('/user/admin/institutions') }"
            >
              <div class="cont pl-1">
                <div class="d-flex justify-content-start link-item">
                  <span>
                    <i class='bx bxs-institution fa-2x'></i>
                  </span>
                  <span class="mt-1 ml-3">Institutions</span>
                </div>
              </div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              exact-active-class="vm-link"
              class="nav-link nav-link2 ml-n2"
              to="/user"
            >
              <div class="cont pl-1">
                <div class="d-flex justify-content-start link-item">
                  <span>
                    <i class='bx bxs-user fa-2x'></i>
                  </span>
                  <span class="mt-1 ml-3">Project Manager</span>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import logo from "../../../assets/images/logo.png";
import avatar from "../../../assets/images/icons/user.png";
export default {
  name: "side-pane",
  components: {},
  data() {
    return {
      logo: logo,
      avatar: avatar,
      title: this.$store.state.manager.title,
      isActive: this.$store.state.manager.isActive,
      state: {
        loading: false
      }
    };
  },
  watch: {
    isActive(){
      this.$store.dispatch("manager/setSidebarAction", true);
      this.isActive=this.$store.state.manager.isActive;
    }
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    all_numbers(){
      return this.$store.state.numbers;
    },
    keywords(){
      return this.$store.state.current_program ? this.$store.state.current_program.keyWords : null;
    },
    user(){
      return this.$store.state.user_data.user;
    },
    role(){
      return this.$store.state.user_data.role;
    },
  },
  beforeMount(){
    this.getNumbers();
    this.getCurrentProgram();
  },
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
   async getNumbers() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getNumbers", config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setNumbers", response.data);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
     await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if(response.data){
            this.state.loading = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
          }
        })
        .catch(() => {
          this.state.loading = false;
          // this.$notification["error"]({
          //   message: "ERROR!",
          //   description: `${error.errorMessage}`,
          //   duration: 5,
          // });
        });
    },
    sideMenuActions(){
      this.isActive=!this.isActive;
    }
  },
};
</script>

<style>
.nav-link2 {
  color: #fff !important;
}

.vm-link .cont {
  border-left: 4px solid #0fa958 !important;
}

.vm-link {
  color: #0fa958 !important;
  border-radius: 0px !important;
}

.vm-link .link-item {
  color: #0fa958 !important;
  background: linear-gradient(
      0deg,
      rgba(165, 165, 165, 0.2),
      rgba(165, 165, 165, 0.2)
    ),
    linear-gradient(0deg, #031030, #031030);
  padding: 0.36rem 0.2rem !important;
  border-radius: 10px !important;
}

.logo {
  width: 50%;
  height: 90%;
}
.avatar {
  width: 50%;
  height: 50%;
}
.nav-top {
  background-color: #fff !important;
  width: 241px;
  height: 50px !important;
  margin: 0 !important;
  margin-left: -2rem !important;
  margin-top: -0.53rem !important;
  margin-bottom: -0.5rem !important;
}

.sidebar {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  z-index: 111;
  padding: 20px 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 15.625rem;
  border-bottom-right-radius: 10px !important;
  background-image: linear-gradient(180deg, white 8%, #031030 8%) !important;
}

.sidebar-hidden{
  position: fixed !important;
  top: 0;
  left: -100% !important;
  transition: 0.6s ease-out !important;
}

.sidebar .nav-item {
  width: 100%;
}

.right-icons {
  margin-left: 47rem !important;
}

@media (min-width: 250px) {
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (max-width: 600px) {
  .nav-top {
    width: 16.563rem !important;
  }

}

@media (min-width: 1280px) {
   .btn-humburger{
     display: none;
  }

  .nav-pills{
    margin-top: 3rem !important;
  }

  .sidebar-hidden{
    position: fixed !important;
    top: 0;
    left: 0 !important;
    transition: 0.4s ease-in !important;
  }
}
</style>