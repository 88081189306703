<template>
  <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.20502 1.00006C4.20502 0.86199 4.09309 0.750061 3.95502 0.750061L1.70502 0.750061C1.56695 0.750061 1.45502 0.86199 1.45502 1.00006C1.45502 1.13813 1.56695 1.25006 1.70502 1.25006L3.70502 1.25006V3.25006C3.70502 3.38813 3.81695 3.50006 3.95502 3.50006C4.09309 3.50006 4.20502 3.38813 4.20502 3.25006L4.20502 1.00006ZM1.17678 4.13185L4.13179 1.17684L3.77824 0.823284L0.823223 3.7783L1.17678 4.13185Z" fill="#238957"/>
    </svg>
</template>

<script>
export default {
    name: "arrow-icon",
}
</script>

<style>

</style>