<template>
  <b-row>
    <div class="col-sm-5 col-md-5 text-left main-wrapper col-lg-5">
      <form>
        <b-row>
          <b-col>
            <div class="form-group">
              <label>Report Title</label>
              <input
                placeholder="Enter title"
                type="text"
                class="form-control"
                v-model="title"
                required
                v-bind:class="validations.title ? 'is-invalid' : null"
                @keyup="onchangeTitle"
              />
              <small v-if="validations.title" class="text-danger">
                Report title is required.
              </small>
            </div>
            <b-form-group>
              <label>Deadline</label>
              <input
                type="datetime-local"
                class="form-control"
                @change="onChangeDate"
                v-bind:class="validations.deadline ? 'is-invalid' : null"
                v-model="deadline"
              />
              <small v-if="validations.deadline" class="text-danger">
                Report deadline is required.
              </small>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </div>
  </b-row>
</template>

<script>
import moment from "moment";
import decode from "jwt-decode";
import encode from "jwt-encode";
export default {
  props: ["store"],
  data() {
    return {
      defaultDate: moment(new Date()).format("YYYY-MM-DD"),
      title: null,
      deadline: null,
      validations: {
        title: false,
        deadline: false,
      },
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    rep() {
      const results = this.$store.state.report_data;
      return results ? results : null;
    },
    report() {
      const data = sessionStorage.getItem("report");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return decoded;
    },
    currentSaved() {
      const data = sessionStorage.getItem("repcache");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.rep ? this.rep : decoded;
    },
  },
  beforeMount() {
    this.getCurrentSaved();
  },
  methods: {
    getCurrentSaved() {
      this.title =
        this.currentSaved !== null ? this.currentSaved.data.Title : null;
      this.from =
        this.currentSaved !== null ? this.currentSaved.data.DeadLine : null;
    },
    nextStep() {
      if (!this.title) {
        this.$emit("error", "Report title is required.");
        this.validations.title = true;
        this.$message.error("Report title is required.");
      } else if (!this.deadline) {
        this.$emit("error", "Report deadline is required.");
        this.validations.deadline = true;
        this.$message.error("Report deadline is required.");
      } else {
        const config = {
          headers: {
            Authorization: `Bearer ${this.token}`,
            Accept: "application/json",
          },
        };
        const requestData = {
          Title: this.title,
          DeadLine: moment(this.deadline).format("YYYY-MM-DD HH:mm:ss"),
        };
        if (this.currentSaved ? this.currentSaved.isSavedStep1 : false) {
          this.$emit("can-continue");
        } else {
          this.$emit("loading", true);
          this.axios
            .post("AddReport", requestData, config)
            .then((response) => {
              if (response.data) {
                const currentSavedReq = {
                  data: requestData,
                  reportData: response.data,
                  isSavedStep1: true,
                };
                this.$emit("loading", false);
                const secret =
                  "tokenHashingProDet--afr0-1200-&$#23',.6^^^^***x";
                const jwt = encode(currentSavedReq, secret);
                sessionStorage.setItem("repcache", jwt);
                this.$store.dispatch("setReport", currentSavedReq);
                this.getCurrentSaved();
                this.$emit("can-continue");
                this.$notification["success"]({
                  message: "SUCCESS!",
                  description: `Report data saved.`,
                  duration: 5,
                });
              } else {
                this.$emit("loading", false);
                this.$message.error(
                  `${
                    response.data.message
                      ? response.data.message
                      : "An error occured while saving report."
                  }`,
                  4
                );
              }
            })
            .catch((error) => {
              this.$notification["error"]({
                message: "ERROR!",
                description: `${error.errorMessage}`,
                duration: 7,
              });
              this.$emit("loading", false);
            });
        }
      }
    },
    onchangeTitle() {
      if (this.title != null || this.title != "") {
        this.validations.title = false;
      } else {
        this.validations.title = true;
      }
    },
    onChangeDate() {
      if (this.deadline) {
        this.validations.deadline = false;
      } else {
        this.validations.deadline = true;
      }
    },
  },
};
</script>

<style scoped>
.main-wrapper .form-group label,
.main-wrapper .form-group input::placeholder,
.form-text,
.form-text::placeholder,
.form-text-label,
.form-control,
.custom-select {
  font-size: 13px !important;
}
</style>
