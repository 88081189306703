<template>
  <b-row class="row-chain">
    <div class="col-sm col-md col-lg">
      <div
        class="nav flex-column nav-result-chain"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <b-container class="my-4" v-if="state.loading">
          <a-skeleton active :loading="state.loading" :paragraph="{rows: 4}" ></a-skeleton>
        </b-container>
        <a
            v-else-if="levels.length"
            v-for="(level, i) in levels"
            :key="i"
            class="tab-left nav-link text-capitalize"
            :id="removeSpace(level.level)"
            @click="onChangeLevel(level)"
            :class="
              levelItem
                ? level.id == levelItem.id
                  ? 'active'
                  : null
                : levels[0].id == level.id
                ? 'active'
                : null"
            :style="[
              levelItem
                ? level.id == levelItem.id
                  ? {
                      color: `${level.color} !important`,
                      borderLeft: `4px solid ${level.color} !important`,
                    }
                  : null
                : levels[0].id == level.id
                ? {
                    color: `${level.color} !important`,
                    borderLeft: `4px solid ${level.color} !important`,
                  }
                : null,
            ]"
            data-toggle="pill"
            href="#v-pills-social-impact"
            role="tab"
            :aria-controls="`v-pills-${removeSpace(level.level)}`"
            aria-selected="true"
            >{{ level.level }}</a
          >
        <b-container class="my-4" v-else>
            <i class="fas fa-exclamation-triangle text-red"></i>
            <span class="text-13 ml-2 text-red">
               {{
                keywords
                  ? keywords.resulchain
                    ? `${keywords.resultchain} data not found.`
                    : "Result chain data not found."
                  : "Result chain data not found."
              }}
            </span>
        </b-container>
        <a
          @click="createChainModal"
          class="btn btn-new-key btn-primary pl-5 mt-5 mb-3 pr-5 text-white"
          >New Level
        </a>

        <router-link :to="{name:'export-pillar-rc'}" class="btn btn-new-key my-3 btn-outline-primary pl-5 mb-3 pr-5 text-dark"
          >Preview
        </router-link>
      </div>
    </div>
    <div class="col-sm-9 col-md-9">
      <div class="tab-content" id="v-pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="v-pills-social-impact"
          role="tabpanel"
          aria-labelledby="v-pills-social-impact-tab"
        >
          <b-row>
            <div class="col-sm-10 col-md-10">
              <b-row>
                <b-container class="my-5" v-if="state.loadElem">
                  <b-spinner type="grow" small></b-spinner>
                  <span class="text-13 ml-2">Loading for boxes...</span>
                </b-container>
                <b-container class="my-5" v-else-if="elementsNum <= 0">
                  <span> No boxes found at this result chain level. </span>
                </b-container>
                <div
                    v-else
                    v-for="(el, j) in elements"
                    :key="j"
                    class="col-sm-6 col-md-6"
                  >
                    <div class="card card-chain">
                      <div
                        :style="[
                          { backgroundColor: `${levelItem.color} !important` },
                        ]"
                        class="card-header">
                        <a-tooltip
                          title="Contribution of boxes"
                          placement="top"
                        >
                          <b-icon-gear-fill scale="2" @click="showContribute(el)" class="text-white cursor-pointer float-right"></b-icon-gear-fill>
                        </a-tooltip>
                      </div>
                      <div class="card-body cb-scroll">
                        <p class="text-left text-13">
                          <strong>{{ el.element }}:</strong>
                          {{ el.Description }}
                        </p>
                      </div>
                    </div>
                  </div>
              </b-row>
            </div>
            <div class="col-sm-2 col-md-2">
              <a
                @click="createKeyModal"
                class="
                  btn btn-new-key btn-primary
                  pl-2
                  btn-sm
                  text-13
                  pr-2
                  text-white
                "
                >Add Box</a
              >
            </div>
          </b-row>
        </div>
      </div>
    </div>
    <b-modal v-model="visibleContribute" hide-footer size="xl" title="Contributions settings">
      <form @submit.prevent="addContribution">
        <b-row>
          <b-col cols="4">
            <div class="form-group">
                <b-input-group>
                  <b-select
                    class="custom-select bg-white text-13"
                    required
                    :disabled="state.loading"
                    v-model="cont.level"
                    @change="onchangeContribute"
                  >
                    <template #first>
                      <b-select-option :value="null" selected disabled>
                        {{
                          keywords
                            ? keywords["theory of change"]
                              ? `Select ${keywords["theory of change"]}`
                              : "Select theory of change."
                            : "Select theory of change"
                        }}
                      </b-select-option>
                    </template>
                    <b-select-option
                      v-for="item in levels"
                      :key="item.id"
                      :value="item"
                    >
                      {{ item.level }}
                    </b-select-option>
                  </b-select>
                  <b-input-group-append>
                    <b-icon-arrow-repeat
                      class="bi-con-right ml-1"
                      font-scale="1.5"
                      :animation="state.loading ? 'spin' : ''"
                      @click="getResultChainLevels"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
              <b-form-group>
                <b-input-group>
                <b-select
                  class="custom-select bg-white text-13"
                  required
                  :disabled="state.loadElem"
                  v-model="cont.element"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled>
                      {{
                        keywords["theory of change"]
                          ? `Select ${keywords["theory of change"]} box`
                          : "Select TOC box"
                      }}
                    </b-select-option>
                  </template>
                  <b-select-option
                    v-for="item in elements"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.element }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right ml-1"
                    font-scale="1.5"
                    :animation="state.loadElem ? 'spin' : ''"
                    @click="getResultChainLevels"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
        <h6 class="text-13">Boxes Contribution</h6>
          <div class="b-bottom w-25 my-2"></div>
          <b-row class="my-2">
            <div class="col-sm col-md"></div>
            <div class="col-sm-8 col-md-8">
              <form>
                <div class="input-group mb-2">
                  <input
                    type="search"
                    v-model="filter"
                    class="form-control input-search rounded-left-10"
                    aria-label="Search"
                    placeholder="Search contributions..."
                  />
                  <b-input-group-append is-text>
                    <b-icon-search scale="0.95" class="text-success"></b-icon-search>
                  </b-input-group-append>
                </div>
              </form>
            </div>
          </b-row>
          <b-row class="mb-2">
            <div class="col-sm-9 col-md-9"></div>
            <div class="col-sm col-md text-right">
              <button
                @click="refresh"
                type="button"
                class="btn btn-secondary btn-sm rounded-10 border-none"
              >
                <b-container v-if="state.loadCont && state.refresh">
                  <b-spinner small></b-spinner>
                  <span class="text-13 ml-2">Refreshing...</span>
                </b-container>
                <span v-else class="text text-13">Refresh</span>
              </button>
            </div>
          </b-row>
          <div class="table-responsive mt-2">
            <b-container v-if="state.loadCont">
              <a-skeleton
                :loading="state.loadCont"
                :paragraph="{ rows: 4 }"
                active
              ></a-skeleton>
            </b-container>
            <b-table
              v-else
              table-class="table table-knowledge rounded-20"
              thead-tr-class="theader-knowledge tb-head text-left indent-20"
              tbody-class="tb-body text-left indent-20"
              :items="contributions"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              show-empty
            >
              <template v-slot:cell(contributions)="data">
                <span>{{ data.item.contributions }}</span>
              </template>
            </b-table>
          </div>
          <b-row class="my-4">
            <div class="col-sm-8 col-md-8"></div>
            <div class="col-sm col-md">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                prev-class="text-13"
                next-class="text-13"
                first-class="text-13"
                last-class="text-13"
              ></b-pagination>
            </div>
          </b-row>
          </b-col>
        </b-row>
        <button
          type="submit"
          class="btn btn-success btn-sm btn-confirm btn-block"
        >
          <b-container v-if="state.contribute">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Saving...</span>
          </b-container>
          <span v-if="!state.contribute" class="text text-13">Submit</span>
        </button>
      </form>
    </b-modal>
    <a-modal v-model="visibleChain" :footer="null" title="New result chain level">
      <form @submit.prevent="addLevels">
        <b-row>
          <div class="col-sm-12 col-md-12">
            <b-row>
              <div class="col-sm-7 col-md-7">
                <div class="form-group">
                  <input
                    placeholder="Level name"
                    type="text"
                    class="form-control form-input-modal"
                    v-model="level"
                    required
                  />
                </div>
              </div>
              <div class="col-sm col-md">
                <div class="form-group">
                  <input
                    placeholder="Abbreviation"
                    type="text"
                    class="form-control form-input-modal"
                    v-model="abbr"
                    required
                  />
                </div>
              </div>
            </b-row>
            <b-row class="mb-3">
              <div class="col-sm col-md">
                <select required v-model="levelNumber" class="custom-select w-100 form-input-modal indent-20 rounded-20 ">
                  <option :value="null" disabled selected> Level number </option>
                  <option v-for="(item,i) in levelNumbers" :key="i" :value="item.id">{{item.name}}</option>
                </select>
              </div>
            </b-row>
            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <b-form-textarea
                  id="desc"
                  placeholder="Description"
                  rows="3"
                  resize
                  class="form-text-modal"
                  v-model="description"
                  required
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="form-group">
              <b-row>
                <div class="col-sm col-md">
                  <label>Select color</label>
                  <div class="d-flex justify-content-around">
                    <button
                      type="button"
                      @click="color1('#ff5e00f6')"
                      class="btn-color orange p-3 h-50 mt-2 border-none"
                    ></button>
                    <button
                      @click="color1('#ffbb00')"
                      type="button"
                      class="btn-color orange-yellow p-3 h-50 mt-2 border-none"
                    ></button>
                    <button
                      @click="color1('#C4FF61')"
                      type="button"
                      class="btn-color green-yellow p-3 h-50 mt-2 border-none"
                    ></button>
                    <button
                      @click="color1('#0077ff')"
                      type="button"
                      class="btn-color blue p-3 h-50 mt-2 border-none"
                    ></button>
                    <button
                      type="button"
                      @click.prevent="openPicker"
                      class="m-2 btn btn-light btn-custom-color rounded-10"
                    >
                      Custom color
                    </button>
                  </div>
                </div>
              </b-row>
              <transition name="fade">
                <div v-if="visiblePicker" class="">
                  <div class="input-group color-picker" ref="colorpicker">
                    <input
                      type="text"
                      class="form-control"
                      v-model="colorValue.hex"
                      @focus="showPicker()"
                      @input="updateFromInput"
                    />
                    <span class="input-group-addon color-picker-container">
                      <span
                        class="current-color"
                        :style="'background-color: ' + colorValue"
                        @click="togglePicker()"
                      ></span>
                      <picker
                        :value="colors"
                        v-model="colorValue"
                        v-if="displayPicker"
                      />
                    </span>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-around">
          <div class="inline-block">
            <button
              type="submit"
              class="btn text-13 btn-primary rounded-20 btn-block btn-confirm"
            >
              <b-container v-if="state.loadLevel">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Saving...</span>
              </b-container>
              <span v-if="!state.loadLevel" class="text text-13">OK</span>
            </button>
          </div>
          <div class="inline-block">
            <a
              @click="cancelChainModal"
              type="button"
              class="btn text-13 btn-secondary rounded-20 btn-block btn-cancel"
              >CANCEL</a
            >
          </div>
        </b-row>
      </form>
    </a-modal>
    <a-modal v-model="visibleCreateKey" :footer="null" title="New result chain box">
      <form @submit.prevent="addElement(levelItem)">
        <b-row>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <input
                placeholder="Box title"
                type="text"
                class="form-control form-input-modal"
                v-model="element.name"
                required
              />
            </div>
            <div class="form-group">
              <b-input-group>
                <b-select
                  class="
                    custom-select
                    select-modal
                    rounded-20
                    text-13
                    bg-gray-white
                  "
                  v-model="rce"
                  required
                  :disabled="state.loadLevel"
                  @change="onchangeToc"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled>Select result chain level</b-select-option>
                  </template>
                  <b-select-option
                    v-for="item in levels"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.level }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right"
                    font-scale="1.5"
                    :animation="state.loading ? 'spin' : ''"
                    @click="getResultChainLevels"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <b-form-textarea
                  id="desc"
                  placeholder="Description"
                  rows="3"
                  resize
                  class="form-text-modal"
                  required
                  v-model="element.description"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <div class="inline-block pr-4">
            <button
              type="submit"
              class="btn text-13 btn-primary rounded-20 btn-block btn-confirm"
            >
              <b-container v-if="state.loadElem">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Saving...</span>
              </b-container>
              <span v-if="!state.loadElem" class="text text-13">OK</span>
            </button>
          </div>
          <div class="inline-block">
            <a
              @click="cancelKeyModal"
              type="button"
              class="btn btn-secondary btn-block text-13 btn-cancel"
              >CANCEL</a
            >
          </div>
        </b-row>
      </form>
    </a-modal>
  </b-row>
</template>

<script>
import decode from "jwt-decode";
import { Chrome } from "vue-color";
import axios from 'axios';
export default {
  props: ["store"],
  components: {
    picker: Chrome,
  },
  data() {
    return {
      visibleCreateKey: false,
      visibleChain: false,
      color: "",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      visiblePicker: false,
      levels: [],
      state: {
        loading: false,
        loadLevel: false,
        loadElem: false,
        refresh: false,
        loadCont: false,
        contribute: false
      },
      level: "",
      abbr: "",
      description: "",
      levelItem: null,
      element: {
        name: "",
        description: "",
      },
      elements: [],
      rce: null,
      colors: {
        hex: "",
      },
      colorValue: "",
      displayPicker: false,
      levelNumbers: [
        {id:1, name: 'Level 1'},
        {id:2, name: 'Level 2'},
        {id:3, name: 'Level 3'},
        {id:4, name: 'Level 4'},
        {id:5, name: 'Level 5'},
        {id:6, name: 'Level 6'},
        {id:7, name: 'Level 7'},
        {id:8, name: 'Level 8'},
        {id:9, name: 'Level 9'},
        {id:10, name: 'Level 10'}
      ],
      levelNumber: null,
      contributions: null,
      visibleContribute: false,
      element1: null,
      cont: {
        level: null,
        element: null,
      },
      filter:"",
      perPage: 5,
      currentPage: 1,
      data: null,
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    pillar(){
      const storeData = sessionStorage.getItem("pl")
      const pillarData = this.$store.state.pillar_data;
      var decoded = null;
      if(storeData){
        decoded = decode(storeData)
      }
      return pillarData ? pillarData : decoded;
    },
    drafted(){
      var decoded = null;
      const storeData = sessionStorage.getItem("drafted-pillar")
      if(storeData){
        decoded = decode(storeData)
      }
      return decoded;
    },
    finPillar(){
      return this.pillar ? this.pillar : this.drafted ? this.drafted : null;
    },
    elementsNum() {
      return this.elements.length;
    },
    rows(){
      return this.contributions ? this.contributions.length : 0;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    user(){
          return this.$store.state.user_data.user;
        },
  },
  beforeMount() {
    this.getResultChainLevels();
    console.log("Drafed pillar", this.drafted)
    console.log("pl", this.finPillar);
  },
  mounted() {
    this.setColor(this.color || "");
  },
  watch: {
    levels: function () {
      this.onChangeLevel(this.levels[0]);
    },
    rce: function () {
      // console.log("TOC ID", this.toc);
    },
    colorValue(){
      this.colors.hex=this.colorValue.hex;
    },
  },
  methods: {
    async addContribution() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        source_toc_element: this.element1,
        target_toc_element: this.cont.element,
        created_by: this.user.id,
      };
      this.state.contribute = true;
      await this.axios
        .post("addContribution", requestData, config)
        .then((response) => {
          if (!response.data.message) {
            this.state.contribute = false;
            this.getContributionData(this.data)
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Contribution added successfully.`,
              duration: 5,
            });
          } else {
            this.$message.error(`${response.data.message}`, 4);
            this.state.contribute = false;
          }
        })
        .catch(() => {
          this.state.contribute = false;
        });
    },
     async getContributionData(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
        params: {
          "Type":"pillar"
        }
      };
      this.state.loadCont = true;
      await axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}getContributions/${param.id}`,config)
        .then((response) => {
          if (response.data) {
            this.state.loadCont = false;
            this.contributions = response.data;
          } else {
            this.state.loadCont = false;
          }
        })
        .catch(() => {
          this.state.loadCont = false;
        });
    },
    async addLevels() {
     const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        level: this.level,
        Description: this.description,
        abbraviation: this.abbr,
        color: this.colors.hex,
        pillarId: this.finPillar.id,
        levelNumber: this.levelNumber
      };
      if (this.colors.hex==''){
        this.$notification["error"]({
          message: "REQUIRED ERROR!",
          description: `Please color choosing is required.`,
          duration: 6,
        });
      }else {
      this.state.loadLevel = true;
      await this.axios
        .post("AddResultChain", requestData, config)
        .then((response) => {
          if (response.data) {
            this.state.loadLevel = false;
            this.visibleChain = false;
            this.getResultChainLevels();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Pillar result chain level has been added successfully.`,
              duration: 5,
            });
            this.colors.hex = ""
            this.colorValue = ''
            this.colorValue.hex = ''
            this.levelNumber = null
            this.level = ""
            this.description = '' 
            this.abbr = ''
          } else {
            this.$message.error(`Something wrong when adding level.`, 4);
            this.state.loadLevel = false;
          }
        })
        .catch(() => {
          this.state.loadLevel = false;
        });
      }
    },
    async addElement() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        element: this.element.name,
        Description: this.element.description,
        RCId: this.rce,
      };
      this.state.loadElem = true;
      await this.axios
        .post("AddresultchainElement", requestData, config)
        .then((response) => {
          if (response.data) {
            this.state.loadElem = false;
            this.visibleCreateKey = false;
            this.getResultChainElement(this.levelItem);
            this.element.name='';
            this.element.description=''
            this.level=''
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Level element added successfully.`,
              duration: 5,
            });
          } else {
            this.$message.error(`Something wrong when adding level.`, 4);
            this.state.loadElem = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loadElem = false;
        });
    },
    async getResultChainElement(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadElem = true;
      await this.axios
        .get(`getResultChainElements/${param.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadElem = false;
            this.elements = response.data;
          } else {
            this.state.loadElem = false;
          }
        })
        .catch(() => {
          this.state.loadElem = false;
        });
    },
    removeSpace(title) {
      return title.replace(/\s/g, "-");
    },
    onChangeLevel(value) {
      this.levelItem = value;
      if (value) this.getResultChainElement(value);
    },
    onchangeContribute(value){
      this.getResultChainElement(value);
    },
    onchangeToc() {
      // console.log("TOC value", value)
    },
    async getResultChainLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getResultChainLevels/${this.finPillar.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.levels = response.data;
            console.log("Levels", this.levels);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    createKeyModal() {
      this.visibleCreateKey = true;
    },
    createChainModal() {
      this.visibleChain = true;
    },
    cancelKeyModal() {
      this.visibleCreateKey = false;
    },
    cancelChainModal() {
      this.visibleChain = false;
    },
    openPicker() {
      this.visiblePicker = !this.visiblePicker;
    },
    changeColor(color) {
      const {
        rgba: { r, g, b, a },
      } = color;
      this.color = `rgba(${(r, g, b, a)})`;
      console.log("Selected color", this.color);
    },
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if (color.slice(0, 1) == "#") {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == "rgba") {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(","),
          hex =
            "#" +
            (
              (1 << 24) +
              (parseInt(rgba[0]) << 16) +
              (parseInt(rgba[1]) << 8) +
              parseInt(rgba[2])
            )
              .toString(16)
              .slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener("click", this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener("click", this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue =
          "rgba(" +
          color.rgba.r +
          ", " +
          color.rgba.g +
          ", " +
          color.rgba.b +
          ", " +
          color.rgba.a +
          ")";
      }
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
    color1(color) {
      this.colors.hex = color;
    },
    refresh(){
      this.state.refresh=true;
      this.getContributionData(this.data)
    },
    showContribute(params) {
      this.visibleContribute = true;
      this.data = params;
      this.element1 = params.id;
      this.getContributionData(params)
    },
  },
};
</script>

<style scoped>
*,
body {
  font-family: Ubuntu !important;
}

.card-chain {
  background: #e5e5e5 !important;
  border-radius: 12px !important;
  border: none !important;
  height: 90% !important;
  font-size: 13px !important;
  overflow-y: scroll;
  max-height: 200px;
}

.card-chain .card-header {
  background: #0fa958 !important;
  border: none !important;
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.table-indicators th {
  font-size: 13px !important;
  border-top: none !important;
}

.table-indicators td {
  font-size: 13px !important;
}

.card-2 {
  border: none !important;
  border-radius: 20px !important;
}

.btn-new-key {
  border-radius: 20px !important;
  font-size: 13px !important;
}

@media (min-width: 600px) {
  .card-chain {
    margin-right: 0 !important;
  }
  .row-chain {
    margin-right: 0 !important;
  }
}
</style>
