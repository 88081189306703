import admin from './admin.vue';
import {
    adminHome,
    usersPage,
    adminRoles,
    adminWorkflows,
    adminDivisions,
    adminAssignedRoles,
    adminWorkflowDetail,
    adminInstitution,
    adminProfile,
    auditTrail

} from './pages/index.js';

const routes = [
    {
        path: "/user/admin",
        component: admin,
        children: [
            {
                path: "",
                redirect: { name: "admin-homepage" },
                meta: { requireAuth: true },
            },
            {
                path:"main",
                name: "admin-homepage",
                component: adminHome,
                meta: { requireAuth: true }
            },
            {
                path: "users",
                name: "admin-users",
                component: usersPage,
                meta: { requireAuth: true } 
            }, 
            {
                path: "roles",
                name: "admin-roles",
                component: adminRoles,
                meta: { requireAuth: true }
            },
            {
                path: "audit_trail",
                name: "admin-audit_trail",
                component: auditTrail,
                meta: { requireAuth: true }
            },
            {
                path: "roles/assigned",
                name: "assign-roles",
                component: adminAssignedRoles,
                meta: { requireAuth: true }
            },
            {
                path: "workflows",
                name: "admin-workflows",
                component: adminWorkflows,
                meta: { requireAuth: true }
            },
            {
                path: "audit",
                name: "admin-audit-trail",
                component: auditTrail,
                meta: { requireAuth: true } 
            }, 
            {
                path: "workflows/single-workflow",
                name: "admin-workflows-single",
                component: adminWorkflowDetail,
                meta: { requireAuth: true }
            },
            {
                path: "divisions",
                name: "admin-divisions",
                component: adminDivisions,
                meta: { requireAuth: true }
            },
            {
                path: "institutions",
                name: "admin-institutions",
                component: adminInstitution,
                meta: { requireAuth: true }
            },
            {
                path: "profile",
                name: "admin-profile",
                component: adminProfile,
                meta: { requireAuth: true }
            }
        ]
    }
]

export default routes;