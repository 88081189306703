<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto card-main-sm col-md-10 pt-0">
      <div class="card card-main card-0 mt-4 mb-6 ml-0">
        <div class="card-body pl-0 ml-0">
          <b-row>
            <div class="col-sm-9"></div>
            <div class="col-sm">
              <form>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control input-search rounded-left-10"
                    aria-label="Search"
                    placeholder="Search ..."
                    v-model="filter"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right-10">
                      <i class="bx bx-search text-success"></i>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </b-row>
          <b-row class="mb-2">
            <div class="col-sm-9 col-md-9"></div>
            <div class="col-sm col-md text-right">
                <button
                @click="refresh"
                type="button"
                class="btn btn-secondary btn-sm rounded-10 border-none"
                >
                <b-container v-if="state.loading && state.refresh">
                    <b-spinner small></b-spinner>
                    <span class="text-13 ml-2">Refreshing...</span>
                </b-container>
                <span v-else class="text text-13">Refresh</span>
                </button>
            </div>
            </b-row>
          <b-row>
            <div class="col-sm col-md">
              <div class="table-responsive">
                <b-container v-if="state.loading">
                    <a-skeleton active :loading="state.loading" :paragraph="{rows: 8}" ></a-skeleton>
                </b-container>
                <b-table
                  v-else
                  table-class="table-approvers text-13 indent-10"
                  striped
                  hover
                  thead-tr-class="tb-header"
                  tbody-class="tb-body indent-10"
                  :items="data"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="fields"
                  show-empty
                >
                  <template v-slot:cell(file)="data">
                    <span>
                      <a :href="data.item.file" target="_blank" download="afr-narative-report-file">Report file</a>
                    </span>
                  </template> 
                  <template v-slot:cell(DisaggregationValues)="data">
                    <ul>
                      <li v-for="(e,i) in data.item.aggregationValues" :key="i">
                       <span> Title: </span> {{e.Title}} 
                       <div class="clearfix"></div>
                       <span> Value: </span> {{ e.value }}<hr>
                      </li>
                    </ul>
                  </template>        
                </b-table>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm-8"></div>
            <div class="col-sm">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="text-13"
              ></b-pagination>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import decode from 'jwt-decode';
import arrowIcon from "../../../components/icons/arrow.vue";
export default {
  component: {
    arrowIcon,
  },
  data() {
    return {
      pageTitle: "Report data",
      perPage: 6,
      currentPage: 1,
      filter: "",
      fields:["Milestone", "indicator", "initiatedBy", "value", "DisaggregationValues", "file"],
      state: {
          loading:false,
          refresh: false,
      },
      data: [],
    };
  },
  beforeMount() {
    this.$title("View report");
    this.getReportData()
  },
  computed: {
    rows() {
      return this.data.length;
    },
    report() {
      let project = sessionStorage.getItem("get-report");
      const resData = decode(project);
      return resData;
    },
    token() {
      return localStorage.getItem("token");
    },
  },
  methods: {
    async getReportData() {
      const config = {
        params: {
          Type: this.report.reportType,
          reportId: this.report.id
        }
      };
      this.state.loading = true;
      await this.axios
        .get(`getReported`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.data=response.data;
            console.log("Aggregations data", this.data);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    refresh(){
        this.state.refresh=true;
        this.getReportData()
    },
    cancel(e) {
      console.log(e);
      this.$message.error("Request has been cancelled.");
    },
  }
};
</script>

<style scoped lang="scss">
</style>