<template>
  <b-row>
    <div class="col-sm-5 col-md-5 main-wrapper col-lg-5 mr-5">
      <form @submit="nextStep">
        <div class="form-group text-left">
          <label for="">{{getKeyword('pillar')}} title</label>
          <input 
              placeholder="Title" 
              type="text"  
              class="form-control" 
              v-bind:class="validations.title ? 'is-invalid' : null"
              @keyup="onchangeTitle"
              v-model="title"
          />
          <small v-if="validations.title" class="text-danger text-left">
            {{`Program ${getKeyword('pillar')} title is required.` }}
          </small>
        </div>
        <div class="form-group text-left">
          <label for="">{{getKeyword('pillar')}} No</label>
          <input 
              placeholder="Number" 
              type="number"
              min="0"
              class="form-control"
              v-model="pillar_no"
          />
        </div>
        <div class="form-group">
          <label class="float-left text-capitalize">{{keywords ? keywords.pillar ? `Head of ${keywords.pillar}.` : 'Head of pillar.' : 'Head of pillar.' }}</label>
          <b-input-group>
            <b-select
              class="mr-2"
              v-model="user"
              required
              :disabled="state.loadUsers"
              v-bind:class="validations.headp ? 'is-invalid' : null"
              @change="onchangeHead"
            >
              <template #first>
                <b-select-option :value="null" selected disabled>{{`Select Head of ${getKeyword('pillar')}`}}</b-select-option>
              </template>
              <b-select-option
                v-for="item in users"
                :key="item.id"
                :value="item.id"
              >
                {{ item.fullnames }}
              </b-select-option>
            </b-select>
            <b-input-group-append>
              <b-icon-arrow-repeat
                class="bi-con-right"
                font-scale="1.5"
                :animation="state.loadUsers ? 'spin' : ''"
                @click="getUsers"
              />
            </b-input-group-append>
          </b-input-group>
          <small v-if="validations.headp" class="text-danger text-capitalize">   
            {{keywords ? keywords.pillar ? `Head of ${keywords.pillar} must be selected` : 'Head of pillar must be selected.':'Head of pillar must be selected.' }}
          </small>
        </div>
        <b-form-group
          class="form-text-label"
          label-for="desc"
        >
          <label class="float-left">Description</label>
          <b-form-textarea
            id="desc"
            placeholder="Enter description"
            rows="3"
            resize
            class="form-text"
            v-bind:class="validations.description ? 'is-invalid' : null"
            @keyup="onchangeDesc"
            v-model="description"
          ></b-form-textarea>
          <small v-if="validations.description" class="text-danger">
            {{ keywords ? keywords.pillar ? `Program ${keywords.pillar} description is required.` : 'Program pillar description is required.' : 'Program pillar description is required.' }}
          </small>
        </b-form-group>
        <b-form-group>
          <button @click.prevent="addPillar" type="submit" class="btn btn-primary rounded-20 float-left btn-sm">
            <b-container v-if="state.adding">
              <b-spinner small></b-spinner>
              <span class="text-13 ml-2">Saving...</span>
            </b-container>
            <span v-if="!state.adding" class="text text-13 text-capitalize">{{keywords.pillar ? `Add ${keywords.pillar}` : 'Add Pillar' }}</span>
          </button>
        </b-form-group>
      </form>
    </div>
    <div class="col-sm col-md col-lg">
      <div class="card card-2">
        <div class="card-body">
          <b-row class="mb-2">
            <div class="col-sm-9 col-md-9"></div>
            <div class="col-sm col-md text-right">
              <button
                @click="refresh"
                type="button"
                class="btn btn-secondary btn-sm rounded-10 border-none"
              >
                <b-container v-if="state.loadPillar && state.refresh">
                  <b-spinner small></b-spinner>
                  <span class="text-13 ml-2">Refreshing...</span>
                </b-container>
                <span v-else class="text text-13">Refresh</span>
              </button>
            </div>
          </b-row>
          <div class="table-responsive mt-2">
            <table  class="table table-indicators">
              <thead>
                <tr class="theader-indicators text-left">
                  <th>No</th>
                  <th class="">Title</th>
                  <th > <span>{{keywords ? keywords.pillar ? `Head of ${keywords.pillar}` : 'Head of Pillar' : 'Head of Pillar' }}</span> </th>
                  <th colspan="2">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-left" v-for="(el,i) in pillars" :key="i">
                  <td>{{el.pillar_no}}</td>
                  <td class="text-left"><span>{{el.Title}}</span> </td>
                  <td>{{el.resultchain}}</td>
                  <td><span @click="showPillarEdit(el)" class="cursor-pointer"><i class="fa fa-edit text-primary"></i></span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </b-row>
</template>

<script>
import encode from 'jwt-encode'
import decode from "jwt-decode";
export default {
  props: ["store"],
  data() {
    return {
      edit_pillar_id: null,
      formMode: 'add',
      title: "",
      pillar_no: "",
      description: null,
      users:[],
      state: {
        loadUsers: false,
        loadPillar: false,
        adding: false,
        refresh: false
      },
      validations: {
        title: false,
        headp: false,
        description: false
      },
      user: null,
      pillars: []
    };
  },
  computed: {
    currentSaved() {
      const data = sessionStorage.getItem("requested");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return decoded;
    },
    pillar(){
      return this.$store.state.pillar_data ? this.$store.state.pillar_data : null;
    },
    savedStep2() {
      const data = sessionStorage.getItem("s2");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.pillar ? this.pillar : decoded;
    },
    token(){
      return localStorage.getItem("token");
    },
     drafted(){
      const data = sessionStorage.getItem("draft")
      var decoded = null; 
      if(data){
        decoded = decode(data);
      }
      return decoded;
    },
    keywords(){
      return this.$store.state.current_program ? this.$store.state.current_program.keyWords : null;
    }
  },
  beforeMount(){
    this.getUsers();
    if(this.currentSaved){
      this.getPillar(this.currentSaved.programData.id);
    }else if(this.drafted){
      this.getPillar(this.drafted.id);
    }
  },
  watch: {},
  methods: {
    async addPillar() {
      if(this.edit_pillar_id){
        return this.editPillar()
      }
      const requestData = {
        Title: this.title,
        pillar_no: this.pillar_no,
        description: this.description,
        headOfPillar:this.user,
        programId: this.currentSaved ? this.currentSaved.programData.id : this.drafted ? this.drafted.id : 0
      };
      if (!this.title) {
        this.$emit("error", `${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} title is required.`);
        this.validations.title = true;
        this.$message.error(`${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} title is required.`);
      }else if(!this.user){
          this.$emit("error", `Head of ${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} is required.`);
          this.validations.headp = true;
          this.$message.error(`Head of ${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} is required.`);
      }else if(!this.description){
          this.$emit("error", `${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} description is required.`);
          this.validations.title = true;
          this.$message.error(`${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} description is required.`);
      }else{
        this.state.adding = true;
        await this.axios
          .post("AddpillarProgram", requestData)
          .then((response) => {
            if (response.data) {
              if(this.currentSaved){
                this.getPillar(this.currentSaved.programData.id);
              }else if(this.drafted){
                this.getPillar(this.drafted.id);
              }
              this.state.adding = false;
              const currentSavedReq = {
                  data: requestData,
                  isSavedStep2: true,
                  pillarData: response.data
                }
                const secret = 'tokenHashingProDet--afrt0-1300x';
                const jwt = encode(currentSavedReq,secret);
                sessionStorage.setItem("s2",jwt);
                this.$store.dispatch("setPillar", currentSavedReq);
              this.$notification["success"]({
                message: "SUCCESS!",
                description: `${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar :'Pillar')} Saved successful`,
                duration: 5,
              });
            } else {
              this.state.adding = false;
              this.$message.error(`Something went wrong while adding program pillar.`, 4);

            }
          })
          .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.adding = false;
        });
      }
    },
    

    showPillarEdit(pillarData){
      this.edit_pillar_id= pillarData.id
      this.title = pillarData.Title
      this.pillar_no = pillarData.pillar_no
      this.description = pillarData.description
      this.user = pillarData.head_of_pillar_id
    },

    async editPillar() {
        const requestData = {
          Title: this.title,
          pillar_no: this.pillar_no,
          description: this.description,
          headOfPillar:this.user,
          programId: this.currentSaved ? this.currentSaved.programData.id : this.drafted ? this.drafted.id : 0
        };
      if (!this.title) {
        this.$emit("error", `${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} title is required.`);
        this.validations.title = true;
        this.$message.error(`${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} title is required.`);
      }else if(!this.user){
          this.$emit("error", `Head of ${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} is required.`);
          this.validations.headp = true;
          this.$message.error(`Head of ${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} is required.`);
      }else if(!this.description){
          this.$emit("error", `${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} description is required.`);
          this.validations.title = true;
          this.$message.error(`${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar : 'Pillar')} description is required.`);
      }else{
        this.state.adding = true;
        await this.axios
          .put(`pillar/${this.edit_pillar_id}`, requestData)
          .then((response) => {
            if (response.data) {
              if(this.currentSaved){
                this.getPillar(this.currentSaved.programData.id);
              }else if(this.drafted){
                this.getPillar(this.drafted.id);
              }
              this.state.adding = false;
              const currentSavedReq = {
                  data: requestData,
                  isSavedStep2: true,
                  pillarData: response.data
                }
                const secret = 'tokenHashingProDet--afrt0-1300x';
                const jwt = encode(currentSavedReq,secret);
                sessionStorage.setItem("s2",jwt);
                this.$store.dispatch("setPillar", currentSavedReq);
              this.$notification["success"]({
                message: "SUCCESS!",
                description: `${this.capitalizeFirstLetter(this.keywords.pillar ? this.keywords.pillar :'Pillar')} Saved successful`,
                duration: 5,
              });
            } else {
              this.state.adding = false;
              this.$message.error(`Something went wrong while adding program pillar.`, 4);

            }
          })
          .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.adding = false;
        });
      }
    },

    async getPillar(param){
      this.state.loadPillar = true;
      await this.axios
        .get(`getprogramPillar/${param}`)
        .then((response) => {
          if (response.data) {
            this.state.loadPillar = false;
            this.pillars = response.data;
          } else {
            this.state.loadPillar = false;
          }
        })
        .catch(() => {
          this.state.loadPillar = false;
        });
    },
    nextStep() {
      if (!this.title && !this.pillars.length) {
        this.$emit("error", "Pillar title is required.");
        this.validations.title = true;
        this.$message.error("Pillar title is required.");
      }else if(!this.user && !this.pillars.length){
          this.$emit("error", "Head of pillar is required.");
          this.validations.headp = true;
          this.$message.error("Head of pillar is required.");
      }else if(!this.description && !this.pillars.length){
          this.$emit("error", "Pillar description is required.");
          this.validations.title = true;
          this.$message.error("Pillar description is required.");
      }else{
        const requestData = {
          Title: this.title,
          pillar_no: this.pillar_no,
          description: this.description,
          headOfPillar:this.user,
          programId: this.currentSaved ? this.currentSaved.programData.id : this.drafted ? this.drafted.id : 0

        };

        if(this.savedStep2 || this.drafted){
          this.$emit("can-continue");
        }else{
        this.$emit("loading", true);
        this.axios
          .post("AddpillarProgram", requestData)
          .then((response) => {
            if (response.data) {
              const currentSavedReq = {
                data: requestData,
                isSavedStep2: true,
                pillarData: response.data
              }
              if(this.currentSaved){
                this.getPillar(this.currentSaved.programData.id);
              }else if(this.drafted){
                this.getPillar(this.drafted.id);
              }
              this.$emit("loading", false);
              const secret = 'tokenHashingProDet--afrt0-1300x';
              const jwt = encode(currentSavedReq,secret);
              sessionStorage.setItem("s2",jwt);
              this.$emit("can-continue");
              this.$notification["success"]({
                message: "SUCCESS!",
                description: `Program pillar data saved.`,
                duration: 5,
              });
            } else {
              this.$emit("loading", false);
              this.$message.error(`An error occured while saving program details`, 4);
            }
          })
          .catch((error) => {
            this.$notification["error"]({
              message: "ERROR!",
              description: `${error.errorMessage}`,
              duration: 7,
            });
            this.$emit("loading", false);
          });
        }
      }
    },
     async getUsers() {
      this.state.loadUsers = true;
      await this.axios
        .get("getUsers")
        .then((response) => {
          if (response.data) {
            this.state.loadUsers = false;
            this.users = response.data;
          } else {
            this.state.loadUsers = false;
          }
        })
        .catch(() => {
          this.state.loadUsers = false;
        });
    },
    onchangeTitle(){
      if(this.title!=null || this.title!=''){
        this.validations.title=false;
      }else{
        this.validations.title=true;
      }
    },
    onchangeHead(){
      if(this.user!=null || this.user!=''){
        this.validations.headp=false;
      }else{
        this.validations.headp=true;
      }
    },
    refresh(){
      this.refresh=true;
      if(this.currentSaved){
        this.getPillar(this.currentSaved.programData.id);
      }else if(this.drafted){
        this.getPillar(this.drafted.id);
      }
    },
    onchangeDesc(){
      if(this.description!=null || this.description!=''){
        this.validations.description=false;
      }else{
        this.validations.description=true;
      }
    },
  },
};
</script>

<style scoped>
.main-wrapper .form-group label,
.main-wrapper .form-group input::placeholder,
.form-text,
.form-text::placeholder,
.form-text-label,
.form-control,
.custom-select {
  font-size: 13px !important;
}

.table-indicators th {
  font-size: 13px !important;
  border-top: none !important;
}

.table-indicators td {
  font-size: 13px !important;
}

.card-2 {
  border: none !important;
  border-radius: 20px !important;
}
</style>
