<template>
  <div id="manager-container">
    <header id="manager-nav">
      <SideNavbar/>
    </header>
    <div id="manager-content">
      <!-- Main -->
      <main id="manager-main-content">
        <transition name="component-fade" id="manager-screen">
          <router-view :key="$route.path" v-cloak></router-view>
        </transition>
      </main>
      <footer-bar id="project-footer" v-once />
    </div>
  </div>
</template>

<script>
import SideNavbar from './shared/SideBarNav.vue';
import FooterBar from './shared/Footer.vue';
export default {
  components:{
      SideNavbar,
      FooterBar
    }
}
</script>

<style>

</style>