<template>
  <div class="d-stepper mt-2 mb-5">
    <div class="d-stepper-header d-flex justify-content-between">
      <div
        class="step-number-content text-center"
        :class="{ active: step == i}"
        v-for="(stepItem, i) in steps"
        :key="i"
      >
        <div
          class="step-number align-items-center justify-content-center mx-auto"
          :class="stepNumberClasses(i)"
        >
          <i v-if="step > i" class="fas fa-check"></i>
          <i v-else-if="step === i && fatalError" class="fas fa-exclamation"></i>
          <span v-else>{{ i + 1 }}</span>
        </div>
        <div class="mt-1 small">{{ stepItem.name }}</div>
      </div>
    </div>

    <b-card
      class="my-4 overflow-hidden card-2"
      bg-variant="light"
      no-body
      :class="{ 'border-danger': error, 'shake-error': shake }"
    >
    <!-- v-loading="loading" -->
      <b-card-body>
        <b-row>
          <div class="col-sm col-md col-lg">
              <div v-if="steps[step].icon" class="d-none d-sm-block">
              <i class="fas fa-fw fa-3x mr-4" :class="iconClasses"></i>
            </div>
            <div>
              <!-- <h3>{{ step + 1 }}. {{ steps[step].name }}</h3>
              <p class="text-muted">{{ steps[step].desc }}</p> -->
              <div v-if="!fatalError">
                <transition :name="effect" mode="out-in">
                  <keep-alive>
                    <component
                      :store="store"
                      :state="store.state"
                      :step="step"
                      :setState="store.setState"
                      ref="step"
                      :is="stepComponent"
                      @loading="loadingAction"
                      @error="errorHandler"
                      @fatal-error="blockStepper"
                      @can-continue="nextStepAction"
                      @set-step="setStep"
                    />
                  </keep-alive>
                </transition>
              </div>
              <div v-else>{{ fatalErrorMsg }}</div>
            </div>
          </div>
        </b-row>
      </b-card-body>
    </b-card>

    <div class="footer d-flex justify-content-start" v-if="!fatalError">
       <!-- Disasble mark as draft -->
       <b-button
        v-if="step < 3 && false"
        variant="light"
        size="sm"
        class="ml-2 btn-draft text-primary"
      > Mark as draft
      </b-button>

      <b-button
        v-if="step > 0"
        variant="light"
        size="sm"
        :disabled="loading"
        class="text-primary btn-prev ml-2"
        @click="backStep"
      >
        <!-- <i class="fas fa-angle-double-left"></i>  -->
        Previous
      </b-button>

      <b-button
        v-if="step < (steps.length - 1)"
        variant="success"
        size="sm"
        class="ml-2 btn-next ml-2"
        no-border
        @click="nextStep"
        :disabled="loading"
      >
        <b-container v-if="loading">
          <b-spinner small></b-spinner>
          <span class="text-13 ml-2">Saving...</span>
        </b-container>
        <span v-if="!loading" class="text text-13">Save and continue</span>
        <!-- <i class="fas fa-angle-double-right"></i> -->
      </b-button>
      
      <b-button
        v-if="steps[step].confirm"
        variant="success"
        size="sm"
        class="ml-2 btn-next"
        @click="nextStep"
        :disabled="loading"
        type="submit"
      > 
        <b-container v-if="loading">
          <b-spinner small></b-spinner>
          <span class="text-13 ml-2">Saving...</span>
        </b-container>
        <span v-if="!loading" class="text text-13"><i class='bx bx-check-double' ></i> Submit</span>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DStepper",
  props: {
    steps: { type: Array, default: () => [] },
    initialState: { type: Object, default: () => ({}) },
    desiredActiveStep: { default: 0}
  },
  data() {
    return {
      store: {
        state: this.initialState,
        setState: this.setState,
        resetState: this.resetState
      },
      step: this.desiredActiveStep,
      loading: false,
      error: false,
      fatalError: false,
      fatalErrorMsg: "",
      effect: "in-out-translate-fade",
      shake: false,
    };
  },
  watch:{
    desiredActiveStep(){
      this.step = this.desiredActiveStep
    }
  },
  computed: {
    activeStep() {
      return this.steps[this.step];
    },
    stepComponent() {
      return this.steps[this.step].component;
    },
    iconClasses() {
      if (!this.activeStep.icon) return "";
      else if (/\s/.test(this.activeStep.icon)) return this.activeStep.icon;
      return `fas ${this.activeStep.icon}`;
    }
  },
  methods: {
    setStep(step) {
      if (step >= 1 && step <= this.steps.length) this.step = step - 1;
    },
    resetState() {
      this.store.state = {
        ...this.initialState
      };
    },
    setState(key, value) {
      this.store.state = {
        ...this.store.state,
        [key]: value
      };
    },
    errorHandler(payload) {
      this.error = payload;
      this.shake = payload;
      setTimeout(() => {
        this.shake = !payload;
      }, 750);
    },
    blockStepper(msg) {
      this.resetParams();
      this.fatalErrorMsg = msg;
      this.fatalError = true;
    },
    resetParams() {
      this.error = false;
      this.loading = false;
      this.fatalErrorMsg = "";
      this.fatalError = false;
    },
    stepNumberClasses(i) {
      return {
        "bg-primary text-white": this.step === i && !this.fatalError,
        "bg-success text-white": this.step > i && !this.fatalerror,
        "bg-danger text-white": this.fatalError && this.step === i,
        "text-primary": this.step < i
      };
    },
    nextStep() {
      if (!this.$refs.step.nextStep) return this.nextStepAction();

      if (this.$refs.step.nextStep()) {
        if (!this.loading) {
          this.nextStepAction();
        }
      }

    },
    nextStepAction(){
      this.effect = "in-out-translate-fade";
      this.resetParams();
      if (this.step < this.steps.length - 1) this.step++;
    },
    backStep() {
      this.effect = "out-in-translate-fade";
      this.resetParams();
      if (this.step > 0) this.step--;
    },
    loadingAction(status) {
      this.loading = status;
      //if (!status) this.nextStepAction();
    }
  }
};
</script>

<style scoped>
.btn-next{
  background: #0FA958 !important;
  width: 230px !important;
  border-radius: 20px !important;
}

.btn-prev{
  background: #CCCCCC !important;
  width: 230px !important;
  border-radius: 20px !important;
}

.btn-draft{
  background: #CCCCCC !important;
  width: 120px !important;
  border-radius: 20px !important;
}


.card-2{
  background-color: transparent !important;
  border: none !important;
  width: 100% !important;
}
.d-stepper .d-stepper-header {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.d-stepper .d-stepper-header::before {
  position: absolute;
  width: 100%;
  height: 1px;
  background: #DDD;
  top: 14px;
  left: 0;
  content: " ";
}

.d-stepper .step-number {
  background: #E9E9E9;
  border-radius: 50%;
  text-align: center;
  height: 30px;
  width: 30px;
  display: flex;
}
.d-stepper .step-number-content {
  transition: transform 0.2s;
  z-index: 0;
  width: 100px;
}

.d-stepper .step-number-content div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.d-stepper .step-number-content.active {
  transform: scale(1.25);
}

.in-out-translate-fade-enter-active,
.in-out-translate-fade-leave-active {
  transition: all 0.15s;
}
.in-out-translate-fade-enter,
.in-out-translate-fade-leave-active {
  opacity: 0;
}
.in-out-translate-fade-enter {
  transform: translateX(100px);
}
.in-out-translate-fade-leave-active {
  transform: translateX(-100px);
}

.out-in-translate-fade-enter-active,
.out-in-translate-fade-leave-active {
  transition: all 0.15s;
}
.out-in-translate-fade-enter,
.out-in-translate-fade-leave-active {
  opacity: 0;
}
.out-in-translate-fade-enter {
  transform: translateX(-100px);
}
.out-in-translate-fade-leave-active {
  transform: translateX(100px);
}
</style>
