<template>
  <div class="">
    <b-row>
      <div class="col-sm col-md">
        <label>Select color</label>
        <div class="d-flex justify-content-around">
          <button
            type="button"
            class="btn-color orange p-3 h-50 mt-2 border-none"
          ></button>
          <button
            type="button"
            class="btn-color orange-yellow p-3 h-50 mt-2 border-none"
          ></button>
          <button
            type="button"
            class="btn-color green-yellow p-3 h-50 mt-2 border-none"
          ></button>
          <button
            type="button"
            class="btn-color blue p-3 h-50 mt-2 border-none"
          ></button>
          <button
            type="button"
            @click.prevent="openPicker"
            class="m-2 btn btn-light btn-custom-color rounded-10"
          >
            Custom color
          </button>
        </div>
      </div>
    </b-row>
    <transition name="fade">
      <div v-if="visiblePicker" class="">
        <color-picker
          :color="color"
          :sucker-hide="false"
          :sucker-canvas="suckerCanvas"
          :sucker-area="suckerArea"
          @changeColor="changeColor"
          @openSucker="openSucker"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    visiblePicker: { type: Boolean, default: false },
    isSucking: { type: Boolean, default: false },
    suckerArea: { type: Array, default: () => {} },
    suckerCanvas: { type: null, default: () => {} },
    color: { type: null, default: () => {} }
  },
  methods: {
    data() {
      return {};
    },
    changeColor(){
      this.$emit('change-color')
    },
    openSucker(isOpen) {
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    },
    openPicker() {
      this.$emit('open-picker');
    },
    
  },
};
</script>

<style>
</style>