<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 main mb-6">
      <div class="container-fluid">
        <b-row class="mt-0">
          <div class="col-sm col-md col-lg">
            <div class="card card-main mt-3 ml-0 mb-4">
              <div class="card-body pl-0 ml-0">
                <b-row>
                  <div class="col-sm col-md col-lg">
                    <div class="d-flex">
                      <div class="justify-content-between">
                        <h5 class="text-primary float-left ml-4">
                          Program Setup
                          <div class="vm-active"></div>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm col-md">
                    <a-tooltip
                      title="Program configuration"
                      v-if="programId || drafted"
                    >
                      <span
                        @click="showModal"
                        class="float-right mr-3 cursor-pointer text-muted"
                      >
                        <i class="bx bxs-cog bx-md"></i>
                      </span>
                    </a-tooltip>
                  </div>
                </b-row>
                <b-row>
                  <div class="col-sm col-md col-lg">
                    <div class="container">
                      <d-stepper
                        :steps="steps"
                        :initial-state="{ name: '', users: [] }"
                      >
                        <template #fatal-error="{ errorMsg }">{{
                          errorMsg
                        }}</template>
                      </d-stepper>
                    </div>
                  </div>
                </b-row>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </main>
    <b-modal v-model="show" size="xl" title="Program configuration" hide-footer>
        <b-row>
          <div class="col-sm col-md col-lg">
            <div class="container">
              <config-stepper
                :steps="configSteps"
                :initial-state="{ name: '', users: [] }"
                @dismiss="onDismiss"
              >
                <template #fatal-error="{ errorMsg }">{{
                  errorMsg
                }}</template>
              </config-stepper>
            </div>
          </div>
        </b-row>
    </b-modal>
  </div>
</template>
<script>
import DStepper from "../../../components/stepper/Stepper.vue";
import ConfigStepper from '../../../components/stepper/config-stepper.vue'
import Step1 from "./components/stepper/step1.vue";
import Step2 from "./components/stepper/step2.vue";
import Step3 from "./components/stepper/step3.vue";
import Step4 from "./components/stepper/step4.vue";
import Step5 from "./components/stepper/step5.vue";
import config1 from './components/config/program-milestones.vue';
import config2 from './components/config/aggregations-config.vue'
import moment from "moment";
import decode from 'jwt-decode'
export default {
  name: "create-program",
  components: {
    DStepper,
    ConfigStepper
  },
  data() {
    return {
      defaultDate: moment(new Date()).format("YYYY-MM-DD"),
      currentPage: 0,
      perPage: 8,
      fields: ["Title", "Due_date", "creation_time", "edit", "delete"],
      filter: "",
      titles: {
        pillarTitle: "Pillars",
        toc: "TOC",
      },
      steps: [
        {
          name: "Program Details",
          disabled: false,
          active: true,
          component: Step1,
        },
        {
          name: this.$store.getters.getCurrentPrograam
            ? `${this.capitalizeFirstLetter(
                this.$store.getters.getCurrentPrograam.keyWords.pillar
              )}s`
            : "Pillars",
          disabled: false,
          active: false,
          component: Step2,
        },
        {
          name: "Theory of Change",
          disabled: false,
          active: false,
          component: Step3,
        },
        {
          name: "Logframe",
          disabled: false,
          active: false,
          component: Step4,
        },
        {
          name: "Submission",
          disabled: false,
          active: false,
          component: Step5,
          confirm: "Confirm",
        },
      ],
      configSteps: [
        {
          name: "Targets Config",
          disabled: false,
          active: true,
          component: config1,
        },
        {
          name: "Disaggregations Config",
          disabled: false,
          active: false,
          component: config2,
        },
      ],
      load: {
        loading: false,
        refresh: false,
        adding: false,
        updating: false,
        deleting: false,
        static: false,
        milestones: false,
      },
      state: {
        currentp: false,
        addingMile: false,
      },
      keywords: null,
      milestones: [],
      types: null,
      temp: [],
      type: "",
      keyword: "",
      value: "",
      activeTab: true,
      pageTitle: "programs",
      show: false,
      data: null,
      visibleDelete: false,
      visibleEdit: false,
      collapse: false,
      milestone: {
        title: null,
        dueDate: null,
      },
      edit: {
        milestone: null,
        dueDate: null,
      },
      isreport: false,
      nreport: {
        Title: "Title",
        DeadLine: null,
        narativeReport: false,
      },
    };
  },
  beforeMount() {
    sessionStorage.setItem("creation", "program");
    this.$title("Create program");
    this.getCurrentProgram();
  },
  watch: {
    types() {},
  },
  computed: {
   programSaved() {
      return this.$store.state.program_data
        ? this.$store.state.program_data
        : null;
    },
    drafted() {
      const data = sessionStorage.getItem("draft");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return decoded;
    },
    program() {
      return this.$store.state.program_data
        ? this.$store.state.program_data
        : null;
    },
    programId() {
      const data = sessionStorage.getItem("requested");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.program ? this.program : decoded;
    },
    token() {
      return localStorage.getItem("token");
    },
    selectedID() {
      return this.drafted
        ? this.drafted.id
        : this.programId
        ? this.programId.programData.id
        : 0;
    },
    rows() {
      return this.keywords ? this.keywords.length : 0;
    },
    keyWordGetter() {
      return this.$store.getters.getCurrentPrograam
        ? this.$store.getters.getCurrentPrograam.keyWords
        : null;
    },
    keyWords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  methods: {
    showModal() {
      this.show = true;
    },
    onDismiss(){
      this.show=!this.show
    },
    async getCurrentProgram() {
      this.state.currentp = true;
      await this.axios
        .get("getCurrentProgram")
        .then((response) => {
          if (response.data) {
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
            this.steps[1].name = "Pillars";
            this.steps[2].name = "Theory of Change";
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
  }
};
</script>

<style scoped>
/* Top card styles */
.top-card {
  background-color: rgba(119, 119, 119, 0.507);
  border-radius: 10px !important;
  padding: 20px 12px !important;
  width: 234px !important;
  height: 123px !important;
}

.top-card h3 {
  font-size: 16px !important;
  padding-bottom: 3px !important;
}

.top-card p {
  font-size: 15px !important;
  padding-top: 2.5px !important;
}
/* End for styles */

.btn-top-create {
  background: #0fa958 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.vm-active {
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important;
  padding-bottom: 1rem !important;
  border-bottom: 4px solid #0fa958 !important;
  width: 60px !important;
  margin-left: 40% !important;
}

.card ul li {
  list-style-type: none;
}

.card-stat {
  background-color: #fff !important;
}

/* icons */
.dash-img {
  width: 75px;
  height: 75px;
}
/* end of icons styles */

.j1-admin h3 {
  font-size: 20px;
}

.j1-admin p {
  font-size: 16px;
}

.ct-admin {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.btn-top {
  background: #e5e5e5 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
}

.btn-top-filter {
  background: #0fa958 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card {
  border-radius: 20px !important;
  text-align: center !important;
}

.row-admin {
  margin-top: 30px !important;
}

.top-buttons {
  margin-left: -8rem !important;
}

.card-main {
  background-color: #f6f6f6 !important;
  border: none !important;
}

.card-2 {
  border: none !important;
}

.card h5 {
  font-style: normal;
  font-family: Ubuntu !important;
  font-size: 14px !important;
  color: #69737f !important;
}

.card p {
  font-style: normal;
  font-family: Ubuntu !important;
  font-size: 25px !important;
  color: #69737f !important;
  font-weight: bold;
}

@media screen and (max-width: 578px) {
  .big-border {
    width: 54.5px;
    height: 5px;
    background: #cecf71 !important;
  }

  .date-top-text {
    color: #000 !important;
  }
}

.collapse .show {
  background-color: #0fa958 !important;
}
</style>