<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 main mb-6">
      <div class="card rounded-20 card-main mt-3 ml-0 mb-8">
        <b-row no-gutters class="mb-3 p-2">
          <div class="col-sm-3 col-md-3 col-lg-3">
            <div class="ml-2 mt-4 text-left top-label">
              <h4 class="text-16 text-capitalize"> User profile </h4>
            </div>
          </div>
        </b-row>
        <div
          class="card-header bg-white rounded-top-left-20 rounded-top-right-20"
        >
          <b-row no-gutters>
            <div class="col-sm col-md">
              <div class="table-responsive">
                <div class="all-tabs">
                  <div
                    class="tab text-12"
                    :class="{ active: selectedTabIndex == i }"
                    v-for="(tab, i) in shownTabs"
                    :key="i"
                    @click="setTab(i)"
                  >
                    {{
                      tab == "UserInfo"
                        ? "Profile Information"
                        : tab == "ChangeSettings"
                        ? "Change Profile Settings" : tab
                    }}
                  </div>
                </div>
              </div>
            </div>
          </b-row>
        </div>
        <div class="card-body pl-0 ml-0">
          <b-row no-gutters class="pb-3 px-2">
            <div class="table-responsive">
              <transition name="tabs-fade">
                <keep-alive :max="3">
                  <Component
                    :is="shownTabs[selectedTabIndex]"
                    :tabName="compID"
                  />
                </keep-alive>
              </transition>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import UserInfo from "./tabs/profile-info.vue";
import ChangeSettings from "./tabs/change-settings.vue";
export default {
  name: "project-dashboard",
  components: {
    UserInfo,
    ChangeSettings,
  },
  data() {
    return {
      pageTitle: "user account profile",
      compID: uuidv4(),
      selectedTabIndex: 0,
      tabs: [
        "UserInfo",
        "ChangeSettings",
      ],
      state: {
        loading: false,
        refresh: false,
      },
    };
  },
  computed: {
    shownTabs() {
      return this.tabs;
    },
    
    token(){
      return localStorage.getItem("token");
    }
  },
  beforeMount() {

  },
  methods: {
    setTab(index) {
      if (isNaN(index)) return;
      if (index == this.selectedTabIndex) return;
      this.$set(this, "selectedTabIndex", index);
    },
    // Pillar synchronizing data functions
  },
};
</script>

<style scoped>
*,
html,
body {
  font-family: Ubuntu;
}

@media (min-width: 600px) {
  .table-responsive {
    overflow-x: hidden !important;
  }
}
</style>