<template>
  <div class="main">
    <div class="card card-2 m-0 p-0 no-print">
      <div class="card-body p-0 m-0">
        <b-row>
          <div class="col-sm-2">
            <div class="">
              <span class="cursor-pointer">
                <a-input
                  v-model="filter"
                  style="width: 180px"
                  placeholder="Search"
                >
                  <a-icon slot="prefix" type="search" />
                </a-input>
              </span>
            </div>
          </div>
          <div class="col-sm-4 d-flex">
            <a
              @click="createCategoryModal"
              type="button"
              class="btn btn-secondary btn-top-create float-right mr-2 pr-4 pl-4 pt-2 pb-2 mb-3"
              role="button"
            >
              Add risk Category
            </a>
            <a
              @click="createConfirmModal"
              type="button"
              class="btn btn-secondary btn-top-create float-right mr-2 pr-4 pl-4 pt-2 pb-2 mb-3"
              role="button"
            >
              Add risk
            </a>
          </div>
        </b-row>
        <div class="mt-2">
          <b-container class="my-5" v-if="loading">
            <a-skeleton
              active
              :loading="loading"
              :paragraphs="{ rows: 6 }"
            ></a-skeleton>
          </b-container>
          <b-table
            v-else
            table-class="table-logframe text-13 table-responsive"
            striped
            hover
            thead-tr-class="tb-head text-left indent-20"
            tbody-class="tb-body text-left indent-20"
            :items="risks"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            show-empty
          >
            <template v-slot:cell(update)="data">
              <button
                @click="showEditModal(data.item)"
                class="btn btn-sm btn-outline-success"
              >
                <i class="bx bx-edit-alt"></i>
              </button>
            </template>
            <template v-slot:cell(archive)="data">
              <button
                @click="deleteModal(data.item)"
                class="btn btn-sm btn-outline-danger"
              >
                <i class="bx bx-x"></i>
              </button>
            </template>
          </b-table>
        </div>
        <b-row class="my-4">
          <div class="col-sm">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
            ></b-pagination>
          </div>
          <div class="col-sm-4"></div>
        </b-row>
      </div>
    </div>
    <div class="card card-2 m-0 p-0">
      <div class="card-body p-0 m-0">
        <b-row class="mt-5">
          <b-col cols="12">
            <b-row>
              <b-col cols="4">
                <h5 class="text-left">Q3 2022 Risk Register</h5>
              </b-col>
              <b-col cols="6">
                <form class="d-flex" @submit.prevent="filterRiskRegister">
                  <b-select
                    :options="risk_quarter_filter"
                    v-model="select_risk_quarter"
                    class="mr-3"
                  ></b-select>
                  <button class="btn btn-info" type="submit">Filter</button>
                </form>
              </b-col>
              <b-col cols="2">
                <button
                  class="btn btn-outline btn-secondary float-right no-print"
                  @click="downloadRegister"
                >
                  Download
                </button>
              </b-col>
            </b-row>
            <div class="clearfix">&nbsp;</div>

            <!-- <p>{{project_risks}}</p> -->
            <table
              class="table table-bordered table-hover risk-register"
              style="overflow-x: scroll !important"
            >
              <tr class="head" style="background: #bdd6ee">
                <!-- <th scope="col">#</th> -->
                <th rowspan="2" scope="col">Risk Category/Taxonomy</th>
                <th rowspan="2" scope="col">Risk Description</th>
                <th rowspan="2" scope="col">Risk Owner</th>
                <th rowspan="2" scope="col">Risk Root Cause(s)</th>
                <th rowspan="2" scope="col">Risk Root Consequences</th>
                <th rowspan="2" scope="col">Existing Controls</th>
                <th colspan="3" scope="col">Before mitigating action</th>
                <th rowspan="2" scope="col">Planned Mitigation</th>
                <th rowspan="2" scope="col">Treatment</th>
                <th colspan="2" scope="col">Residual Risk Assessment</th>
                <th colspan="1" scope="col">Is Risk Acceptable</th>
                <th rowspan="2" scope="col">Responsible Person</th>
                <th rowspan="2" scope="col">Implementation Date</th>
                <th rowspan="2" scope="col">Status</th>
                <th rowspan="2" scope="col">Comment</th>
                <th rowspan="2" scope="col">Overall Risk Rating</th>
              </tr>
              <tr class="head">
                <th scope="col">Prob</th>
                <th scope="col">Impact</th>
                <th scope="col">Rate</th>
                <th>Prob</th>
                <th>Impact</th>
                <th>Yes Or No</th>
              </tr>

              <template v-for="(project_risk, key) in project_risks">
                <tr :key="key" class="category-description">
                  <td colspan="20">
                    <b>{{ project_risk.category_title }}</b>
                    {{ project_risk.category_description }}
                  </td>
                </tr>
                <!-- Project risks -->
                <template v-for="(project, key) in project_risk.projectRisks">
                  <tr
                    :key="`project_${project_risk.category_id}_${project.Id}`"
                    :dump="key"
                  >
                    <td :rowspan="project.risks.length + 1">
                      <b>{{ project.Project_name }}</b>
                    </td>
                  </tr>
                  <tr v-for="risk in project.risks" :key="'pro_risk' + risk.id">
                    <td>{{ risk.Description }}</td>
                    <td>{{ risk.owner_name }}</td>
                    <td>{{ risk.root_cause }}</td>
                    <td>{{ risk.consequences }}</td>
                    <td>{{ risk.controls }}</td>
                    <td>{{ risk.likelihood }}</td>
                    <td>{{ risk.impact }}</td>
                    <td>{{ risk.rate }}</td>
                    <td>{{ risk.treatment_narrative }}</td>
                    <td>
                      <b>{{ risk.treatment }}</b>
                    </td>
                    <td>
                      <b>{{ risk.likelihood_residual }}</b>
                    </td>
                    <td>{{ risk.impact_residual }}</td>
                    <td>{{ risk.is_acceptable }}</td>
                    <td>{{ risk.responsible_name }}</td>
                    <td>{{ risk.implementation_date }}</td>
                    <td>{{ risk.status }}</td>
                    <td>{{ risk.comment }}</td>
                    <td>{{ risk.rate }}</td>
                  </tr>
                </template>

                <!-- Program risks -->
                <template v-if="project_risk.programRisks.length > 0">
                  <tr :key="'prog' + key * 7">
                    <td :rowspan="project_risk.programRisks.length + 1">
                      All Projects
                    </td>
                  </tr>
                  <tr
                    v-for="risk in project_risk.programRisks"
                    :key="'prog_risk' + risk.id"
                  >
                    <td>{{ risk.Description }}</td>
                    <td>{{ risk.owner_name }}</td>
                    <td>{{ risk.root_cause }}</td>
                    <td>{{ risk.consequences }}</td>
                    <td>{{ risk.controls }}</td>
                    <td>{{ risk.likelihood }}</td>
                    <td>{{ risk.impact }}</td>
                    <td>{{ risk.rate }}</td>
                    <td>{{ risk.treatment_narrative }}</td>
                    <td>
                      <b>{{ risk.treatment }}</b>
                    </td>
                    <td>
                      <b>{{ risk.likelihood_residual }}</b>
                    </td>
                    <td>{{ risk.impact_residual }}</td>
                    <td>{{ risk.is_acceptable }}</td>
                    <td>{{ risk.responsible_name }}</td>
                    <td>{{ risk.implementation_date }}</td>
                    <td>{{ risk.status }}</td>
                    <td>{{ risk.comment }}</td>
                    <td>{{ risk.rate }}</td>
                  </tr>
                </template>
              </template>
            </table>
          </b-col>
        </b-row>
      </div>
    </div>
    <a-modal
      v-model="visibleCreate"
      :footer="null"
      :title="
        modalCreateEditAction == 'create' ? 'Create risk' : 'Edit' + ' risk'
      "
    >
      <form @submit.prevent="submitModal">
        <b-row>
          <div class="col-sm-12 col-md-12">
            <!-- <div class="form-group">
              <input
                placeholder="Title"
                type="text"
                class="form-control form-input-modal text-13"
                v-model="risk.title"
              />
            </div> -->
            <b-form-group>
              <label for="">Category</label>
              <b-input-group>
                <b-select
                  class="rounded-20 select-modal bg-gray-white text-13 mr-3"
                  v-model="risk.category"
                  selected="risk.category"
                  required
                  :disabled="state.category"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled
                      >Select risk category</b-select-option
                    >
                  </template>
                  <b-select-option
                    v-for="item in categories"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.Title }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right ml-n2"
                    font-scale="1.5"
                    :animation="state.category ? 'spin' : ''"
                    @click="getRiskCategories"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <label for="">Description</label>
                <b-form-textarea
                  id="desc"
                  placeholder="Risk Description"
                  rows="3"
                  resize
                  class="form-text-modal text-13"
                  v-model="risk.description"
                ></b-form-textarea>
              </b-form-group>
            </div>

            <b-form-group>
              <label for="">Owner</label>
              <b-input-group>
                <b-select
                  class="rounded-20 select-modal bg-gray-white text-13 mr-3"
                  v-model="risk.owner"
                  selected="risk.owner"
                  required
                  :disabled="state.loadUsers"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled
                      >Select risk owner</b-select-option
                    >
                  </template>
                  <b-select-option
                    v-for="item in users"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.fullnames }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right ml-n2"
                    font-scale="1.5"
                    :animation="state.loadUsers ? 'spin' : ''"
                    @click="getUsers"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <label for="">Root Cause</label>
                <b-form-textarea
                  id="desc"
                  placeholder="Risk Root Cause"
                  rows="2"
                  resize
                  class="form-text-modal text-13"
                  v-model="risk.root_cause"
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <label for="">Consequences</label>
                <b-form-textarea
                  id="desc"
                  placeholder="Risk Consequences"
                  rows="2"
                  resize
                  class="form-text-modal text-13"
                  v-model="risk.consequences"
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <label for="">Existing controls</label>
                <b-form-textarea
                  id="desc"
                  placeholder="Existing controls"
                  rows="2"
                  resize
                  class="form-text-modal text-13"
                  v-model="risk.controls"
                ></b-form-textarea>
              </b-form-group>
            </div>

            <p>Before mitigating action</p>
            <div class="form-group">
              <label for="">Likelihood</label>
              <select
                class="rounded-20 custom-select select-modal bg-gray-white text-13"
                v-model="risk.likelihood"
              >
                <option selected value="" disabled>Choose likelihood</option>
                <option
                  :value="option"
                  v-for="(keyword, option) in keywords.Likelihood"
                  :key="option"
                >
                  {{ option }}.{{ keyword }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Impact</label>
              <select
                class="rounded-20 custom-select select-modal bg-gray-white text-13"
                v-model="risk.impact"
              >
                <option selected value="" disabled>Choose impact</option>
                <option
                  :value="option"
                  v-for="(keyword, option) in keywords.Impact"
                  :key="option"
                >
                  {{ option }}.{{ keyword }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="">Risk treatment</label>
              <select
                class="rounded-20 custom-select select-modal bg-gray-white text-13"
                v-model="risk.treatment"
              >
                <option selected value="" disabled>Choose treatment</option>
                <option
                  v-for="el in treatments"
                  :value="el.name"
                  :key="el.name"
                >
                  {{ el.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <b-form-textarea
                  id="desc"
                  placeholder="Risk Treatment Narrative"
                  rows="3"
                  resize
                  class="form-text-modal text-13"
                  v-model="risk.treatment_narrative"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="form-group">
              <label for="">Appetite</label>
              <select
                class="rounded-20 custom-select select-modal bg-gray-white text-13"
                v-model="risk.appetite"
              >
                <option selected value="" disabled>Choose appetite</option>
                <option
                  :value="option"
                  v-for="(keyword, option) in keywords.appetite"
                  :key="option"
                >
                  {{ option }}.{{ keyword }}
                </option>
              </select>
            </div>
            <p>Residual risk</p>

            <div class="form-group">
              <label for="">Likelihood</label>
              <select
                class="rounded-20 custom-select select-modal bg-gray-white text-13"
                v-model="risk.likelihood_residual"
              >
                <option selected value="" disabled>Choose likelihood</option>
                <option
                  :value="option"
                  v-for="(keyword, option) in keywords.Likelihood"
                  :key="option"
                >
                  {{ option }}.{{ keyword }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Impact</label>
              <select
                class="rounded-20 custom-select select-modal bg-gray-white text-13"
                v-model="risk.impact_residual"
              >
                <option selected value="" disabled>Choose impact</option>
                <option
                  :value="option"
                  v-for="(keyword, option) in keywords.Impact"
                  :key="option"
                >
                  {{ option }}.{{ keyword }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Overall Risk rating</label>
              <p>{{ risk.impact * risk.likelihood }}</p>
            </div>
            <div class="form-group">
              <div class="row">
                <label class="col-md-12" for="">Is the Risk Acceptable?</label>
                <div class="col-md-6">
                  <b-form-radio value="1" v-model="risk.is_acceptable"
                    >Yes</b-form-radio
                  >
                </div>
                <div class="col-md-6">
                  <b-form-radio value="0" v-model="risk.is_acceptable"
                    >No</b-form-radio
                  >
                </div>
              </div>
            </div>
            <b-form-group>
              <label for=""
                >Person Responsible for Implementing Treatment</label
              >
              <b-input-group>
                <b-select
                  class="rounded-20 select-modal bg-gray-white text-13 mr-3"
                  v-model="risk.responsible"
                  selected="risk.owner"
                  :disabled="state.loadUsers"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled
                      >Select risk responsibility</b-select-option
                    >
                  </template>
                  <b-select-option
                    v-for="item in users"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.fullnames }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right ml-n2"
                    font-scale="1.5"
                    :animation="state.loadUsers ? 'spin' : ''"
                    @click="getUsers"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group>
              <label for="">Implementation Date</label>
              <b-input
                class="rounded-20 select-modal bg-gray-white"
                v-model="risk.implementation_date"
                type="date"
              >
              </b-input>
            </b-form-group>

            <b-form-group>
              <label for="">Status</label>
              <b-input-group>
                <b-select
                  class="rounded-20 select-modal bg-gray-white text-13 mr-3"
                  v-model="risk.status"
                  :disabled="state.loadUsers"
                  :options="['Implemented', 'Not implemented']"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled
                      >Select risk status</b-select-option
                    >
                  </template>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right ml-n2"
                    font-scale="1.5"
                    :animation="state.loadUsers ? 'spin' : ''"
                    @click="getUsers"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <label for="">Comment</label>
                <b-form-textarea
                  placeholder="Additional feedback arising from the status"
                  rows="1"
                  resize
                  class="form-text-modal text-13"
                  v-model="risk.comment"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <div class="inline-block">
            <a
              @click="cancelConfirmModal"
              type="button"
              class="btn btn-secondary btn-block btn-cancel text-13"
              >Cancel</a
            >
          </div>
          <div class="inline-block pl-4">
            <button
              type="submit"
              class="btn btn-primary btn-block btn-confirm text-13"
            >
              <b-container v-if="state.adding">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Saving...</span>
              </b-container>
              <span v-if="!state.adding" class="text text-13">Submit</span>
            </button>
          </div>
        </b-row>
      </form>
    </a-modal>
    <a-modal
      v-model="visibleCreateCtg"
      :footer="null"
      title="Create risk category"
    >
      <form @submit.prevent="addRiskCategory">
        <b-row>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <input
                placeholder="Title"
                type="text"
                class="form-control form-input-modal text-13"
                v-model="title"
              />
            </div>
            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <b-form-textarea
                  id="desc"
                  placeholder="Description"
                  rows="3"
                  resize
                  class="form-text-modal text-13"
                  v-model="description"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <div class="inline-block pr-4">
            <button
              type="submit"
              class="btn btn-primary btn-block btn-confirm text-13"
            >
              <b-container v-if="state.adding">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Saving...</span>
              </b-container>
              <span v-if="!state.adding" class="text text-13">Ok</span>
            </button>
          </div>
          <div class="inline-block">
            <a
              @click="cancelCreateCtg"
              type="button"
              class="btn btn-secondary btn-block btn-cancel text-13"
              >CANCEL</a
            >
          </div>
        </b-row>
      </form>
    </a-modal>
    <!-- Update risk modal -->
    <a-modal
      v-model="visibleEdit"
      v-if="false"
      :footer="null"
      title="Update risk"
    >
      <form @submit.prevent="updateRisk(data)">
        <b-row>
          <div class="col-sm-12 col-md-12">
            {{ risk }}
            <div class="form-group">
              <input
                placeholder="Title"
                type="text"
                class="form-control form-input-modal text-13"
                v-model="risk.title"
              />
            </div>
            <b-form-group>
              <b-input-group>
                <b-select
                  class="rounded-20 select-modal bg-gray-white text-13 mr-3"
                  v-model="risk.category"
                  required
                  :disabled="state.category"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled
                      >Select risk caregory</b-select-option
                    >
                  </template>
                  <b-select-option
                    v-for="item in categories"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.Title }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right ml-n2"
                    font-scale="1.5"
                    :animation="state.category ? 'spin' : ''"
                    @click="getRiskCategories"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <b-form-textarea
                  id="desc"
                  placeholder="Description"
                  rows="3"
                  resize
                  class="form-text-modal text-13"
                  v-model="risk.description"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="form-group">
              <label for="">Likelihood</label>
              <select
                class="rounded-20 custom-select select-modal bg-gray-white text-13"
                v-model="risk.likelihood"
              >
                <option selected value="" disabled>Choose likelihood</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Impact</label>
              <select
                class="rounded-20 custom-select select-modal bg-gray-white text-13"
                v-model="risk.impact"
              >
                <option selected value="" disabled>Choose impact</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Risk rate</label>
              <select
                class="rounded-20 custom-select select-modal bg-gray-white text-13"
                v-model="risk.rate"
              >
                <option selected value="" disabled>Choose ratings</option>
                <option v-for="(num, i) in 25" :value="i" :key="i">
                  {{ i }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Risk treatment</label>
              <select
                class="rounded-20 custom-select select-modal bg-gray-white text-13"
                v-model="risk.treatment"
              >
                <option selected value="" disabled>Choose treatment</option>
                <option
                  v-for="el in treatments"
                  :value="el.name"
                  :key="el.name"
                >
                  {{ el.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="">Appetite</label>
              <select
                class="rounded-20 custom-select select-modal bg-gray-white text-13"
                v-model="risk.appetite"
              >
                <option selected value="" disabled>Choose appetite</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
        </b-row>

        <b-row class="d-flex justify-content-center">
          <div class="inline-block pr-4">
            <button
              :disabled="state.saving"
              type="submit"
              class="btn btn-primary btn-block btn-confirm"
            >
              <b-container v-if="state.saving">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Updating...</span>
              </b-container>
              <span v-if="!state.saving" class="text text-13">Update risk</span>
            </button>
          </div>
          <div class="inline-block">
            <a
              @click="visibleEdit = false"
              type="button"
              class="btn btn-secondary btn-block btn-cancel text-13"
              >CANCEL</a
            >
          </div>
        </b-row>
      </form>
    </a-modal>
    <!-- Delete risk modal -->
    <a-modal v-model="visibleDelete" :footer="null" title="Archive risk">
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to archive this information ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <button
            @click="visibleDelete = false"
            type="submit"
            class="btn btn-secondary btn-block btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
        <div class="inline-block">
          <a-popconfirm
            title="Are you sure archive this risk?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteRisk(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="state.deleting">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Deleting...</span>
              </b-container>
              <span v-if="!state.deleting" class="text text-13">Archive</span>
            </button>
          </a-popconfirm>
        </div>
        
      </b-row>
    </a-modal>
  </div>
</template>

<script>
// import decode from "jwt-decode";
export default {
  name: "projects-tab",
  props: {
    loading: { type: Boolean, default: false },
  },
  data() {
    const risk_initial = {
      title: "",
      description: "",
      owner: null,
      root_cause: null,
      consequences: null,
      controls: null,
      likelihood: null,
      impact: null,
      rate: null,
      is_acceptable: null,
      responsible: null,
      implementation_date: null,
      status: null,
      comment: null,
      category: null,
      treatment: null,
      treatment_narrative: null,
      likelihood_residual: null,
      impact_residual: null,
      appetite: null,
    };
    return {
      visibleCreate: false,
      modalCreateEditAction: "create",
      visibleCreateCtg: false,
      visibleDelete: false,
      visibleEdit: false,
      title: null,
      description: null,
      risk_quarter_filter: [],
      select_risk_quarter: null,
      users: [],
      state: {
        adding: false,
        loading: false,
        category: false,
        keywords: false,
        deleting: false,
        saving: false,
        loadUsers: false,
      },
      risk_initial_values: risk_initial,
      risk: risk_initial,
      treatments: [
        { name: "accept" },
        { name: "avoid" },
        { name: "mitigate" },
        { name: "transfer" },
      ],
      keywords: [],
      categories: [],
      fields: [
        "Description",
        "likelihood",
        "impact",
        "rate",
        "treatment",
        "appetite",
        "update",
        "archive",
      ],
      perPage: 8,
      currentPage: 1,
      filter: "",
      data: null,
    };
  },
  watch: {},
  computed: {
    rows() {
      return this.risks ? this.risks.length : 0;
    },
    program() {
      return this.$store.state.current_program;
    },
    token() {
      return localStorage.getItem("token");
    },
    risks() {
      return this.$store.state.selected_program.program_risks;
    },
    project_risks() {
      return this.$store.state.selected_program.program_projects_risks;
    },
  },
  beforeMount() {
    this.getRiskCategories();
    this.getRiskKeywords();
    this.getUsers();
  },
  methods: {
    async getRiskKeywords() {
      this.state.keywords = true;
      await this.axios
        .get(`getRiskValues`)
        .then((response) => {
          if (response.data) {
            this.state.keywords = false;
            this.keywords = response.data;
          } else {
            this.state.keywords = false;
          }
        })
        .catch(() => {
          this.state.category = false;
        });
    },
    async getRiskCategories() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.category = true;
      await this.axios
        .get(`getRiskCategories`, config)
        .then((response) => {
          if (response.data) {
            this.state.category = false;
            this.categories = response.data;
          } else {
            this.state.category = false;
          }
        })
        .catch(() => {
          this.state.category = false;
        });
    },
    async getUsers() {
      this.state.loadUsers = true;
      await this.axios
        .get("getUsers")
        .then((response) => {
          if (response.data) {
            this.state.loadUsers = false;
            this.users = response.data;
          } else {
            this.state.loadUsers = false;
          }
        })
        .catch(() => {
          this.state.loadUsers = false;
        });
    },
    async filterRiskRegister() {
      const config = {
        params: {
          scope: "program",
          elementId: this.program.id,
          id: this.program.id,
          quarter_filter: this.select_risk_quarter,
        },
      };
      await this.axios
        .get(`getProgrampProjectRisk`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setProgramProjectRisk", response.data);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async addRiskCategory() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        Title: this.title,
        Description: this.description,
      };
      this.state.adding = true;
      await this.axios
        .post("AddRiskCategory", requestData, config)
        .then((response) => {
          if (response.data.message) {
            this.state.adding = false;
            this.$message.error(`${response.data.message}`, 4);
          } else {
            this.state.adding = false;
            this.visibleCreateCtg = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Risk category has been added successfully.`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.adding = false;
        });
    },
    async submitModal() {
      if (this.modalCreateEditAction == "create") {
        this.addRisk();
      } else if (this.modalCreateEditAction == "edit") {
        this.updateRisk();
      } else {
        alert("Unkown action");
      }
    },
    async addRisk() {
      const requestData = {
        ...this.risk,
        Title: this.risk.title,
        Description: this.risk.description,
        likelihood: this.risk.likelihood,
        impact: this.risk.impact,
        CategoryId: this.risk.category,
        treatment: this.risk.treatment,
        appetite: this.risk.appetite,
        treatment_narrative: this.risk.treatment_narrative,
        likelihood_residual: this.risk.likelihood_residual,
        impact_residual: this.risk.impact_residual,
        elementId: this.program.id,
        scope: "program",
      };
      this.state.adding = true;
      await this.axios
        .post("AddRisk", requestData)
        .then((response) => {
          if (response.data.message) {
            this.state.adding = false;
            this.$message.error(`${response.data.message}`, 4);
          } else {
            this.refreshPage();
            this.state.adding = false;
            this.visibleCreate = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Risk has been added successfully.`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.state.adding = false;
        });
    },
    async updateRisk() {
      const reqData = {
        Title: this.risk.title,
        Description: this.risk.description,
        likelihood: this.risk.likelihood,
        impact: this.risk.impact,
        rate: this.risk.rate,
        CategoryId: this.risk.category,
        treatment: this.risk.treatment,
        appetite: this.risk.appetite,
        elementId: this.program.id,
        scope: "program",
      };

      this.state.saving = true;
      await this.axios
        .post(`UpdateRiskHistory/${this.data.id}`, reqData)
        .then((response) => {
          if (response) {
            this.state.saving = false;
            this.visibleCreate = false;
            this.refreshPage();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Risk updated successfully.`,
              duration: 5,
            });
          } else {
            this.state.saving = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.saving = false;
        });
    },
    async deleteRisk(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.state.deleting = true;
      await this.axios
        .delete(`deleteRisk/${param.id}`, config)
        .then((response) => {
          if (response) {
            this.state.deleting = false;
            this.visibleDelete = false;
            this.refreshPage();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Risk deleted successfully.`,
              duration: 5,
            });
          } else {
            this.state.deleting = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.deleting = false;
        });
    },
    createConfirmModal() {
      this.visibleCreate = true;
      this.resetRisk();
      this.modalCreateEditAction = "create";
    },
    cancelConfirmModal() {
      this.visibleCreate = false;
    },
    resetRisk() {
      this.risk = this.risk_initial_values;
    },
    createCategoryModal() {
      this.visibleCreateCtg = true;
    },
    cancelCreateCtg() {
      this.visibleCreateCtg = false;
    },
    deleteModal(param) {
      this.data = param;
      this.visibleDelete = true;
    },
    showEditModal(param) {
      console.log(param);
      this.data = param;
      this.resetRisk();
      this.visibleCreate = true;
      this.modalCreateEditAction = "edit";
      this.risk = {
        ...param,
        // Swapping values and text values to allow preselection in b-select
        likelihood: param.likelihood_value,
        likelihood_value: param.likelihood,
        impact: param.impact_value,
        impact_value: param.impact,

        appetite: param.appetite_value,
        appetite_value: param.appetite,

        likelihood_residual: param.likelihood_residual_value,
        likelihood_residual_value: param.likelihood_residual,
        impact_residual: param.impact_residual_value,
        impact_residual_value: param.impact_residual,

        title: param.Title,
        description: param.Description,
      };
    },
    cancel(e) {
      console.log(e);
      this.visibleDelete = false;
      this.$message.error("Archiving risk has been canceled.");
    },
    refreshPage() {
      this.$emit("refresh");
    },
    downloadRegister() {
      window.print();
    },
  },
  mounted() {
    if (this.program) {
      let quarters = ["Q1", "Q2", "Q3", "Q4"];
      let quartes_data = [];
      for (let n = this.program.from; n <= this.program.to; n++) {
        quarters.forEach((quarter) => quartes_data.push(`${quarter} ${n}`));
      }
      console.log(quartes_data);
      this.risk_quarter_filter = quartes_data;
    }
  },
};
</script>

<style lang="scss" scoped>
.table-all {
  border: 1px solid #cdd0d1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-top-create {
  background: #0fa958 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.table-all .tbody-row td {
  text-align: left;
}

.row-1 {
  border: 1px solid #f5f6f7 !important;
}

.card-2 {
  border: none !important;
  background-color: transparent !important;
}

table.risk-register {
  .head {
    background: #bdd6ee;
    td,
    th {
      text-align: center;
      vertical-align: middle;
    }
  }
  td,
  th {
    border-color: #000;
  }
}

.table-all .top-panel td span {
  font-size: 13px !important;
}

.table-all .theader-projects th {
  font-size: 13px !important;
}

.table-all th {
  text-align: left !important;
}
.category-description {
  background: #f2dbdb;
}
@media (max-width: 768px) {
  .table-all .theader-projects .table-search {
    width: 165px !important;
  }
}
</style>