<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 main mb-5">
      <div class="card card-main card-0 mt-3 rounded-20 ml-0 mb-4">
        <div class="card-body pl-0 ml-0">
          <b-row>
            <div class="col-sm col-md">
              <b-row class="my-1">
                <div class="col-sm col-md"></div>
                <div class="col-sm-4 col-md-4">
                  <form action="">
                    <div class="form-group ml-2">
                      <input
                        type="text"
                        class="form-control p-3 search-box indent-10 rounded-10 text-13"
                        placeholder="Search Program Name"
                        v-model="filter"
                      />
                    </div>
                  </form>
                </div>
              </b-row>
              <b-row class="mb-2">
                <div class="col-sm-9 col-md-9"></div>
                <div class="col-sm col-md text-right">
                  <button
                    @click="refresh"
                    type="button"
                    class="btn btn-secondary btn-sm rounded-10 border-none"
                  >
                    <b-container  v-if="state.loading && state.refresh">
                      <b-spinner small></b-spinner>
                      <span class="text-13 ml-2">Refreshing...</span>
                    </b-container>
                    <span v-else class="text text-13">Refresh</span>
                  </button>
                </div>
              </b-row>
              <b-row class="ml-2">
                <div class="table-responsive">
                  <b-container class="my-5" v-if="state.loading">
                    <a-skeleton
                      active
                      :paragraph="{ rows: 8 }"
                      :loading="state.loading"
                    ></a-skeleton>
                  </b-container>
                  <b-table
                    v-else
                    table-class="table-programs text-13"
                    striped
                    hover
                    thead-tr-class="tb-head text-left indent-20"
                    tbody-class="tb-body text-left indent-20"
                    :items="programs"
                    :filter="filter"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    show-empty
                  >
                    <template v-slot:cell(name)="data">
                      <a-tooltip v-if="data.item.status =='draft'" title="This program is not completed while setup, when it cliked you will be redirected to complete program setup." placement="bottom" :color="'#87d068'">
                        <span @click="unCompleteGo(data.item)" class="text-capitalize link-text cursor-pointer">{{data.item.name}}</span>
                      </a-tooltip>
                      <a-tooltip v-else title="Go to program" placement="bottom" :color="'#87d068'">
                        <span @click="goToProgram(data.item)" class="text-capitalize link-text cursor-pointer">{{data.item.name}}</span>
                      </a-tooltip>
                    </template>
                    <template v-slot:cell(edit)="data">
                      <a-tooltip v-if="data.item.status != 'drafted'" title="Edit program" placement="bottom">
                        <span @click="EditProgram(data.item)" class="text-capitalize link-text cursor-pointer"> <button class="btn btn-light rounded-20"> <i class='bx bxs-edit-alt text-success'></i> </button> </span>
                      </a-tooltip>
                    </template>
                    <template v-slot:cell(Status)="data">
                        <span v-if="data.item.status =='draft'"><a type="button" @click="unCompleteGo(data.item)" class="btn-sm btn btn-warning rounded-20 pr-3">Uncompleted</a></span>
                        <span v-else><a type="link" class="text-success rounded-20 pr-3">Completed</a></span>
                    </template>
                  </b-table>
                </div>
              </b-row>
              <b-row>
                <div class="col-sm-8"></div>
                <div class="col-sm">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    class="ml-2"
                  ></b-pagination>
                </div>
              </b-row>
              <b-row class="mb-6">
                <div class="col-sm col-md">
                  <div class="d-flex justify-content-end">
                    <a
                      class="btn btn-primary rounded-20 pl-4 pr-4 text-12"
                      role="button"
                      @click="createProgram"
                    >
                      Add Program
                    </a>
                  </div>
                </div>
              </b-row>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import encode from 'jwt-encode';
export default {
  component: {},
  data() {
    return {
      title: null,
      pageTitle: "programs",
      fields: ["name", "pillars", "projects", "logframe","Status","edit"],
      perPage: 8,
      currentPage: 1,
      filter: "",
      programs: [],
      state: {
        loading: false,
        refresh: false,
        saving: false,
      },
    };
  },
  beforeMount() {
    this.$title("Programs");
    this.getPrograms();
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    rows() {
      return this.programs.length;
    },
  },
  methods: {
    createProgram(){
      sessionStorage.removeItem('draft');
      sessionStorage.removeItem("requested");
      sessionStorage.removeItem("s2");
      sessionStorage.removeItem("prg");
      this.$store.dispatch("setProgram", null);
      this.$router.push({ name: 'create-programs' })
    },
     EditProgram(data){
      sessionStorage.removeItem("prg");
      sessionStorage.removeItem("requested");
      sessionStorage.removeItem("s2");
      const secret = 'tokenHashingViv--afr0-101';
      const jwt = encode(data,secret);
      sessionStorage.setItem("draft",jwt);
      this.$router.push({name:'create-programs'})
    },
    goToProgram(param){
      sessionStorage.removeItem('draft');
      sessionStorage.removeItem("requested");
      sessionStorage.removeItem("s2");
      const secret = 'tokenHashingViv--afr0-101';
      const jwt = encode(param,secret);
      sessionStorage.setItem("prg",jwt);
      this.$router.push({name:'program-details'})
    },
     unCompleteGo(data){
      sessionStorage.removeItem("prg");
      sessionStorage.removeItem("requested");
      sessionStorage.removeItem("s2");
      const secret = 'tokenHashingViv--afr0-101';
      const jwt = encode(data,secret);
      sessionStorage.setItem("draft",jwt);
      this.$router.push({name:'create-programs'})
    },
    async getPrograms() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getPrograms", config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.programs = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    refresh() {
      this.state.refresh = true;
      this.getPrograms();
    },
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.progress-wrapper {
  width: 260px !important;
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  background: #f3f3f3 !important;
}

.card-pillars {
  background: #3fb15d21 !important;
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}

.label-remain h6 {
  font-size: 12px !important;
  margin-right: 10px !important;
}

.label-completed h6 {
  font-size: 12px !important;
  margin-left: 10px !important;
}

.top-texts {
  font-size: 13px !important;
}
</style>