<template>
  <div>
    <adminTopBar :title="title" />
    <main role="main" class="col-sm-9 ml-sm-auto main-home col-md-10 pt-0">
      <div class="container-fluid p-0 m-0 mt-n5">
        <div class="jumbotron ml-0 pl-0 pr-0 mr-0 b-none mb-4">
          <div class="card bg-gray border-none rounded-20">
            <div class="card-header bg-gray rounded-20 border-none">
              <div class="row d-flex justify-content-between mt-n5">
                <div class="text-lef left-label col-xs">
                  <h6 class="p-0 mt-0 ml-4">All audit trails</h6>
                </div>
              </div>
            </div>
            <div class="card-body">
              <b-row>
                <div class="col-sm col-md"></div>
                <div class="col-sm-4 col-md-4">
                  <form>
                    <div class="input-group mb-2">
                      <input
                        type="search"
                        v-model="filter"
                        class="form-control input-search rounded-left-10"
                        aria-label="Search"
                        placeholder="Search..."
                      />
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right-10">
                          <i class="bx bx-search text-success"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-sm-1">
                  <button
                    @click="refresh"
                    type="button"
                    class="btn btn-secondary btn-sm rounded-10 border-none"
                  >
                    <b-container v-if="state.loading && state.refresh">
                      <b-spinner small></b-spinner>
                      <span class="text-13 ml-2">Refreshing...</span>
                    </b-container>
                    <span v-else class="text text-13">Refresh</span>
                  </button>
                </div>
              </b-row>
              <b-row>
                <div class="col-sm col-md">
                  <div class="table-responsive">
                    <b-container class="my-2" v-if="state.loading">
                      <a-skeleton
                        :loading="state.loading"
                        :paragraph="{ rows: 8 }"
                        active
                      ></a-skeleton>
                    </b-container>
                    <b-table
                      v-else
                      table-class="table-roles text-13"
                      striped
                      hover
                      thead-tr-class="tb-head text-left"
                      tbody-class="tb-body text-left  text-12"
                      :items="audit_trail"
                      :filter="filter"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :fields="tableFields"
                      show-empty
                    >
                      <template v-slot:cell(last_login)="data">
                        {{
                         data.item.lastlogin ? moment(data.item.lastlogin).format(
                            "DD/MM/YYYY HH:mm:ss a"
                          ) : "-"
                        }}
                      </template>
                      <template v-slot:cell(full_name)="data">
                        <span class="text-capitalize">{{
                          data.item.fullnames
                        }}</span>
                      </template>
                      <template v-slot:cell(role)="data">
                        <span class="text-capitalize">{{
                          data.item.Role
                        }}</span>
                      </template>
                      <template v-slot:cell(institution)="data">
                        <span class="text-capitalize">{{
                          data.item.Insitution
                        }}</span>
                      </template>
                      <template v-slot:cell(edit)="data">
                        <button
                          @click="showEditModal(data.item)"
                          class="btn btn-sm btn-outline-success"
                        >
                          <i class="bx bx-edit-alt"></i>
                        </button>
                      </template>
                      <template v-slot:cell(delete)="data">
                        <button
                          @click="deleteUsrModal(data.item)"
                          class="btn btn-sm btn-outline-danger"
                        >
                          <i class="bx bx-trash-alt"></i>
                        </button>
                      </template>
                    </b-table>
                  </div>
                </div>
              </b-row>
              <b-row>
                <div class="col-sm-8"></div>
                <div class="col-sm">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                  ></b-pagination>
                </div>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      filter: "",
      audit_trail: [],
      perPage: 20,
      currentPage: 1,
      title: "Audit trail",
      tableFields: [
        "type",
        {label: "Activity", key: 'Changes'},
        "elementType",
        "ElementTitle",
        "performed_by",
        {label: "Performed date", key: 'created_date'},
      ],
      state: {
        loading: false,
        refresh: false,
        adding: false,
        updating: false,
        fetchingUser: false,
        loadDept: false,
      },
      data: null,
    };
  },
  computed: {
    rows() {
      return this.audit_trail.length;
    },
    all_numbers() {
      return this.$store.state.numbers;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  beforeMount() {
    this.$title("Audit Trail");
    this.getAuditTrail();
  },
  watch: {},
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async getAuditTrail() {
      this.state.loading = true;
      await this.axios
        .get("audit_trail")
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.audit_trail = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    refresh() {
      this.state.refresh = true;
      this.getAuditTrail();
    },
  },
};
</script>

<style scoped>
*,
html,
body {
  font-family: Ubuntu;
}

@media screen and (max-width: 578px) {
  .btn-add {
    margin-top: 1rem !important;
    margin-left: 2rem;
  }
  .card .left-label h6 {
    margin-left: 3rem !important;
  }

  .row-admin {
    margin-top: 60px !important;
  }
}
</style>