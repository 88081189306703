<template>
    <section>
        <div class="row mt-0 pt-0">
            <div class="col-sm-7">
                <div class="left-container mt-0">
                    <div class="row d-flex justify-content-center">
                        <div class="col-sm-2"></div>
                            <div class="message-cont w-75 h-75 col-sm-6">
                                <p style="" class="text-center p-3 mt-1 text-white text-bold">
                                    We facilitate a diverse and inclusive financial 
                                    sector that delivers real value to the economy
                                    and people, in particular unserved and underserved populations.
                                </p>
                            </div>
                        <div class="col-sm-2"></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-5">
                <b-row class="d-flex justify-content-center mt-5">
                    <b-img fluid  class="logo" :src="require('../assets/images/logo-1.svg')"/>
                </b-row>

                <b-row class="d-flex justify-content-center mt-5">
                    <div class="text-dark d-block text-center">
                        <h1 class="text-dark">Welcome,</h1>
                        <div class="clearfix"></div>
                        <small class="text-center">Please login to continue</small>
                    </div>
                </b-row>
                
                <b-row class="mt-5 d-flex justify-content-center">
                     <form class="mt-4 form-data p-4 w-75">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-transparent form-rounded" > <i class='bx bxs-user-circle bx-sm text-success'></i> </span>
                                </div>
                                    <input :value="email" readonly placeholder="Email address" type="email" class="form-control text-center email-input border form-rounded">
                                <span class="input-group-append">
                                    <div class="input-group-text bg-transparent form-rounded"><i class='bx bx-chevron-down bx-sm text-success'></i></div>
                                </span>
                            </div>
                        </div>
                        <div class="form-group mt-4">
                            <input type="password" value="" placeholder="Password" class="form-control form-input">
                        </div> 
                        <div class="form-group mt-4">
                            <router-link to="/admin">
                                <button style="height:40px;" type="submit" class="btn btn-success btn-block button">LOGIN</button>
                            </router-link>
                        </div>                       
                        <div class="row mt-4">
                            <div class="col-sm-12 text-right">
                                <a class="text-info mr-3" href="#">Forgot password</a>
                            </div>
                        </div>
                    </form>
                </b-row>
                
                <b-row class="d-flex justify-content-center social-container pt-5 mt-5">
                    <div>
                        <b-img  fluid class="social w-25" :src="require('../assets/images/social/facebook.png')"></b-img>
                        <b-img  fluid class="ml-2 social w-25" :src="require('../assets/images/social/twitter.png')"></b-img>
                        <b-img  fluid class="ml-2 social w-25" :src="require('../assets/images/social/instagram.png')"></b-img>
                    </div>
                </b-row>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:'login',
    components:{},
    mounted(){
        console.log("Email from login-1", this.$store.state.login_email);
    },
    data(){
        return {
            email: this.$store.state.login_email,
        }
    },
    methods: {
        loginHandler(){
            this.$router.replace(`/admin-page`);
        }
    }
}
</script>

<style scoped>
    .card-trx{
        background-color: transparent !important;
        box-shadow: 0 0 0 0 transparent !important;
        border: none !important;
    }
    *, html {
        margin:0;
        padding:0;
    }
    .left-container{
        background-image: url('../assets/images/login/login-wall.png');
        background-size: cover; 
        height: 900px;
    }

    .social-container{
        margin-top: 130px !important;
    }


    .login-form{
        margin-top: 280px;
        padding: 0;
    }

    .form-data .button{
        background-color: #0FA958 !important;
        border-radius: 20px;
        width: 100%;
    }

    .form-data .button:hover{
        background-color: #0f8345 !important;
        /* border-color: #fff; */
    }

    .form-data .button-blue{
        background-color: #6C63FF !important;
        border-radius: 20px;
        width: 70%;
        border: none;
    }

    .form-data .button-blue a{
        text-decoration: none;
    }

     .form-data .button-blue:hover{
         background-color: #423cc4 !important;
         border-color: #fff;
     }

    .form-data{
        background: #F3F3F3 !important;
        border-radius: 20px;
    }

    .message-cont{
        width: 311px;
        height: 140px;
        background: rgba(216, 208, 208, 0.438);
        /* opacity: 0.4; */
        border-radius: 27px;
        margin-top: 350px;
    }

    .message-cont p{
        opacity: 1;
        font-weight: 2000 !important;
    }
 
    @media screen and (max-width: 578px) {
        .login-form{
            margin-top: 100px;
            padding: 0;
        }
        .top-text{
            margin-left: 10%;
        }
        .login-form .button{
            text-align:center;
            margin-left: 10%;
        }
        .login-form .button-blue{
            text-align:center;
            margin-left: 10%;
        }

        .social-container{
            margin-top: 60px !important;
        }

        .left-container{
            height: 420px;
            background-size: cover;
        }

        .message-cont{
            margin-top: 180px;
            margin-bottom: 180px;
            margin-left: 20px;
            margin-right: 20px;
        }

        .message-cont p{
            margin-top: -30px;
            margin-bottom: 10px;
        }

        
    }

    

    @media screen and (max-width: 1200px) {
        /* .form-control {
            margin-left: 10%;
        } */

        .left-container {
            height: 100%;
        }
        
        .center-card{
            padding-left:154px;
        }

        .login-form .button{
            margin-left: 10%;
            width: 70%;
        }


        .login-form .button-blue{
            margin-left: 10%;
        }
    }


    @media (max-width: 768px) { 
       
     }


    @media (max-width: 992px) { 
        
     }

    @media (min-width: 1200px) { 
        
     }


    @media (min-width: 768px) { 
        .center-card{
            margin-left:0;
        }
     }

    .form-group{
        margin-top: 30px;
    }

    .form-rounded {
        border-radius: 1rem;
    }

    .form-input{
        border-radius: 50px !important;
        text-align: center;
    }

    .email-input{
        background-color: #F3F3F3 !important;
        border-right: none;
        border-left: none;
    }
    

    .form-control::placeholder{
        font-size: 13px;
        text-align: center;
    }

    .btn-block {
        width: 100% !important;
    }

    .btn-primary:focus .btn-primary.focus{
        box-shadow: 0 0 0 0.1rem rgba(48, 228, 102, 0.5) !important;
    }


    .forgot a:hover{
        transition: 0.5s ease-in-out;
        color: rgba(48, 228, 102, 0.5) !important;
        text-decoration: none;
    }

    .logo {
        width: 160px;
        height: 80px;
    }

    .center-card{
        margin-left:-254px;
    }

    .login-text{
        padding-right: 160px !important;
    }

    

</style>