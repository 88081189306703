<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 main mb-6">
      <div class="container-fluid">
        <b-row class="mt-5 mb-6 row-admin">
          <div class="col-sm col-md col-lg">
            <div class="card card-main mt-3 ml-0 mb-4">
              <div class="jumbotron card-main p-4">
                <b-row no-gutters>
                  <div class="col-sm-3 col-md-3 col-lg-3">
                    <div class="ml-2 mt-4 text-left top-label">
                      <h4 class="text-16">{{program.name}}</h4>
                    </div>
                  </div>
                  <div class="col-sm-3 col-md-3 col-lg-3">
                    <div class="text-left top-label">
                      <button class="btn btn-success text-white">
                        <span class="text-white clearfix mb-0 pb-0 text-17"
                          >{{program.indicators}}</span
                        >
                        <span class="text-white text-12">Indicators</span>
                      </button>
                    </div>
                  </div>
                </b-row>
                <b-row class="mb-0">
                <div class="col-sm-9 col-md-9"></div>
                <div class="col-sm col-md text-right">
                  <a-tooltip title="Refresh program data" placement="bottom" :color="'#87d068'">
                    <button 
                      @click="refresh"
                      type="button"
                      class="btn mr-3 mt-1 btn-secondary btn-sm rounded-20 border-none"
                    >
                      <b-icon-arrow-repeat               
                        font-scale="1.6"
                        :animation="state.refresh && state.loading ? 'spin' : ''"
                      />
                    </button>
                  </a-tooltip>
                </div>       
              </b-row>
              </div>
              <div
                class="
                  card-header
                  bg-white
                  rounded-top-right-20 rounded-top-left-20
                "
              >
                <div class="container">
                  <b-row no-gutters>
                    <div class="col-sm col-md">
                      <div class="table-responsive">
                        <div class="all-tabs">
                          <div
                            class="tab"
                            :class="{ active: selectedTabIndex == i }"
                            v-for="(tab, i) in shownTabs"
                            :key="i"
                            @click="setTab(i)"
                          >
                            {{
                              tab == "TheoryOfChange"
                                ? "Theory of change"
                                : tab == "LogFrame"
                                ? "Logframe"
                                : tab == "Risk"
                                ? "Risk Register"
                                : tab == "KnowledgeBase"
                                ? "Knowledge Base"
                                : tab
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-row>
                </div>
              </div>
              <div class="card-body pl-0 ml-0">
                <b-row no-gutters class="pb-3 px-2">
                  <div class="table-responsive">
                    <transition name="tabs-fade">
                      <keep-alive :max="3">
                        <Component
                          :is="shownTabs[selectedTabIndex]"
                          :tabName="compID"
                          @refresh="refresh"
                          :loading="state.loading"
                        />
                      </keep-alive>
                    </transition>
                  </div>
                </b-row>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </main>
  </div>
</template>

<script>
import decode from 'jwt-decode';
import TheoryOfChange from "./program-theory-of-change.vue";
import LogFrame from "./program-log-frame.vue";
import ResultChain from "./program-result-chain.vue";
import Reports from "./program-reports.vue";
import Risk from "./program-risk-register.vue";
import KnowledgeBase from "./program-knowdgebase.vue";
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
export default {
  components: {
    TheoryOfChange,
    LogFrame,
    ResultChain,
    Reports,
    KnowledgeBase,
    Risk,
  },
  data() {
    return {
      compID: uuidv4(),
      selectedTabIndex: 0,
      tabs: ["TheoryOfChange", "LogFrame", "Risk", "Assumptions", "KnowledgeBase"],
      pageTitle: "programs",
      selectedTab: ["LogFrame"],
      isLogFrame: this.$store.state.manager.logFrameTab,
      state: {
        loading: false,
        refresh: false
      }
    };
  },
  computed: {
    shownTabs() {
      if (this.isLogFrame) return this.selectedTab;
      return this.tabs;
    },
    program() {
      let project = sessionStorage.getItem("prg");
      const resData = decode(project);
      return resData;
    },
    token(){
      return localStorage.getItem("token");
    }
  },
  beforeMount(){
    this.getProgramLevels()
    this.getLogFrames()
    this.getALlRisks()
    this.getKnowledgeBase()
  },
  beforeDestroy() {
    this.$store.dispatch("manager/setLogFrameTab", false);
  },
  methods: {
    setTab(index) {
      if (isNaN(index)) return;
      if (index == this.selectedTabIndex) return;
      this.$set(this, "selectedTabIndex", index);
    },
    async getProgramLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getTOClevelsAll/${this.program.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setProgramToc",response.data);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getLogFrames() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getLogframe/${this.program.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setProgramLogfram", response.data);
            console.log("Logframe data", response.data)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getALlRisks() {
       const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
        params: {
          scope: "program",
          elementId: this.program.id
        }
      };
      this.state.loading = true;
      await axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}getRisks`,config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setProgramRisk",response.data)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
     async getKnowledgeBase() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
        params: {
          scope: "program",
          elementId: `${this.program.id}`
        }
      };
      this.state.loading = true;
      await this.axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}getKnoledgeBase`,config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setKnowledgeBase", response.data)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    refresh(){
      this.state.refresh = true;
      this.getProgramLevels();
      this.getLogFrames()
      this.getALlRisks()
      this.getKnowledgeBase()
    }
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

.card-main .paragraph {
  font-size: 11px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #9a9a9a !important;
}

.top-label p small {
  font-size: 12px !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Top card styles */
.top-card {
  background-color: rgba(119, 119, 119, 0.507);
  border-radius: 10px !important;
  padding: 20px 12px !important;
  width: 234px !important;
  height: 123px !important;
}

.top-card h3 {
  font-size: 16px !important;
  padding-bottom: 3px !important;
}

.top-card p {
  font-size: 15px !important;
  padding-top: 2.5px !important;
}
/* End for styles */

@media (min-width: 768px) {
  .table-responsive {
    overflow-x: hidden;
  }
}

.card ul li {
  list-style-type: none;
}

.card-stat {
  background-color: #fff !important;
}

/* icons */
.dash-img {
  width: 75px;
  height: 75px;
}
/* end of icons styles */

</style>