import { render, staticRenderFns } from "./program-contribs.vue?vue&type=template&id=2e4db8f7&scoped=true&"
import script from "./program-contribs.vue?vue&type=script&lang=js&"
export * from "./program-contribs.vue?vue&type=script&lang=js&"
import style0 from "./program-contribs.vue?vue&type=style&index=0&id=2e4db8f7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e4db8f7",
  null
  
)

export default component.exports