<template>
  <div>
    <adminTopBar :title="title" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 mt-4">
      <div class="container-fluid main-container mb-4">
        <div class="jumbotron pl-0 pr-0 pt-0 mt-0 b-none">
          <b-row>
            <div class="col-sm">
              <div class="card border-none bg-gray table-data rounded-20">
                <div class="card-header bg-gray border-none rounded-20">
                  <div class="row d-flex justify-content-between">
                    <div class="text-left left-label col-xs">
                      <h6 class="p-2 ml-2 mt-2">
                        Workflows/
                        <span class="text-secondary">My approvers list</span>
                      </h6>
                    </div>
                    <div class="p-2 col-xs4 d-inline">
                      <button
                        @click="showCreateModal"
                        class="
                          btn btn-outline-primary
                          mr-4
                          p-2
                          mt-2
                          btn-approvals btn-custom-primary
                          text-13
                        "
                      >
                        ADD NEW <i class="bx bx-plus"></i>
                      </button>
                      <router-link to="/user/admin/workflows">
                        <button
                          class="
                            btn btn-outline-primary
                            mr-4
                            p-2
                            mt-2
                            btn-approvals btn-custom-primary
                            text-13
                          "
                        >
                          <i class="bx bx-left-arrow-alt"></i> BACK
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <b-row>
                    <b-col class="text-left mb-2">
                      <h6 class="text-bold">
                        {{ resource.name }} / Approvers list
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <div class="d-flex justify-content-between row pl-3">
                      <button
                        class="
                          btn-filtering
                          col-sm
                          ml-3
                          mt-2
                          pr-5
                          pl-5
                          btn btn-outline-success btn-custom-primary
                        "
                      >
                        Asc
                      </button>
                      <button
                        class="
                          btn-filtering
                          col-sm
                          ml-3
                          mt-2
                          btn btn-outline-success btn-custom-primary
                          ml-2
                          mr-2
                          pr-5
                          pl-5
                        "
                      >
                        Desc
                      </button>
                      <button
                        @click="createVariantModal"
                        class="
                          btn-filtering
                          col-sm
                          ml-3
                          mt-2
                          pl-5
                          pr-5
                          btn btn-outline-success btn-custom-primary
                        "
                      >
                        +
                      </button>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-sm col-md"></div>
                    <div class="col-sm-4 col-md-4">
                      <form>
                        <div class="input-group mb-3 mt-2">
                          <input
                            type="search"
                            v-model="filter"
                            class="form-control input-search rounded-left-10"
                            aria-label="Search"
                            placeholder="Search a user..."
                          />
                          <div class="input-group-append">
                            <span class="input-group-text rounded-right-10">
                              <i class="bx bx-search text-success"></i>
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </b-row>
                  <b-row class="mb-2">
                    <div class="col-sm-9 col-md-9"></div>
                    <div class="col-sm col-md text-right">
                      <button
                        @click="refresh"
                        type="button"
                        class="btn btn-secondary btn-sm rounded-10 border-none"
                      >
                        <b-container v-if="state.loading && state.refresh">
                          <b-spinner small></b-spinner>
                          <span class="text-13 ml-2">Refreshing...</span>
                        </b-container>
                        <span v-else class="text text-13">Refresh</span>
                      </button>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-sm col-md mb-4">
                      <div class="table-responsive">
                        <b-container class="my-2" v-if="state.loading">
                          <a-skeleton
                            :loading="state.loading"
                            :paragraph="{ rows: 8 }"
                            active
                          ></a-skeleton>
                        </b-container>
                        <b-table
                          v-else
                          table-class="table-roles text-13"
                          striped
                          hover
                          thead-tr-class="tb-head text-left indent-20"
                          tbody-class="tb-body text-left indent-20"
                          :items="approvers"
                          :filter="filter"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :fields="headers"
                          show-empty
                        >
                          <template v-slot:cell(full_name)="data">
                            <span class="text-capitalize">{{
                              data.item.fullnames
                            }}</span>
                          </template>
                          <template v-slot:cell(edit)="data">
                            <button
                              @click="showEditModal(data.item)"
                              class="btn btn-sm btn-outline-success"
                            >
                              <i class="bx bx-edit-alt"></i>
                            </button>
                          </template>
                          <template v-slot:cell(delete)="data">
                            <button
                              @click="deleteModal(data.item)"
                              class="btn btn-sm btn-outline-danger"
                            >
                              <i class="bx bx-x"></i>
                            </button>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-sm-8"></div>
                    <div class="col-sm">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                      ></b-pagination>
                    </div>
                  </b-row>
                </div>
              </div>
            </div>
          </b-row>
        </div>
      </div>
    </main>
    <!-- Create & update approval -->
    <a-modal v-model="visible" :footer="null" title="Create approver">
      <form @submit.prevent="addApproval">
        <div class="form-group">
          <label>User</label>
          <b-input-group>
            <b-select
              class="form-input"
              @change="onChange"
              v-model="user"
              required
              :disabled="state.loadUsers"
            >
              <template #first>
                <b-select-option :value="null" selected disabled
                  >Select user</b-select-option
                >
              </template>
              <b-select-option
                v-for="item in users"
                :key="item.id"
                :value="item.id"
              >
                {{ item.fullnames }}
              </b-select-option>
            </b-select>
            <b-input-group-append>
              <b-icon-arrow-repeat
                class="bi-con"
                font-scale="1.5"
                :animation="state.loadUsers ? 'spin' : ''"
                @click="getUsers"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="form-group">
          <label>Level</label>
          <input
            placeholder="Enter number"
            type="number"
            class="form-control form-input"
            v-model="level"
          />
        </div>
        <div class="form-group">
          <label>Department</label>
          <input
            readonly
            type="text"
            placeholder="Auto generated"
            class="form-control form-input"
            v-model="department"
          />
        </div>
        <div class="form-group">
          <label>Description</label>
          <input
            placeholder="Enter description"
            type="text"
            class="form-control form-input"
            v-model="description"
          />
        </div>
        <button
          :disabled="state.saving"
          type="submit"
          class="btn btn-success btn-block"
        >
          <b-container v-if="state.saving">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Saving...</span>
          </b-container>
          <span v-if="!state.saving" class="text text-13">Save Approver</span>
        </button>
      </form>
    </a-modal>
    <a-modal
      v-model="visibleCreateVariant"
      :footer="null"
      title="Create variant"
    >
      <form>
        <div class="form-group">
          <label>Name</label>
          <input
            placeholder="Enter name"
            type="text"
            class="form-control form-input"
          />
        </div>
        <button
          :disabled="state.saving"
          type="submit"
          class="btn btn-success btn-block"
        >
          <b-container v-if="state.saving">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Saving...</span>
          </b-container>
          <span v-if="!state.saving" class="text text-13">Save Variant</span>
        </button>
      </form>
    </a-modal>
    <a-modal v-model="visibleEdit" :footer="null" title="Update approver">
      <form @submit.prevent="updateApprover(data)">
        <div class="form-group">
          <label>Level</label>
          <input
            placeholder="Level"
            type="text"
            class="form-control form-input"
            v-model="level"
          />
        </div>
        <button
          :disabled="state.saving"
          type="submit"
          class="btn btn-success btn-block"
        >
          <b-container v-if="state.saving">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Updating...</span>
          </b-container>
          <span v-if="!state.saving" class="text text-13">Update Approver</span>
        </button>
      </form>
    </a-modal>
    <!-- Delete approver modal -->
    <a-modal v-model="visibleDelete" :footer="null" title="Delete approver">
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to delete this information ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <a-popconfirm
            title="Are you sure delete this record?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteApprover(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="state.loading">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Deleting...</span>
              </b-container>
              <span v-if="!state.loading" class="text text-13">DELETE</span>
            </button>
          </a-popconfirm>
        </div>
        <div class="inline-block">
          <button
            @click="visibleDelete = false"
            type="submit"
            class="btn btn-secondary btn-block btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
      </b-row>
    </a-modal>
  </div>
</template>

<script>
import decode from "jwt-decode";
export default {
  components: {},
  data() {
    return {
      active: true,
      visible: false,
      visibleEdit: false,
      visibleDelete: false,
      visibleCreateVariant: false,
      perPage: 8,
      currentPage: 1,
      title: "Approval List",
      filter: "",
      headers: ["full_name", "Department", "Role", "level", "edit", "delete"],
      users: [],
      approvers: [],
      state: {
        loading: false,
        refresh: false,
        loadUsers: false,
        userLoad: false,
        saving: false,
      },
      user: null,
      user_details: null,
      level: null,
      department: "",
      description: "",
      data: null,
    };
  },
  beforeMount() {
    this.$title("ADMIN - Approval details");
    this.getApprovals();
    this.getUsers();
  },
  computed: {
    rows() {
      return this.approvers.length;
    },
    resource() {
      let resource = sessionStorage.getItem("resource");
      const resData = decode(resource);
      return resData;
    },
    token() {
      return localStorage.getItem("token");
    },
  },
  methods: {
    getUser() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.userLoad = true;
      this.axios
        .get(`getUser/${this.user}`, config)
        .then((response) => {
          this.state.userLoad = false;
          this.user_details = response.data;
          this.department = response.data.department;
        })
        .catch((error) => {
          this.state.userLoad = false;
          console.log(error.errorMessage);
        });
    },
    async addApproval() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        userId: this.user,
        workflowId: this.resource.id,
        level: this.level,
        description: this.description,
      };
      this.state.saving = true;
      await this.axios
        .post("AddapproverList", requestData, config)
        .then((response) => {
          if (!response.data.message) {
            this.visible = false;
            this.state.saving = false;
            this.$notification["success"]({
              message: "SAVED!",
              description: `Approver saved successfully.`,
              duration: 5,
            });
          } else {
            this.$notification["error"]({
              message: "ERROR!",
              description: `${response.data.message}`,
              duration: 5,
            });
            this.state.saving = false;
          }
        })
        .catch((error) => {
          this.$message.error(`${error.errorMessage}`);
          this.state.saving = false;
        });
    },
    async getUsers() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadUsers = true;
      await this.axios
        .get("getUsers", config)
        .then((response) => {
          if (response.data) {
            this.state.loadUsers = false;
            this.users = response.data;
          } else {
            this.state.loadUsers = false;
          }
        })
        .catch(() => {
          this.state.loadUsers = false;
        });
    },
    async getApprovals() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getApproverList/${this.resource.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.approvers = response.data;
          } else {
            this.state.loading = false;
            this.$notification["error"]({
              message: "ERROR!",
              description: `Something wrong when getting workflows`,
              duration: 5,
            });
          }
        })
        .catch(() => {
          // this.$notification["error"]({
          //   message: "ERROR!",
          //   description: `${error.errorMessage}`,
          //   duration: 5,
          // });
          this.state.loading = false;
        });
    },
    async updateApprover(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      const reqData = {
        level: this.level,
      };

      this.state.saving = true;
      await this.axios
        .put(`updateAproversList/${param.id}`, reqData, config)
        .then((response) => {
          if (!response.message) {
            this.state.saving = false;
            this.visibleEdit = false;
            this.getApprovals();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Approver changed successfully.`,
              duration: 5,
            });
          } else {
            this.$notification["error"]({
              message: "SUCCESS!",
              description: `${response.data.message}`,
              duration: 5,
            });
            this.state.saving = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.saving = false;
        });
    },
    async deleteApprover(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.state.loading = true;
      await this.axios
        .delete(`deleteAproversList/${param.id}`, config)
        .then((response) => {
          if (response) {
            this.state.loading = false;
            this.visibleDelete = false;
            this.getApprovals();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Approver deleted successfully.`,
              duration: 5,
            });
          } else {
            this.state.loading = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.loading = false;
        });
    },
    onChange(value) {
      console.log(value);
      this.getUser();
    },
    refresh() {
      this.state.refresh = true;
      this.getApprovals();
    },
    showCreateModal() {
      this.user = "";
      this.level = "";
      this.description = "";
      this.visible = true;
    },
    showEditModal(param) {
      this.data = param;
      this.visibleEdit = true;
      this.level = param.level;
    },
    deleteModal(param) {
      this.data = param;
      this.visibleDelete = true;
    },
    createVariantModal() {
      this.visibleCreateVariant = true;
    },
    handleDelete() {
      this.visibleDelete = false;
    },
    cancel(e) {
      console.log(e);
      this.visibleDelete = false;
      this.$message.error("Deleting approver has been canceled.");
    },
  },
};
</script>

<style scoped>
*,
html,
body {
  font-family: Ubuntu;
}
</style>