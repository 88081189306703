<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 main mb-6">
        <div class="container-fluid">
          <b-row class="mt-0">
            <div class="col-sm col-md col-lg">
              <div class="card card-main mt-3 ml-0 mb-6">
                <div class="jumbotron card-main p-4">
                    <b-row no-gutters>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="ml-2 mt-4 text-left top-label">
                                <h4 class="text-16">{{program.name}} </h4>
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class=" text-left top-label">
                                <button class="btn btn-success text-white">
                                    <span class="text-white clearfix mb-0 pb-0 text-17">{{program.indicators}}</span>
                                    <span class="text-white text-12">Indicators</span>
                                </button>
                            </div>
                        </div>
                    </b-row>
                </div>
                <div class="card-body pl-0 ml-0 bg-white rounded-top-right-20 rounded-top-left-20">
                  <b-container>
                    <b-row>
                      <div class="col-sm col-md">
                        <h6 class="text-left mb-3">Add New Knowledge Base</h6>
                        <div class="border-bottom border-3 my-2"></div>
                      </div>
                    </b-row>
                    <b-row>
                      <div class="col-sm col-md text-left main-form col-lg">
                        <form @submit.prevent="addKnowledgeBase">
                          <b-row>
                            <div class="col-sm col-md">
                              <div class="form-group mt-4">
                                <input
                                  placeholder="Title"
                                  type="text"
                                  class="form-control form-input text-left"
                                  v-model="formData.title"
                                />
                              </div>
                              <div class="form-group">
                                <select v-model="formData.scope" class="custom-select bg-gray-white">
                                  <option selected value="" disabled>
                                    Scope
                                  </option>
                                  <option value="program">Program</option>
                                  <option value="pillar">Pillar</option>
                                  <option value="project">Project</option>
                                </select>
                              </div>
                              <div class="form-group">
                                <input
                                  placeholder="Sources"
                                  type="number"
                                  class="form-control form-input text-left"
                                  v-model="formData.sources"
                                />
                              </div>
                              <b-form-group
                                class="form-text-label mt-2"
                                label-for="desc"
                                label="Description"
                              >
                                <b-form-textarea
                                  id="desc"
                                  placeholder="Enter Description"
                                  rows="6"
                                  resize
                                  class="form-text-modal text-left"
                                  v-model="formData.description"
                                ></b-form-textarea>
                              </b-form-group>
                            </div>
                            <div class="col-sm col-md">
                              <h5 class="mt-2">Attachments</h5>
                              <div class="card card-2">
                                <div class="card-body">
                                  <div class="d-block justify-content-center">
                                    <b-img :src="dragIcon" />
                                    <div v-if="!formData.files.length">
                                      <div
                                        :class="[
                                          'dropZone',
                                          dragging ? 'dropZone-over' : '',
                                        ]"
                                        @dragenter="dragging = true"
                                        @dragleave="dragging = false"
                                        class="mt-2"
                                      >
                                        <div
                                          class="dropZone-info"
                                          @drag="filesChange"
                                        >
                                          <span
                                            class="
                                              fa fa-cloud-upload
                                              dropZone-title
                                            "
                                          ></span>
                                          <span class="dropZone-title"
                                            >Drag and Drop your files here</span
                                          >
                                          <div
                                            class="
                                              dropZone-upload-limit-info
                                              d-none
                                            "
                                          >
                                            <div>
                                              extension supported: pdf,images
                                              (.jpg,.png.jpeg)
                                            </div>
                                            <div>maximum file size: 5 MB</div>
                                          </div>
                                        </div>
                                        <input multiple ref="files" type="file" @change="filesChange" />
                                      </div>
                                    </div>
                                    <div v-else class="dropZone-uploaded">
                                      <div class="dropZone-uploaded-info">
                                        <span class="dropZone-title"
                                          >
                                            <b-row class="my-3">
                                              <div class="col-sm-12 col-md-12">
                                                <span v-for="(item,index) in formData.files" :key="index"> <i class="bx bxs-file"></i> {{item.name}} </span>
                                              </div>  
                                            </b-row> 
                                            </span>
                                        <a
                                          type="button"
                                          class="btn-link removeFile"
                                          @click="removeFile"
                                          >Remove File(s)</a
                                        >
                                      </div>
                                    </div>

                                    <div class="uploadedFile-info d-none">
                                      <div v-for="(item,index) in formData.files" :key="index">fileName: <p> {{item.name}}</p></div>
                                      <div>
                                        fileZise(bytes): {{ file ?  file.size : null }}
                                      </div>
                                      <div>extension:{{ extension }}</div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <div class="left-border mt-3 mr-3"></div>
                                      <div class="mt-2">OR</div>
                                      <div class="right-border ml-3 mt-3"></div>
                                    </div>
                                    <div class="d-block">
                                      <label
                                        class="
                                          btn
                                          pl-5
                                          pr-5
                                          text-white
                                          btn-light
                                          rounded
                                          btn-browse
                                        "
                                        for="my-file-selector"
                                      >
                                        <input
                                          @change="filesChange"
                                          id="my-file-selector"
                                          type="file"
                                          class="d-none"
                                          multiple
                                        />
                                        Browse
                                      </label>
                                    </div>
                                    <div v-if="file1" class="mt-3">
                                      Selected file:
                                      <p v-for="(item,index) in formData.files" :key="index">{{item.name}}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-row>
                          <b-row>
                            <div class="col-sm col-md">
                              <div
                                class="
                                  d-block
                                  justify-content-center
                                  text-center
                                "
                              >
                                <button
                                  type="submit"
                                  class="btn btn-warning rounded-20 text-13"
                                >
                                  <b-container v-if="state.adding">
                                    <b-spinner small></b-spinner>
                                    <span class="text-13 ml-2">Saving...</span>
                                  </b-container>
                                  <span v-if="!state.adding" class="text text-13">Add New Knowledge</span>
                                </button>
                              </div>
                            </div>
                          </b-row>
                        </form>
                      </div>
                    </b-row>
                  </b-container>
                </div>
              </div>
            </div>
          </b-row>
        </div>
    </main>
  </div>
</template>

<script>
import decode from 'jwt-decode';
import dragIcon from "../../../../../assets/images/icons/drag-drop.svg";
export default {
  component: {
    dragIcon,
  },
  delimiters: ["${", "}"],
  data() {
    return {
      active: true,
      dragIcon: dragIcon,
      file1: null,
      file: null,
      dragging: false,
      pageTitle: "programs",
      formData: {
        title: "",
        description: "",
        scope: "",
        sources: null,
        files:[]
      },
      state: {
        loading: false,
        adding:false
      },
    };
  },
  computed: {
    program() {
      let project = sessionStorage.getItem("prg");
      const resData = decode(project);
      return resData;
    },
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
    token(){
      return localStorage.getItem("token");
    }
  },
  watch: {},
  mounted(){
    console.log("Program details", this.program)
  },
  methods: {
    filesChange(){
      for(let i=0; i < this.$refs.files.files.length; i++){
        this.formData.files.push(this.$refs.files.files[i])
        this.dragging=false;
      }

      if (!this.$refs.files.files.length) {
        this.dragging = false;
        return;
      }
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files);
    },
    // createFile(files) {
    //   if (file.size > 5000000) {
    //     this.$message.warning("please check file size not over 5 MB.", 7);
    //     this.dragging = false;
    //     return;
    //   }

    //   this.file = file;
    //   this.dragging = false;
    //   this.formData.files.push(this.file)
    //   console.log("Form data", this.formData);
    // },
    removeFile() {
      this.formData.files = [];
    },
    async addKnowledgeBase() {
      var self = this;
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      const req = new FormData();
      req.append("Title", this.formData.title);
      req.append("Description", this.formData.description);
      req.append("scope", this.formData.scope);
      req.append("sources", this.formData.sources);
      for(let i = 0; i < this.formData.files.length; i++){
        let file = self.formData.files[i];
        req.append('files[' + i + ']', file);
      }
      req.append("elementId", this.program.id);
      this.state.adding = true;
      await this.axios
        .post("AddKnoledgeBase", req, config)
        .then((response) => {
          if (response.data.message) {
            this.state.adding = false;
            this.$message.error(`${response.data.message}`, 4);
          } else {
            this.state.adding = false;
            this.visibleCreateCtg = false;
            this.$router.push({name: 'program-details'})
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Knowledge base has been added successfully.`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.adding = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  border-radius: 10px !important;
  text-align: center !important;
}
</style>