<template>
  <div class="main">
    <top-banner title="Program Assumptions" />
    <div class="card card-2 m-0 p-0">
      <div class="card-body p-0 m-0">
        <b-row>
          <div class="col-sm col-md">
            <div class="float-right">
              <span class="ml-n5 cursor-pointer">
                <a-input
                  v-model="filter"
                  style="width: 180px"
                  placeholder="Search"
                >
                  <a-icon slot="prefix" type="search" />
                </a-input>
              </span>
            </div>
          </div>
        </b-row>
        <div class="table-responsive mt-2">
          <b-table
            table-class="table-logframe text-13"
            striped
            hover
            thead-tr-class="tb-head text-left indent-20"
            tbody-class="tb-body text-left indent-20"
            :items="assumptions"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            show-empty
          >
            <template v-slot:cell(assumption_From_The_start)="data">
              <span>{{data.item.assumptionFromThestart}}</span>
            </template>
             <template v-slot:cell(edit)="data">
              <button
                @click="showEditModal(data.item)"
                class="btn btn-sm btn-outline-success"
              >
                <i class="bx bx-edit-alt"></i>
              </button>
            </template>
            <template v-slot:cell(delete)="data">
              <button
                @click="deleteModal(data.item)"
                class="btn btn-sm btn-outline-danger"
              >
                <i class="bx bx-x"></i>
              </button>
            </template>
          </b-table>
        </div>
        <b-row class="my-4">
          <div class="col-sm">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
            ></b-pagination>
          </div>
          <div class="col-sm-4"></div>
        </b-row>
        <b-row>
          <div class="col-md col-lg"></div>
          <div class="col-sm-3 col-md-3 col-lg-3">
            <a
              @click="createConfirmModal"
              type="button"
              class="
                btn btn-secondary btn-top-create
                float-right
                mr-2
                pr-4
                pl-4
                pt-2
                pb-2
                mb-3
              "
              role="button"
            >
              Add assumption
            </a>
          </div>
        </b-row>
      </div>
    </div>
    <a-modal v-model="visibleCreate" :footer="null" title="Add program assumption">
      <form @submit.prevent="addAssumption">
        <b-row>
          <div class="col-sm-12 col-md-12">
            
            <b-form-group>
              <label for="">TOC level</label>
              <b-input-group>
                <b-select
                  class="rounded-20 select-modal bg-gray-white text-13 mr-3"
                  v-model="assumption.level"
                  required
                  :disabled="state.levels"
                  @change="onChangeLevel"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled>Select result chain level</b-select-option>
                  </template>
                  <b-select-option
                    v-for="item in tocLevels"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.level }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right ml-n2"
                    font-scale="1.5"
                    :animation="state.levels ? 'spin' : ''"
                    @click="getTocLevels"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <div class="form-group">
              <b-input-group v-if="assumption.level">
                <b-select
                  class="custom-select bg-white text-13 mr-2"
                  v-model="assumption.indicator"
                  required
                  :disabled="state.loadingLogFrame"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled
                      >Select Indicator
                    </b-select-option>
                  </template>
                  <b-select-option
                    v-for="item in levelIndicators"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.Title }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right"
                    font-scale="1.5"
                    :animation="state.loadingLogFrame ? 'spin' : ''"
                    @click="getTocLevelIndicators"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="form-group">
              <label for="">Assumption Description</label>
              <textarea
                  placeholder=""
                  type="text"
                  class="form-control text-13"
                  rows="2"
                  v-model="assumption.as_from_start"
              ></textarea>
            </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <div class="inline-block pr-4">
            <a
              @click="visibleCreate=false"
              type="button"
              class="btn btn-secondary btn-block btn-cancel text-13"
              >CANCEL</a
            >
          </div>
          <div class="inline-block">
            <button
              type="submit"
              class="btn btn-primary btn-block btn-confirm text-13"
            >
              <b-container v-if="state.adding">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Saving...</span>
              </b-container>
              <span v-if="!state.adding" class="text text-13">Submit</span>
            </button>
          </div>
        </b-row>
      </form>
    </a-modal>
   <!-- edit modal -->
    <a-modal v-model="visibleEdit" :footer="null" title="Update Assumption">
      <form @submit.prevent="updateAssumption(data)">
        <b-row>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <input
                placeholder="Title"
                type="text"
                class="form-control form-input-modal text-13"
                v-model="assumption.title"
              />
            </div>
            <b-form-group>
              <b-input-group>
                <b-select
                  class="rounded-20 select-modal bg-gray-white text-13 mr-3"
                  v-model="assumption.level"
                  required
                  :disabled="state.levels"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled
                      >Select result chain level</b-select-option
                    >
                  </template>
                  <b-select-option
                    v-for="item in levels"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.level }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right ml-n2"
                    font-scale="1.5"
                    :animation="state.levels ? 'spin' : ''"
                    @click="getLevels"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <div class="form-group">
              <input
                placeholder="Assumption from start"
                type="text"
                class="form-control form-input-modal text-13"
                v-model="assumption.as_from_start"
              />
            </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <div class="inline-block pr-4">
            <button
              type="submit"
              class="btn btn-primary btn-block btn-confirm text-13"
            >
              <b-container v-if="state.saving">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Updating...</span>
              </b-container>
              <span v-if="!state.saving" class="text text-13">Ok</span>
            </button>
          </div>
          <div class="inline-block">
            <a
              @click="visibleEdit=false"
              type="button"
              class="btn btn-secondary btn-block btn-cancel text-13"
              >CANCEL</a
            >
          </div>
        </b-row>
      </form>
    </a-modal>
    <!-- Delete  modal -->
    <a-modal v-model="visibleDelete" :footer="null" title="Delete risk">
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to delete this information ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <a-popconfirm
            title="Are you sure delete this assumption ?"
            ok-text="Yes"
            cancel-text="No"
            @confirm=" deleteAssumption(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="state.deleting">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Deleting...</span>
              </b-container>
              <span v-if="!state.deleting" class="text text-13">DELETE</span>
            </button>
          </a-popconfirm>
        </div>
        <div class="inline-block">
          <button
            @click="visibleDelete = false"
            type="button"
            class="btn btn-secondary btn-block btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
      </b-row>
    </a-modal>
  </div>
</template>

<script>
import decode from "jwt-decode";
export default {
  name: "projects-tab",
  data() {
    return {
      visibleCreate: false,
      visibleCreateCtg: false,
      visibleDelete: false,
      visibleEdit: false,
      title: null,
      description: null,
      state: {
        adding: false,
        loading: false,
        deleting: false,
        saving: false,
        refresh: false,
        levels: false,
        loadElem: false,
      },
      tocLevels: [],
      levelIndicators: [],
      assumption: {
        level: null,
        indicator: null,
        title: null,
        as_from_start: null,
      },
      fields: [
        {
          label: 'assumption',
          key: 'description'
        },
        {
          label: 'Indicator',
          key: 'indicator_title'
        },
        // "TOC Level",
        // "TOC Box",
        "edit",
        // "delete",
      ],
      perPage: 8,
      currentPage: 1,
      filter: "",
      data: null,
      levels: [],
      elements: [],
      assumptions: [],
    };
  },
  computed: {
    rows() {
      return this.assumptions?.length;
    },
    project(){
      const storeData = sessionStorage.getItem("pjt")
      const projectData = this.$store.state.project_data;
      var decoded = null;
      if(storeData){
        decoded = decode(storeData)
      }
      return projectData ? projectData : decoded;
    },
    token() {
      return localStorage.getItem("token");
    },
    program() {
      return this.$store.state.current_program;
    },
  },
  watch: {},
  beforeMount() {
     this.getAssumptions();
  },
  mounted() {
    this.getAssumptions();
    this.getTocLevels();
  },
  methods: {
    async getAssumptions() {
      this.state.assumptions = true;
      await this.axios
        .get(`program/assumption/${this.program.id}`,)
        .then((response) => {
          if (response.data) {
            this.state.assumptions = false;
            this.assumptions = response.data;
          } else {
            this.state.assumptions = false;
          }
        })
        .catch(() => {
          this.state.assumptions = false;
        });
    },
    async getLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.levels = true;
      await this.axios
        .get(`getAllProjectResultchains/${this.project.id}`,config)
        .then((response) => {
          if (response.data) {
            this.state.levels = false;
            this.levels = response.data;
          } else {
            this.state.levels = false;
          }
        })
        .catch(() => {
          this.state.levels = false;
        });
    },
    onChangeLevel(value) {
      console.log(value)
      this.levelItem = value;
      if (value) this.getTocLevelIndicators();
    },
    async getTocLevels() {
      this.state.loading = true;
      await this.axios
        .get(`getTocLevels/${this.program.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.tocLevels = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getTocLevelIndicators() {
      this.state.loadingLogFrame = true;
      await this.axios
        .get(`getIndicatorByElement/${this.assumption.level}`)
        .then((response) => {
          if (response.data) {
            this.state.loadingLogFrame = false;
            this.levelIndicators = response.data;
          } else {
            this.state.loadingLogFrame = false;
          }
        })
        .catch(() => {
          this.state.loadingLogFrame = false;
        });
    },
    async addAssumption() {
      const requestData = {
        indicator: this.assumption.indicator,
        assumption: this.assumption.as_from_start,
        programId: this.program.id
      };
      this.state.adding = true;
      await this.axios
        .post("program/assumption", requestData)
        .then((response) => {
          if (response.data.message) {
            this.state.adding = false;
            this.$message.error(`${response.data.message}`, 4);
          } else {
            this.$emit('refresh');
            this.state.adding = false;
            this.visibleCreate = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Assumption has been added successfully.`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.adding = false;
        });
    },
    async updateAssumption(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      const reqData = {
        ResultChainLevel: this.assumption.level,
        Title: this.assumption.title,
        assumptionFromThestart: this.assumption.as_from_start,
        projectId: this.project.id
      }

      this.state.saving = true;
      await this.axios
        .put(`updateAssuption/${param.id}`,reqData,config)
        .then((response) => {
          if (response) {
            this.state.saving = false;
            this.visibleEdit = false;
            this.$emit('refresh');
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Assumption has been changed successfully.`,
              duration: 5,
            });
          } else {
            this.state.saving = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.saving = false;
        });
    },
    async deleteAssumption(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.state.deleting = true;
      await this.axios
        .delete(`deleteAssumption/${param.id}`, config)
        .then((response) => {
          if (response) {
            this.state.deleting = false;
            this.visibleDelete = false;
            this.$emit('refresh');
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Assumption deleted successfully.`,
              duration: 5,
            });
          } else {
            this.state.deleting = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.deleting = false;
        });
    },
    createConfirmModal() {
      this.visibleCreate = true;
    },
    cancelConfirmModal() {
      this.visibleCreate = false;
    },
    deleteModal(param) {
      this.data = param;
      this.visibleDelete = true;
    },
    showEditModal(param) {
      this.data = param;
      this.visibleEdit = true;
      this.assumption.title = param.Title;
      this.assumption.level = param.ResultChainLevel;
      this.assumption.as_from_start = param.assumptionFromThestart;
    },
    cancel(e) {
      console.log(e);
      this.visibleDelete = false;
      this.$message.error("Deleting assumption has been canceled.");
    },
  },
};
</script>

<style scoped>
.table-all {
  border: 1px solid #cdd0d1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-top-create {
  background: #0fa958 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.table-all .tbody-row td {
  text-align: left;
}

.row-1 {
  border: 1px solid #f5f6f7 !important;
}

.card-2 {
  border: none !important;
  background-color: transparent !important;
}

.table-all .top-panel td span {
  font-size: 13px !important;
}

.table-all .theader-projects th {
  font-size: 13px !important;
}

.table-all th {
  text-align: left !important;
}

@media (max-width: 768px) {
  .table-all .theader-projects .table-search {
    width: 165px !important;
  }
}
</style>