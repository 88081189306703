<template>
     <b-row>
       <div class="col-sm-5 col-md-5 text-left main-wrapper col-lg-5">
          <form>
                <div class="form-group">
                  <b-row>
                    <div class="col-sm-9 col-md-9">
                        <select class="custom-select bg-white" >
                          <option selected value="" disabled>Select Indicator</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                      </select>
                    </div>
                    <div class="col-sm col-md">
                      <input placeholder="Value" type="number" class="form-control form-input-login">
                    </div>
                  </b-row>
                </div>
            <div class="form-group mt-3">
                  <b-row>
                    <div class="col-sm-9 col-md-9">
                        <select class="custom-select bg-white" >
                          <option selected value="" disabled>Select Indicator</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                      </select>
                    </div>
                    <div class="col-sm col-md">
                      <input placeholder="Value" type="number" class="form-control form-input-login">
                    </div>
                  </b-row>
                </div>
              <div class="form-group mt-3">
                  <b-row>
                    <div class="col-sm-9 col-md-9">
                        <select class="custom-select bg-white" >
                          <option selected value="" disabled>Select Indicator</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                      </select>
                    </div>
                    <div class="col-sm col-md">
                      <input placeholder="Value" type="number" class="form-control form-input-login">
                    </div>
                  </b-row>
                </div>
            
              <div class="input-group mb-3">
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="inputGroupFile03" aria-describedby="inputGroupFileAddon03">
                  <label class="custom-file-label bg-white" for="inputGroupFile03">Narative report</label>
                </div>
            </div>
        </form>
       </div>
     </b-row>
</template>

<script>
export default {
  props: ["store"],
  data() {
    return {
      email: ""
    };
  },
  methods: {
    //nextStep() {
    /*if (this.email.length > 0) return true;

      this.$bvToast.toast(`El formluario tiene campos obligatorios`, {
        title: "Error",
        autoHideDelay: 2000,
        variant: "danger",
        toaster: "b-toaster-bottom-right"
      });
      this.$emit("error", true);
      return false;*/
    //}
  }
};
</script>

<style scoped>
.main-wrapper .form-group label, 
.main-wrapper .form-group input::placeholder,
.form-text,.form-text::placeholder,
.form-text-label,.form-control,
.custom-select
{
  font-size: 13px !important;
}
</style>
