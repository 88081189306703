<template>
  <section>
    <div class="row mt-0 pt-0">
      <div class="col-sm-7">
        <div class="left-container mt-0">
          <div class="bg-overlay">
            <div class="row d-flex justify-content-center">
              <div class="col-sm-2"></div>
              <div class="message-cont w-75 h-75 col-sm-6">
                <p style="" class="text-center p-3 mt-1 text-white text-bold">
                  We facilitate a diverse and inclusive financial sector that
                  delivers real value to the economy and people, in particular
                  unserved and underserved populations.
                </p>
              </div>
              <div class="col-sm-2"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isTab" class="col-sm-5">
        <b-row class="d-flex justify-content-center mt-5">
          <b-img fluid class="logo" :src="logo" />
        </b-row>

        <b-row class="d-flex justify-content-center mt-5">
          <div class="text-dark d-block text-center">
            <h1 class="text-dark">Reset your password</h1>
            <div class="clearfix"></div>
          </div>
        </b-row>

        <b-row class="mt-5 d-flex justify-content-center slide-out">
          <div class="mt-4 form-data p-4 w-75">
            <form @submit.prevent="Submit">
              <div class="form-group">
                <input
                  v-model="email"
                  required
                  type="email"
                  class="form-control form-input-login bg-white"
                  placeholder="Email"
                />
              </div>
              <div class="form-group mt-4">
                <button
                  style="height: 30px"
                  type="submit"
                  class="btn btn-success text-13 btn-block button"
                  :disabled="state.loading"
                >
                  <b-container v-if="state.loading">
                      <b-spinner small type="grow"></b-spinner>
                      <span class="text-13 ml-2">Resetting password</span>
                  </b-container>
                  <span v-else>Forgot password</span>
                 
                </button>
              </div>
            </form>

            <div class="row mt-4">
              <div class="col-sm-12 text-right">
                <router-link :to="{name: 'login'}">Login</router-link>
                <!-- <a class="text-info mr-3" href="https://outlook.office365.com/mail/inbox"></a> -->
              </div>
            </div>
          </div>
        </b-row>
      </div>
      <div v-else class="col-sm-5">
        <b-row class="d-flex justify-content-center mt-5">
          <b-img fluid class="logo" :src="logo" />
        </b-row>

        <b-row class="d-flex justify-content-center mt-5">
          <div class="text-dark d-block text-center">
            <h1 class="text-dark">Reset password</h1>
            <div class="clearfix"></div>
          </div>
        </b-row>

        <b-row
          id="slider"
          v-bind:class="
            isTab
              ? 'slide-in mt-5 d-flex justify-content-center'
              : 'slide-out mt-5 d-flex justify-content-center'"
        >
          <form @submit.prevent="verifyResetCode" class="mt-4 form-data p-4 w-75">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text input-pretend bg-white form-rounded"
                  >
                    <i class="bx bxs-user-circle bx-sm text-success"></i>
                  </span>
                </div>
                <input
                  :value="email"
                  readonly
                  placeholder="Email address"
                  type="email"
                  class="form-control text-center email-input border form-rounded"
                />
                <span class="input-group-append">
                  <div
                    class="
                      input-group-text input-append
                      bg-transparent
                      form-rounded
                    "
                  >
                    <i class="bx bx-chevron-down bx-sm text-success"></i>
                  </div>
                </span>
              </div>
            </div>

            <p class="mt-3">{{responseMessage}}</p>
            <template v-if="showCodeInput">
              <div class="form-group mt-2">
                <input
                  v-model="reset_code"
                  type="password"
                  maxlength="10"
                  placeholder="Reset Code"
                  class="form-control bg-white form-input-login"
                  required
                />
              </div>
              <div class="form-group mt-4">
                <button
                  style="height: 30px"
                  type="submit"
                  class="btn btn-success btn-block button"
                  :disabled="state.reset_code"
                  
                >
                  <b-container v-if="state.reset_code">
                      <b-spinner small type="grow"></b-spinner>
                      <span class="text-13 ml-2">Verifying code</span>
                  </b-container>
                  <span class="text text-13">Verify code</span>
                </button>
              </div>
            </template>
             <a v-else class="btn btn-primary mr-3" :href="resetLink">Reset your password</a>
          </form>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import logo from "../assets/images/logo-1.svg";
// import encode from 'jwt-encode';
export default {
  name: "reset_password",
  components: {},
  data() {
    return {
      email: "",
      reset_code: null,
      isTab: false,
      logo: logo,
      responseMessage: null,
      resetLink: null,
      showCodeInput: false,
      state: {
        loading: false,
        reset_code: false,
      },
      visibleLogin: false
    };
  },
  methods: {
    openAFRlogin(){
      let host = process.env.VUE_APP_API_ENDPOINT.replace("/api/", "")
      console.log(host)
      var fileLink = document.createElement("a");
      fileLink.href = `${host}/login/azure`;
      // fileLink.setAttribute("href", `${host}/login/azure`);
      document.body.appendChild(fileLink);

      fileLink.click();
      // this.visibleLogin=true;
    },
    loginHandler() {
      if(!this.email){
          this.$message.error('Email field is required',3)
      }else{
        this.$store.state.login_email = this.email;
        this.$store.commit("setIsInternetConnected");
        this.isTab = true;
        //    console.log("Email login", this.$store.state.login_email);
        }
    },
    async Submit() {
      const requestData = {
        email: this.email,
      };
      this.state.loading = true;
      await this.axios
        .post("reset_password/1", requestData)
        .then((response) => {
          let respData = response.data
          
          if (respData) {
            this.isTab = true
            this.responseMessage = respData.message
            this.showCodeInput = respData.showCodeInput
            this.resetLink = respData.link
          }else{
            this.state.loading = false;
          }
        })
        .catch((error) => {
          this.state.loading = false;
          if (error.response) {
            console.log(error.response.data);
            console.log("Error x", error);
          }
          this.$notification["error"]({
            message: "ERROR!",
            description: error.errorMessage,
            duration: 5,
          });
        });
    },
    async verifyResetCode() {
      const requestData = {
        reset_code: this.reset_code,
        email: this.email,
      };
      this.state.loading = true;
      await this.axios
        .post("reset_password/verify_code", requestData)
        .then((response) => {
          let respData = response.data
          this.$notification["success"]({
            message: "Notice",
            description: respData.message,
            duration: 5,
          });
          localStorage.setItem('resetPasswordData', JSON.stringify({
            'email': this.email,
            'code': this.reset_code,
            "user": respData.user,
          }))
          this.$router.push({name: 'reset_password_enter_new'})
        })
        .catch((error) => {
          console.log(error)
          this.state.loading = false;
          if (error.response) {
            console.log(error.response.data);
            console.log("Error x", error);
          }
          this.$notification["error"]({
            message: "ERROR!",
            description: error.errorMessage,
            duration: 5,
          });
        });
    },
  },
  beforeMount(){
  },
  mounted(){
    this.$title("Forgot password");
  }
};
</script>

<style scoped>
.card-trx {
  background-color: transparent !important;
  box-shadow: 0 0 0 0 transparent !important;
  border: none !important;
}
*,
html {
  margin: 0;
  padding: 0;
}
.left-container {
  background-image: url("/bg_login.jpeg");
  background-size: cover;
  height: 900px;
}
.bg-overlay{
  background: rgb(16 168 88 / 34%);
  height: 100%;
}

.social-container {
  margin-top: 120px !important;
}

.login-form {
  margin-top: 280px;
  padding: 0;
}

.form-data .blink {
  background-color: #0fa958 !important;
}

.form-data .button {
  background-color: #0fa958 !important;
  border-radius: 20px;
  width: 100%;
}

.form-data .button:hover {
  background-color: #0f8345 !important;
  /* border-color: #fff; */
}

.form-data .button-blue {
  background-color: #6c63ff !important;
  border-radius: 20px;
  width: 70%;
  border: none;
}

.form-data .button-blue a {
  text-decoration: none;
}

.form-data .button-blue:hover {
  background-color: #423cc4 !important;
  border-color: #fff;
}

.form-data {
  background: #f3f3f3 !important;
  border-radius: 20px;
}

.message-cont {
  width: 311px;
  height: 140px;
  background: rgba(216, 208, 208, 0.438);
  /* opacity: 0.4; */
  border-radius: 27px;
  margin-top: 350px;
}

.message-cont p {
  opacity: 1;
  font-weight: 2000 !important;
}

@media screen and (max-width: 578px) {
  .login-form {
    margin-top: 100px;
    padding: 0;
  }
  .top-text {
    margin-left: 10%;
  }
  .login-form .button {
    text-align: center;
    margin-left: 10%;
  }
  .login-form .button-blue {
    text-align: center;
    margin-left: 10%;
  }

  .social-container {
    margin-top: 60px !important;
  }

  .left-container {
    height: 420px;
    background-size: cover;
  }

  .message-cont {
    margin-top: 180px;
    margin-bottom: 180px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .message-cont p {
    margin-top: -30px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1200px) {
  /* .form-control {
            margin-left: 10%;
        } */

  .left-container {
    height: 100%;
  }

  .center-card {
    padding-left: 154px;
  }

  .login-form .button {
    margin-left: 10%;
    width: 70%;
  }

  .login-form .button-blue {
    margin-left: 10%;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 768px) {
  .center-card {
    margin-left: 0;
  }
}

.form-rounded {
  border-radius: 1rem;
}

.form-input-login {
  border-radius: 50px !important;
  text-align: center;
}

.email-input {
  background-color: #ffffff !important;
  border: none !important;
  border-left: none !important;
}

.form-group {
  margin-top: 30px;
}

.form-control::placeholder {
  font-size: 13px;
  text-align: center;
}

.btn-block {
  width: 100% !important;
}

.btn-primary:focus .btn-primary.focus {
  box-shadow: 0 0 0 0.1rem rgba(48, 228, 102, 0.5) !important;
}

.forgot a:hover {
  transition: 0.5s ease-in-out;
  color: rgba(48, 228, 102, 0.5) !important;
  text-decoration: none;
}

.logo {
  width: 160px;
  height: 80px;
}

.center-card {
  margin-left: -254px;
}

.login-text {
  padding-right: 160px !important;
}

/* Login 2 aliding animations */
#slider {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

/* End of sliding */
</style>