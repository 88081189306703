<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto main-home col-md-10 pt-0 main mb-6">
      <b-row class="mt-n2 mb-6 row-admin">
        <div class="col-sm col-md col-lg">
          <div class="card card-main mt-1 ml-0 mb-4">
            <div class="card-body pl-0 ml-0">
              <b-row>
                <div class="col-sm col-md col-lg">
                  <div class="d-flex justify-content-between">
                    <h5 class="text-primary float-left ml-4">
                      Submit Report
                      <div class="vm-active"></div>
                    </h5>
                    <!-- <h1 class=" text-14 text-red">
                      Due date
                      <div class="clear-fix"></div>
                      <h1 class="text-red text-14">{{moment(new Date()).format("DD/MM/YYYY")}}</h1>
                    </h1> -->
                  </div>
                </div>
              </b-row>
              <b-row>
                <div class="col-sm col-md col-lg">
                  <div class="container">
                    <d-stepper
                      :steps="steps"
                      :initial-state="{ name: '', users: [] }"
                    >
                      <template #fatal-error="{ errorMsg }">{{
                        errorMsg
                      }}</template>
                    </d-stepper>
                  </div>
                </div>
              </b-row>
            </div>
          </div>
        </div>
      </b-row>
    </main>
  </div>
</template>

<script>
import DStepper from "../../../components/stepper/stepper-submit-report.vue";
import Step1 from "./components/step1.vue";
import Step2 from "./components/step2.vue";
export default {
  name: "create-project",
  components: {
    DStepper,
  },
  data() {
    return {
      steps: [
        {
          name: "Details",
          disabled: false,
          active: true,
          component: Step1,
        },
        {
          name: "Submission",
          disabled: false,
          active: false,
          component: Step2,
          confirm: "Confirm",
        },
      ],
      activeTab: true,
      pageTitle: "reports",
    };
  },
  beforeMount(){
      this.title("Submit Report")
  },
  methods: {},
};
</script>

<style scoped>
/* Top card styles */
.top-card {
  background-color: rgba(119, 119, 119, 0.507);
  border-radius: 10px !important;
  padding: 20px 12px !important;
  width: 234px !important;
  height: 123px !important;
}

.top-card h3 {
  font-size: 16px !important;
  padding-bottom: 3px !important;
}

.top-card p {
  font-size: 15px !important;
  padding-top: 2.5px !important;
}
/* End for styles */
.vm-active {
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important;
  padding-bottom: 1rem !important;
  border-bottom: 4px solid #0fa958 !important;
  width: 50px !important;
  margin-left: 30% !important;
}

.card-stat {
  background-color: #fff !important;
}

/* icons */
.dash-img {
  width: 75px;
  height: 75px;
}
/* end of icons styles */

.j1-admin h3 {
  font-size: 20px;
}

.j1-admin p {
  font-size: 16px;
}

.ct-admin {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.btn-top {
  background: #e5e5e5 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
}

.btn-top-filter {
  background: #0fa958 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card {
  border-radius: 20px !important;
  text-align: center !important;
}

.card .text-danger {
  font-size: 13px !important;
}

.row-admin {
  margin-top: 30px !important;
}

.top-buttons {
  margin-left: -8rem !important;
}

.card-main {
  background-color: #f6f6f6 !important;
  border: none !important;
}

.card-2 {
  border: none !important;
}

.card h5 {
  font-style: normal;
  font-family: Ubuntu !important;
  font-size: 14px !important;
  color: #69737f !important;
}
</style>