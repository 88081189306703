import Vue from 'vue'
import App from './App.vue'
import './assets/bootstrap/dist/css/bootstrap.css';
import Antd from 'ant-design-vue';
import 'jquery/dist/jquery.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'boxicons';
import 'boxicons/css/boxicons.min.css';
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/antd/antd.css';
import './assets/app.scss';
import './helpers/axios.js';
import moment from 'moment';
import store from './vuex';
Vue.prototype.moment=moment;
moment(new Date().getTime()).utcOffset(new Date()).format('YYYY-MM-DD HH:mm:ss');
import TopBanner from './dashboards/project-manager/shared/TopBanner.vue';
import adminTopPanel from './dashboards/admin/shared/TopBanner.vue';
// import VueApexCharts from 'vue-apexcharts'
import colorPicker from '@caohenghu/vue-colorpicker';
import colorSelector from './dashboards/project-manager/components/color-picker/color-picker.vue';
import Chrome from 'vue-color';
import './mixins/generalMixin'
import {
  BootstrapVue,
  BIconBell,
  BIconPersonCircle,
  BIconJournalAlbum,
  BIconChatLeftDots,
  BIconQuestionSquare,
  BIconArrowLeft,
  BIconCaretDownSquareFill,
  BIconCloudDownload,
  BIconArrowRepeat,
  BIconX,
  BIconPlusSquare,
  BIconXCircle,
  BIconTrashFill,
  BIconSearch,
  BIconArrowRightCircleFill,
  BIconPeopleFill,
  BIconPersonLinesFill,
  BIconEyeFill,
  BIconEye,
  BIconEyeSlash,
  BIconXSquareFill,
  BIconArrowCounterclockwise,
  BIconGearFill,
  BIconGear,
  BIconPencilSquare,
  BIconTrash,
  BIconPencil
} from "bootstrap-vue";
Vue.component("chromePicker", Chrome);
Vue.component("colorPicker", colorPicker);
Vue.component("colorSelector", colorSelector);
Vue.component("TopBanner", TopBanner);
Vue.component("adminTopBar", adminTopPanel);
Vue.component("BIconBell", BIconBell);
Vue.component("BIconTrash", BIconTrash);
Vue.component("BIconPencil",BIconPencil);
Vue.component("BIconX", BIconX);
Vue.component("BIconXSquareFill", BIconXSquareFill);
Vue.component("BIconPencilSquare", BIconPencilSquare);
Vue.component("BIconEyeSlash", BIconEyeSlash);
Vue.component("BIconEye", BIconEye);
Vue.component("BIconEyeFill", BIconEyeFill);
Vue.component("BIconPersonLinesFill", BIconPersonLinesFill);
Vue.component("BIconPeopleFill", BIconPeopleFill);
Vue.component("BIconArrowRightCircleFill", BIconArrowRightCircleFill);
Vue.component("BIconSearch", BIconSearch);
Vue.component("BIconTrashFill", BIconTrashFill);
Vue.component("BIconXCircle", BIconXCircle);
Vue.component("BIconPlusSquare", BIconPlusSquare);
Vue.component("BIconArrowRepeat", BIconArrowRepeat);
Vue.component("BIconCloudDownload", BIconCloudDownload);
Vue.component("BIconCaretDownSquareFill", BIconCaretDownSquareFill);
Vue.component("BIconArrowLeft", BIconArrowLeft);
Vue.component("BIconChatLeftDots", BIconChatLeftDots);
Vue.component("BIconQuestionSquare", BIconQuestionSquare);
Vue.component("BIconJournalAlbum", BIconJournalAlbum);
Vue.component("BIconPersonCircle", BIconPersonCircle);
Vue.component("BIconRefresh", BIconArrowCounterclockwise);
Vue.component("BIconGearFill", BIconGearFill);
Vue.component("BiconGear", BIconGear);
// Vue.use(VueApexCharts)
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
// Vue.component('apexchart', VueApexCharts)

Vue.use(BootstrapVue);

Vue.prototype.$title = (title) =>
  (document.title = `${title ? title : "Welcome"} - Access Finance Rwanda`);
Vue.use(Antd);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
