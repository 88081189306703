<template>
  <div>
    <adminTopBar :title="title" />
    <main role="main" class="col-sm-9 ml-sm-auto main-home col-md-10 pt-0">
      <div class="container-fluid p-0 m-0 mt-n5">
        <div class="jumbotron ml-0 pl-0 pr-0 mr-0 b-none mb-4">
          <div class="card bg-gray border-none rounded-20">
            <div class="card-header bg-gray rounded-20 border-none">
              <div class="jumbotron pl-0 pr-0 pt-0 mt-0 b-none text-left">
                <b-row class="mt-0 row-admin">
                  <div class="col-sm col-xs col-md mt-0">
                    <div class="card rounded-20 mb-0 mt-2 mr-xs-4 shadow">
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <div class="text-left">
                            <h5 class="card-title">
                              {{ all_numbers ? all_numbers.afrusers : 0 }}
                            </h5>
                            <p class="card-text">AFR USERS</p>
                          </div>
                          <div class="p-2">
                            <usersIcon class="icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm col-xs col-md mt-0">
                    <div
                      class="card mb-0 rounded-20 mt-2 mr-xs-4 bg-white shadow"
                    >
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <div class="text-left">
                            <h5 class="card-title text-purple">
                              {{ all_numbers ? all_numbers.ipusers : 0 }}
                            </h5>
                            <p class="card-text text-purple">OTHER USERS</p>
                          </div>
                          <div class="p-2">
                            <users2Icon class="icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6"></div>
                </b-row>
              </div>
              <div class="row d-flex justify-content-between mt-n5">
                <div class="text-lef left-label col-xs">
                  <h6 class="p-0 mt-0 ml-4">All users</h6>
                </div>
                <div class="p-2 col-xs">
                  <button
                    @click="showModal"
                    class="btn btn-outline-info mr-4 p-2 btn-add"
                  >
                    ADD 3RD PARTY USER <i class="bx bx-plus"></i>
                  </button>
                  <button
                    @click="createAFrModal"
                    class="
                      btn btn-outline-primary btn-custom-primary
                      mr-4
                      p-2
                      btn-add
                    "
                  >
                    ADD AFR USER <i class="bx bx-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <b-row>
                <div class="col-sm col-md"></div>
                <div class="col-sm-4 col-md-4">
                  <form>
                    <div class="input-group mb-2">
                      <input
                        type="search"
                        v-model="filter"
                        class="form-control input-search rounded-left-10"
                        aria-label="Search"
                        placeholder="Search a user..."
                      />
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right-10">
                          <i class="bx bx-search text-success"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </b-row>
              <b-row class="mb-2">
                <div class="col-sm-9 col-md-9"></div>
                <div class="col-sm col-md text-right">
                  <button
                    @click="refresh"
                    type="button"
                    class="btn btn-secondary btn-sm rounded-10 border-none"
                  >
                    <b-container v-if="state.loading && state.refresh">
                      <b-spinner small></b-spinner>
                      <span class="text-13 ml-2">Refreshing...</span>
                    </b-container>
                    <span v-else class="text text-13">Refresh</span>
                  </button>
                </div>
              </b-row>
              <b-row>
                <div class="col-sm col-md">
                  <div class="table-responsive">
                    <b-container class="my-2" v-if="state.loading">
                      <a-skeleton
                        :loading="state.loading"
                        :paragraph="{ rows: 8 }"
                        active
                      ></a-skeleton>
                    </b-container>
                    <b-table
                      v-else
                      table-class="table-roles text-13"
                      striped
                      hover
                      thead-tr-class="tb-head text-left"
                      tbody-class="tb-body text-left  text-12"
                      :items="users"
                      :filter="filter"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :fields="tableFields"
                      show-empty
                    >
                      <template v-slot:cell(last_login)="data">
                        {{
                         data.item.lastlogin ? moment(data.item.lastlogin).format(
                            "DD/MM/YYYY HH:mm:ss a"
                          ) : "-"
                        }}
                      </template>
                      <template v-slot:cell(full_name)="data">
                        <span class="text-capitalize">{{
                          data.item.fullnames
                        }}</span>
                      </template>
                      <template v-slot:cell(role)="data">
                        <span class="text-capitalize">{{
                          data.item.Role
                        }}</span>
                      </template>
                      <template v-slot:cell(institution)="data">
                        <span class="text-capitalize">{{
                          data.item.Insitution
                        }}</span>
                      </template>
                      <template v-slot:cell(edit)="data">
                        <button
                          @click="showEditModal(data.item)"
                          class="btn btn-sm btn-outline-success"
                        >
                          <i class="bx bx-edit-alt"></i>
                        </button>
                      </template>
                      <template v-slot:cell(delete)="data">
                        <button
                          @click="deleteUsrModal(data.item)"
                          class="btn btn-sm btn-outline-danger"
                        >
                          <i class="bx bx-trash-alt"></i>
                        </button>
                      </template>
                    </b-table>
                  </div>
                </div>
              </b-row>
              <b-row>
                <div class="col-sm-8"></div>
                <div class="col-sm">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                  ></b-pagination>
                </div>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- Create & update users -->
    <a-modal
      v-model="visible1"
      :footer="null"
      title="Update user"
      @ok="handleOk"
    >
      <form @submit.prevent="updateUser(data)">
        <b-row v-if="state.fetchingUser">
          <b-container class="mb-5">
            <a-skeleton
              active
              avatar
              :paragraph="{ rows: 4 }"
              :loading="state.fetchingUser"
            />
            <b-spinner class="d-none" type="grow" small></b-spinner>
            <span class="text-13 ml-2 d-none">
              Waiting for user information...</span
            >
          </b-container>
        </b-row>
        <section v-else>
          <b-row>
            <div class="col-xs col-sm">
              <div class="form-group">
                <label>First name</label>
                <input
                  placeholder="Enter first name"
                  type="text"
                  class="form-control form-input"
                  v-model="firstname"
                />
              </div>
            </div>
            <div class="col-xs col-sm">
              <div class="form-group">
                <label>Last name</label>
                <input
                  placeholder="Enter first name"
                  type="text"
                  class="form-control form-input"
                  v-model="lastname"
                />
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-xs col-sm d-none">
              <div class="form-group">
                <label>Address</label>
                <input
                  placeholder="Enter address"
                  type="text"
                  class="form-control form-input"
                  v-model="address"
                />
              </div>
            </div>
            <div class="col-xs col-sm">
              <div class="form-group">
                <label>Phone</label>
                <input
                  placeholder="Enter Phone"
                  type="text"
                  class="form-control form-input"
                  v-model="phone"
                  autocomplete="off"
                />
              </div>
            </div>
          </b-row>
          <div class="form-group">
            <label>Email</label>
            <input
              placeholder="Enter email"
              type="email"
              class="form-control form-input"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <label class="float-left text-muted text-12">Password</label>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control form-input"
                v-model="password"
                placeholder="New password"
                v-if="showPassword"
                required
                v-bind:class="
                  validations.password
                    ? 'is-invalid'
                    : validations.length
                    ? 'is-invalid'
                    : null"
                @keyup="onchangePassword"
              />
              <input
                type="password"
                class="form-control form-input"
                placeholder="New password"
                v-model="password"
                required
                v-else
                v-bind:class="
                  validations.password
                    ? 'is-invalid'
                    : validations.length
                    ? 'is-invalid'
                    : null"
                @keyup="onchangePassword"
              />

              <div class="input-group-append rounded-right-10">
                <span
                  @click="toggleShow"
                  class="input-group-text rounded-right-10"
                  id="basic-addon2"
                >
                  <i
                    :class="{
                      'bx bx-hide': showPassword,
                      'bx bx-show': !showPassword,
                    }"
                  ></i>
                </span>
              </div>
            </div>
            <small v-if="validations.password" class="text-danger">
              Please enter password.
            </small>
            <small v-else-if="validations.length" class="text-danger">
              Password must be at least six(6) characters long.
            </small>
          </div>
          <div class="form-group">
            <label>Expiration date</label>
            <input
              placeholder="Expiration"
              type="date"
              class="form-control form-input"
              v-model="dob"
            />
          </div>
          <div class="form-group">
            <label>Department</label>
            <div class="input-group mb-3">
              <input
                type="text"
                readonly
                v-model="department"
                class="form-control form-input"
                aria-describedby="basic-addon2"
              />
              <a-popover title="Edit user department" trigger="click">
                <template #content>
                  <b-form @submit.prevent="updateUserDepartment">
                    <b-input-group>
                      <b-select
                        class="
                          select-modal
                          bg-gray-white
                          rounded-20
                          text-13
                          mr-3
                        "
                        v-model="edit.department"
                        required
                        :disabled="state.loadDept"
                      >
                        <template #first>
                          <b-select-option :value="null" selected disabled
                            >Select department</b-select-option
                          >
                        </template>
                        <b-select-option
                          v-for="item in departments"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </b-select-option>
                      </b-select>
                      <b-input-group-append>
                        <b-icon-arrow-repeat
                          class="bi-con-right ml-n2"
                          font-scale="1.5"
                          :animation="state.loadDept ? 'spin' : ''"
                          @click="getDepartments"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-group>
                      <button
                        :disabled="edit.DepartmentUpdating"
                        type="submit"
                        class="
                          btn btn-success btn-sm btn-confirm btn-block
                          mt-3
                        "
                      >
                        <b-container v-if="edit.DepartmentUpdating">
                          <b-spinner small></b-spinner>
                          <span class="text-13 ml-2">Updating...</span>
                        </b-container>
                        <span
                          v-if="!edit.DepartmentUpdating"
                          class="text text-13"
                          >Save Changes</span
                        >
                      </button>
                    </b-form-group>
                  </b-form>
                </template>
                <div class="input-group-append rounded-right-10">
                  <span
                    class="input-group-text rounded-right-10"
                    id="basic-addon2"
                  >
                    <a href="#!" class="text-success text-12">Edit</a>
                  </span>
                </div>
              </a-popover>
            </div>
          </div>
          <div class="form-group">
            <label>Role</label>
            <div class="input-group mb-3">
              <input
                type="text"
                readonly
                v-model="role"
                class="form-control form-input"
                aria-describedby="basic-addon2"
              />
              <a-popover title="Edit user role" trigger="click">
                <template #content>
                  <b-form @submit.prevent="updateUserRole">
                    <b-input-group>
                      <b-select
                        class="
                          select-modal
                          bg-gray-white
                          rounded-20
                          text-13
                          mr-3
                        "
                        v-model="edit.role"
                        required
                        :disabled="state.loading"
                      >
                        <template #first>
                          <b-select-option :value="null" selected disabled
                            >Select role</b-select-option
                          >
                        </template>
                        <b-select-option
                          v-for="item in roles"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </b-select-option>
                      </b-select>
                      <b-input-group-append>
                        <b-icon-arrow-repeat
                          class="bi-con-right ml-n2"
                          font-scale="1.5"
                          :animation="state.loading ? 'spin' : ''"
                          @click="getRoles"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-group>
                      <button
                        :disabled="edit.changeRole"
                        type="submit"
                        class="
                          btn btn-success btn-sm btn-confirm btn-block
                          mt-3
                        "
                      >
                        <b-container v-if="edit.changeRole">
                          <b-spinner small></b-spinner>
                          <span class="text-13 ml-2">Updating...</span>
                        </b-container>
                        <span v-if="!edit.changeRole" class="text text-13"
                          >Save Changes</span
                        >
                      </button>
                    </b-form-group>
                  </b-form>
                </template>
                <div class="input-group-append rounded-right-10">
                  <span
                    class="input-group-text rounded-right-10"
                    id="basic-addon2"
                  >
                    <a href="#!" class="text-success text-12">Edit</a>
                  </span>
                </div>
              </a-popover>
            </div>
          </div>
          <div class="form-group">
            <label>Institution</label>
            <div class="input-group mb-3">
              <input
                type="text"
                readonly
                v-model="institution"
                class="form-control form-input"
                aria-describedby="basic-addon2"
              />
              <a-popover title="Edit user institution" trigger="click">
                <template #content>
                  <b-form @submit.prevent="updateUserInstitution">
                    <b-input-group>
                      <b-select
                        class="
                          select-modal
                          bg-gray-white
                          rounded-20
                          text-13
                          mr-3
                        "
                        v-model="edit.institution"
                        required
                        :disabled="state.loading"
                      >
                        <template #first>
                          <b-select-option :value="null" selected disabled
                            >Select institution</b-select-option
                          >
                        </template>
                        <b-select-option
                          v-for="item in institutions"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </b-select-option>
                      </b-select>
                      <b-input-group-append>
                        <b-icon-arrow-repeat
                          class="bi-con-right ml-n2"
                          font-scale="1.5"
                          :animation="state.loading ? 'spin' : ''"
                          @click="getInstitutions"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-group>
                      <button
                        :disabled="edit.InstitutionUpdating"
                        type="submit"
                        class="
                          btn btn-success btn-sm btn-confirm btn-block
                          mt-3
                        "
                      >
                        <b-container v-if="edit.InstitutionUpdating">
                          <b-spinner small></b-spinner>
                          <span class="text-13 ml-2">Updating...</span>
                        </b-container>
                        <span
                          v-if="!edit.InstitutionUpdating"
                          class="text text-13"
                          >Save Changes</span
                        >
                      </button>
                    </b-form-group>
                  </b-form>
                </template>
                <div class="input-group-append rounded-right-10">
                  <span
                    class="input-group-text rounded-right-10"
                    id="basic-addon2"
                  >
                    <a href="#!" class="text-success text-12">Edit</a>
                  </span>
                </div>
              </a-popover>
            </div>
          </div>
          <button
            :disabled="state.updating"
            type="submit"
            class="btn btn-success btn-block"
          >
            <b-container v-if="state.updating">
              <b-spinner small></b-spinner>
              <span class="text-13 ml-2">Updating...</span>
            </b-container>
            <span v-if="!state.updating" class="text text-13">Update User</span>
          </button>
        </section>
      </form>
    </a-modal>
    <a-modal
      v-model="visible"
      :footer="null"
      title="Create new user"
      @ok="handleOk"
    >
      <form @submit.prevent="addUsers">
        <b-row>
          <div class="col-xs col-sm">
            <div class="form-group">
              <label>First name</label>
              <input
                placeholder="Enter first name"
                type="text"
                class="form-control form-input"
                v-model="firstname"
              />
            </div>
          </div>
          <div class="col-xs col-sm">
            <div class="form-group">
              <label>Last name</label>
              <input
                placeholder="Enter first name"
                type="text"
                class="form-control form-input"
                v-model="lastname"
              />
            </div>
          </div>
        </b-row>
        <b-row>
          <div class="col-xs col-sm d-none">
            <div class="form-group">
              <label>Address</label>
              <input
                placeholder="Enter address"
                type="text"
                class="form-control form-input"
                v-model="address"
              />
            </div>
          </div>
          <div class="col-xs col-sm">
            <div class="form-group">
              <label>Phone number</label>
              <div class="input-group">
                <!-- <div class="input-group-prepend rounded-left-10">
                  <span class="input-group-text rounded-left-10 text-13"></span>
                </div> -->
                <input
                  placeholder="250xxxxx"
                  type="number"
                  class="form-control form-input"
                  v-model="phone"
                  autocomplete="off"
                  maxlength="9"
                />
              </div>
            </div>
          </div>
        </b-row>
        <div class="form-group">
          <label>Email</label>
          <input
            placeholder="Enter email"
            type="email"
            class="form-control form-input"
            v-model="email"
          />
        </div>
        <div class="form-group">
            <label class="float-left text-muted text-12">Password</label>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control form-input"
                v-model="password"
                placeholder="New password"
                v-if="showPassword"
                required
                v-bind:class="
                  validations.password
                    ? 'is-invalid'
                    : validations.length
                    ? 'is-invalid'
                    : null"
                @keyup="onchangePassword"
              />
              <input
                type="password"
                class="form-control form-input"
                placeholder="New password"
                v-model="password"
                required
                v-else
                v-bind:class="
                  validations.password
                    ? 'is-invalid'
                    : validations.length
                    ? 'is-invalid'
                    : null"
                @keyup="onchangePassword"
              />

              <div class="input-group-append rounded-right-10">
                <span
                  @click="toggleShow"
                  class="input-group-text rounded-right-10"
                  id="basic-addon2"
                >
                  <i
                    class="bx"
                    :class="{
                      ' bx-hide': showPassword,
                      ' bx-show': !showPassword,
                    }"
                  ></i>
                </span>
              </div>
            </div>
            <small v-if="validations.password" class="text-danger">
              Please enter password.
            </small>
            <small v-else-if="validations.length" class="text-danger">
              Password must be at least six(6) characters long.
            </small>
          </div>
        <div class="form-group">
          <label>Expiration Date</label>
          <input
            placeholder="Expiration Date"
            type="date"
            class="form-control form-input"
            v-model="dob"
          />
          <!-- <a-date-picker class="form-input form-control" v-model="dob" :format="dateFormat" :default-value="moment('01/01/1900', dateFormat)" /> -->
        </div>
        <div class="form-group">
          <label>Department</label>
          <select v-model="department" required class="custom-select form-input">
            <option selected value="" disabled>Select department</option>
            <option v-for="(item, i) in departments" :key="i" :value="item.id">
              {{ item.name }}
            </option>
          </select>
          <small v-if="validDept" class="form-text text-red"
            >Please select department.</small
          >
        </div>
        <div class="form-group">
          <label>Role</label>
          <select v-model="role" class="custom-select form-input">
            <option selected value="" disabled>Select role</option>
            <option v-for="(role, i) in roles" :key="i" :value="role.id">
              {{ role.name }}
            </option>
          </select>
          <small v-if="validRole" class="form-text text-red"
            >Please select role.</small
          >
        </div>
        <div class="form-group">
          <label>Select institution</label>
          <select v-model="institution" class="custom-select form-input">
            <option selected value="" disabled>Select institution</option>
            <option v-for="(item, i) in institutions" :key="i" :value="item.id">
              {{ item.name }}
            </option>
          </select>
          <small v-if="validIns" class="form-text text-red"
            >Please select role.</small
          >
        </div>
        <button
          :disabled="state.adding"
          type="submit"
          class="btn btn-success btn-block"
        >
          <b-container v-if="state.adding">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Saving...</span>
          </b-container>
          <span v-if="!state.adding" class="text text-13">SAVE NEW USER</span>
        </button>
      </form>
    </a-modal>
    <a-modal
      v-model="visibleAfr"
      :footer="null"
      title="Create an AFR user"
      @ok="handleOk"
    >
      <form @submit.prevent="fetchUserInfoModal">
        <div class="form-group">
          <label>Email</label>
          <input
            required
            placeholder="Enter email"
            type="email"
            v-model="email"
            class="form-control form-input"
          />
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-success btn-block">
            FETCH USER INFO
          </button>
        </div>
      </form>
    </a-modal>
    <a-modal
      v-model="visibleDelete"
      :footer="null"
      title="Delete User"
      @ok="handleOk"
    >
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to delete this information ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <a-popconfirm
            title="Are you sure delete this user?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteUser(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="state.loading">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Deleting...</span>
              </b-container>
              <span v-if="!state.loading" class="text text-13">DELETE</span>
            </button>
          </a-popconfirm>
        </div>
        <div class="inline-block">
          <button
            @click="visibleDelete = false"
            type="submit"
            class="btn btn-secondary btn-block btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
      </b-row>
    </a-modal>
    <a-modal
      v-model="visibleFetchUsr"
      :footer="null"
      title="Confirm an AFR user"
      @ok="handleOk"
    >
      <form @submit.prevent="addUsers">
        <div class="form-group">
          <label>Email</label>
          <input
            readonly
            type="email"
            v-model="email"
            class="form-control form-input"
          />
        </div>
        <div class="form-group">
          <label>Department</label>
          <select class="custom-select form-input" v-model="department">
            <option selected value="" disabled>Select department</option>
            <option v-for="item in departments" :key="item.id" :value="item.id">{{item.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label>Role</label>
          <b-input-group>
            <b-select
              class="
                select-modal
                bg-gray-white
                rounded-20
                text-13
                mr-3
              "
              v-model="role"
              required
              :disabled="state.loading"
            >
              <template #first>
                <b-select-option :value="null" selected disabled
                  >Select role</b-select-option
                >
              </template>
              <b-select-option
                v-for="item in roles"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </b-select-option>
            </b-select>
            <b-input-group-append>
              <b-icon-arrow-repeat
                class="bi-con-right ml-n2"
                font-scale="1.5"
                :animation="state.loading ? 'spin' : ''"
                @click="getRoles"
              />
            </b-input-group-append>
          </b-input-group>
        </div>

        <div class="form-group">
            <label>Expiration date</label>
            <input
              placeholder="Expiration"
              type="date"
              class="form-control form-input"
              v-model="dob"
            />
          </div>
        <div class="text-center">
          <hr />
          <span><strong>User info</strong></span>
          <hr />
        </div>
        <b-row>
          <div class="col-sm-12 d-flex text-bold">
            <span
              ><p><strong>Names: </strong></p>
            </span>
            <span class="ml-3">{{firstname}} {{lastname}}</span>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm-12 d-flex text-bold">
            <span
              ><p><strong>Email: </strong></p>
            </span>
            <span class="ml-3"><p>{{email}}</p></span>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm-12 d-flex text-bold">
            <span
              ><p><strong>Phone number: </strong></p>
            </span>
            <span class="ml-3">{{phone}}</span>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm-12 d-flex text-bold">
            <span
              ><p><strong>Address:</strong></p></span
            >
            <span v-if="afrUserInfo" class="ml-3">{{afrUserInfo.officeLocation}}</span>
          </div>
        </b-row>
        <button type="submit" class="btn btn-success btn-block">
          ADD USER
        </button>
      </form>
    </a-modal>
  </div>
</template>

<script>
import usersIcon from "../components/icons/users.vue";
import users2Icon from "../components/icons/users2.vue";

export default {
  components: {
    usersIcon,
    users2Icon,
  },
  data() {
    return {
      filter: "",
      active: true,
      visible: false,
      visible1: false,
      visibleAfr: false,
      visibleDelete: false,
      visibleFetchUsr: false,
      perPage: 8,
      currentPage: 1,
      title: "users",
      tableFields: [
        "full_name",
        "email",
        "Department",
        "Role",
        "Institution",
        "last_login",
        "edit",
        "delete",
      ],
      users: [],
      userInfo: null,
      afrUserInfo: null,
      state: {
        loading: false,
        refresh: false,
        adding: false,
        updating: false,
        fetchingUser: false,
        loadDept: false,
      },
      data: null,
      firstname: "",
      lastname: "",
      address: "",
      phone: "",
      email: "",
      role: "",
      user_type: "ip",
      institution: "",
      department: "",
      roles: [],
      institutions: [],
      departments: [],
      dob: "",
      password: "",
      dateFormat: "DD/MM/YYYY",
      validDept: false,
      validRole: false,
      validIns: false,
      edit: {
        role: null,
        institution: null,
        department: null,
        changeRole: false,
        InstitutionUpdating: false,
        DepartmentUpdating: false,
      },
      showPassword: false,
      validations: {
        password: false,
        length: false,
      },
    };
  },
  computed: {
    rows() {
      return this.users.length;
    },
    token() {
      return localStorage.getItem("token");
    },
    all_numbers() {
      return this.$store.state.numbers;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  beforeMount() {
    this.$title("System Users");
    this.getRoles();
    this.getInstitutions();
    this.getUsers();
    this.getDepartments();
  },
  watch: {},
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    onchangePassword() {
      if (this.password) {
        this.validations.password = false;
      } else {
        this.validations.password = true;
      }
      if (this.password.length > 5) {
        this.validations.length = false;
      } else {
        this.validations.length = true;
      }
    },
    async addUsers() {
      const requestData = {
        fname: this.firstname,
        lname: this.lastname,
        type: this.user_type,
        phone: this.phone,
        DOB: this.dob,
        email: this.email,
        department: this.department,
        role: this.role,
        institution: this.institution,
        password: this.password,
      };

      if(this.afrUserInfo){
        requestData.aad_id = this.afrUserInfo.id
      }
      this.state.adding = true;
      await this.axios
        .post("AddUser", requestData)
        .then((response) => {
          if (response.data.message) {

            this.state.adding = false;
            this.visible = false;
            this.$message.error(`${response.data.message}`, 3);
          } else {
            this.getUsers();
            this.state.adding = false;
            this.visible = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `User added successfully.`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.adding = false;
        });
    },
    async updateUser(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        fname: this.firstname,
        lname: this.lastname,
        type: this.userInfo.type == "ip"
            ? "ip"
            : this.userInfo.type == "afr"
            ? "afr"
            : "ip",
        phone: this.phone,
        DOB: this.dob,
        email: this.email,
        DepartmentId: this.department,
        roleId: this.role,
        instituteId: this.institution,
        password: this.password,
      };

      this.state.updating = true;
      this.valid = false;
      await this.axios
        .put(`updateUser/${param.id}`, requestData, config)
        .then((response) => {
          if (response.data.message) {
            this.state.updating = false;
            this.visible1 = false;
            this.$message.error(`${response.data.message}`, 3);
          } else {
            this.getUsers();
            this.state.updating = false;
            this.visible1 = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `User updated successfully.`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.updating = false;
        });
    },
    async deleteUser(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.state.loading = true;
      await this.axios
        .delete(`deleteUser/${param.id}`, config)
        .then((response) => {
          if (response) {
            this.state.loading = false;
            this.visibleDelete = false;
            this.getUsers();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `User  deleted successfully.`,
              duration: 5,
            });
          } else {
            this.state.loading = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loading = false;
        });
    },
    async getInstitutions() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getInstitutions", config)
        .then((response) => {
          if (response.data.data) {
            this.state.loading = false;
            this.institutions = response.data.data;
          } else {
            this.state.loading = false;
            this.$notification["error"]({
              message: "ERROR!",
              description: `Something wrong when getting departments`,
              duration: 5,
            });
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getRoles() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getRoles", config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.roles = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getDepartments() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadDept = true;
      await this.axios
        .get("getDepartment", config)
        .then((response) => {
          if (response.data.data) {
            this.state.loadDept = false;
            this.departments = response.data.data;
          } else {
            this.state.loadDept = false;
          }
        })
        .catch(() => {
          this.state.loadDept = false;
        });
    },
    async getUsers() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getUsers", config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.users = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    // get user info
    async showEditModal(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.data = param;
      this.visible1 = true;
      this.state.fetchingUser = true;
      await this.axios
        .get(`getUser/${param.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.fetchingUser = false;
            this.userInfo = response.data;
            this.firstname = this.userInfo.firstname;
            this.lastname = this.userInfo.lastname;
            this.type = "ip";
            this.phone = this.userInfo.Phone;
            this.dob = this.userInfo.DOB;
            this.email = this.userInfo.email;
            this.department = this.userInfo.department;
            this.role = this.userInfo.Role;
            this.institution = this.userInfo.Insitution;
          } else {
            this.state.fetchingUser = false;
            this.$notification["error"]({
              message: "ERROR!",
              description: `Something wrong when getting departments`,
              duration: 5,
            });
          }
        })
        .catch(() => {
          this.state.fetchingUser = false;
        });
    },
    async updateUserDepartment() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        department: this.edit.department,
      };
      this.edit.DepartmentUpdating = true;
      await this.axios
        .put(`updateUserDepartment/${this.userInfo.id}`, requestData, config)
        .then((response) => {
          if (response.data) {
            this.edit.DepartmentUpdating = false;
            this.visible1 = false;
            this.getUsers();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `User department  has been updated successfully.`,
              duration: 5,
            });
          } else {
            this.edit.DepartmentUpdating = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.updating = false;
        });
    },
    async updateUserRole() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        Role: this.edit.role,
      };
      this.edit.changeRole = true;
      await this.axios
        .put(`updateRoleUser/${this.userInfo.id}`, requestData, config)
        .then((response) => {
          if (response.data) {
            this.edit.changeRole = false;
            this.visible1 = false;
            this.getUsers();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `User role  has been updated successfully.`,
              duration: 5,
            });
          } else {
            this.edit.changeRole = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.edit.changeRole = false;
        });
    },
    async updateUserInstitution() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        institute: this.edit.institution,
      };
      this.edit.InstitutionUpdating = true;
      await this.axios
        .put(`updateUserInstitution/${this.userInfo.id}`, requestData, config)
        .then((response) => {
          if (response.data) {
            this.edit.InstitutionUpdating = false;
            this.visible1 = false;
            this.getUsers();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `User Institution  has been updated successfully.`,
              duration: 5,
            });
          } else {
            this.edit.InstitutionUpdating = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.InstitutionUpdating = false;
        });
    },
    refresh() {
      this.state.refresh = true;
      this.getUsers();
    },
    // Show user modal
    showModal() {
      this.visible = true;
      this.firstname = "";
      this.lastname = "";
      this.type = "";
      this.phone = "";
      this.dob = "";
      this.email = "";
      this.department = "";
      this.role = "";
      this.institution = "";
    },
    createAFrModal() {
      this.visibleAfr = true;
    },
    async fetchUserInfoModal() {
      this.state.adding = true;
      await this.axios
        .get(`/user/fetch_afr_ad/${this.email}`)
        .then((response) => {
          this.state.adding = false;
          let userInfo = response.data
          this.afrUserInfo = userInfo
          this.user_type = 'afr'
          this.phone = userInfo.mobilePhone;
          this.firstname = userInfo.givenName;
          this.lastname = userInfo.surname;
          this.visibleFetchUsr = true;
          this.visibleAfr = false;
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.adding = false;
        });
    },
    deleteUsrModal(data) {
      this.data = data;
      this.visibleDelete = true;
    },
    handleOk(e) {
      console.log(e);
      this.visibleDelete = false;
    },
    cancel(e) {
      console.log(e);
      this.visibleDelete = false;
      this.$message.error("Delete canceled.");
    },
    handleChangeDepartment(value) {
      this.validDept = false;
      this.department = value;
    },
    handleChangeRoles(value) {
      this.validRole = false;
      this.role = value;
    },
    handleInstitution(value) {
      this.validIns = false;
      this.institution = value;
    },
  },
};
</script>

<style scoped>
*,
html,
body {
  font-family: Ubuntu;
}

@media screen and (max-width: 578px) {
  .btn-add {
    margin-top: 1rem !important;
    margin-left: 2rem;
  }
  .card .left-label h6 {
    margin-left: 3rem !important;
  }

  .row-admin {
    margin-top: 60px !important;
  }
}
</style>