import Vue from 'vue';
import moment from 'moment'
Vue.mixin({
    methods: {
      capitalizeFirstLetter(string) {
        if(!string) return string
        return string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
      },
      getKeyword(keyword){
        let keywords = this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
        
        if(keywords && keywords[keyword]) return keywords[keyword]
        else return keyword
      },
      getDatacollectionMethods(){
        return [
          'Data collection tools',
          'Observation',
          'Desk survey',
          'Interviews',
          'Focus Group Discussions',
          'Rapid and formal surveys',
        ]
      },
      getReportingFrequency(){
        return [
          'weekly',
          'monthly',
          'quartely',
          'semi-annual',
          'annual',
        ]
      },
      formatDateTime(value) {
        return moment(String(value)).format('MM/DD/YYYY HH:mm')
      },
    }
  })