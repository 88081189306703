import manager from './manager.vue';
import {
    managerHome,
    managerProjects,
    manageCreateProjects,
    projectDashboard,
    programs,
    createProgram,
    programDetails,
    monthlyReport,
    addKnowledge,
    mainPillars,
    approvals,
    reports,
    singlePillar,
    createPillar,
    addPillarKnowledgeBase,
    addProjectKnowledge,
    profile,
    approversList,
    submitReport,
    exportProgramTOC,
    exportPillarRC,
    exportProjectRc,
    currentProgram,
    exportCurrent,
    submitReportAll,
    viewReport,
    exportLogFrame,
    exportLogframeFrame,
    exportMeasurementPlan,
    pillarSummary,
    indicatorsSummary,
    programIndicatorContributions
} from './pages/index.js';

const routes = [
    {
        path: "/user",
        component: manager,
        children: [
            {
                path: "",
                redirect: { name: "user-homepage" },
                meta: { requireAuth: true },
            },
            {
                path:"main",
                name: "user-homepage",
                component: managerHome,
                meta: { requireAuth: true }
            },
            {
                path: "projects",
                name: "user-projects",
                component: managerProjects,
                meta: { requireAuth: true },
            },
            {
                path: "projects/project-dashboard",
                name: "project-dashboard",
                component: projectDashboard,
                meta: { requireAuth: true },
            },
            {
                path:"projects/kowldgebase/add",
                name: "add-project-knowledge",
                component: addProjectKnowledge,
                meta: { requireAuth: true }
            },
            {
                path: "projects/submit-month",
                name: "projects-report-1",
                component: monthlyReport,
                meta: { requireAuth: true }
            },
            {
                path: "projects/create",
                name: "create-project",
                component: manageCreateProjects,
                meta: { requireAuth: true }
            },
            {
                path: "projects/export-result-chain",
                name: "export-project-rc",
                component: exportProjectRc,
                meta: { requireAuth: true }
            },
            {
                path: "projects/export-project-mplan",
                name: "export-project-mplan",
                component: exportMeasurementPlan,
                meta: { requireAuth: true }
            }, 
            {
                path: "programs",
                name: "user-programs",
                component: programs,
                meta: { requireAuth: true }
            },
            {
                path: "programs/create",
                name: "create-programs",
                component: createProgram,
                meta: { requireAuth: true }
            },
            {
                path: "programs/single-details",
                name: "program-details",
                component: programDetails,
                meta: { requireAuth: true }
            },
            {
                path: "programs/export-toc",
                name: "export-toc",
                component: exportProgramTOC,
                meta: { requireAuth: true }
            },
            {
                path: "programs/add-knowledge",
                name: "add-knowledge",
                component: addKnowledge,
                meta: { requireAuth: true }
            },  
            {
                path: "programs/current",
                name: "current-program",
                component: currentProgram,
                meta: { requireAuth: true }
            },
            {
                path: "programs/export-current-program-toc",
                name: "export-current-toc",
                component: exportCurrent,
                meta: { requireAuth: true }
            },
            {
                path: "programs/export-logframe",
                name: "export-logframe",
                component: exportLogFrame,
                meta: { requireAuth: true }
            },
            {
                path: "programs/export-program-logframe",
                name: "export-p-logframe",
                component: exportLogframeFrame,
                meta: { requireAuth: true }
            },
           
            {
                path: "pillars",
                name: "program-pillars",
                component: mainPillars,
                meta: { requireAuth: true }
            },
            {
                path: "pillars/create",
                name: "create-pillar",
                component: createPillar,
                meta: { requireAuth: true }
            },
            {
                path:"pillars/details",
                name: "home-pillars",
                component: singlePillar,
                meta: { requireAuth: true }
            },
            {
                path:"pillars/knowledgebase/add",
                name: "add-pillar-knowledge",
                component: addPillarKnowledgeBase,
                meta: { requireAuth: true }
            },
            {
                path: "pillars/export-result-chain",
                name: "export-pillar-rc",
                component: exportPillarRC,
                meta: { requireAuth: true }
            },
            {
                path: "approvals",
                name: "project-approvers",
                component: approvals,
                meta: { requireAuth: true }
            },
            {
                path: "approvers/:element_id/:element_type/list",
                name: "approvers-list",
                component: approversList,
                meta: { requireAuth: true }
            },
            {
                path: "reports",
                name: "all-reports",
                component: reports,
                meta: { requireAuth: true }
            },
            {
                path: "reports/view-report",
                name: "view-report",
                component: viewReport,
                meta: { requireAuth: true }
            },
            {
                path: "reports/submit-report",
                name: "submit-report",
                component: submitReport,
                meta: { requireAuth: true }
            },
            {
                path: "reports/submit-report-all",
                name: "submit-report-all",
                component: submitReportAll,
                meta: { requireAuth: true }
            },
            {
                path: "profile/pm",
                name: "pm-profile",
                component: profile,
                meta: { requireAuth: true }
            },
            {
                path: "pillars/summary",
                name: "pillar-summary",
                component: pillarSummary,
                meta: { requireAuth: true }
            },
            {
                path: "programs/indicators",
                name: "indicator-summary",
                component: indicatorsSummary,
                meta: { requireAuth: true }
            },
            {
                path: "programs/indicators/contributions/:id?",
                name: "indicator-contrib-prog",
                component: programIndicatorContributions,
                meta: { requireAuth: true }
            },  
        ]
    }
]

export default routes;