<template>
  <div>
    <adminTopBar :title="title" />
    <main role="main" class="col-sm-9 ml-sm-auto main-home col-md-10 pt-0 mt-4">
      <div class="container-fluid mb-5 main-container">
        <div class="jumbotron pl-0 pr-0 pt-0 mt-0 b-none">
          <b-row>
            <div class="col-sm">
              <div class="card bg-gray rounded-20 border-none table-data">
                <div class="card-header rounded-20 bg-gray border-none">
                  <div class="row d-flex justify-content-between">
                    <div class="text-left left-label col-xs">
                      <h6 class="mt-2 text-15 ml-4 text-capitalize">
                        {{
                          keywords.workflow
                            ? `${keywords.workflow}`
                            : "Workflows"
                        }}
                      </h6>
                    </div>
                    <div class="p-2 col-xs">
                      <button
                        @click="showCreateModal"
                        class="
                          btn btn-outline-primary btn-custom-primary
                          mr-4
                          btn-add
                        "
                      >
                        ADD NEW <i class="bx bx-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <b-row>
                    <div class="col-sm col-md"></div>
                    <div class="col-sm-4 col-md-4">
                      <form>
                        <div class="input-group mb-2">
                          <input
                            type="search"
                            v-model="filter"
                            class="form-control input-search rounded-left-10"
                            aria-label="Search"
                            placeholder="Search ..."
                          />
                          <div class="input-group-append">
                            <span class="input-group-text rounded-right-10">
                              <i class="bx bx-search text-success"></i>
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </b-row>
                  <b-row class="mb-2">
                    <div class="col-sm-9 col-md-9"></div>
                    <div class="col-sm col-md text-right">
                      <button
                        @click="refresh"
                        type="button"
                        class="btn btn-secondary btn-sm rounded-10 border-none"
                      >
                        <b-container v-if="state.loading && state.refresh">
                          <b-spinner small></b-spinner>
                          <span class="text-13 ml-2">Refreshing...</span>
                        </b-container>
                        <span v-else class="text text-13">Refresh</span>
                      </button>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-sm col-md">
                      <div class="table-responsive">
                        <b-container class="my-2" v-if="state.loading">
                          <a-skeleton
                            :loading="state.loading"
                            :paragraph="{ rows: 8 }"
                            active
                          ></a-skeleton>
                        </b-container>
                        <b-table
                          v-else
                          table-class="table-roles text-13"
                          striped
                          hover
                          thead-tr-class="tb-head text-left indent-10"
                          tbody-class="tb-body text-left indent-10"
                          :items="resources"
                          :filter="filter"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :fields="fields"
                          show-empty
                        >
                          <template v-slot:cell(Workflow)="data">
                            <span class="text-capitalize">{{
                              data.item.name
                            }}</span>
                          </template>
                          <template v-slot:cell(levels)="data">
                            <span>{{ data.item.level }}</span>
                          </template>
                          <template v-slot:cell(edit)="data">
                            <button
                              @click="showEditModal(data.item)"
                              class="btn btn-sm btn-outline-success"
                            >
                              <i class="bx bx-edit-alt"></i>
                            </button>
                          </template>
                          <template v-slot:cell(view)="data">
                            <button
                              @click="goToWorkflow(data.item)"
                              class="btn btn-sm btn-outline-info"
                            >
                              <i class="fas fa-eye"></i>
                            </button>
                          </template>
                          <template v-slot:cell(delete)="data">
                            <button
                              @click="deleteModal(data.item)"
                              class="btn btn-sm btn-outline-danger"
                            >
                              <i class="bx bx-trash-alt"></i>
                            </button>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-sm-8"></div>
                    <div class="col-sm">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                      ></b-pagination>
                    </div>
                  </b-row>
                </div>
              </div>
            </div>
          </b-row>
        </div>
      </div>
    </main>
    <!-- Create & update resources -->
    <a-modal v-model="visibleCreate" :footer="null" title="Create workflow">
      <form @submit.prevent="addResource">
        <div class="form-group">
          <label>{{
            keywords.workflow ? `${keywords.workflow}` : "Workflow name"
          }}</label>
          <input
            :placeholder="`Enter ${
              keywords.workflow
                ? `${keywords.workflow} name`
                : 'Enter workflow name'
            }`"
            type="text"
            class="form-control form-input"
            required
            v-model="resource_name"
          />
        </div>
        <button type="submit" class="btn btn-success btn-block">
          <b-container v-if="state.adding">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Saving...</span>
          </b-container>
          <span v-if="!state.adding" class="text text-13">Save New</span>
        </button>
      </form>
    </a-modal>
    <a-modal v-model="visibleEdit" :footer="null" title="Update workflow">
      <form @submit.prevent="updateWorkflow(data)">
        <div class="form-group">
          <label class="text-capitalize"
            >{{
              keywords.workflow ? `${keywords.workflow}` : "Workflows"
            }}
            name</label
          >
          <input
            placeholder="Enter workflow name"
            v-model="resource_name"
            type="text"
            class="form-control form-input"
            required
          />
        </div>
        <button type="submit" class="btn btn-success btn-block">
          <b-container v-if="state.updating">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Updating...</span>
          </b-container>
          <span v-if="!state.updating" class="text text-13">Save Changes</span>
        </button>
      </form>
    </a-modal>
    <!-- Delete resourse modal -->
    <a-modal v-model="visibleDelete" :footer="null" title="Delete workflow">
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to delete this information ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <a-popconfirm
            title="Are you sure delete this workflow ?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteWorkflow(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="state.loading">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Deleting...</span>
              </b-container>
              <span v-if="!state.loading" class="text text-13">DELETE</span>
            </button>
          </a-popconfirm>
        </div>
        <div class="inline-block">
          <button
            @click="handleDelete"
            type="submit"
            class="btn btn-secondary btn-block btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
      </b-row>
    </a-modal>
  </div>
</template>

<script>
import encode from "jwt-encode";
export default {
  components: {},
  data() {
    return {
      active: true,
      visibleCreate: false,
      visibleEdit: false,
      visibleDelete: false,
      title: "workflows",
      fields: ["Workflow", "levels", "edit", "view", "delete"],
      filter: "",
      resource_name: "",
      levels: 0,
      perPage: 8,
      currentPage: 1,
      resources: [],
      state: {
        loading: false,
        adding: false,
        updating: false,
        refresh: false,
      },
      data: [],
    };
  },
  beforeMount() {
    this.$title("ADMIN - Workflows");
    this.getWorkflows();
  },
  computed: {
    rows() {
      return this.resources.length;
    },
    token() {
      return localStorage.getItem("token");
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  methods: {
    goToWorkflow(param) {
      const secret = "tokenHashingTom--afr0-101";
      const jwt = encode(param, secret);
      sessionStorage.setItem("resource", jwt);
      this.$router.push({ name: "admin-workflows-single" });
    },
    async addResource() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        name: this.resource_name,
      };
      this.state.adding = true;
      await this.axios
        .post("addWorkFlow", requestData, config)
        .then((response) => {
          if (response.data.message) {
            this.state.adding = false;
            this.$message.error(`${response.data.message}`, 4);
          } else {
            this.getWorkflows();
            this.state.adding = false;
            this.visibleCreate = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Resource has been added successfully.`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.adding = false;
        });
    },
    async getWorkflows() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getWorkFlows", config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.resources = response.data;
          } else {
            this.state.loading = false;
            this.$notification["error"]({
              message: "ERROR!",
              description: `Something wrong when getting workflows`,
              duration: 5,
            });
          }
        })
        .catch(() => {
          // this.$notification["error"]({
          //   message: "ERROR!",
          //   description: `${error.errorMessage}`,
          //   duration: 5,
          // });
          this.state.loading = false;
        });
    },
    async updateWorkflow(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        name: this.resource_name,
      };
      this.state.updating = true;
      await this.axios
        .put(`updateWorkFlow/${param[0].id}`, requestData, config)
        .then((response) => {
          if (response.data) {
            this.getWorkflows();
            this.state.updating = false;
            this.visibleEdit = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Resource  has been updated successfully.`,
              duration: 5,
            });
          } else {
            this.state.updating = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.updating = false;
        });
    },
    async deleteWorkflow(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.state.loading = true;
      await this.axios
        .delete(`DeleteWorkflow/${param[0].id}`, config)
        .then((response) => {
          if (response) {
            this.state.loading = false;
            this.visibleDeleteRole = false;
            this.getWorkflows();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Resource  deleted successfully.`,
              duration: 5,
            });
          } else {
            this.state.loading = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loading = false;
        });
    },
    refresh() {
      this.state.refresh = true;
      this.getWorkflows();
    },
    showCreateModal() {
      this.resource_name = "";
      this.visibleCreate = true;
    },
    showEditModal(data) {
      this.data = [
        {
          id: data.id,
          name: data.name,
        },
      ];
      (this.resource_name = data.name), (this.visibleEdit = true);
    },
    deleteModal(data) {
      this.data = [
        {
          id: data.id,
        },
      ];
      this.visibleDelete = true;
    },
    cancel(e) {
      console.log(e);
      this.visibleDelete = false;
      this.$message.error("Deleting resource has been canceled.");
    },
    handleDelete() {
      this.visibleDelete = false;
    },
  },
};
</script>

<style scoped>
*,
html,
body {
  font-family: Ubuntu;
}
</style>