<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 main mb-6">
      <div class="card card-main mt-3 rounded-20 ml-0 mb-8">
        <div class="jumbotron card-main rounded-20 p-4">
          <b-row>
            <div class="col-sm col-">
              <div class="text-left">
                <h5 class="text-uppercase text-black">{{project.code}} {{project.Title}}</h5>
              </div>
            </div>
            <div class="col-sm-5 col-md-5">
              <div class="justify-content-">
                <b-row>
                  <div class="col-sm col-md col-lg">
                    <button
                      @click="downloadProjectGuide"
                      class="
                        btn btn-secondary btn-create
                        float-right
                        mr-2
                        pr-5
                        pl-5
                        pt-2
                        pb-2
                        mb-2
                        m-3
                      "
                      role="button"
                      v-if="!state.downloading"
                    >
                      Export Project Guide
                    </button>
                    <button v-else class="btn btn-light d-flex rounded-20 mb-2 float-right download-btn">
                    <span class="">Downloading &nbsp;</span>
                    <div class="spinner-grow text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                  </div>
                </b-row>
                <b-row class="mb-0">
                <div class="col-sm-9 col-md-9"></div>
                <div class="col-sm col-md text-right">
                  <a-tooltip title="Refresh project data" placement="bottom" :color="'#87d068'">
                    <button 
                      @click="refresh"
                      type="button"
                      class="btn mr-3 mt-1 btn-secondary btn-sm rounded-20 border-none"
                    >
                      <b-icon-arrow-repeat               
                        font-scale="1.6"
                        :animation="state.refresh && state.loading ? 'spin' : ''"
                        @click="refresh"
                      />
                    </button>
                  </a-tooltip>
                </div>       
              </b-row>
              </div>
            </div>
            <div class="col">
              <canvas id="planet-chart"></canvas>
            </div>
          </b-row>
        </div>
        <div
          class="card-header bg-white rounded-top-left-20 rounded-top-right-20"
        >
          <div class="container">
            <b-row no-gutters>
              <div class="col-sm col-md">
                <div class="table-responsive">
                  <div class="all-tabs">
                    <div
                      class="tab text-12 pl-0 ml-2 pr-2 pl-2"
                      :class="{ active: selectedTabIndex == i }"
                      v-for="(tab, i) in shownTabs"
                      :key="i"
                      @click="setTab(i)"
                    >
                      {{
                        tab == "ResultChain" ? "Result Chain"
                          : tab == "Dashboard"
                          ? `${capitalizeFirstLetter(getKeyword("project"))} Details`
                          : tab == "MeasurementPlan"
                          ? "Measurement Plan"
                          : tab == "Risk"
                          ? "Risk Register"
                          : tab == "KnowledgeBase"
                          ? "Knowledge Base"
                          : tab == "projectActivity"
                          ? "Activity and Observation Log"
                          : tab == "ChangeLog"
                          ? "Change Log"
                          : tab
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </b-row>
          </div>
        </div>
        <div class="card-body pl-0 ml-0">
          <b-row no-gutters class="pb-3 px-2">
            <div class="table-responsive">
              <transition name="tabs-fade">
                <keep-alive :max="3">
                  <Component
                    :is="shownTabs[selectedTabIndex]"
                    :tabName="compID"
                    @refresh="refresh"
                  />
                </keep-alive>
              </transition>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
// import Chart from 'chart.js'
import decode from 'jwt-decode';
import { v4 as uuidv4 } from "uuid";
import Dashboard from "./dashboard-tab.vue";
import ResultChain from "./project-result-chain.vue";
import MeasurementPlan from "./measurement-plan.vue";
import Risk from "./risk-management.vue";
import KnowledgeBase from "./knowledge-management.vue";
import Reports from "./reports.vue";
import Assumptions from "./project-assumptions.vue";
import ChangeLog from "./project-change-log.vue";
import projectActivity from "./project-activity-log.vue";
import Approvals from './project-aprovals.vue';
export default {
  name: "project-dashboard",
  components: {
    Dashboard,
    ResultChain,
    MeasurementPlan,
    Risk,
    KnowledgeBase,
    Reports,
    Assumptions,
    ChangeLog,
    projectActivity,
    Approvals
  },
  data() {
    return {
      compID: uuidv4(),
      selectedTabIndex: 0,
      tabs: [
        "Dashboard",
        "ResultChain",
        "Assumptions",
        "MeasurementPlan",
        "Risk",
        "Reports",
        "ChangeLog",
        "projectActivity",
        "KnowledgeBase",
        "Approvals"
      ],
      pageTitle: this.$store.getters.getCurrentPrograam
            ? `${this.capitalizeFirstLetter(this.$store.getters.getCurrentPrograam.keyWords.project)}`
            : "projects",

      labels: ["Mercury", "Venus", "Earth", "Mars", "Jupiter", "Saturn", "Uranus", "Neptune"],
      datasets: [
        {
          label: "Number of Moons",
          data: [0, 0, 1, 2, 79, 82, 27, 14],
          backgroundColor: "rgba(54,73,93,.5)",
          borderColor: "#36495d",
          borderWidth: 3
        },
        {
          label: "Planetary Mass (relative to the Sun x 10^-6)",
          data: [0.166, 2.081, 3.003, 0.323, 954.792, 285.886, 43.662, 51.514],
          backgroundColor: "rgba(71, 183,132,.5)",
          borderColor: "#47b784",
          borderWidth: 3
        }
      ],
      state: {
        loading : false,
        refresh: false,
        currentp: false,
        downloading: false,
      }
    };
  },
  options: {
    responsive: true,
    lineTension: 1,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            padding: 25
          }
        }
      ]
    }
  },
  computed: {
    shownTabs() {
      return this.tabs;
    },
    project(){
      const storeData = sessionStorage.getItem("pjt")
      const projectData = this.$store.state.project_data;
      var decoded = null;
      if(storeData){
        decoded = decode(storeData)
      }
      return projectData ? projectData : decoded;
    },
    token(){
      return localStorage.getItem("token");
    },
    keywords(){
      return this.$store.state.current_program ? this.$store.state.current_program.keyWords : null;
    }
  },
  beforeMount(){
    this.getResultChainLevels();
    this.getAssumptions();
    this.getMeasurementPlan();
    this.getRisks();
    this.getKnowledgeBase();
    this.getCurrentProgram()
  },
  mounted(){
    // const ctx = document.getElementById('planet-chart');
    // new Chart(ctx, this.datasets);
  },
  methods: {
    setTab(index) {
      if (isNaN(index)) return;
      if (index == this.selectedTabIndex) return;
      this.$set(this, "selectedTabIndex", index);
    },
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.currentp = true;
     await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if(response.data){
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
            this.pageTitle = this.keywords ? this.keywords.project ? `${this.keywords.project}s` : "Projects" : "Projects";
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
    // Project data synchronized before
    async getResultChainLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getAllProjectResultchains/${this.project.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setProjectLevels",response.data)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    // Project data synchronized before
    async downloadProjectGuide() {
      const config = {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.downloading = true;
      await this.axios
        .get(`/export-project-guide/${this.project.id}?export=true`, config)
        .then((response) => {
          console.log(response)
          if (response.data) {
            this.state.downloading = false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.project.Title + ' Project Guide.xls'); //or any other extension
            document.body.appendChild(link);
            link.click();
            // this.$store.dispatch("setProjectLevels",response.data)
          } else {
            this.state.downloading = false;
          }
        })
        .catch(() => {
          this.state.downloading = false;
        });
    },
    // get risks
    async getRisks() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
        params: {
          scope: "project",
          elementId: this.project.id
        }
      };
      this.state.loading = true;
      await axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}getRisks`,config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setProjectRisks", response.data)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    // fetch project assumptions
     async getAssumptions() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getAssumptions/${this.project.id}`,config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setProjectAssumptions", response.data)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
     async getMeasurementPlan() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`project/${this.project.id}/measurement_plan`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setProjectMplans", response.data)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
     async getKnowledgeBase() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
        params: {
          scope: "project",
          elementId: `${this.project.id}`
        }
      };
      this.state.loading = true;
      await axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}getKnoledgeBase`,config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setProjectKnowledge", response.data);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    refresh(){
      this.state.refresh = true;
      this.getResultChainLevels();
      this.getAssumptions();
      this.getMeasurementPlan();
      this.getRisks();
      this.getKnowledgeBase();
    }
  },
};
</script>

<style scoped>
*,
html,
body {
  font-family: Ubuntu;
}
</style>