<template>
  <b-row class="row-chain m-5">
    <div class="col-sm col-md col-lg">
      <div
        class="nav flex-column nav-result-chain"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <h6 class="text-primary mb-4 ml-3 text-left">Social Impact</h6>
        <a
          class="tab-left nav-link active"
          id="v-pills-home-tab"
          data-toggle="pill"
          href="#v-pills-home"
          role="tab"
          aria-controls="v-pills-home"
          aria-selected="true"
          >Intermediate Outcomes</a
        >
        <a
          class="tab-left nav-link"
          id="v-pills-profile-tab"
          data-toggle="pill"
          href="#v-pills-profile"
          role="tab"
          aria-controls="v-pills-profile"
          aria-selected="false"
          >Market Sector Outcome</a
        >
        <a
          class="tab-left nav-link"
          id="v-pills-messages-tab"
          data-toggle="pill"
          href="#v-pills-messages"
          role="tab"
          aria-controls="v-pills-messages"
          aria-selected="false"
          >Financial Sector Outcome</a
        >
        <a
          class="tab-left nav-link"
          id="v-pills-settings-tab"
          data-toggle="pill"
          href="#v-pills-settings"
          role="tab"
          aria-controls="v-pills-settings"
          aria-selected="false"
          >Impact</a
        >
        <a
          @click="createChainModal"
          class="btn btn-new-key btn-primary pl-1 mt-5 pr-1 text-white"
          >New Result Chain</a
        >
      </div>
    </div>
    <div class="col-sm-9 col-md-9">
      <div class="tab-content" id="v-pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="v-pills-home"
          role="tabpanel"
          aria-labelledby="v-pills-home-tab"
        >
          <b-row>
            <div class="col-sm col-md col-lg">
              <b-row>
                <div class="col-sm col-md col-lg">
                  <div class="card card-chain">
                    <div class="card-header"></div>
                    <div class="card-body">
                      <p class="text-left">
                        <strong>S13:</strong> Increased resillece ans access to
                        basic goods and services - specifically education,
                        health, and housing by women and marginalised groups in
                        Rwanda.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm col-md col-lg">
                  <div class="card card-chain">
                    <div class="card-header"></div>
                    <div class="card-body">
                      <p class="text-left">
                        <strong>S13:</strong> Inclusive growth characterised by
                        expansion in income opportunities/reduced vulnerability
                        by poor men and women in Rwanda through access to jobs
                        and improved livelihoods.
                      </p>
                    </div>
                  </div>
                </div>
              </b-row>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-4">
              <a
                @click="createKeyModal"
                class="btn btn-new-key btn-primary pl-5 pr-5 text-white"
                >New Key</a
              >
            </div>
          </b-row>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-profile"
          role="tabpanel"
          aria-labelledby="v-pills-profile-tab"
        >
          <b-row>
            <div class="col-sm col-md col-lg">
              <b-row>
                <div class="col-sm col-md col-lg">
                  <div class="card card-chain">
                    <div class="card-header"></div>
                    <div class="card-body">
                      <p class="text-left">
                        <strong>SI3:</strong> Increased resilience and access to
                        basic goods and services - specifically education,
                        health, and housing by women and marginalised groups in
                        Rwanda
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm col-md col-lg">
                  <div class="card card-chain">
                    <div class="card-header"></div>
                    <div class="card-body">
                      <p class="text-left">
                        <strong>SI2:</strong> Inclusive growth characterised by
                        expansion in income opportunities/ reduced vulnerability
                        by poor men and women in Rwanda through access to jobs
                        and improved livelihoods
                      </p>
                    </div>
                  </div>
                </div>
              </b-row>
              <b-row>
                <div class="col-sm col-md col-lg">
                  <div class="card card-chain">
                    <div class="card-header"></div>
                    <div class="card-body">
                      <p class="text-left">
                        <strong>SI13:</strong> Increased resilience and access
                        to basic goods and services - specifically education,
                        health, and housing by women and marginalised groups in
                        Rwanda
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm col-md col-lg">
                  <div class="card card-chain">
                    <div class="card-header"></div>
                    <div class="card-body">
                      <p class="text-left">
                        <strong>SI2:</strong> Inclusive growth characterised by
                        expansion in income opportunities/ reduced vulnerability
                        by poor men and women in Rwanda through access to jobs
                        and improved livelihoods
                      </p>
                    </div>
                  </div>
                </div>
              </b-row>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-4">
              <a
                @click="createKeyModal"
                class="btn btn-new-key btn-primary pl-5 pr-5 text-white"
                >New Key</a
              >
            </div>
          </b-row>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-messages"
          role="tabpanel"
          aria-labelledby="v-pills-messages-tab"
        >
          <b-row>
            <div class="col-sm col-md col-lg">
              <b-row>
                <div class="col-sm col-md col-lg">
                  <div class="card card-chain">
                    <div class="card-header"></div>
                    <div class="card-body">
                      <p class="text-left">
                        <strong>SI3:</strong> Increased resilience and access to
                        basic goods and services - specifically education,
                        health, and housing by women and marginalised groups in
                        Rwanda
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm col-md col-lg">
                  <div class="card card-chain">
                    <div class="card-header"></div>
                    <div class="card-body">
                      <p class="text-left">
                        <strong>SI2:</strong> Inclusive growth characterised by
                        expansion in income opportunities/ reduced vulnerability
                        by poor men and women in Rwanda through access to jobs
                        and improved livelihoods
                      </p>
                    </div>
                  </div>
                </div>
              </b-row>
              <b-row>
                <div class="col-sm col-md col-lg">
                  <div class="card card-chain">
                    <div class="card-header"></div>
                    <div class="card-body">
                      <p class="text-left">
                        <strong>SI13:</strong> Increased resilience and access
                        to basic goods and services - specifically education,
                        health, and housing by women and marginalised groups in
                        Rwanda
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm col-md col-lg">
                  <div class="card card-chain">
                    <div class="card-header"></div>
                    <div class="card-body">
                      <p class="text-left">
                        <strong>SI2:</strong> Inclusive growth characterised by
                        expansion in income opportunities/ reduced vulnerability
                        by poor men and women in Rwanda through access to jobs
                        and improved livelihoods
                      </p>
                    </div>
                  </div>
                </div>
              </b-row>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-4">
              <a
                @click="createKeyModal"
                class="btn btn-new-key btn-primary pl-5 pr-5 text-white"
                >New Key</a
              >
            </div>
          </b-row>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-settings"
          role="tabpanel"
          aria-labelledby="v-pills-settings-tab"
        >
          ...
        </div>
      </div>
    </div>
    <b-row>
      <a-modal
        v-model="visibleChain"
        :footer="null"
        title="Create Result Chain"
      >
        <form>
          <b-row>
            <div class="col-sm-12 col-md-12">
              <div class="form-group">
                <input
                  placeholder="Element"
                  type="text"
                  class="form-control form-input-modal"
                />
              </div>
              <div class="form-group">
                <b-form-group class="form-text-label" label-for="desc">
                  <b-form-textarea
                    id="desc"
                    placeholder="Description"
                    rows="3"
                    resize
                    class="form-text-modal"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>
          </b-row>
          <b-row class="d-flex justify-content-center">
            <div class="inline-block pr-4">
              <a type="button" class="btn btn-primary btn-block btn-confirm"
                >OK</a
              >
            </div>
            <div class="inline-block">
              <a
                @click="cancelChainModal"
                type="button"
                class="btn btn-secondary btn-block btn-cancel"
                >CANCEL</a
              >
            </div>
          </b-row>
        </form>
      </a-modal>
      <a-modal v-model="visibleCreateKey" :footer="null" title="New Key">
        <form>
          <b-row>
            <div class="col-sm-12 col-md-12">
              <div class="form-group">
                <input
                  placeholder="Element"
                  type="text"
                  class="form-control form-input-modal"
                />
              </div>
              <div class="form-group">
                <select class="custom-select custom-select-modal bg-white">
                  <option selected value="" disabled>
                    Select result chain
                  </option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div class="form-group">
                <b-form-group class="form-text-label" label-for="desc">
                  <b-form-textarea
                    id="desc"
                    placeholder="Description"
                    rows="3"
                    resize
                    class="form-text-modal"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>
          </b-row>
          <b-row class="d-flex justify-content-center">
            <div class="inline-block pr-4">
              <a type="button" class="btn btn-primary btn-block btn-confirm"
                >OK</a
              >
            </div>
            <div class="inline-block">
              <a
                @click="cancelKeyModal"
                type="button"
                class="btn btn-secondary btn-block btn-cancel"
                >CANCEL</a
              >
            </div>
          </b-row>
        </form>
      </a-modal>
    </b-row>
  </b-row>
</template>

<script>
export default {
  name: "program-result-chain-tab",
  props: ["store"],
  // methods: {
  //   addUsers() {
  //     this.store.setState("users", [...this.store.state.users, "Julian"]);
  //   }
  data() {
    return {
      visibleCreateKey: false,
      visibleChain: false,
    };
  },
  methods: {
    createKeyModal() {
      this.visibleCreateKey = true;
    },
    createChainModal() {
      this.visibleChain = true;
    },
    cancelKeyModal() {
      this.visibleCreateKey = false;
    },
    cancelChainModal() {
      this.visibleChain = false;
    },
  },
};
</script>

<style scoped>
*,
body {
  font-family: Ubuntu !important;
}
.nav-result-chain .active {
  color: var(--primary) !important;
  border-left: 4px solid #0fa958 !important;
  color: #0fa958 !important;
}

.nav-result-chain .nav-link {
  text-align: left !important;
  font-family: Ubuntu;
  color: #acacac;
}

.main-wrapper .form-group label,
.main-wrapper .form-group input::placeholder,
.form-text,
.form-text::placeholder,
.form-text-label,
.form-control,
.custom-select {
  font-size: 13px !important;
}

.card-chain {
  background: #e5e5e5 !important;
  border-radius: 12px !important;
  border: none !important;
  height: 90% !important;
  font-size: 13px !important;
}

.card-chain .card-header {
  background: #0fa958 !important;
  border: none !important;
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.table-indicators th {
  font-size: 13px !important;
  border-top: none !important;
}

.table-indicators td {
  font-size: 13px !important;
}

.card-2 {
  border: none !important;
  border-radius: 20px !important;
}

.btn-new-key {
  border-radius: 20px !important;
  font-size: 13px !important;
}

@media (min-width: 600px) {
  .card-chain {
    margin-right: 0 !important;
  }
  .row-chain {
    margin-right: 0 !important;
  }
}
</style>
