<template>
  <div>
    <top-banner :title="pageTitle" />
    <main
      role="main"
      class="col-sm-10 ml-sm-auto col-md-10 pt-0 main-export-log-lg mb-8"
    >
      <b-container class="my-5" v-if="state.loading || !program || !data">
          <a-skeleton active :loading="state.loading" :paragraph="{rows: 16}"></a-skeleton>
      </b-container>
      <div v-else class="overflow-auto h-scroll overflow-y-scroll my-3 mt-5">
        <button v-if="!state.downloading" class="btn btn-primary rounded-20 mb-2 float-right download-btn" @click="downloadLogframe">Download</button>
        <button v-else class="btn btn-light d-flex rounded-20 mb-2 float-right download-btn">
          <span class="">Downloading &nbsp;</span>
           <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
        <div class="content-logframe-box my-4">
          <table class="table table-bordered w-100 text-13">
            <thead>
              <tr>
                <th
                  style=""
                  scope="col"
                  class="bg-yellow-white"
                >
                  Levels
                </th>
                <th
                  class="bg-dark-gray"
                  scope="col"
                >
                  Ref
                </th>
                <th scope="col" colspan="100%" class="text-center">
                  AFR  {{program.name}} LOGFRAME {{program.from}}-{{program.to}}
                  <br>
                  Generated on {{formatDateTime(new Date().toISOString())}}
                </th>
              </tr>
            </thead>
            <tbody v-for="(item,i) in data" :key="i">
              <tr>
                <td rowspan="1" class="bg-blue-white">{{item.levelName}}</td>
              </tr>
              <tr>
                <td :rowspan="getElementRowCount(item.indicatordata)" style="width: 150px !important;">
                  {{ item.elemenDescription }}
                </td>
              </tr>
              <template v-for="(indicator, k) in item.indicatordata">
                <tr :key="`inh_${k}`">
                  <td class="bg-dark-gray" style="width: 50px !important;">{{item.element}}</td>
                  <td style="width: 300px !important;" class="bg-yellow-white"> <span>AFR {{indicator.IndicatorTitle}}</span> </td>
                  <td style="width: 300px !important;" class="bg-yellow-white">Indicator Definition</td>
                  <td class="bg-yellow-white" style="width: 140px !important;"></td>
                  <td class="bg-green-white" style="width: 140px !important;">Baseline</td>
                  <th class="bg-green-white" v-for="(milestone,k) in indicator.milestones" :key="k" style="width: 140px !important;">{{milestone.MilestoneTitle}}</th>
                  <td class="bg-brown-white" style="width: 100px !important;" :rowspan="2 + 4 + getIndicatorDisaggregationRows(indicator.disaggregations)">
                    <span class="text-bold">Assumptions</span>
                    <template v-if="indicator.assumptions">
                      <br />
                      <span>{{indicator.assumptions.description}}</span>
                    </template>
                  </td>
                </tr>
                <tr :key="`ind_${k}`">
                  <!-- row span is for planned, achieved + two source rows -->
                  <td class="bg-dark-gray" :rowspan="5 + getIndicatorDisaggregationRows(indicator.disaggregations)"></td>
                  <td :rowspan="2 + 3 + getIndicatorDisaggregationRows(indicator.disaggregations)">{{indicator.name}}</td>
                  <td :rowspan="2 + 3 + getIndicatorDisaggregationRows(indicator.disaggregations)">{{indicator.indicatorDefinition}}</td>
                </tr>

                <!-- Planned and achieved values -->
                <tr :key="`ind_pl_${k}`">
                  <td>Planned</td>
                  <td class="b-right-none" style="width: 115px !important;">  {{ indicator.baseline }} </td>
                  <td class="b-right-none" v-for="(item,f) in indicator.milestones" :key="f" style="width: 115px !important;"> {{item.target}} </td>
                </tr>

                <!-- Disaggregatons -->
                <template v-for="(aggregation, agk) in extractDisaggregationElements(indicator.disaggregations)">
                  <!-- <template v-for="(aggregationElement, agEl) in aggregation"> -->
                  <tr :key="`ind_pl_ag_${agk}`" v-if="true">
                    <td>Planned {{aggregation.Title}}</td>
                    <td class="b-right-none" style="width: 115px !important;">  {{aggregation.baseline}} </td>
                    <td class="b-right-none" v-for="(item,f) in indicator.milestones" :key="f" style="width: 115px !important;"> {{searchDisaggregationElement(aggregation.id, item.Aggregation).target}} </td>
                  </tr>
                  <tr :key="`ind_ac_ag_${agk}`" v-if="true">
                    <td>Achieved {{aggregation.Title}}</td>
                    <td class="bg-dark-gray"></td>
                    <td class="b-right-none" v-for="(item,f) in indicator.milestones" :key="f" style="width: 115px !important;"> {{searchDisaggregationElement(aggregation.id, item.Aggregation).achieved}} </td>
                  </tr>
                  <!-- </template> -->
                </template>

                <tr :key="`ind_ac_${k}`">
                  <td>Achieved</td>
                  <td class="bg-dark-gray b-right-none"></td>
                  <td  class="b-right-none" style="width: 115px !important;" v-for="(ac,l) in indicator.milestones" :key="l"> {{ ac.achieved }} </td>
                </tr>
                <tr :key="`ind_sr_${k}`">
                  <td class="bg-green-white text-center text-bold" :colspan="indicator.milestones.length + 2">Source</td>
                </tr>
                <tr :key="`ind_srd_${k}`">
                  <td :colspan="indicator.milestones.length + 2">
                    <span><strong>Source:</strong> AFR impact assessments / evaluations</span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  component: {},
  data() {
    return {
      title: null,
      pageTitle: "Program",
      perPage: 8,
      currentPage: 1,
      filter: "",
      data: [],
      state: {
        loading: false,
        refresh: false,
        saving: false,
        downloading: false,
      },
      milestones: [],
      finalMiles:[],
      extra:[],
      indicatorData: []
    };
  },
  beforeMount() {
    this.$title(`Export program logframe`);
    this.getCurrentProgram();
  },
  computed: {
    token() {
      return sessionStorage.getItem("token");
    },
    program() {
      return this.$store.state.current_program;
    },
  },
  watch:{
    program(){
      if(this.program) this.getLogframData();
    }

  },
  methods: {
    async getCurrentProgram() {
      this.state.loading = true;
      await this.axios
        .get("getCurrentProgram")
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getLogframData() {
      this.state.loading = true;
      await this.axios
        .get(`ExportLogframe/${this.program.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.milestones=[];
            this.data = response.data;
            console.log('Logframe data', this.data)
            this.data.forEach(element => {
              element.indicatordata.forEach(item => {
                this.indicatorData.push(item);
                this.milestones.push(item.milestones);
              })
            });

            console.log("exported ", this.data);
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async downloadLogframe() {
      const config = {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.downloading = true;
      await this.axios
        .get(`/export-logframe_test/${this.program.id}?export=true`, config)
        .then((response) => {
          console.log(response)
          if (response.data) {
            this.state.downloading = false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.program.name + ' Logframe.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            // this.$store.dispatch("setProjectLevels",response.data)
          } else {
            this.state.downloading = false;
          }
        })
        .catch(() => {
          this.state.downloading = false;
        });
    },
    getElementRowCount(elementData){
      // returns the row count using indicators data and their aggregations
      let aggSum = 0;

      if(!elementData){
        console.error("element with no datta", elementData)
        return aggSum
      }
      elementData.forEach(function(elem){
        let milesm = (elem.milestones??[]).map(mile => (mile.Aggregation??[]).length)
        
        aggSum += milesm.reduce( (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue), 0);
      })
      let sum = (elementData.length??1) * 7; //rows per each indicator

      sum += parseInt(aggSum);
      // console.log('fts', sum, aggSum, elementData)

      return sum;
    },
    getIndicatorDisaggregationRows(disaggregations){
      // get elements in disaggregations
      let elements = this.extractDisaggregationElements(disaggregations)
      
      return (elements??[]).length * 2;

    },
    searchDisaggregationElement(disaggregationId, allDisaggregations){
      // this function searches an id in list of milestone disaggregations returned from the api and sends empty object to avoid errors for non disaggregated values
      let disaggElement =  allDisaggregations.find(agg => disaggregationId == agg.id) ?? {} 
      return disaggElement
    },
    extractDisaggregationElements(disaggregation){
      
      let elements = []
      disaggregation.forEach(agg => {
        (agg.elements??[]).map(elem => elements.push(elem))
      })
      if(disaggregation && disaggregation.length) console.log("extract", disaggregation, elements)
      return elements
    }
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family:  Arial, Helvetica, sans-serif !important;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.progress-wrapper {
  width: 260px !important;
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  background: #f3f3f3 !important;
}

.card-pillars {
  background: #3fb15d21 !important;
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}
.d-grid{
  display: grid;
}
.content-logframe-box{
  .table-bordered th, .table-bordered td{
    border-color: #000;
  }
}
.overflow-y-scroll{
  overflow-x: scroll !important;
}
</style>