<template>
  <div>
      <b-container>
          <b-row>
              <div class="col-sm col-md">
                  <b-img fluid :src="error404"/>
              </div>
          </b-row>
          <b-row class="mb-4">
              <div class="col-sm col-md">
                  <div class="d-block justify-content-center mb-5">
                      <h5 class="text-secondary">
                          Oops!, the page you're looking for not found.
                      </h5>
                      <div class="clear-fix"></div>
                    <a @click="$router.back()" class="btn text-center btn-success text-white btn-sm" href="#!"> Back to home </a>
                  </div>
              </div>
          </b-row>
      </b-container>
  </div>
</template>

<script>
import error404 from '../../assets/images/errors/404.gif';
export default {
    name: "404-error-not-found",
    data(){
        return{
            error404: error404
        }
    }

}
</script>

<style scoped>
    .btn-back{
        background-color: #0FA958 !important;
    }
</style>