<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 main mb-5">
      <b-row class="mt-3 row-admin">
        <div class="col-sm col-md col-lg">
          <div class="card card-main mt-1 ml-0 mb-4">
            <div class="card-body pl-0 ml-0">
              <b-row>
                <div class="col-sm col-md col-lg">
                  <h5 class="text-primary float-left ml-4">
                    {{`Pre - ${getKeyword('project')}`}}
                    <div class="vm-active"></div>
                  </h5>
                </div>
                <div class="col-sm col-md">
                  <a-tooltip
                    title="Project configuration"
                    v-if="projectID || drafted"
                  >
                    <span
                      @click="showModal"
                      class="float-right mr-3 cursor-pointer text-muted"
                    >
                      <i class="bx bxs-cog bx-md"></i>
                    </span>
                  </a-tooltip>
                </div>
              </b-row>
              <b-row>
                <div class="col-sm col-md col-lg">
                  <div class="container">
                      <!-- @project-submit="submitProject" -->
                    <d-stepper
                      :steps="steps"
                      :initial-state="{ name: '', users: [] }"
                      :desiredActiveStep="initialStep"
                    >
                      <template #fatal-error="{ errorMsg }">{{
                        errorMsg
                      }}</template>
                    </d-stepper>
                  </div>
                </div>
              </b-row>
            </div>
          </div>
        </div>
      </b-row>
    </main>
    <b-modal v-model="show" size="xl" title="Project Configuration" hide-footer>
      <!-- Hidden as its not needed now -->
      <b-row>
        <b-col>
          <b-row>
            <div class="col-sm col-md">
              <h6 class="text-13">Project Targets</h6>
              <div class="b-bottom w-25 my-2"></div>
              <b-row class="my-2">
                <div class="col-sm col-md"></div>
                <div class="col-sm-8 col-md-8">
                  <form>
                    <div class="input-group mb-2">
                      <input
                        type="search"
                        v-model="filter"
                        class="form-control input-search rounded-left-10"
                        aria-label="Search"
                        placeholder="Search project targets..."
                      />
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right-10">
                          <i class="bx bx-search text-success"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </b-row>
              <b-row class="mb-2">
                <div class="col-sm-9 col-md-9"></div>
                <div class="col-sm col-md text-right">
                  <button
                    @click="refresh"
                    type="button"
                    class="btn btn-secondary btn-sm rounded-10 border-none"
                  >
                    <b-container v-if="load.milestones && load.refresh">
                      <b-spinner small></b-spinner>
                      <span class="text-13 ml-2">Refreshing...</span>
                    </b-container>
                    <span v-else class="text text-13">Refresh</span>
                  </button>
                </div>
              </b-row>
              <div class="table-responsive mt-2">
                <b-container v-if="load.milestones">
                  <a-skeleton
                    :loading="load.milestones"
                    :paragraph="{ rows: 4 }"
                    active
                  ></a-skeleton>
                </b-container>
                <b-table
                  v-else
                  table-class="table table-knowledge rounded-20"
                  thead-tr-class="theader-knowledge tb-head text-left indent-20"
                  tbody-class="tb-body text-left indent-20"
                  :items="milestones"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="fields"
                  show-empty
                >
                  <template v-slot:cell(edit)="data">
                    <span @click="showEdit(data.item)" class="cursor-pointer"
                      ><i class="fa fa-edit text-success"></i
                    ></span>
                  </template>
                  <template v-slot:cell(delete)="data">
                    <span @click="deleteModal(data.item)" class="cursor-pointer"
                      ><i class="fa fa-trash text-danger"></i
                    ></span>
                  </template>
                  <template v-slot:cell(Due_date)="data">
                    <span>{{
                      moment(data.item.Duedate).format("DD/MM/YYYY")
                    }}</span>
                  </template>
                  <template v-slot:cell(creation_time)="data">
                    <span>{{
                      moment(data.item.created_at).format(
                        "DD/MM/YYYY H:mm:ss a"
                      )
                    }}</span>
                  </template>
                </b-table>
              </div>
              <b-row class="my-4">
                <div class="col-sm-8 col-md-8"></div>
                <div class="col-sm col-md">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    prev-class="text-13"
                    next-class="text-13"
                    first-class="text-13"
                    last-class="text-13"
                  ></b-pagination>
                </div>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12">
                  <div class="card">
                    <div class="card-body">
                      <b-form @submit.prevent="addMilestone">
                        <b-form-group>
                          <label class="float-left text-13"
                            >Target title</label
                          >
                          <input
                            v-model="milestone.title"
                            required
                            type="text"
                            class="form-control"
                            placeholder="Target title"
                          />
                        </b-form-group>
                        <b-form-group>
                          <label class="float-left">Due date</label>
                          <a-date-picker
                            v-model="defaultDate"
                            class="form-control"
                            @change="onChangeDate"
                          />
                        </b-form-group>
                        <b-form-group>
                          <b-form-checkbox
                            class="float-left"
                            :value="true"
                            :unchecked-value="false"
                            v-model="isreport"
                          >
                            Have a report
                          </b-form-checkbox>
                        </b-form-group>
                        <b-row v-if="isreport">
                          <b-col>
                            <b-form-group>
                              <label class="float-left text-13"
                                >Report title</label
                              >
                              <input
                                v-model="nreport.Title"
                                :required="isreport"
                                type="text"
                                class="form-control"
                                placeholder="Report title"
                              />
                            </b-form-group>
                            <b-form-group>
                              <label class="float-left">Report deadline </label>
                              <input
                                v-model="nreport.DeadLine"
                                :required="isreport"
                                type="date"
                                class="form-control"
                                placeholder="Report due date"
                              />
                            </b-form-group>
                            <b-form-group>
                              <b-form-checkbox
                                class="float-left"
                                value="true"
                                unchecked-value="false"
                                v-model="nreport.narativeReport"
                              >
                                Narative report
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-form-group>
                          <button
                            type="submit"
                            class="btn btn-primary rounded-20 float-left btn-sm"
                          >
                            <b-container v-if="state.addingMile">
                              <b-spinner small></b-spinner>
                              <span class="text-13 ml-2">Saving...</span>
                            </b-container>
                            <span v-if="!state.addingMile" class="text text-13"
                              >Submit</span
                            >
                          </button>
                        </b-form-group>
                      </b-form>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Update milestone -->
    <a-modal v-model="visibleEdit" :footer="null" title="Update Target">
      <form @submit.prevent="changeMilestone(data)">
        <div class="form-group">
          <label>Target title</label>
          <input
            placeholder="Target title"
            type="text"
            class="form-control"
            required
            v-model="edit.milestone"
          />
        </div>
        <b-form-group>
          <label>Due date</label>
          <input
            v-model="edit.dueDate"
            type="date"
            class="form-control"
          />
        </b-form-group>
        <button type="submit" class="btn btn-success btn-block">
          <b-container v-if="load.updating">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Saving...</span>
          </b-container>
          <span v-if="!load.updating" class="text text-13">Save Changes</span>
        </button>
      </form>
    </a-modal>
    <!-- ****** -->
  </div>
</template>

<script>
import moment from "moment";
import decode from "jwt-decode";
import DStepper from "../../../components/stepper/Stepper.vue";
import Step1 from "./components/stepper/step1.vue";
import Step2 from "./components/stepper/step2.vue";
import Step3 from "./components/stepper/step3.vue";
import Step4 from "./components/stepper/step4.vue";
export default {
  name: "create-project",
  components: {
    DStepper,
  },
  data() {
    return {
      defaultDate: moment(new Date()).format("YYYY-MM-DD"),
      currentPage: 0,
      perPage: 8,
      fields: ["Title", "Due_date", "creation_time", "edit", "delete"],
      filter: "",
      titles: {
        pillarTitle: "Pillars",
        toc: "TOC",
      },
      steps: [
        {
          name: "Details",
          disabled: false,
          active: true,
          component: Step1,
        },
        {
          name: "Result Chain",
          disabled: false,
          active: true,
          component: Step2,
        },
        {
          name: "Measurement Plan",
          disabled: false,
          active: false,
          component: Step3,
        },
        {
          name: "Submission",
          disabled: false,
          active: false,
          component: Step4,
          confirm: "Confirm",
        },
      ],
      activeTab: true,
      initialStep: 0,
      pageTitle: "OVERVIEW",
      state: {
        loading: false,
        currentp: false,
        addingMile: false,
      },
      milestone: {
        title: null,
        dueDate: null,
      },
      edit: {
        milestone: null,
        dueDate: null,
      },
      isreport: false,
      nreport: {
        Title: "",
        DeadLine: null,
        narativeReport: false,
      },
      milestones: [],
      show: false,
      data: null,
      visibleDelete: false,
      visibleEdit: false,
      load: {
        loading: false,
        refresh: false,
        adding: false,
        updating: false,
        deleting: false,
        static: false,
        milestones: false,
      },
    };
  },
  mounted() {
    sessionStorage.setItem("creation", "project");
    this.$title("Create project");
    this.getCurrentProgram();

    // Check if we should take user to a particular step
    let desiredStep = this.$route.query.step
    if(desiredStep && desiredStep >= 0 && desiredStep <= this.steps.length){
      console.log("step", desiredStep)
      this.initialStep = desiredStep
    }
  },
  computed: {
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    token() {
      return localStorage.getItem("token");
    },
    currentSaved() {
      const data = sessionStorage.getItem("project");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.project ? this.project : decoded;
    },
    project() {
      return this.$store.state.project_data
        ? this.$store.state.project_data
        : null;
    },
    drafted() {
      var decoded = null;
      const storeData = sessionStorage.getItem("drafted-project");
      if (storeData) {
        decoded = decode(storeData);
      }
      return decoded;
    },
    projectStore() {
      return this.currentSaved
        ? this.currentSaved
        : this.drafted
        ? this.drafted
        : this.drafted;
    },
    projectID() {
      return this.drafted
        ? this.drafted
        : this.currentSaved
        ? this.currentSaved.PojectData
        : null;
    },
    rows() {
      return this.milestones.length;
    },
  },
  methods: {
    showModal() {
      this.show = true;
      if (this.projectID) {
        this.getMilestones();
      } else if (this.drafted) {
        this.getMilestones();
      }
    },
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    // milestones
    getMilestones() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.load.milestones = true;
      this.axios
        .get(`getProjectMilestone/${this.projectID.id}`, config)
        .then((response) => {
          this.load.milestones = false;
          this.milestones = response.data;
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.load.milestones = false;
        });
    },
    async addMilestone() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        projectId: this.projectID.id,
        Title: this.milestone.title,
        Duedate: this.milestone.dueDate
          ? this.milestone.dueDate
          : this.defaultDate,
        reportData: this.nreport,
      };
      this.state.addingMile = true;
      await this.axios
        .post("AddMilestoneProject", requestData, config)
        .then((response) => {
          if (response.data.message) {
            this.state.addingMile = false;
            this.$message.error(`${response.data.message}`, 4);
            this.$notification["error"]({
              message: "ERROR!",
              description: `${response.data.message}`,
              duration: 5,
            });
          } else {
            this.state.addingMile = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Project reporting cycle added successfully.`,
              duration: 5,
            });

            // reset the data
            this.milestone.title = null
            this.defaultDate = null
            this.isreport = false
            this.nreport = {
              Title: "",
              DeadLine: null,
              narativeReport: false,
            },
            this.getMilestones();
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.addingMile = false;
        });
    },
    async changeMilestone(param) {
      const requestData = {
        Title: this.edit.milestone,
        Duedate: this.edit.dueDate,
      };
      this.load.updating = true;
      await this.axios
        .put(`updateProjectMilestone/${param.id}`, requestData)
        .then((response) => {
          console.log(response)
          this.load.updating = false;
          this.visibleEdit = false;
          if (this.programId) {
            this.getMilestones();
          } else if (this.drafted) {
            this.getMilestones();
          }
          this.$notification["success"]({
            message: "SUCCESS!",
            description: `Target has been updated successfully.`,
            duration: 5,
          });
          this.show = true;
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.load.updating = false;
        });
    },
    deleteModal(data) {
      this.data = data;
      this.show = false;
      this.visibleDelete = true;
    },
    async deleteMilestone(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.load.deleting = true;
      await this.axios
        .delete(`deleteProjectMilestone/${param.id}`, config)
        .then((response) => {
          if (response) {
            this.load.deleting = false;
            this.show = true;
            this.visibleDelete = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Program target has been deleted successfully.`,
              duration: 4,
            });
            if (this.projectID) {
              this.getMilestones();
            } else if (this.drafted) {
              this.getMilestones();
            }
          } else {
            this.load.deleting = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loading = false;
        });
    },
    cancel(e) {
      console.log(e);
      this.visibleDelete = false;
      this.$message.error("Deleting target has been canceled.");
    },
    refresh() {
      this.load.refresh = true;
      this.getMilestones();
    },
    onChangeDate(date, dateString) {
      date;
      this.milestone.dueDate = dateString;
    },
    handleCancel() {
      this.visibleDelete = false;
      this.show = true;
    },
    showEdit(data) {
      this.show = false;
      this.visibleEdit = true;
      this.data = data;
      this.edit.milestone = data.Title;
      this.edit.dueDate = this.data.Duedate;
    },
  },
};
</script>

<style scoped>
/* Top card styles */
.top-card {
  background-color: rgba(119, 119, 119, 0.507);
  border-radius: 10px !important;
  padding: 20px 12px !important;
  width: 234px !important;
  height: 123px !important;
}

.top-card h3 {
  font-size: 16px !important;
  padding-bottom: 3px !important;
}

.top-card p {
  font-size: 15px !important;
  padding-top: 2.5px !important;
}

.btn-top-create {
  background: #0fa958 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>