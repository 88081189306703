<template>
  <div>
      <div class="card bg-white border-none mt-3 ml-0 mb-4">
        <div class="card-body pl-0 ml-0">
          <b-row class="mb-3">
          <div class="col-sm col-md">
            <div class="d-flex justify-content-end">
              <router-link
                class="btn btn-primary rounded-20 pl-4 pr-4 text-12"
                :to="{name:'export-project-mplan'}"
                
              >
                Preview Measurement Plan
              </router-link>
            </div>
          </div>
        </b-row>
          <b-row>
            <div class="col-sm col-md">
              <b-row class="ml-2">
                <div class="table-responsive">
                  <table
                    class="
                      table
                      text-13                   
                      table-program-measurements
                      text-left
                      w-100
                    "
                  >  
                    <thead>
                      <tr class="tb-head">
                        <th>Element</th>
                        <th>Indicator</th>
                        <th>Baseline</th>
                        <th>Target</th>
                        <th>Frequency</th>
                        <th>Methods</th>
                      </tr>
                    </thead>
                    <tbody class="tb-body">
                      <template v-for="(item, i) in measurement">
                        <tr :key="`row_${i}`" v-if="item.indicatordata">
                          <td v-if="item.indicatordata"  :rowspan="item.indicatordata.length + 1">{{item.element}}</td>
                        </tr>
                        <tr v-for="(indicator,j) in item.indicatordata" :key="`elem_${j}`">
                          <td>{{indicator.Title}}</td>
                          <td><span class="ml-2">{{indicator.baseline}}</span></td>
                          <td><span class="ml-2">{{indicator.target}}</span></td>
                          <td>{{indicator.frequency}} </td>
                          <td>{{indicator.methods}} Methods</td>
                        </tr>  
                      </template>
                                     
                    </tbody>
                  </table>
                </div>
              </b-row>
            </div>
          </b-row>
        </div>
      </div>
  </div>
</template>

<script>
import decode from 'jwt-decode';
export default {
    name:'measurement-tab',
    data(){
      return {
        state: {
          loading: false,
          refresh: false
        },
        filter:''
      }
    },
    computed: {
      project(){
        const storeData = sessionStorage.getItem("pjt")
        const projectData = this.$store.state.project_data;
        var decoded = null;
        if(storeData){
          decoded = decode(storeData)
        }
          return projectData ? projectData : decoded;
      },
      token(){
        return localStorage.getItem("token");
        },
        measurement(){
          return this.$store.state.selected_project.project_mplan;
        }
      },
    beforeMount(){},
    methods: {}
}
</script>

<style scoped>
  .table-all{
    border: 1px solid #cdd0d1 !important;
    border-radius: 4px !important;
    font-size: 12px !important;
  }

  .cursor-pointer{
    cursor: pointer !important;
  }
  
  .table-all .tbody-row td{
    text-align: left;
  }

  .row-1{
    border: 1px solid #F5F6F7 !important;
  }

  .card-2{
        border: none !important;
        background-color: transparent !important;
    }

  .table-all .top-panel td span{
    font-size: 13px !important;
  }

  .table-all .theader-projects th{
    font-size: 13px !important;
  }

  .table-all th{
    text-align: left !important;
    
  }

  @media (max-width: 768px) {
    .table-all .theader-projects .table-search{
      width: 165px !important;
    }
  }
</style>