<template>
    <div>
        <b-row>
            <div class="col-sm col-md col-lg"></div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <form>
                    <div class="form-group">
                        <select class="custom-select bg-white" >
                            <option selected value="" disabled>Default workflow</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="col-sm col-md col-lg"></div>
        </b-row>
        <b-row>
            <div class="col-sm col-md col-lg"></div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <div class="card card-2">
                    <div class="card-body">
                        <div class="table-responsive mt-2">
                            <table class="table table-indicators">
                                <thead>
                                    <tr class="theader-indicators">
                                        <th>Level</th>
                                        <th>Name</th>
                                        <th>Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>MUHIRE Aime</td>
                                        <td>MRM Manager</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Eric Uwera</td>
                                        <td>Head MRMLC</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm col-md col-lg"></div>
        </b-row>
    </div>  
</template>

<script>

export default {
  props: ["store"],
  methods: {
    pushRoute() {
        this.$router.push('/user/projects/project-dashboard');
    },
    confirmReport(){
      this.$on('confirm', this.pushRoute());
    }
    //nextStep() {
    /*this.$emit("loading", true);
      setTimeout(() => {
        this.$emit("can-continue");
        //this.$emit("fatal-error", "Se ha producido un error inesperado");
      }, 2000);*/
    //}
  }
};
</script>

<style scoped>
.main-wrapper .form-group label, 
.main-wrapper .form-group input::placeholder,
.form-text,.form-text::placeholder,
.form-text-label,.form-control,
.custom-select
{
  font-size: 13px !important;
}

.table-indicators th{
  font-size: 13px !important;
  border-top: none !important;
}

.table-indicators td{
  font-size: 13px !important;
}

.card-2{
  border: none !important;
  border-radius: 20px !important;
}
</style>
