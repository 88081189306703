<template>
  <div>
    <div class="card border-none b-none">
      <div class="card-body">
        <b-row class="ml-2">
          <div class="col-sm col-md">
            <div class="card card-2 mt-2">
              <div class="card-body">
                <b-row class="mb-2">
                  <div class="col-sm col-md text-left">
                    <button
                      @click="refresh"
                      type="button"
                      class="btn btn-secondary btn-sm rounded-10 border-none"
                    >
                      <b-container v-if="state.loading && state.refresh">
                        <b-spinner small></b-spinner>
                        <span class="text-13 ml-2">Refreshing...</span>
                      </b-container>
                      <span v-else class="text text-13">Refresh</span>
                    </button>
                  </div>
                  <div class="col-sm-3 col-md"></div>
                </b-row>
                <div class="d-flex justify-content-between">
                  <div>
                    <h6 class="knowledge-text ml-2 text-black">
                      Knowledge Base
                    </h6>
                  </div>
                  <div class="">
                    <router-link
                      :to="{ name: 'add-knowledge' }"
                      class="border btn btn-primary btn-sm btn-add-knowledge"
                      href="#"
                      role="button"
                    >
                      <i class="fas fa-plus" aria-hidden="true"></i> Add new
                      knowledge
                    </router-link>
                  </div>
                </div>
                <div class="table-responsive mt-2">
                  <b-container class="my-5" v-if="loading">
                    <a-skeleton :loading="loading" active :paragraph="{rows: 6}" ></a-skeleton>
                  </b-container>
                  <b-table
                    v-else
                    table-class="table table-knowledge rounded-20"
                    thead-tr-class="theader-knowledge tb-head text-left indent-20"
                    tbody-class="tb-body text-left indent-20"
                    :items="knowlege"
                    :filter="filter"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    show-empty
                  >
                    <template v-slot:cell(No)="data">
                      <span>{{ data.index + 1 }}</span>
                    </template>
                    <template v-slot:cell(files)="data">
                      <a
                        v-for="(file, i) in data.item.file"
                        :href="file"
                        target="_blank"
                        :key="i"
                        download="afr-knowledge-base-file"
                      >
                        <i class="bx bxs-file"></i> file {{ i + 1 }}
                      </a>
                    </template>
                    <template v-slot:cell(edit)="data">
                      <button
                        @click="showEditModal(data.item)"
                        class="btn btn-sm btn-outline-success"
                      >
                        <i class="bx bx-edit-alt"></i>
                      </button>
                    </template>
                    <template v-slot:cell(delete)="data">
                      <button
                        @click="deleteModal(data.item)"
                        class="btn btn-sm btn-outline-danger"
                      >
                        <i class="bx bx-x"></i>
                      </button>
                    </template>
                  </b-table>
                </div>
                <b-row class="my-4">
                  <div class="col-sm-8 col-md-8"></div>
                  <div class="col-sm col-md">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      first-text="First"
                      prev-text="Prev"
                      next-text="Next"
                      last-text="Last"
                      prev-class="text-13"
                      next-class="text-13"
                      first-class="text-13"
                      last-class="text-13"
                    ></b-pagination>
                  </div>
                </b-row>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </div>
     <!-- Delete risk modal -->
    <a-modal v-model="visibleDelete" :footer="null" title="Delete knowledge base">
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to delete this information ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <a-popconfirm
            title="Are you sure delete this Knowledge base?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteKnowledgeBase(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="state.deleting">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Deleting...</span>
              </b-container>
              <span v-if="!state.deleting" class="text text-13">DELETE</span>
            </button>
          </a-popconfirm>
        </div>
        <div class="inline-block">
          <button
            @click="visibleDelete = false"
            type="submit"
            class="btn btn-secondary btn-block btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
      </b-row>
    </a-modal>
  </div>
</template>

<script>
import axios from "axios";
import decode from "jwt-decode";
export default {
  name: "program-knowledge-base-tab",
  props: {
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      state: {
        loading: false,
        deleting: false
      },
      fields: ["No", "Title", "scope", "sources", "files", "edit", "delete"],
      perPage: 4,
      currentPage: 1,
      visibleDelete: false,
      data: null
    };
  },
  computed: {
    program() {
      let project = sessionStorage.getItem("prg");
      const resData = decode(project);
      return resData;
    },
    token() {
      return localStorage.getItem("token");
    },
    rows() {
      return this.knowlege ?  this.knowlege.length : 0;
    },
    knowlege(){
      return this.$store.state.selected_program.program_knowledge;
    }
  },
  mounted() {},
  methods: {
    onDownload(url) {
      axios({
        url: `https://quick-proxy.herokuapp.com/${url}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    async deleteKnowledgeBase(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.state.deleting = true;
      await this.axios
        .delete(`deleteKnowledgeBase/${param.id}`, config)
        .then((response) => {
          if (response) {
            this.state.deleting = false;
            this.visibleDelete = false;
            this.refresh();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Knowledge base deleted successfully.`,
              duration: 5,
            });
          } else {
            this.state.deleting = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.deleting = false;
        });
    },
    deleteModal(param) {
      this.data = param;
      this.visibleDelete = true;
    },
    refresh() {
      this.$emit('refresh')
    },
    cancel(e) {
      console.log(e);
      this.visibleDelete = false;
      this.$message.error("Deleting knowledge base has been canceled.");
    },
  },
};
</script>

<style>
</style>