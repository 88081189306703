<template>
  <b-container v-if="state.loadingLevel">
    <a-skeleton active avatar :paragraph="{rows: 6}" :loading="state.loadingLevel"></a-skeleton>
  </b-container>
  <form v-else @submit.prevent="addLevels">
    <b-row>
      <div class="col-sm-12 col-md-12">
        <b-row>
          <div class="col-sm-7 col-md-7">
            <div class="form-group">
              <input
                placeholder="Level name"
                type="text"
                class="form-control form-input-modal"
                v-model="level"
                required
              />
            </div>
          </div>
          <div class="col-sm col-md">
            <div class="form-group">
              <input
                placeholder="Abbreviation"
                type="text"
                class="form-control form-input-modal"
                v-model="abbr"
                required
              />
            </div>
          </div>
        </b-row>
        <b-row class="mb-3">
          <div class="col-sm col-md">
            <select
              required
              v-model="levelNumber"
              class="custom-select w-100 form-input-modal indent-20 rounded-20"
            >
              <option :value="null" disabled selected>Level number</option>
              <option
                v-for="(item, i) in programTempArray"
                :key="i"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
        </b-row>
        <div class="form-group">
          <b-form-group class="form-text-label" label-for="desc">
            <b-form-textarea
              id="desc"
              placeholder="Description"
              rows="3"
              resize
              class="form-text-modal"
              v-model="description"
              required
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="form-group">
          <b-row>
            <div class="col-sm col-md">
              <label>Select color</label>
              <div class="d-flex justify-content-around">
                <button
                  type="button"
                  @click="color1('#ff5e00f6')"
                  class="btn-color orange p-3 h-50 mt-2 border-none"
                ></button>
                <button
                  @click="color1('#ffbb00')"
                  type="button"
                  class="btn-color orange-yellow p-3 h-50 mt-2 border-none"
                ></button>
                <button
                  @click="color1('#C4FF61')"
                  type="button"
                  class="btn-color green-yellow p-3 h-50 mt-2 border-none"
                ></button>
                <button
                  @click="color1('#0077ff')"
                  type="button"
                  class="btn-color blue p-3 h-50 mt-2 border-none"
                ></button>
                <button
                  type="button"
                  @click.prevent="openPicker"
                  class="m-2 btn btn-light btn-custom-color rounded-10"
                >
                  Custom color
                </button>
              </div>
            </div>
          </b-row>
          <transition name="fade">
            <div v-if="visiblePicker" class="">
              <div class="input-group color-picker" ref="colorpicker">
                <input
                  type="text"
                  class="form-control"
                  v-model="colorValue.hex"
                  @focus="showPicker()"
                  @input="updateFromInput"
                />
                <span class="input-group-addon color-picker-container">
                  <span
                    class="current-color"
                    :style="'background-color: ' + colorValue"
                    @click="togglePicker()"
                  ></span>
                  <picker
                    :value="colors"
                    v-model="colorValue"
                    v-if="displayPicker"
                  />
                </span>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </b-row>
    <b-row class="d-flex justify-content-around">
      <div class="inline-block">
        <button
          type="submit"
          class="btn text-13 btn-primary rounded-20 btn-block btn-confirm"
        >
          <b-container v-if="state.loadLevloadingLevelel">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Saving...</span>
          </b-container>
          <span v-if="!state.loadLevel" class="text text-13">OK</span>
        </button>
      </div>
      <div class="inline-block">
        <a
          @click="$emit('dismiss')"
          type="button"
          class="btn text-13 btn-secondary rounded-20 btn-block btn-cancel"
          >CANCEL</a
        >
      </div>
    </b-row>
  </form>
</template>

<script>
import decode from "jwt-decode";
import { Chrome } from "vue-color";
export default {
  props: ["store","leveldata"],
  components: {
    picker: Chrome,
  },
  data() {
    return {
      visibleCreateKey: false,
      visibleTOCContributionModal: false,
      visibleChain: false,
      visibleContribute: false,
      color: "",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      visiblePicker: false,
      levels: [],
      state: {
        loading: false,
        loadLevel: false,
        loadElem: false,
        contribute: false,
        refresh: false,
        loadingLevel:false
      },
      cont: {
        loading: false,
        level: null,
        element: null,
      },
      contLevel:null,
      level: "",
      abbr: "",
      description: "",
      levelItem: null,
      element: {
        name: "",
        description: "",
      },
      elements: [],
      toc: null,
      colors: {
        hex: "",
      },
      levelNumber: null,
      colorValue: "",
      displayPicker: false,
      data: null,
      currentPage:1,
      perPage: 5,
      filter:"",
      plevels: null,
      programTempArray: [],
    };
  },
  computed: {
    token(){
      return localStorage.getItem("token");
    },
    levelsNumber() {
      return this.levels.length ? this.levels.length : 0;
    },
    // drafted() {
    //   const data = sessionStorage.getItem("draft");
    //   var decoded = null;
    //   if (data) {
    //     decoded = decode(data);
    //   }
    //   return decoded;
    // },
    currentp(){
      return this.$store.state.current_program;
    },
    storagep() {
      var session = sessionStorage.getItem("prg");
      var tableData = null;
      if(session){
        tableData = decode(session);
      }
      return tableData;
    },
    program(){
        return this.storagep ? this.storagep : this.currentp ? this.currentp : null;
    },
    keywords(){
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    rows(){
      return this.allelements ? this.allelements : 0;
    },
    user(){
      return this.$store.state.user_data.user;
    },
  },
  beforeMount() {
    this.getProgramLevels()
    this.getTOClevelData(this.leveldata.id)
    if(this.program) {
      for (let i = 1; i <= parseInt(this.program.levels); i++) {
        this.programTempArray.push(i)
      }
    }
  },
  mounted() {
    this.setColor(this.color || "")
  },
  watch: {
    colorValue() {
      this.colors.hex = this.colorValue.hex;
    },
  },
  methods: {
    async addLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        level: this.level,
        Description: this.description,
        abbraviation: this.abbr,
        color: this.colors.hex,
        program: this.currentSaved
          ? this.currentSaved.programData.id
          : this.drafted
          ? this.drafted.id
          : 0,
        levelNumber: this.levelNumber,
      };
      if (this.colors.hex == "") {
        this.$notification["error"]({
          message: "REQUIRED ERROR!",
          description: `Please color choosing is required.`,
          duration: 6,
        });
      } else {
        this.state.loadLevel = true;
        await this.axios
          .post("AddTOC", requestData, config)
          .then((response) => {
            if (response.data) {
              this.state.loadLevel = false;
              this.visibleChain = false;
              this.getProgramLevels();
              this.$notification["success"]({
                message: "SUCCESS!",
                description: `Theory of change level added successfully.`,
                duration: 5,
              });
              this.colors.hex = "";
              this.colorValue = "";
              this.colorValue.hex = "";
              this.levelNumber = null;
              this.level = "";
              this.description = "";
              this.abbr = "";
            } else {
              this.$message.error(`Something wrong when adding level.`, 4);
              this.state.loadLevel = false;
            }
          })
          .catch(() => {
            // this.$notification["error"]({
            //   message: "ERROR!",
            //   description: `${error.errorMessage}`,
            //   duration: 7,
            // });
            this.state.loadLevel = false;
          });
      }
    },
    
    async getTOClevelData(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadingLevel = true;
      await this.axios
        .get(`getTheoryofChangeId/${param}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadingLevel = false;
            this.data = response.data
            this.levelNumber = this.data.levelNumber;
            this.level = this.data.level;
            this.description = this.data.Description;
            this.abbr = this.data.abbraviation;
          } else {
            this.state.loadingLevel = false;
          }
        })
        .catch(() => {
          this.state.loadingLevel = false;
        });
    },
    removeSpace(title) {
      return title.replace(/\s/g, "-");
    },
    async getProgramLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(
          `getTocLevels/${this.program.id}`,
          config
        )
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.levels = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    createKeyModal() {
      this.visibleCreateKey = true;
    },
    createChainModal() {
      this.visibleChain = true;
    },
    cancelKeyModal() {
      this.visibleCreateKey = false;
    },
    createTOCContributionModal() {
      this.visibleTOCContributionModal = true;
    },

    cancelChainModal() {
      this.visibleChain = false;
    },
    openPicker() {
      this.visiblePicker = !this.visiblePicker;
    },
    changeColor(color) {
      const {
        rgba: { r, g, b, a },
      } = color;
      this.color = `rgba(${(r, g, b, a)})`;
    },
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if (color.slice(0, 1) == "#") {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == "rgba") {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(","),
          hex =
            "#" +
            (
              (1 << 24) +
              (parseInt(rgba[0]) << 16) +
              (parseInt(rgba[1]) << 8) +
              parseInt(rgba[2])
            )
              .toString(16)
              .slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener("click", this.documentClick);
      this.displayPicker = true;
    },
    showContribute(params) {
      this.visibleContribute = true;
      this.data = params;
      this.element1 = params.id;
      this.getContributionData(params)
    },
    hidePicker() {
      document.removeEventListener("click", this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue =
          "rgba(" +
          color.rgba.r +
          ", " +
          color.rgba.g +
          ", " +
          color.rgba.b +
          ", " +
          color.rgba.a +
          ")";
      }
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
    color1(color) {
      this.colors.hex = color;
    },
    refresh(){
      this.state.refresh=true;
    }
  },
};
</script>

<style>
</style>