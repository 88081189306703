<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-12 ml-sm-auto col-md-12 pt-0 main mb-5">
      <div class="card card-main card-0 rounded-20 ml-0 mb-4">
        <div class="card-body pl-0 ml-0">
          <b-row>
            <div class="col-sm col-md">
              <b-row class="my-0">
                <div class="col-sm col-md"></div>
                <div class="col-sm-4 col-md-4">
                  <form action="">
                    <div class="form-group ml-2">
                      <input
                        type="text"
                        class="form-control p-3 search-box indent-10 rounded-20 text-13"
                        :placeholder="keywords ? keywords.project ? `Search ${keywords.project} name` : 'Search project name' : 'Search project name'"
                        v-model="filter"
                      />
                    </div>
                  </form>
                </div>
              </b-row>
              <b-row class="ml-2">
                <div class="table-responsive">
                  <b-container class="my-5" v-if="state.loading">
                    <a-skeleton active :loading="state.loading" :paragraph="{rows: 8}"></a-skeleton>
                  </b-container>
                  <b-table
                    v-else
                    table-class="table-programs text-13"
                    striped
                    hover
                    thead-tr-class="tb-head text-left indent-20"
                    tbody-class="tb-body text-left indent-20"
                    :items="projects"
                    :filter="filter"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    show-empty
                  >
                    <template v-slot:cell(title)="data">
                      <span @click="goToProject(data.item)" class="text-capitalize link-text cursor-pointer">{{data.item.Title}}</span>
                    </template>
                    <template v-slot:cell(timeline)="data">
                        <div>
                          <b-progress
                            height="10px"
                            class="ml-1 pt-0 progress-wrapper"
                            :max="100"
                          >
                            <b-progress-bar
                              class="af-progress"
                              :value="data.item.completion_percentage"
                            ></b-progress-bar>
                          </b-progress>
                        </div>
                    </template>
                  </b-table>
                </div>
              </b-row>
              <b-row class="mb-4">
                <div class="col-sm-8"></div>
                <div class="col-sm">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    class="ml-2 float-right"
                  ></b-pagination>
                </div>
              </b-row>
              <b-row class="mb-6">
                <div class="col-sm col-md">
                  <div class="d-flex justify-content-end">
                    
                  </div>
                </div>
              </b-row>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import decode from 'jwt-decode';
import encode from 'jwt-encode';
export default {
  component: {},
  data() {
    return {
      title: null,
      pageTitle: this.getKeyword('pillar'),
      fields: ['code', "title","timeline"],
      perPage: 8,
      currentPage: 1,
      filter: "",
      state: {
        loading: false,
        refresh: false,
        saving: false,
      },
    };
  },
  beforeMount() {
    this.$title("Projects");
    this.refresh();
    this.getCurrentProgram()
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    rows() {
      return this.projects ? this.projects.length : 0;
    },
    pillar(){
      const storeData = sessionStorage.getItem("pl")
      const pillarData = this.$store.state.pillar_data;
      var decoded = null;
      if(storeData){
        decoded = decode(storeData)
      }
      return pillarData ? pillarData : decoded;
    },
    projects(){
      return this.$store.state.selected_pillar.pillar_projects;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  methods: {
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.currentp = true;
     await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if(response.data){
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
            this.pageTitle = this.keywords ? this.keywords.pillar ? `${this.keywords.pillar}` : "Pillar" : "Pillar";
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
    goToProject(param){
      const secret = 'tokenHashingViv--afr0-101-6534bjhdvw-+++5##';
      const jwt = encode(param,secret);
      sessionStorage.setItem("pjt",jwt);
      this.$router.push({name:'project-dashboard'})
    },
    refresh() {
      this.$emit('refresh');
    },
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.progress-wrapper {
  width: 260px !important;
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  background: #f3f3f3 !important;
}

.card-pillars {
  background: #3fb15d21 !important;
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}

</style>