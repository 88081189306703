<template>
  <div id="admin-container">
    <header id="admin-nav">
      <Sidebar/>
    </header>

    <div id="admin-content">
      <!-- Main -->
      <main id="admin-main-content">
        <transition name="component-fade" id="student-screen">
          <router-view :key="$route.path"/>
        </transition>
        <AppFooter v-once />
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from './shared/SideBarNav.vue';
import AppFooter from './shared/Footer.vue';
export default {
    components:{
        Sidebar,
        AppFooter
     }
}
</script>

<style>

</style>