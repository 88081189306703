<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto main col-md-10 pt-0 mb-8">
      <div class="card card-main card-0 mt-4 mb-6 ml-0 mb-8">
        <div class="card-body pl-0 ml-0">
          <b-row>
            <div class="col-sm-9"></div>
            <div class="col-sm">
              <form>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control input-search rounded-left-10"
                    aria-label="Search"
                    placeholder="Search ..."
                    v-model="filter"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right-10">
                      <i class="bx bx-search text-success"></i>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </b-row>
          <b-row class="mb-2">
            <div class="col-sm-9 col-md-9"></div>
            <div class="col-sm col-md text-right">
                <button
                @click="refresh"
                type="button"
                class="btn btn-secondary btn-sm rounded-10 border-none"
                >
                <b-container v-if="state.loading && state.refresh">
                    <b-spinner small></b-spinner>
                    <span class="text-13 ml-2">Refreshing...</span>
                </b-container>
                <span v-else class="text text-13">Refresh</span>
                </button>
            </div>
            </b-row>
          <b-row>
            <div class="col-sm col-md">
              <div class="table-responsive">
                <b-container v-if="state.loading">
                    <a-skeleton active :loading="state.loading || state.currentp" :paragraph="{rows: 8}" ></a-skeleton>
                </b-container>
                <b-table
                  v-else
                  table-class="table-approvers text-13 indent-10"
                  striped
                  hover
                  thead-tr-class="tb-header"
                  tbody-class="tb-body indent-10"
                  :items="pillars"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  show-empty
                >
                  <template v-slot:cell(featured)="data">
                    <span v-for="(item, i) in data.item.featured" :key="i">{{item}}</span>
                  </template>
                </b-table>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm-8"></div>
            <div class="col-sm">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="text-13"
              ></b-pagination>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import arrowIcon from "../../components/icons/arrow.vue";
export default {
  component: {
    arrowIcon,
  },
  data() {
    return {
      pageTitle: "pillars",
      perPage: 4,
      currentPage: 1,
      filter: "",
      state: {
          loading:false,
          refresh: false,
          currentp: false
      },
      pillars: null,
    };
  },
  beforeMount() {
    this.$title("Pillars");
    this.getCurrentProgram()
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    rows() {
      return this.pillars ? this.pillars.length : 0;
    },
    program() {
      return this.$store.state.current_program;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  methods: {
   async fetchPillars() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getPillarsInProgram/${this.program.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.pillars = response.data;
            // console.log("Pillars", this.pillars)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
     async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.currentp = true;
      await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if (response.data) {
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
            this.fetchPillars();
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
    refresh(){
      this.state.refresh=true
      this.fetchPillars()
    }
  }
};
</script>

<style scoped lang="scss">
</style>