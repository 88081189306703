<template>
  <div>
    <adminTopBar :title="title" />
    <main role="main" class="col-sm-9 ml-sm-auto main-home col-md-10 pt-0 mt-4">
      <div class="container-fluid main-container">
        <div class="jumbotron pl-0 pr-0 pt-0 mt-0 b-none">
          <b-row>
            <div class="col-sm">
              <div class="card border-none bg-gray rounded-20 table-data">
                <div class="card-header bg-gray border-none rounded-20">
                  <div class="row d-flex justify-content-between">
                    <div class="text-left left-label col-xs">
                      <h1 class="ml-4 text-13 mt-2">
                        All departments /
                        <span class="text-muted">Home</span>
                      </h1>
                    </div>
                    <div class="p-2 col-xs">
                      <button
                        @click="createModal"
                        class="
                          btn btn-outline-primary btn-custom-primary
                          pl-3
                          pr-3
                          mr-4
                          btn-add
                        "
                      >
                        ADD NEW <i class="bx bx-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <b-row>
                    <div class="col-sm col-md"></div>
                    <div class="col-sm-4 col-md-4">
                      <form>
                        <div class="input-group mb-2">
                          <input
                            type="search"
                            v-model="filter"
                            class="form-control input-search rounded-left-10"
                            aria-label="Search"
                            placeholder="Search a user..."
                          />
                          <div class="input-group-append">
                            <span class="input-group-text rounded-right-10">
                              <i class="bx bx-search text-success"></i>
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </b-row>
                  <b-row class="mb-2">
                    <div class="col-sm-9 col-md-9"></div>
                    <div class="col-sm col-md text-right">
                      <button
                        @click="refresh"
                        type="button"
                        class="btn btn-secondary btn-sm rounded-10 border-none"
                      >
                        <b-container v-if="state.loading && state.refresh">
                          <b-spinner small></b-spinner>
                          <span class="text-13 ml-2">Refreshing...</span>
                        </b-container>
                        <span v-else class="text text-13">Refresh</span>
                      </button>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-sm col-md">
                      <div class="table-responsive">
                        <b-container class="my-2" v-if="state.loading">
                          <a-skeleton
                            :loading="state.loading"
                            :paragraph="{ rows: 8 }"
                            active
                          ></a-skeleton>
                        </b-container>
                        <b-table
                          v-else
                          table-class="table-roles text-13"
                          striped
                          hover
                          thead-tr-class="tb-head text-left indent-10"
                          tbody-class="tb-body text-left indent-10"
                          :items="departments"
                          :filter="filter"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :fields="headers"
                          show-empty
                        >
                          <template v-slot:cell(department_name)="data">
                            {{ data.item.name }}
                          </template>
                          <template v-slot:cell(created_time)="data">
                            {{
                              moment(data.item.created_at).format(
                                "DD/MM/YYYY H:mm:ss a"
                              )
                            }}
                          </template>
                          <template v-slot:cell(edit)="data">
                            <button
                              @click="updateModal(data.item)"
                              class="btn btn-sm btn-outline-success"
                            >
                              <i class="bx bx-edit-alt"></i>
                            </button>
                          </template>
                          <template v-slot:cell(delete)="data">
                            <button
                              @click="deleteModal(data.item)"
                              class="btn btn-sm btn-outline-danger"
                            >
                              <i class="bx bx-trash-alt"></i>
                            </button>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-sm-6"></div>
                    <div class="col-sm">
                      <b-pagination
                        v-if="!state.loading || !state.refresh"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                        class="float-right"
                      ></b-pagination>
                    </div>
                  </b-row>
                </div>
              </div>
            </div>
          </b-row>
        </div>
      </div>
    </main>
    <a-modal
      v-model="visibleCreateDepartment"
      :footer="null"
      title="Create new department"
    >
      <form @submit.prevent="addDepartment">
        <div class="form-group">
          <label>Department name</label>
          <input
            placeholder="Enter name"
            type="text"
            class="form-control form-input"
            v-model="department"
          />
        </div>
        <button
          :disabled="state.loading"
          type="submit"
          class="btn btn-success btn-block"
        >
          <b-container v-if="state.loading">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Saving...</span>
          </b-container>
          <span v-if="!state.loading" class="text text-13">Save New</span>
        </button>
      </form>
    </a-modal>
    <!-- Edit department modal -->
    <a-modal
      v-model="visibleEditDepartment"
      :footer="null"
      title="Update department"
    >
      <form @submit.prevent="updateDepartment(data)">
        <div class="form-group">
          <label>Department name</label>
          <input
            placeholder="Enter name"
            v-model="department"
            type="text"
            class="form-control form-input"
          />
        </div>
        <button
          :disabled="state.loading"
          type="submit"
          class="btn btn-success btn-block"
        >
          <b-container v-if="state.loading">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Updating...</span>
          </b-container>
          <span v-if="!state.loading" class="text text-13">Update</span>
        </button>
      </form>
    </a-modal>
    <!-- Delete resourse modal -->
    <a-modal
      v-model="visibleDeleteDepartment"
      :footer="null"
      title="Delete department"
    >
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to delete this information ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <a-popconfirm
            title="Are you sure delete this department?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteDepartment(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="state.loading">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Deleting...</span>
              </b-container>
              <span v-if="!state.loading" class="text text-13">DELETE</span>
            </button>
          </a-popconfirm>
        </div>
        <div class="inline-block">
          <button
            @click="visibleDeleteDepartment = false"
            type="submit"
            class="btn btn-secondary btn-block btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
      </b-row>
    </a-modal>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      active: true,
      visibleCreateDepartment: false,
      visibleEditDepartment: false,
      visibleDeleteDepartment: false,
      title: "Departments",
      department: "",
      data: [],
      filter: "",
      state: {
        loading: false,
        refresh: false,
      },
      headers: ["department_name", "created_time", "edit", "delete"],
      perPage: 8,
      currentPage: 1,
      departments: [],
    };
  },
  beforeMount() {
    this.$title("ADMIN - Divisions");
    this.getDepartments();
  },
  computed: {
    rows() {
      return this.departments.length;
    },
    token() {
      return localStorage.getItem("token");
    },
  },
  methods: {
    async getDepartments() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getDepartment", config)
        .then((response) => {
          if (response.data.data) {
            this.state.loading = false;
            this.departments = response.data.data;
          } else {
            this.state.loading = false;
            this.$notification["error"]({
              message: "ERROR!",
              description: `Something wrong when getting departments`,
              duration: 5,
            });
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    refresh() {
      this.state.refresh = true;
      this.getDepartments();
    },
    async addDepartment() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        name: this.department,
      };
      this.state.loading = true;
      await this.axios
        .post("addDepartment", requestData, config)
        .then((response) => {
          if (response.data.message) {
            this.state.loading = false;
            this.visibleCreateDepartment = false;
            this.$message.error(`${response.data.message}`, 3);
          } else {
            this.getDepartments();
            this.state.loading = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Department added successfully.`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loading = false;
        });
    },
    async updateDepartment(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        name: this.department,
      };
      this.state.loading = true;
      await this.axios
        .put(`updateDepartment/${param[0].id}`, requestData, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.visibleEditDepartment = false;
            this.getDepartments();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Department  has been changed successfully.`,
              duration: 5,
            });
          } else {
            this.state.loading = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loading = false;
        });
    },
    cancel(e) {
      console.log(e);
      this.visibleDeleteDepartment = false;
      this.$message.error("Delete canceled.");
    },
    async deleteDepartment(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.state.loading = true;
      await this.axios
        .delete(`deleteDepartment/${param[0].id}`, config)
        .then((response) => {
          if (response) {
            this.state.loading = false;
            this.visibleDeleteDepartment = false;
            this.getDepartments();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Department  deleted successfully.`,
              duration: 5,
            });
          } else {
            this.state.loading = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loading = false;
        });
    },
    createModal() {
      this.visibleCreateDepartment = true;
    },
    updateModal(data) {
      this.data = [
        {
          id: data.id,
          name: data.name,
        },
      ];
      this.department = data.name;
      this.visibleEditDepartment = true;
    },
    deleteModal(data) {
      this.data = [
        {
          id: data.id,
          name: data.name,
        },
      ];
      this.visibleDeleteDepartment = true;
    },
  },
};
</script>

<style scoped>
*,
html,
body {
  font-family: Ubuntu;
}
</style>