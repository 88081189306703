<template>
  <div>
    <b-row class="">
      <div class="col-sm col-md">
        <div class="card rounded-20 card-2 mt-2">
          <div class="card-body">
            <div class="d-flex justify-content-start">
              <div>
                <h6 class="knowledge-text ml-2 text-black">Profile info</h6>
                <div class="b-bottom ml-2 my-2"></div>
              </div>
            </div>
          </div>
          <b-row>
            <div class="col-sm-3 col-md-3">
              <div class="text-left ml-5">
                <h6 class="text-13 my-3 text-black">Full Name:</h6>
                <h6 class="text-13 my-3 text-black">Email:</h6>
                <h6 class="text-13 my-3 text-black">Role:</h6>
                <h6 class="text-13 my-3 text-black">Phone number:</h6>
                <h6 class="text-13 my-3 text-black">Expiration date:</h6>
                <h6 class="text-13 my-3 text-black">User type:</h6>
              </div>
            </div>
            <div class="col-sm-3 col-md-3">
                <div class="text-left ml-5">
                    <h6 class="text-13 my-3 text-capitalize"> {{ profile.user.fname }} {{profile.user.lname}} </h6>
                    <h6 class="text-13 my-3">{{ profile.user.email }}</h6>
                    <h6 class="text-13 my-3"> {{ profile.role }} </h6>
                    <h6 class="text-13 my-3">+{{ profile.user.phone }}</h6>
                    <h6 class="text-13 my-3">{{ moment(profile.user.DOB).format("DD/MM/YYYY") }}</h6>
                    <h6 class="text-13 my-3">{{ profile.user.type == 'afr' ? 'AFR User' : 'Implementing Partner' }}</h6>
                </div>
            </div>
            <div class="col-sm-6 col-md-6"></div>
          </b-row>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "profile-tab",
  data() {
    return {
      state: {
        loading: false,
        deleting: false,
      },
      perPage: 4,
      currentPage: 1,
      visibleDelete: false,
      data: null,
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    profile(){
        return this.$store.state.user_data;
    }
  },
  mounted() {},
  methods: {},
};
</script>

<style>
</style>