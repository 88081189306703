<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto main-home col-md-10 pt-0">
      <div class="card card-main card-0 mt-3 ml-0">
        <div class="card-body pl-0 ml-0">
          <b-row>
            <div class="col-sm col-md">
              <b-row>
                <div class="col-sm-5 col-md-5">
                  <div class="accordion ml-2" id="accordionExample">
                    <div class="card p-0 b-none">
                      <div class="card-header p-0" id="headingTwo">
                        <b-row>
                          <div class="col-sm-11 col-md-11">
                            <h2 class="mb-0 my-2">
                              <button
                                class="
                                  btn btn-link
                                  program-collapsed
                                  btn-block
                                  text-left text-dark
                                  collapsed
                                  ml-1
                                "
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                <span>PROGRAM: <span class="text-capitalize"> {{ program ? program.name : 'None selected' }} </span> </span>
                                <span class="float-right">
                                  <i class="bx bx-menu"></i><br />
                                </span>
                                <span class="float-right">
                                  <i class="bx bx-caret-down"></i>
                                </span>
                              </button>
                            </h2>
                          </div>
                        </b-row>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body p-2 text-left">
                          <h4 class="text-17">Program Details</h4>
                          <p v-if="program" class="text-13">
                            {{ program ? program.Description : '' }}
                          </p>
                          <p v-else class="text-12 text-red">
                            Select program first to get the details.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-row>
              <b-row class="ml-2">
                <div class="col-sm col-md" v-if="false">
                  <div class="card card-1 mt-3">
                    <div class="card-body pt-2">
                      <div class="mb-2">
                        <b-img class="card-img-1" fluid :src="image1"></b-img>
                      </div>
                      <div class="d-flex justify-content-around">
                        <div class="border-remain"></div>
                        <div class="border-completed"></div>
                      </div>
                      <div class="d-flex justify-content-around">
                        <span class="label-remain"><h6>Remain</h6></span>
                        <span class="label-completed"><h6>Completed</h6></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm col-md" v-if="role != 'project manager' && role != 'implementing partner' ">
                  <div class="card border card-pillars mt-3">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <span>
                          <i class="bx bx-building fa-2x cursor-pointer"></i>
                        </span>
                        <span>
                          <p class="cursor-pointer text-capitalize">{{ `${capitalizeFirstLetter(getKeyword('pillar'))}s` }}</p>
                        </span>
                        <span class="">
                          <i
                            class="
                              bx bx-dots-vertical-rounded bx-sm
                              dotted
                              cursor-pointer
                            "
                          ></i>
                        </span>
                      </div>
                      <div class="number m-3 pt-5 mb-5">
                        <b-container v-if="state.loading">
                          <!-- <a-skeleton :paragraph="3" active :loading="state.loading"></a-skeleton> -->
                        </b-container>
                        <span v-else class="pt-3">
                          <h1> {{ numbers ? numbers.pillars : 0 }} </h1>
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="program"
                      @click="goToPillars"
                      class="card-footer card-btn-footer box"
                    >
                      <span class="mb-2"> View all </span>
                      <span
                        ><i class="fas fa-arrow-right float-right"></i
                      ></span>
                    </div>
                    <div
                      v-else
                      @click="pillarValidate"
                      class="card-footer card-btn-footer box"
                    >
                      <span class="mb-2"> View all </span>
                      <span
                        ><i class="fas fa-arrow-right float-right"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm col-md">
                  <div class="card border card-pillars card-2 mt-3">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <span>
                          <i class="bx bx-trending-up cursor-pointer bx-sm"></i>
                        </span>
                        <span>
                          <p class="cursor-pointer text-capitalize">{{ `${capitalizeFirstLetter(getKeyword('project'))}s` }} </p>
                        </span>
                        <span class="">
                          <i
                            class="
                              bx bx-dots-vertical-rounded bx-sm
                              dotted
                              cursor-pointer
                            "
                          ></i>
                        </span>
                      </div>
                      <div class="number m-3 pt-5 mb-5">
                        <b-container v-if="state.loading">
                          <a-skeleton :paragraph="{rows: 3}" active :loading="state.loading"></a-skeleton>
                        </b-container>
                        <span v-else class="pt-3">
                          <h1> {{ numbers ? numbers.projects : 0 }} </h1>
                        </span>
                      </div>
                    </div>
                    <div @click="goToProjects" class="card-footer card-btn-footer box">
                        <span class="mb-2"> View all </span>
                        <span
                          ><i class="fas fa-arrow-right float-right"></i
                        ></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm col-md" v-if="role != 'project manager' && role != 'implementing partner' ">
                  <div class="card border card-log-frame card-3 mt-3">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <span>
                          <i class="bx bxs-traffic cursor-pointer bx-sm"></i>
                        </span>
                        <span>
                          <p class="cursor-pointer text-capitalize">{{`${capitalizeFirstLetter(getKeyword('logframe'))}`}}</p>
                        </span>
                        <span class="">
                          <i
                            class="
                              bx bx-dots-vertical-rounded bx-sm
                              dotted
                              cursor-pointer
                            "
                          ></i>
                        </span>
                      </div>
                      <div class="number m-2 pt-0 mb-2">
                        <span class="pt-1">
                          <b-container v-if="state.loading">
                            <a-skeleton :paragraph="{rows: 3}" active :loading="state.loading"></a-skeleton>
                          </b-container>
                          <h1 v-else>
                            {{ logframe_achieved }}
                            <br />
                            <span class="text-14" :title="featuredIndicatorTitle">{{featuredIndicatorTitle.slice(0, 40)}}...</span>
                          </h1>
                        </span>
                        <template v-if="show_disaggregations">
                          <span>Male: <b>{{logframe_achieved_male}}</b></span>
                          <span class="ml-2">Female: <b>{{logframe_achieved_female}}</b></span>
                        </template>
                      </div>
                    </div>
                    <div
                      @click="goToLogFrame"
                      class="card-footer card-btn-footer box"
                    >
                      <span class="mb-2"> View all </span>
                      <span><i class="fas fa-arrow-right float-right"></i></span>
                    </div>
                  </div>
                </div>
              </b-row>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import image1 from "../../../assets/images/project-manager/project/project-dash-chart1.png";
import image2 from "../../../assets/images/project-manager/charts/chart2.png";
import image3 from "../../../assets/images/project-manager/charts/chart3.png";
import arrowIcon from "../components/icons/arrow.vue";
import navBarTop from "../shared/Navbar.vue";
export default {
  component: {
    arrowIcon,
    navBarTop,
  },
  data() {
    return {
      image1: image1,
      active: true,
      chart1: image1,
      chart2: image2,
      chart3: image3,
      pageTitle: "dashboard",
      numbers: null,
      state: {
        loading: false,
        programs: false,
      },
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    pillarsLength(){
      return this.pillars.length;
    },
    program(){
      return this.$store.state.current_program;
    },
    logframe_achieved(){
      return this.numbers?.featured?.milestone?.achieved??'-'
    },
    featuredIndicatorTitle(){
      return this.numbers?.featured?.indicator?.Title??''
    },
    show_disaggregations(){
       let disaggregations = this.numbers?.featured?.indicator?.disaggregations
       if(disaggregations && disaggregations.length) return true
       else return false
    },
    logframe_achieved_male(){
      let disaggregations = this.numbers?.featured?.milestone?.disaggregations??[]

      var achieved = 0;

      if(disaggregations){
        // loop and find male
        disaggregations.forEach((disaggregation) => {
          if(disaggregation.Title.toLocaleLowerCase() == 'male'){
           achieved = disaggregation.achieved
          }
        })
      }
      return achieved
    },
    logframe_achieved_female(){
      let disaggregations = this.numbers?.featured?.milestone?.disaggregations??[]

      var achieved = 0;

      if(disaggregations){
        // loop and find female
        disaggregations.forEach((disaggregation) => {
          if(disaggregation.Title.toLocaleLowerCase() == 'female'){
           achieved = disaggregation.achieved
          }
        })
      }
      return achieved
    },
    user(){
      return this.$store.state.user_data.user;
    },
    role(){
      return this.$store.state.user_data.role.toLowerCase();
    },
    keywords(){
      return this.$store.state.current_program ? this.$store.state.current_program.keyWords : null;
    },
  },
  watch: {},
  beforeMount() {
    this.$title("Dashboard");
    this.getCurrentProgram();
  },
  methods: {
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
     await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if(response.data){
            this.state.loading = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
            this.getNumbers(response.data[0].id);
          }
        })
        .catch((error) => {
          this.state.loading = false;
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
        });
    },
    async getNumbers(id) {
      this.state.loading = true;
      await this.axios
        .get(`getDashobordNumbers/${id}`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.numbers = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    pillarValidate(){
      this.$message.warning("Pleae select the program first.",4);
    },
    goToPillars() {
      this.$router.push({name:'pillar-summary'});
    },
    goToProjects(){
      this.$router.push("/user/projects");
    },
    goToLogFrame() {
      // this.$store.dispatch("manager/setLogFrameTab", true);
      this.$router.push({ name: "indicator-summary" });
    },

  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  // background: #f3f3f3 !important;
  background: yellow !important;
}

.card-pillars {
  background: rgb(63, 177, 93)!important;
  &.card-2{
    background: #09c1ff !important;
  }
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}

.label-remain h6 {
  font-size: 12px !important;
  margin-right: 10px !important;
}

.label-completed h6 {
  font-size: 12px !important;
  margin-left: 10px !important;
}

.top-texts {
  font-size: 13px !important;
}

.knowledge-text {
  color: #001 !important;
  font-size: 15px !important;
}

.avatar {
  width: 60px;
  height: 60px;
  background: #eeeeee !important;
}

.report-status h6 {
  color: #e5e5e5 !important;
}

.price-1 button {
  background-color: #8dd070 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.price-2 button {
  background-color: #cecf71 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.price-3 button {
  background-color: #ffcdd3 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.row1 {
  border-left: 6px solid #8dd070 !important;
  border-spacing: 4px !important;
}

.row2 {
  border-left: 6px solid #cecf71 !important;
  border-spacing: 4px !important;
}

.row3 {
  border-left: 6px solid #ffcdd3 !important;
  border-spacing: 4px !important;
}

.card {
  text-align: center !important;
}

@media screen and (max-width: 578px) {
  .big-border {
    width: 54.5px;
    height: 5px;
    background: #cecf71 !important;
  }

  .date-top-text {
    color: #000 !important;
  }

  .user-info,
  .report-status {
    width: 150px !important;
  }
}
</style>