import decode from "jwt-decode";
import store from "../vuex/index";
import appRouter from "../router/index";

export default (to, from, next) => {
  const token = localStorage.getItem("token");
  if (token) {
    isLoggedIn(to, next);
  } else {
    isNotLoggedIn(to, next);
  }
};

function isLoggedIn(to, next) {
  if (to.matched.some((record) => record.meta.guest)) {
    navigateToUserPage(to, next);
  } else if (to.matched.some((record) => record.meta.requireAuth)) {
    settingUserData(to, next);
  } else if (to.matched.some((record) => record.meta.helper)) {
    next();
  } else if (to.matched.some((record) => record.meta.notFound)) {
    next();
  }else if (to.matched.some((record) => record.meta.serverError)) {
    next();
  }
  
}

function isNotLoggedIn(to, next) {
  if (to.matched.some((record) => record.meta.guest)) {
    next();
  } else if (to.matched.some((record) => record.meta.requireAuth)) {
    goToRoute("/", next);
  } else if (to.matched.some((record) => record.meta.helper)) {
    next();
  } else if (to.matched.some((record) => record.meta.notFound)) {
    next();
  }else if (to.matched.some((record) => record.meta.serverError)) {
    next();
  }
}

function navigateToUserPage(to, next) {
  const token = localStorage.getItem("token2");
  const tokenData = decode(token);

  switch (tokenData.user.type) {
    case "ip":
      goToRoute("/user", next);
      break;
    case "afr":
      goToRoute("/user", next);
      break;
    default:
      goToRoute("/", next);
      break;
  }
}

function settingUserData(to, next) {
  const token = localStorage.getItem("token2");
  const tokenData = decode(token);
  switch (tokenData.user.type) {
    case "ip":
      store.dispatch("setUserData", tokenData);
      if (!to.path.includes("user")) {
        next("/user");
      }else {
        next();
      }
      break;
    case "afr":
      store.dispatch("setUserData", tokenData);
      if (!to.path.includes("user")) {
        next("/user");
      }else {
        next();
      }
      break;
  }
}

function goToRoute(route, next) {
  if (appRouter.currentRoute.path != route) {
    next(route);
  }
}
