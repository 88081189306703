import { render, staticRenderFns } from "./risk-management.vue?vue&type=template&id=7a3042c2&scoped=true&"
import script from "./risk-management.vue?vue&type=script&lang=js&"
export * from "./risk-management.vue?vue&type=script&lang=js&"
import style0 from "./risk-management.vue?vue&type=style&index=0&id=7a3042c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a3042c2",
  null
  
)

export default component.exports