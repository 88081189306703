<template>
  <nav
    class="
      navbar navbar-expand-md
      fixed-top
      navbar-light
      bg-light
      justify-content-between
      navbar-colored
    "
  >
    <a @click="sideMenuActions" class="navbar-brand abs btn btn-outline-secondary" type="button">
      <i class="bx bx-menu text-secondary bx-sm"></i> Side Menu
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#collapsingNavbar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="collapsingNavbar">
      <ul class="navbar-nav navbar-title">
        <li class="nav-item">
          <router-link class="text-uppercase text-secondary" to="/user">
            {{ title }}
          </router-link>
        </li>
      </ul>
      <ul class="navbar-nav navbar-top-right ml-auto">
        <li class="nav-item">
          <span
            ><i class="bx bx-bell text-secondary bx-sm cursor-pointer"></i
          ></span>
        </li>
        <li class="nav-item">
          <span>
            <i class="bx bx-user-circle bx-sm"></i>
          </span>
        </li>
        <li class="nav-item">
          <span class="mt-1">
            <h6 class="text-uppercase">{{ user.fname }} {{ user.lname }} - <span class="text-capitalize">{{ role }}</span></h6>
          </span>
        </li>
        <li class="nav-item">
          <div class="dots ml-3">
            <div class="dropdown">
              <span
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="bx bx-dots-vertical-rounded cursor-pointer"></i>
              </span>
              <div
                class="dropdown-menu dropdown-menu-right drop-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <router-link :to="{name: 'pm-profile'}" class="dropdown-item">
                  <i class="bx bx-cog"></i> Account Settings</router-link
                >
                <a class="dropdown-item" href="#">
                  <i class="fas fa-bell"></i> Notifictions
                </a>
                <a href="#" class="dropdown-item"
                  ><i class="bx bxs-inbox"></i> Inbox</a
                >
                <a class="dropdown-item" @click="logout()" @click.prevent>
                    <i class="bx bx-log-out-circle"></i> Logout
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import logo from "../../../assets/images/logo.png";
import avatar from "../../../assets/images/icons/user.png";
export default {
  name: "top-banner",
  props: {
    title: { type: String, default: "" },
  },
  data() {
    return {
      logo: logo,
      avatar: avatar,
      isActive: this.$store.state.manager.isActive,
      state: {
        currentp: false
      }
    };
  },
  watch: {
    isActive(){
      this.$store.dispatch("manager/setSidebarAction", false);
      this.isActive=this.$store.state.manager.isActive;
    }
  },
  computed: {
    user(){
      return this.$store.state.user_data.user;
    },
    role(){
      return this.$store.state.user_data.role;
    },
    token(){
      return localStorage.getItem("token")
    }
  },
  beforeMount(){
    this.getCurrentProgram();
  },
  methods: {
    sideMenuActions() {
      this.isActive=!this.isActive;
    },
    async getCurrentProgram() {
      this.state.currentp = true;
      await this.axios
        .get("getCurrentProgram")
        .then((response) => {
          if (response.data) {
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
    logout(){
      localStorage.removeItem("token")
      this.$router.push("/logout")
    },
  },
  
};
</script>

<style scoped>
/* Top card styles */
@media (min-width: 768px) {
  .navbar-title {
    position: absolute;
    width: auto;
    left: 50%;
    text-align: center;
  }
}

.navbar-title {
  position: absolute;
  width: auto;
  left: 24%;
  transform: translateX(-50%);
  text-align: center;
}
/* End for styles */
</style>