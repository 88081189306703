<template>
  <div>
    <b-row>
      <div class="col-sm col-md col-lg">
        <b-row>
          <div class="col-sm col-md mt-n2 mb-3">
            <a-tooltip title="Refresh program data" placement="bottom">
              <button
                @click="refresh"
                type="button"
                class="btn mr-3 btn-secondary btn-sm rounded-20 float-right border-none"
              >
                <b-icon-arrow-repeat
                  font-scale="1.6"
                  :animation="state.refresh && state.loading ? 'spin' : ''"
                />
              </button>
            </a-tooltip>
          </div>
        </b-row>
        <b-form @submit.prevent="nextStep">
          <b-row>
            <div class="col-sm col-md">
              <div class="form-group">
                <label class="float-left">Indicator title</label>
                <input
                  placeholder="Title"
                  v-bind:class="validations.title ? 'is-invalid' : null"
                  @keyup="onchangeTitle"
                  v-model="title"
                  type="text"
                  class="form-control"
                  required
                />
                <small v-if="validations.title" class="text-danger float-left">
                  Title is required.
                </small>
              </div>
              <div class="form-group">
                <label class="float-left">Indicator number</label>
                <input
                  placeholder="ex 1.1"
                  v-model="indicator_number"
                  max="3"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label class="float-left">TOC Level</label>
                <b-input-group>
                  <b-select
                    class="custom-select bg-white text-13"
                    v-model="level"
                    required
                    :disabled="state.loading"
                    v-bind:class="validations.level ? 'is-invalid' : null"
                    @change="onchangeLevel"
                  >
                    <template #first>
                      <b-select-option :value="null" selected disabled>
                        {{
                          keywords
                            ? keywords["theory of change"]
                              ? `Select ${keywords["theory of change"]}`
                              : "Select theory of change."
                            : "Select theory of change"
                        }}
                      </b-select-option>
                    </template>
                    <b-select-option
                      v-for="item in levels"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.level }}
                    </b-select-option>
                  </b-select>
                  <b-input-group-append>
                    <b-icon-arrow-repeat
                      class="bi-con-right ml-1"
                      font-scale="1.5"
                      :animation="state.loading ? 'spin' : ''"
                      @click="getProgramLevels"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small v-if="validations.level" class="text-danger float-left">
                  {{
                    keywords
                      ? keywords["theory of change"]
                        ? `${keywords["theory of change"]} is required`
                        : "Theory of change is required."
                      : "Select theory of change"
                  }}
                </small>
              </div>
              <div class="form-group">
                <label class="float-left">TOC Box</label>

                <b-input-group>
                  <b-select
                    class="custom-select bg-white text-13"
                    v-model="element"
                    required
                    :disabled="state.loadElem"
                    v-bind:class="validations.element ? 'is-invalid' : null"
                    @change="onchangeElement"
                  >
                    <template #first>
                      <b-select-option :value="null" selected disabled>
                        {{
                          keywords["theory of change"]
                            ? `Select ${keywords["theory of change"]} box`
                            : "Select TOC box"
                        }}
                      </b-select-option>
                    </template>
                    <b-select-option
                      v-for="item in elements"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.element }}
                    </b-select-option>
                  </b-select>
                  <b-input-group-append>
                    <b-icon-arrow-repeat
                      class="bi-con-right ml-1"
                      font-scale="1.5"
                      :animation="state.loadElem ? 'spin' : ''"
                      @click="getProgramElement"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="validations.element"
                  class="text-danger float-left"
                >
                  {{
                    keywords["theory of change"]
                      ? `${keywords["theory of change"]} element is required`
                      : "TOC element is required."
                  }}
                </small>
              </div>

              <div class="form-group">
                <label class="float-left">Logframe level</label>
                <b-input-group>
                  <b-select
                    class="custom-select bg-white text-13"
                    v-model="selectedLogframeLevel"
                    required
                    :disabled="state.getLogLevel"
                  >
                    <template #first>
                      <b-select-option :value="null" selected disabled>
                        Select logframe level
                      </b-select-option>
                    </template>
                    <b-select-option
                      v-for="item in loglevels"
                      :key="`ok_${item.id}`"
                      :value="item"
                    >
                      {{ item.Title }}
                    </b-select-option>
                  </b-select>
                  <b-input-group-append>
                    <b-icon-arrow-repeat
                      class="bi-con-right"
                      font-scale="1.5"
                      :animation="state.getLogLevel ? 'spin' : ''"
                      @click="getLogframeLevel"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>

              <div class="form-group">
                <label class="float-left">Logframe box</label>
                <b-input-group>
                  <b-select
                    class="custom-select bg-white text-13"
                    v-model="logframeElement"
                    required
                    :disabled="state.loadLogElem"
                  >
                    <template #first>
                      <b-select-option :value="null" selected disabled>
                        Select logframe box
                      </b-select-option>
                    </template>
                    <b-select-option
                      v-for="item in logelements"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.element }}
                    </b-select-option>
                  </b-select>
                  <b-input-group-append>
                    <b-icon-arrow-repeat
                      class="bi-con-right"
                      font-scale="1.5"
                      :animation="state.loadLogElem ? 'spin' : ''"
                      @click="getLogframeElement"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>

              <div class="form-group" v-if="ask_pillar">
                <label class="float-left mt-2">Associated pillar</label>
                <b-input-group>
                  <b-select
                    class="custom-select bg-white text-13"
                    v-model="associated_pillar"
                    required
                    :disabled="state.pillars"
                    v-bind:class="validations.pillar ? 'is-invalid' : null"
                    @change="onchangeIndicator"
                  >
                    <template #first>
                      <b-select-option :value="null" selected disabled
                        >Select pillar</b-select-option
                      >
                    </template>
                    <b-select-option
                      v-for="item in pillars"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.Title }}
                    </b-select-option>
                  </b-select>
                  <b-input-group-append>
                    <b-icon-arrow-repeat
                      class="bi-con-right ml-1"
                      font-scale="1.5"
                      :animation="state.pillars ? 'spin' : ''"
                      @click="getPillars"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="validations.indicator"
                  class="text-danger float-left"
                >
                  Disaggregation is required.
                </small>
              </div>

              <div class="form-group text-left">
                <label class="text-left mt-2">Select Disaggregation</label>
                <v-select
                  label="name"
                  v-model="selectedDisaggregations"
                  :options="disaggregations"
                  placeholder="Disaggregation"
                  multiple
                ></v-select>
                <small
                  v-if="validations.indicator"
                  class="text-danger float-left"
                >
                  Disaggregation is required.
                </small>
              </div>
              <b-form-group class="form-text-label" label-for="desc">
                <label class="float-left">Indicator definition</label>
                <b-form-textarea
                  id="desc"
                  placeholder="Enter definition"
                  rows="3"
                  resize
                  class="form-text"
                  v-bind:class="validations.description ? 'is-invalid' : null"
                  @keyup="onchangeDescription"
                  v-model="description"
                ></b-form-textarea>
                <small
                  v-if="validations.description"
                  class="text-danger float-left"
                >
                  Definition is required.
                </small>
              </b-form-group>
            </div>

            <div class="col-sm col-md">
              <div class="form-group">
                <b-row>
                  <div class="col-sm col-md col-lg">
                    <label class="float-left">Baseline</label>
                    <input
                      placeholder="Enter baseline"
                      type="number"
                      class="form-control"
                      v-model="baseline"
                      required
                      v-bind:class="validations.baseline ? 'is-invalid' : null"
                      @keyup="onchangeBaseline"
                    />
                    <small
                      v-if="validations.baseline"
                      class="text-danger float-left"
                    >
                      Baseline is required.
                    </small>
                  </div>
                </b-row>
              </div>

              <div class="form-group">
                <b-container v-if="state.milestones || state.refreshM">
                  <a-skeleton
                    active
                    :paragraph="{ rows: 6 }"
                    :loading="state.milestones || state.refreshM"
                  ></a-skeleton>
                </b-container>
                <b-row v-else>
                  <div class="col-sm col-md col-lg">
                    <div class="float-left">
                      <p class="text-left text-bold">Target</p>
                      <!-- <p>{{inputs}}</p> -->
                    </div>
                  </div>

                  <b-col cols="10" sm="12" md="12">
                    <b-row>
                      <b-col cols="3">Reporting Period</b-col>
                      <b-col cols="3">Due date</b-col>
                      <b-col cols="3">value</b-col>
                      <b-col cols="2">Skip reporting</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="10" sm="12" md="12">
                    <b-row v-for="(input, i) in inputs" :key="i" class="my-3">
                      <b-col>
                        <input
                          @keyup="onKeyInputs"
                          readonly
                          type="text"
                          v-model="input.title"
                          class="form-control"
                          disabled
                        />
                      </b-col>
                      <b-col>
                        <input
                          @keyup="onKeyInputs"
                          readonly
                          type="text"
                          v-model="input.Duedate"
                          class="form-control"
                          disabled
                        />
                      </b-col>
                      <b-col>
                        <input
                          @keyup="onKeyInputs"
                          type="number"
                          v-model="input.target"
                          class="form-control"
                          placeholder="Target"
                          required
                        />
                      </b-col>
                      <b-col>
                        <input
                          @keyup="onKeyInputs"
                          type="checkbox"
                          v-model="input.skip_report"
                          class="form-control"
                          required
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        v-for="(
                          aggregationsList, aggregationId
                        ) in input.aggregation"
                        :key="aggregationId"
                        class="my-1"
                      >
                        <b-row
                          cols="12"
                          v-for="(
                            aggregationElement, aggregationElementKey
                          ) in aggregationsList"
                          :key="aggregationElementKey"
                        >
                          <b-col></b-col>
                          <b-col>
                            <input
                              @keyup="onKeyInputs"
                              readonly
                              type="text"
                              :value="aggregationElement.Title"
                              class="form-control"
                              disabled
                            />
                            <hr />
                          </b-col>
                          <b-col>
                            <input
                              @keyup="
                                onKeyTargetInput(
                                  `${input.id}-${aggregationElement.id}`,
                                  aggregationId,
                                  input
                                )
                              "
                              type="number"
                              v-model="
                                aggregationResponses[
                                  `${input.id}-${aggregationElement.id}`
                                ]
                              "
                              class="form-control"
                              placeholder="Value"
                              required
                            />
                            <hr />
                          </b-col>
                          <b-col></b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>

            <div class="col-12">
              <b-form-group>
                <button
                  v-if="formMode == 'update'"
                  @click="resetIndicatorData"
                  type="button"
                  class="btn btn-outline-primary rounded-20 cursor-pointer px-4 my-3 mr-5 btn-sm"
                >
                  Cancel
                </button>

                <button
                  @click.prevent="
                    formMode == 'update' ? updateIndicator() : saveIndicator()
                  "
                  type="submit"
                  class="btn btn-primary rounded-20 my-3 btn-sm"
                >
                  <b-container v-if="state.adding">
                    <b-spinner small></b-spinner>
                    <span class="text-13 ml-2">Saving...</span>
                  </b-container>
                  <span
                    v-if="!state.adding"
                    class="text text-13 text-capitalize"
                    >{{ capitalizeFirstLetter(formMode) }} program
                    indicator</span
                  >
                </button>
              </b-form-group>
            </div>
          </b-row>
        </b-form>
        <b-row>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="card card-2">
              <div class="card-body">
                <b-row class="mb-2">
                  <div class="col-sm-9 col-md-9"></div>
                  <div class="col-sm col-md text-right">
                    <button
                      @click="refresh"
                      type="button"
                      class="btn btn-secondary btn-sm rounded-10 border-none"
                    >
                      <b-container v-if="state.loadp && state.refresh">
                        <b-spinner small></b-spinner>
                        <span class="text-13 ml-2">Refreshing...</span>
                      </b-container>
                      <span v-else class="text text-13">Refresh</span>
                    </button>
                  </div>
                </b-row>
                <div class="table-responsive mt-2">
                  <b-container class="my-1" v-if="state.loadp">
                    <a-skeleton
                      active
                      :paragraph="{ rows: 4 }"
                      :loading="state.loadp"
                    ></a-skeleton>
                  </b-container>
                  <table
                    v-else-if="lengthIndicators > 0"
                    class="table table-indicators"
                  >
                    <thead>
                      <tr class="theader-indicators">
                        <th>No</th>
                        <th>Title</th>
                        <th><span>Description</span></th>
                        <th>Baseline</th>
                        <th colspan="2">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(el, i) in programIndicators" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td>
                          <span class="text-capitalize">{{ el.title }}</span>
                        </td>
                        <td>{{ el.description }}</td>
                        <td>{{ el.baseline }}</td>
                        <td>
                          <span
                            @click="triggerIndicatorEdit(el.indicatorId)"
                            class="cursor-pointer"
                          >
                            <button
                              type="button"
                              class="btn btn-light btn-sm rounded"
                            >
                              <i class="bx bxs-edit text-info"></i>
                            </button>
                          </span>
                          <span
                            @click="deleteModal(el)"
                            class="cursor-pointer mr-1"
                            ><i class="fa fa-trash text-danger"></i
                          ></span>
                          <span
                            @mouseover="star_hover[el.indicatorId] = true"
                            @mouseleave="star_hover[el.indicatorId] = false"
                            @click="featureIndicator(el)"
                            class="cursor-pointer"
                            ><i
                              :class="{
                                'far fa-star': !star_hover[el.indicatorId],
                                'fa fa-star':
                                  el.featured || star_hover[el.indicatorId],
                              }"
                              class="text-success"
                            ></i
                          ></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-container class="my-5" v-else>
                    <span class="text-13">Program indicators not found.</span>
                  </b-container>
                </div>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </b-row>
    <a-modal
      v-model="visibleDelete"
      :footer="null"
      title="Delete Program Indicator"
    >
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to delete this information ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <a-popconfirm
            title="Are you sure delete this program indicator ?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteIndicator(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="state.deleting">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Deleting...</span>
              </b-container>
              <span v-if="!state.deleting" class="text text-13">DELETE</span>
            </button>
          </a-popconfirm>
        </div>
        <div class="inline-block">
          <button
            @click="handleCancel"
            type="submit"
            class="btn btn-secondary btn-block btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
      </b-row>
    </a-modal>
  </div>
</template>

<script>
import decode from "jwt-decode";
import moment from "moment";
import vSelect from "vue-select";
export default {
  props: ["store"],
  components: {
    vSelect,
  },
  data() {
    return {
      formMode: "add",
      title: null,
      indicator_number: null,
      levels: [],
      level: null,
      indicators: [],
      disaggregations: [],
      allInclude: [],
      editIndicatorData: {},
      state: {
        loading: false,
        loadElem: false,
        loadIndicator: false,
        adding: false,
        loadp: false,
        refresh: false,
        milestones: false,
        pillars: false,
        deleting: false,
        loadUsers: false,
        loadSubs: false,
        refreshM: false,
        getLogLevel: false,
        loadLogElem: false,
      },
      element: null,
      elements: [],
      milestones: [],
      description: null,
      baseline: null,
      indicator: null,
      selectedDisaggregations: [],
      validations: {
        title: false,
        level: false,
        element: false,
        indicator: false,
        disaggregations: false,
        dueDate: false,
        year: false,
        target: false,
        description: false,
        baseline: false,
        user: false,
      },
      programIndicators: [],
      inputs: [],
      inputsAgg: [],
      inputValues: [],
      inputAgg: [],
      visibleDelete: false,
      data: null,
      user: null,
      users: [],
      aggregationResponses: {},
      temp: [],
      aggregatorSubs: [],
      subCout: [],
      aggragTemp: [],
      allJoined: [],
      loglevels: [],
      selectedLogframeLevel: null,
      associated_pillar: null,
      pillars: [],
      logelements: [],
      logframeElement: null,
      star_hover: [],
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    currentSaved() {
      const data = sessionStorage.getItem("requested");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.program ? this.program : decoded;
    },
    programDiff() {
      const from = this.drafted
        ? this.drafted.from
        : this.currentSaved
        ? this.currentSaved.data.from
        : new Date().getFullYear();
      const to = this.drafted
        ? this.drafted.to
        : this.currentSaved
        ? this.currentSaved.data.to
        : new Date().getFullYear();
      return to - from;
    },
    program() {
      return this.$store.state.program_data
        ? this.$store.state.program_data
        : null;
    },
    pillar() {
      return this.$store.state.pillar_data
        ? this.$store.state.pillar_data
        : null;
    },
    savedStep2() {
      const data = sessionStorage.getItem("s2");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.pillar ? this.pillar : decoded;
    },
    drafted() {
      const data = sessionStorage.getItem("draft");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return decoded;
    },
    programStart() {
      const from = this.drafted
        ? this.drafted.from
        : this.currentSaved
        ? this.currentSaved.data.from
        : new Date().getFullYear();
      return from;
    },
    programEnd() {
      const to = this.drafted
        ? this.drafted.to
        : this.currentSaved
        ? this.currentSaved.data.to
        : new Date().getFullYear();
      return to;
    },
    loglevelId() {
      let ret = null;
      if (this.selectedLogframeLevel) {
        ret = this.selectedLogframeLevel.id;
      }
      return ret;
    },
    ask_pillar() {
      // determines if we request setup of pillar
      let level = this.selectedLogframeLevel?.Title?.toLowerCase() ?? null;
      if (level == "outcome" || level == "outputs") {
        if (this.pillars.length <= 0) {
          this.getPillars();
        }
        return true;
      } else {
        return false;
      }
    },
    programYears() {
      let years = [];
      for (
        let currentYear = this.programStart;
        currentYear <= this.programEnd;
        currentYear++
      ) {
        if (new Date(currentYear, 0, 1).getDay() === 0) {
          // console.log(currentYear);
        }
        years.push(parseInt(currentYear));
      }
      return years;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    lengthIndicators() {
      return this.programIndicators ? this.programIndicators.length : 0;
    },
    bothJoined() {
      return this.program;
    },
    programID() {
      return this.currentSaved
        ? this.currentSaved.programData
        : this.drafted
        ? this.drafted
        : 0;
    },
  },
  beforeMount() {
    this.$emit("loading", false);
    this.getProgramLevels();
    this.getIndicatorTypes();
    this.getMilestones();
    this.fetchProgramIndicators();
    this.getUsers();
    this.getLogframeLevel();
  },
  watch: {
    level() {
      this.getProgramElement();
    },
    inputs() {},
    indicator() {
      this.fetchAggregationsSubs();
      this.refreshMiles();
    },
    selectedDisaggregations(next, prev) {
      console.log("disagg", next, prev);
      this.addDisaggregationElements();
    },
    subCout() {
      this.inputValues = [];
      this.aggragTemp = [];
      this.subCout.forEach((object) => {
        this.inputValues.push({
          MilestoneId: object.item.id,
          target: object.item.target,
          aggregations: object.aggregations,
        });
      });
      // console.log("Inputted values", this.inputValues)
    },
    loglevelId() {
      this.getLogframeElement();
    },
  },
  methods: {
    onValueKey() {
      this.inputValues = [];
      this.aggragTemp = [];
      this.subCout.forEach((object) => {
        this.inputValues.push({
          MilestoneId: object.item.id,
          target: object.item.target,
          aggregations: object.aggregations,
        });
      });
      // console.log("Inputted values", this.inputValues)
    },
    // Adds disaggregation elements based on dissaggregations selected
    async addDisaggregationElements() {
      console.log("inputs", this.inputs);
      // reset aggregations
      this.inputs = this.inputs.map((input) => {
        return {
          ...input,
          aggregation: {},
        };
      });

      this.selectedDisaggregations.forEach((disaggregation) => {
        let disaggregationElements = disaggregation.elements;

        let aggregatorSubsTemp = [];
        disaggregationElements.forEach((item, key) => {
          let temp = {
            id: item.id,
            Title: item.Title,
            targetValue: null,
          };
          this.inputAgg.push(temp);
          aggregatorSubsTemp[key] = {
            ...item,
            // targetValue: null
          };
        });

        this.aggregatorSubs = aggregatorSubsTemp;

        this.inputs.forEach((_, index) => {
          let aggregationId = disaggregation.id;
          if (!this.inputs[index].aggregation) {
            this.inputs[index].aggregation[aggregationId] = {};
          }
          this.inputs[index].aggregation[aggregationId] = [
            ...this.aggregatorSubs,
          ];
        });
      });
      this.state.loadSubs = true;
    },

    // get aggregaions elements
    async fetchAggregationsSubs() {
      this.state.loadSubs = true;
      await this.axios
        .get(`getSubAggregation/${this.indicator}`)
        .then((response) => {
          if (response.data) {
            this.state.loadSubs = false;
            let subAggregates = response.data;
            this.state.refreshM = false;
            this.inputAgg = [];
            this.allInclude = [];
            this.aggregatorSubs = [];
            let aggregatorSubsTemp = [];
            subAggregates.forEach((item, key) => {
              let temp = {
                id: item.id,
                Title: item.Title,
                targetValue: null,
              };
              this.inputAgg.push(temp);
              aggregatorSubsTemp[key] = {
                ...item,
                // targetValue: null
              };
            });
            this.aggregatorSubs = aggregatorSubsTemp;

            this.inputs.forEach((_, index) => {
              let aggregationId = this.indicator;
              if (!this.inputs[index].aggregation) {
                this.inputs[index].aggregation[aggregationId] = {};
              }
              this.inputs[index].aggregation[aggregationId] = [
                ...this.aggregatorSubs,
              ];
            });
          } else {
            this.state.loadSubs = false;
          }
        })
        .catch(() => {
          this.state.loadSubs = false;
        });
    },

    // end for grtting aggregation elements
    // get ogframe level
    async getLogframeLevel() {
      this.state.getLogLevel = true;
      await this.axios
        .get(`getLogframeLevel/${this.programID.id}`)
        .then((response) => {
          if (response.data) {
            this.state.getLogLevel = false;
            this.loglevels = response.data;
            // console.log("Log Levels", this.loglevels);
          } else {
            this.state.getLogLevel = false;
          }
        })
        .catch(() => {
          this.state.getLogLevel = false;
        });
    },

    // end gettig logframe level
    async getUsers() {
      this.state.loadUsers = true;
      await this.axios
        .get("getUsers")
        .then((response) => {
          if (response.data) {
            this.state.loadUsers = false;
            this.users = response.data;
          } else {
            this.state.loadUsers = false;
          }
        })
        .catch(() => {
          this.state.loadUsers = false;
        });
    },
    onchangeUser() {
      if (this.user) {
        this.validations.user = false;
      } else {
        this.validations.user = true;
      }
    },
    onKeyInputs() {
      // console.log("Aggregation response",this.aggregationResponses)
      this.inputsAgg = [];
      // var arrayed = null;
      // arrayed = this.aggregationResponses
      // // console.log("Spliited", arrayed)
      this.inputs.forEach((item) => {
        this.inputsAgg.push({
          MilestoneId: item.id,
          target: item.target,
          skip_reporting: item.skip_report,
        });
      });
      // console.log("Controlsss", this.subCout)
      // console.log("inputs data", this.inputsAgg)
    },
    onKeyTargetInput(milestoneAggElemId, aggregationId, aggInput) {
      // Validate total of aggregation categories not to exceed target
      let target = aggInput.target;

      let aggregationItems = aggInput.aggregation[aggregationId] ?? [];

      console.log("items", aggregationItems);
      // get total of available aggregation elemnts in this agg
      var total = aggregationItems.reduce((prevValue, aggItem) => {
        let currentValue =
          this.aggregationResponses[`${aggInput.id}-${aggItem.id}`];
        console.log("inside", currentValue, aggInput.id, aggItem.id);
        if (currentValue) {
          return prevValue + parseInt(currentValue);
        } else {
          return prevValue;
        }
      }, 0);

      if (total > target) {
        this.$notification["error"]({
          message: "ERROR!",
          description: `The total of disaggregations exceeds target`,
          duration: 10,
        });
      }
      return false;
    },

    triggerIndicatorEdit(indincatorId) {
      this.resetIndicatorData();

      this.formMode = "update";
      this.editIndicatorData.id = indincatorId;

      if (!indincatorId) {
        this.$notification["error"]({
          message: "WARNING!",
          description: `Wait 3 seconds`,
          duration: 3,
        });
        return false;
      }
      this.state.loading = true;
      this.axios
        .get(`program/indicator_details/${indincatorId}`)
        .then((response) => {
          this.state.loading = false;
          let editIndicatorData = response.data;
          this.title = editIndicatorData.name;
          this.indicator_number = editIndicatorData.sorting_order;
          this.baseline = editIndicatorData.baseline;
          this.description = editIndicatorData.Definition;

          this.level = editIndicatorData.toc_level;
          this.element = editIndicatorData.TOCelement;
          this.associated_pillar = editIndicatorData.pillar;

          //We need to find actual level using log level id
          let logLevel =
            this.loglevels.find(
              (level) => level.id == editIndicatorData.LogframeLevel
            ) ?? {};

          this.selectedLogframeLevel = Object.assign(logLevel, {});
          this.logframeElement = editIndicatorData.LogframeElement;
          this.alignedIndicator = editIndicatorData.AlignedIndicator;
          this.reporters = editIndicatorData.reporters;
          this.selectedDisaggregations = editIndicatorData.disaggregation;

          //populate the data
          let existingMilestoneData = editIndicatorData.milestones ?? [];
          console.log("mile data", existingMilestoneData);

          this.inputs.forEach((input, key) => {
            let milestoneId = input.id;
            let existingValue = existingMilestoneData.find(
              (milestone) => milestone.MilestoneId == milestoneId
            );
            this.inputs[key].target = existingValue.target;
          });

          // Populate the disaggregations
          let temp = {};
          existingMilestoneData.forEach((milestone) => {
            let disaggregations = milestone.disaggregations ?? [];
            disaggregations.forEach((disaggregation) => {
              temp[`${milestone.MilestoneId}-${disaggregation.AggregatorId}`] =
                disaggregation.target;
              this.aggregationResponses[
                `${milestone.MilestoneId}-${disaggregation.AggregatorId}`
              ] = disaggregation.target;
            });
          });
          console.log("response", temp);
          console.log(response.data);
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.state.loading = true;
        });
      // this.editProject=data;
      // this.showEdit=true;
    },
    featureIndicator(el) {
      console.log(el);
      this.state.loadp = true;
      this.axios
        .post("indicator/feature", { indicator: el.indicatorId })
        .then((response) => {
          if (!response.data.message) {
            this.state.loadp = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Indicator featured successfully`,
              duration: 5,
            });
            this.fetchProgramIndicators();
          } else {
            this.state.loadp = false;
            this.$message.error(`${response.data.message}`, 4);
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.loadp = false;
        });
    },
    dumpInputValues() {
      // co
    },
    dumpData() {
      console.log(this.aggregationResponses);
    },
    // milestones
    getMilestones() {
      this.state.milestones = true;
      this.axios
        .get(
          `getProgramMilestones/${
            this.currentSaved
              ? this.currentSaved.programData.id
              : this.drafted
              ? this.drafted.id
              : 0
          }`
        )
        .then((response) => {
          this.state.milestones = false;
          this.milestones = response.data;
          this.inputs = [];
          this.milestones.forEach((item) => {
            this.inputs.push({
              id: item.id,
              title: item.Title,
              Duedate: moment(item.Duedate).format("DD/MM/YYYY"),
              target: null,
              skip_report: null,
              aggregation: {},
            });
          });
          // console.log("Inputted values", this.inputs)
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.state.milestones = false;
        });
    },

    getPillars() {
      this.state.pillars = true;
      this.axios
        .get(
          `getprogramPillar/${
            this.currentSaved
              ? this.currentSaved.programData.id
              : this.drafted
              ? this.drafted.id
              : 0
          }`
        )
        .then((response) => {
          this.state.pillars = false;
          this.pillars = response.data;
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.state.pillars = false;
        });
    },

    async fetchProgramIndicators() {
      this.state.loadp = true;
      await this.axios
        .get(
          `getProgramIndicators/${
            this.currentSaved
              ? this.currentSaved.programData.id
              : this.drafted
              ? this.drafted.id
              : 0
          }`
        )
        .then((response) => {
          if (response.data) {
            this.state.loadp = false;
            this.programIndicators = response.data;
            // console.log("All indicators", response.data)
          } else {
            this.state.loadp = false;
          }
        })
        .catch(() => {
          this.state.loadp = false;
        });
    },

    saveIndicator() {
      let milestoneData = this.inputsAgg;
      let objKeys = Object.keys(this.aggregationResponses);
      let aggregationFiltered = [];
      // Format aggregation data
      objKeys.forEach((key) => {
        // console.log(key)
        let split = key.split("-");
        let milestoneId = split[0];
        if (!aggregationFiltered[milestoneId])
          aggregationFiltered[milestoneId] = [];
        aggregationFiltered[milestoneId][split[1]] =
          this.aggregationResponses[key];
      });

      // Add aggregation data into milestone
      milestoneData.forEach((milestone, key) => {
        if (!milestoneData[key].aggregation)
          milestoneData[key].aggregation = [];
        milestoneData[key].aggregation =
          aggregationFiltered[milestone.MilestoneId] ??
          [].map((elem, key) => {
            return {
              AggregatorTarget: elem,
              AggregatorId: key,
            };
          });
      });
      // console.log("filtered", aggregationFiltered, milestoneData)
      const requestData = {
        Title: this.title,
        indicator_number: this.indicator_number,
        TOClevel: this.level,
        indicatorType: this.indicator,
        Description: this.description,
        baseline: this.baseline,
        TOCelement: this.element,
        Milestones: milestoneData,
        programId: this.currentSaved
          ? this.currentSaved.programData.id
          : this.drafted.id,
        LogframeLevel: this.loglevelId,
        LogframeElement: this.logframeElement,
        pillar: this.associated_pillar,
      };

      // console.log(this.aggregationResponses, requestData);
      if (!this.title) {
        this.$emit("error", "Logframe indicator title is required.");
        this.validations.title = true;
        this.$message.error("Logframe indicator title is required.");
      } else if (!this.level) {
        this.$emit("error", "Level of chain is required.");
        this.validations.level = true;
        this.$message.error("Level of chain is required.");
      } else if (!this.element) {
        this.$emit("error", "Element of level of chain is required.");
        this.validations.element = true;
        this.$message.error("Element of level of chain is required.");
      } else if (!this.baseline) {
        this.$emit("error", "Program baseline is required.");
        this.validations.baseline = true;
        this.$message.error("Program baseline is required.");
      } else if (!this.description) {
        this.$emit("error", "Logframe description is required.");
        this.validations.description = true;
        this.$message.error("Logframe description is required.");
      } else {
        if (
          this.inputValues.filter(
            (item) => item.target === null || item.target === ""
          ).length > 0
        ) {
          this.$message.error(
            "Some targets are missing please fill all form fields corretly.",
            5
          );
        } else if (
          this.inputsAgg.filter(
            (item) => item.value === null || item.value === ""
          ).length > 0
        ) {
          this.$message.error(
            "Some disaggregation values are missing please fill all form fields corretly.",
            5
          );
        } else {
          this.state.adding = true;
          this.axios
            .post("program/add_indicator", requestData)
            .then((response) => {
              if (!response.data.message) {
                this.state.adding = false;
                this.$notification["success"]({
                  message: "SUCCESS!",
                  description: `Logframe indicator added successful.`,
                  duration: 5,
                });
                this.getMilestones();
                this.fetchProgramIndicators();
                this.inputValues = [];
                this.inputs = [];
                this.resetIndicatorData();
              } else {
                this.state.adding = false;
                this.$message.error(`${response.data.message}`, 4);
              }
            })
            .catch((error) => {
              this.$notification["error"]({
                message: "ERROR!",
                description: `${error.errorMessage}`,
                duration: 5,
              });
              this.state.adding = false;
            });
        }
      }
    },

    updateIndicator() {
      let milestoneData = this.inputsAgg;
      let objKeys = Object.keys(this.aggregationResponses);
      let aggregationFiltered = [];
      // Format aggregation data
      objKeys.forEach((key) => {
        // console.log(key)
        let split = key.split("-");
        let milestoneId = split[0];
        if (!aggregationFiltered[milestoneId])
          aggregationFiltered[milestoneId] = [];
        aggregationFiltered[milestoneId][split[1]] =
          this.aggregationResponses[key];
      });

      // Add aggregation data into milestone
      milestoneData.forEach((milestone, key) => {
        if (!milestoneData[key].aggregation)
          milestoneData[key].aggregation = [];
        milestoneData[key].aggregation =
          aggregationFiltered[milestone.MilestoneId] ??
          [].map((elem, key) => {
            return {
              AggregatorTarget: elem,
              AggregatorId: key,
            };
          });
      });
      // console.log("filtered", aggregationFiltered, milestoneData)
      const requestData = {
        Title: this.title,
        indicator_number: this.indicator_number,
        TOClevel: this.level,
        indicatorType: this.indicator,
        Description: this.description,
        baseline: this.baseline,
        TOCelement: this.element,
        Milestones: milestoneData,
        programId: this.currentSaved
          ? this.currentSaved.programData.id
          : this.drafted.id,
        LogframeLevel: this.loglevelId,
        LogframeElement: this.logframeElement,
        pillar: this.associated_pillar,
      };

      // console.log(this.aggregationResponses, requestData);
      if (!this.title) {
        this.$emit("error", "Logframe indicator title is required.");
        this.validations.title = true;
        this.$message.error("Logframe indicator title is required.");
      } else if (!this.level) {
        this.$emit("error", "Level of chain is required.");
        this.validations.level = true;
        this.$message.error("Level of chain is required.");
      } else if (!this.element) {
        this.$emit("error", "Element of level of chain is required.");
        this.validations.element = true;
        this.$message.error("Element of level of chain is required.");
      } else if (!this.baseline) {
        this.$emit("error", "Program baseline is required.");
        this.validations.baseline = true;
        this.$message.error("Program baseline is required.");
      } else if (!this.description) {
        this.$emit("error", "Logframe description is required.");
        this.validations.description = true;
        this.$message.error("Logframe description is required.");
      } else {
        if (
          this.inputValues.filter(
            (item) => item.target === null || item.target === ""
          ).length > 0
        ) {
          this.$message.error(
            "Some targets are missing please fill all form fields corretly.",
            5
          );
        } else if (
          this.inputsAgg.filter(
            (item) => item.value === null || item.value === ""
          ).length > 0
        ) {
          this.$message.error(
            "Some disaggregation values are missing please fill all form fields corretly.",
            5
          );
        } else {
          this.state.adding = true;
          this.axios
            .put(
              `program/update_indicator/${this.editIndicatorData.id}`,
              requestData
            )
            .then((response) => {
              if (!response.data.message) {
                this.state.adding = false;
                this.$notification["success"]({
                  message: "SUCCESS!",
                  description: `Logframe indicator updated successfully`,
                  duration: 5,
                });
                // setInterval(this.$router.go(), 5)
                this.getMilestones();
                this.fetchProgramIndicators();
                this.resetIndicatorData();
              } else {
                this.state.adding = false;
                this.$message.error(`${response.data.message}`, 4);
              }
            })
            .catch((error) => {
              this.$notification["error"]({
                message: "ERROR!",
                description: `${error.errorMessage}`,
                duration: 5,
              });
              this.state.adding = false;
            });
        }
      }
    },

    nextStep() {
      if (!this.programIndicators.length > 0) {
        this.$emit("error", "Error");
        this.$message.error("You must add an indicator.", 5);
      } else {
        this.$emit("can-continue");
      }
    },
    async getProgramLevels() {
      this.state.loading = true;
      await this.axios
        .get(
          `getTocLevels/${
            this.currentSaved
              ? this.currentSaved.programData.id
              : this.drafted.id
          }`
        )
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.levels = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },

    resetIndicatorData() {
      this.inputValues = [];
      // reset indicator targets and disaggregations
      this.inputs = this.inputs.map((input) => {
        return {
          ...input,
          target: null,
          aggregation: {},
        };
      });

      this.title = null;
      this.description = null;
      this.level = null;
      this.element = null;
      this.Description = null;
      this.baseline = null;
      this.selectedLogframeLevel = null;
      this.selectedDisaggregations = [];
      this.logframeElement = null;
      this.associated_pillar = null;
      this.aggregationResponses = {};
      this.editIndicatorData = {};
      this.formMode = "add";
    },

    // get program element logframe
    async getLogframeElement() {
      this.state.loadLogElem = true;
      await this.axios
        .get(`getLogframeElement/${this.loglevelId}`)
        .then((response) => {
          if (response.data) {
            this.state.loadLogElem = false;
            this.logelements = response.data;
            console.log("Elemets logframe", this.logelements);
          } else {
            this.state.loadLogElem = false;
          }
        })
        .catch(() => {
          this.state.loadLogElem = false;
        });
    },

    // endd
    async getProgramElement() {
      this.state.loadElem = true;
      await this.axios
        .get(`getTOCElementsAll/${this.level}`)
        .then((response) => {
          if (response.data) {
            this.state.loadElem = false;
            this.elements = response.data;
          } else {
            this.state.loadElem = false;
          }
        })
        .catch(() => {
          this.state.loadElem = false;
        });
    },

    async getIndicatorTypes() {
      this.state.loadIndicator = true;
      await this.axios
        .get(
          `getAggragator/${
            this.currentSaved
              ? this.currentSaved.programData.id
              : this.drafted
              ? this.drafted.id
              : 0
          }`
        )
        .then((response) => {
          if (response.data) {
            this.state.loadIndicator = false;
            this.indicators = response.data;
            this.disaggregations = response.data;
          } else {
            this.state.loadIndicator = false;
          }
        })
        .catch(() => {
          this.state.loadIndicator = false;
        });
    },

    onchangeTitle() {
      if (this.title) {
        this.validations.title = false;
      } else {
        this.validations.title = true;
      }
    },
    onchangeBaseline() {
      if (this.baseline) {
        this.validations.baseline = false;
      } else {
        this.validations.baseline = true;
      }
    },
    onChangeYear(value) {
      this.inputs.map((item) => {
        if (item.year) {
          this.validations.year = false;
        } else {
          this.validations.year = true;
        }
      });
      value;
    },
    onChangeTarget(value) {
      this.inputs.map((item) => {
        if (item.target) {
          this.validations.target = false;
        } else {
          this.validations.target = true;
        }
      });
      value;
    },

    onChangeDueDate(value) {
      this.inputs.map((item) => {
        if (item.dueDate) {
          this.validations.dueDate = false;
        } else {
          this.validations.dueDate = true;
        }
      });
      console.log(value);
    },
    onchangeInputs() {
      this.inputs.forEach((element) => {
        if (element.year) {
          this.validations.year = false;
        } else {
          this.validations.year = true;
        }

        if (element.target) {
          this.validations.target = false;
        } else {
          this.validations.target = true;
        }

        if (element.dueDate) {
          this.validations.dueDate = false;
        } else {
          this.validations.dueDate = true;
        }
      });
    },
    onchangeLevel() {
      if (this.level != null || this.level != "") {
        this.validations.level = false;
      } else {
        this.validations.level = true;
      }
    },
    onchangeElement() {
      if (this.element != null || this.element != "") {
        this.validations.element = false;
      } else {
        this.validations.element = true;
      }
    },
    onchangeIndicator() {
      if (this.indicator != null || this.indicator != "") {
        this.validations.indicator = false;
      } else {
        this.validations.indicator = true;
      }
    },
    onchangeDescription() {
      if (this.description != null || this.description != "") {
        this.validations.description = false;
      } else {
        this.validations.description = true;
      }
    },
    refreshMiles() {
      this.state.refreshM = true;
      this.getMilestones();
      this.fetchAggregationsSubs();
    },
    refresh() {
      this.state.refresh = true;
      this.fetchProgramIndicators();
      this.getMilestones();
      this.getProgramLevels();
      this.getIndicatorTypes();
    },
    cancel(e) {
      console.log(e);
      this.visibleDelete = false;
      this.$message.error("Deleting has been canceled.");
    },
    deleteModal(data) {
      this.data = data;
      this.visibleDelete = true;
    },
    async deleteIndicator(param) {
      this.state.deleting = true;
      await this.axios
        .delete(`deleteIndicator/${param.id}`)
        .then((response) => {
          if (response) {
            this.state.deleting = false;
            this.visibleDelete = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Program indicator has been deleted successfully.`,
              duration: 4,
            });
            this.fetchProgramIndicators();
          } else {
            this.state.deleting = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loading = false;
        });
    },

    handleCancel() {
      this.visibleDelete = false;
    },
  },
};
</script>

<style scoped>
.main-wrapper .form-group label,
.main-wrapper .form-group input::placeholder,
.form-text,
.form-text::placeholder,
.form-text-label,
.form-control,
.custom-select {
  font-size: 13px !important;
}

.table-indicators th {
  font-size: 13px !important;
  border-top: none !important;
}

.table-indicators td {
  font-size: 13px !important;
}

.card-2 {
  border: none !important;
  border-radius: 20px !important;
}
</style>
