<template>
  <div class="mb-5">
    <b-row class="mt-2 mb-4">
      <div class="col-sm col-md">
        <div class="card high-ind-card rounded-8">
          <div class="card-body">
            <h4 class="text-bold text-15 text-left">
              High Performing Indicators
            </h4>
            <b-row class="p1-3">
              <div class="col-sm-12 col-md-12 text-left">
                <h6 class="ind-title my-2 text-13">Indicator Name</h6>
                <span class="text-success text-12">55%</span>
                <b-progress
                  height="12px"
                  class="pt-0 progress-high-ind mt-1"
                  :max="100"
                >
                  <b-progress-bar
                    class="af-progress-ind"
                    :value="55"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </b-row>
            <b-row class="p1-3">
              <div class="col-sm-12 col-md-12 text-left">
                <h6 class="ind-title my-2 text-13">Indicator Name</h6>
                <span class="text-success text-12">55%</span>
                <b-progress
                  height="12px"
                  class="pt-0 progress-high-ind mt-1"
                  :max="100"
                >
                  <b-progress-bar
                    class="af-progress-ind"
                    :value="55"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </b-row>
            <b-row class="p1-3">
              <div class="col-sm-12 col-md-12 text-left">
                <h6 class="ind-title my-2 text-13">Indicator Name</h6>
                <span class="text-success text-12">55%</span>
                <b-progress
                  height="12px"
                  class="pt-0 progress-high-ind mt-1"
                  :max="100"
                >
                  <b-progress-bar
                    class="af-progress-ind"
                    :value="55"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </b-row>
          </div>
        </div>
      </div>
      <div class="col-sm col-md">
        <div class="card low-ind-card rounded-8">
          <div class="card-body">
            <h4 class="text-bold text-15 text-left">
              Low Performing Indicators
            </h4>
            <b-row class="p1-3">
              <div class="col-sm-12 col-md-12 text-left">
                <h6 class="ind-title my-2 text-13">Indicator Name</h6>
                <span class="text-red text-12">12%</span>
                <b-progress
                  height="12px"
                  class="pt-0 progress-low-ind mt-1"
                  :max="100"
                >
                  <b-progress-bar class="bg-red" :value="5"></b-progress-bar>
                </b-progress>
              </div>
            </b-row>
            <b-row class="p1-3">
              <div class="col-sm-12 col-md-12 text-left">
                <h6 class="ind-title my-2 text-13">Indicator Name</h6>
                <span class="text-red text-12">12%</span>
                <b-progress
                  height="12px"
                  class="pt-0 progress-low-ind mt-1"
                  :max="100"
                >
                  <b-progress-bar class="bg-red" :value="5"></b-progress-bar>
                </b-progress>
              </div>
            </b-row>
            <b-row class="p1-3">
              <div class="col-sm-12 col-md-12 text-left">
                <h6 class="ind-title my-2 text-13">Indicator Name</h6>
                <span class="text-red text-12">12%</span>
                <b-progress
                  height="12px"
                  class="pt-0 progress-low-ind mt-1"
                  :max="100"
                >
                  <b-progress-bar class="bg-red" :value="5"></b-progress-bar>
                </b-progress>
              </div>
            </b-row>
          </div>
        </div>
      </div>
    </b-row>
    <b-row class="row-chain">
      <div class="col-sm col-md col-lg">
        <div
          class="nav flex-column nav-result-chain"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            v-for="(level, i) in levels"
            :key="i"
            class="tab-left nav-link text-capitalize"
            :id="removeSpace(level.level)"
            @click="onChangeLevel(level)"
            :class="
              levelItem
                ? level.id == levelItem.id
                  ? 'active'
                  : null
                : levels[0].id == level.id
                ? 'active'
                : null
            "
            :style="[
              levelItem
                ? level.id == levelItem.id
                  ? {
                      color: `${level.color} !important`,
                      borderLeft: `4px solid ${level.color} !important`,
                    }
                  : null
                : levels[0].id == level.id
                ? {
                    color: `${level.color} !important`,
                    borderLeft: `4px solid ${level.color} !important`,
                  }
                : null,
            ]"
            data-toggle="pill"
            href="#v-pills-social-impact"
            role="tab"
            :aria-controls="`v-pills-${removeSpace(level.level)}`"
            aria-selected="true"
            >{{ level.level }}</a
          >
          <a
            class="
              btn btn-new-key btn-primary
              pl-5
              mt-5
              mb-3
              pr-5
              d-none
              text-white
            "
            >New Level
          </a>

          <router-link
            :to="{ name: 'export-pillar-rc' }"
            class="btn btn-new-key btn-outline-primary pl-5 my-3 pr-5 text-dark"
            >Preview
          </router-link>
        </div>
      </div>
      <div class="col-sm-9 col-md-9">
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-social-impact"
            role="tabpanel"
            aria-labelledby="v-pills-social-impact-tab"
          >
            <b-row>
              <div class="col-sm-10 col-md-10">
                <b-row>
                  <b-container class="my-5" v-if="state.loadElem">
                    <b-spinner type="grow" small></b-spinner>
                    <span class="text-13 ml-2">Loading elements...</span>
                  </b-container>
                  <b-container class="my-5" v-else-if="elementsNum <= 0">
                    <span> No elements found at this level. </span>
                  </b-container>
                  <div
                    v-else
                    v-for="(el, j) in elements"
                    :key="j"
                    class="col-sm-6 col-md-6"
                  >
                    <div class="card card-chain">
                      <div
                        :style="[
                          { backgroundColor: `${levelItem.color} !important` },
                        ]"
                        class="card-header"
                      ></div>
                      <div class="card-body cb-scroll">
                        <p class="text-left text-13">
                          <strong>{{ el.element }}:</strong>
                          {{ el.Description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-row>
              </div>
              <div class="col-sm-2 col-md-2">
                <a
                  class="
                    btn btn-new-key btn-primary
                    pl-3
                    btn-sm
                    text-13
                    pr-3
                    text-white
                    d-none
                  "
                  >Add Element</a
                >
              </div>
            </b-row>
          </div>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import decode from "jwt-decode";
export default {
  name: "pillar-result-chain-tab",
  props: ["store"],
  data() {
    return {
      state: {
        loading: false,
        loadLevel: false,
        loadElem: false,
      },
      level: "",
      abbr: "",
      description: "",
      levelItem: null,
      element: {
        name: "",
        description: "",
      },
      elements: [],
      rce: null,
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    pillar() {
      const storeData = sessionStorage.getItem("pl");
      const pillarData = this.$store.state.pillar_data;
      var decoded = null;
      if (storeData) {
        decoded = decode(storeData);
      }
      return pillarData ? pillarData : decoded;
    },
    drafted(){
      var decoded = null;
      const storeData = sessionStorage.getItem("drafted-pillar")
      if(storeData){
        decoded = decode(storeData)
      }
      return decoded;
    },
    finPillar(){
      return this.pillar ? this.pillar : this.drafted ? this.drafted : null;
    },
    elementsNum() {
      return this.elements.length;
    },
    levels() {
      return this.$store.state.selected_pillar.pillar_levels;
    },
  },
  beforeMount() {
    this.onChangeLevel(this.levels[0]);
  },
  watch: {
    levels: function () {
      this.onChangeLevel(this.levels[0]);
    },
    rce: function () {
      // console.log("TOC ID", this.toc);
    },
  },
  methods: {
    async getResultChainElement(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadElem = true;
      await this.axios
        .get(`getResultChainElements/${param.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadElem = false;
            this.elements = response.data;
          } else {
            this.state.loadElem = false;
          }
        })
        .catch(() => {
          this.state.loadElem = false;
        });
    },
    removeSpace(title) {
      return title.replace(/\s/g, "-");
    },
    onChangeLevel(value) {
      this.levelItem = value;
      if (value) this.getResultChainElement(value);
    },
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>

<style scoped>
*,
body {
  font-family: Ubuntu !important;
}
.nav-result-chain .active {
  color: var(--primary) !important;
  border-left: 4px solid #0fa958 !important;
  color: #0fa958 !important;
}

.nav-result-chain .nav-link {
  text-align: left !important;
  font-family: Ubuntu;
  color: #acacac;
}

.main-wrapper .form-group label,
.main-wrapper .form-group input::placeholder,
.form-text,
.form-text::placeholder,
.form-text-label,
.form-control,
.custom-select {
  font-size: 13px !important;
}

.card-chain {
  background: #e5e5e5 !important;
  border-radius: 12px !important;
  border: none !important;
  height: 90% !important;
  font-size: 13px !important;
}

.card-chain .card-header {
  background: #0fa958 !important;
  border: none !important;
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.table-indicators th {
  font-size: 13px !important;
  border-top: none !important;
}

.table-indicators td {
  font-size: 13px !important;
}

.card-2 {
  border: none !important;
  border-radius: 20px !important;
}

.btn-new-key {
  border-radius: 20px !important;
  font-size: 13px !important;
}

@media (min-width: 600px) {
  .card-chain {
    margin-right: 0 !important;
  }
  .row-chain {
    margin-right: 0 !important;
  }
}
</style>
