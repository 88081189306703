<template>
    <footer class="main-footer fixed-bottom mt-5 mb-auto py-3 text-center">
        <div class="footer-container">
            <span class="text-white"> &copy; Copyright {{dateNow}} AFR</span>
        </div>
    </footer> 
</template>

<script>
export default {
    name: "AppFooter",
    data(){
        return {
            dateNow: new Date().getFullYear()
        }
    }
}
</script>

<style>

</style>