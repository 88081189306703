<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 main-home mb-5">
      <div class="card card-main  card-0 mt-3 ml-0 mb-3">
        <div class="card-body pl-0 ml-0">
          <b-row>
            <div class="col-sm col-md">
              <div class="d-flex justify-content-end">
                <button
                  class="
                    btn btn-secondary
                    rounded-20
                    pt-1
                    pb-1
                    pl-5
                    pr-5
                    btn-filter"
                >
                  Filter <i class="bx bx-filter-alt mt-2"></i>
                </button>
              </div>
            </div>
          </b-row>
          <b-row class="mb-0 mt-2">
              <div class="col-sm-9 col-md-9"></div>
                <div class="col-sm col-md text-right">
                  <a-tooltip title="Refresh data" placement="bottom" :color="'#87d068'">
                    <button 
                      @click="refresh"
                      type="button"
                      class="btn mr-3 mt-1 btn-secondary btn-sm rounded-20 border-none"
                    >
                      <b-icon-arrow-repeat               
                        font-scale="1.6"
                        :animation="state.refresh && state.loading ? 'spin' : ''"
                      />
                    </button>
                  </a-tooltip>
                </div>       
              </b-row>
          <b-container v-if="state.loading || state.currentp" class="my-5">
            <a-skeleton
              active
              :paragraph="{ rows: 18 }"
              :loading="state.loading || state.currentp"
            />
          </b-container>
          <b-row v-else-if="pillarsLength > 0" class="mb-4">
            <div
              class="col-sm-3 col-md-3 my-3"
              v-for="(item, i) in pillars"
              :key="i"
            >
              <div
                @click="
                  item.status == 'incomplete'
                    ? goToDraftedPillar(item)
                    : goToPillar(item)"
                class="card pillar-1 ml-2 box cursor-pointer mt-2"
                :class="
                  i == 1
                    ? 'pillar-2'
                    : i == 2
                    ? 'pillar-3'
                    : i == 3
                    ? 'pillar-4'
                    : ''"
              >
                <div class="card-body">
                  <h4 class="text-left text-13">
                    <strong class="text-uppercase"> {{ item.Title }} </strong>
                  </h4>
                  <div class="content pt-4 pb-4 mb-4">
                    <div class="text-center">
                      <h4>
                        <strong>{{
                          `${item.peopleHelped.length > 0}%`
                            ? item.peopleHelped
                            : "0%"
                        }}</strong>
                      </h4>
                      <span><strong>People reached</strong></span>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span class="h6 text-13 text-capitalize">
                      {{ keywords ? `${keywords.project}s` : "Projects" }}:
                      <big class="text-bold">{{
                        item.projects ? item.projects : "0"
                      }}</big>
                    </span>
                    <span class="cursor-pointer">
                      <i class="bx bx-dots-vertical-rounded bx-sm dotted"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
          <b-container v-else class="my-5">
            <i class="fas fa-exclamation-triangle text-red fa-3x"></i>
            <span class="text-17 ml-2">
              No {{ getKeyword('pillar') }} data found or you do not have access to any pillar
            </span>
          </b-container>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import encode from "jwt-encode";
export default {
  data() {
    return {
      pageTitle: this.$store.getters.getCurrentPrograam
        ? `${this.$store.getters.getCurrentPrograam.keyWords.pillar}s`
        : "Pillars",
      pillars: [],
      state: {
        loading: false,
        refresh: false,
      },
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    pillarsLength() {
      return this.pillars.length;
    },
    program() {
      return this.$store.state.current_program;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  beforeMount() {
    this.$title("Pillars");
    this.getCurrentProgram()
  },
  methods: {
    goToPillar(param) {
      const secret = "tokenHashingViv--afr0-101--12=+.dfeo33@##";
      const jwt = encode(param, secret);
      sessionStorage.setItem("pl", jwt);
      sessionStorage.removeItem("drafted-pillar")
      this.$store.dispatch("setPillar", param);
      this.$router.push("pillars/details");
    },
    goToDraftedPillar(param) {
      sessionStorage.removeItem("pl");
      const secret = "tokenHashingViv--afr0-101--12=+.dfeo33@##";
      const jwt = encode(param, secret);
      sessionStorage.setItem("drafted-pillar", jwt);
      this.$router.replace({ name: "create-pillar" });
    },
    async fetchPillars() {
      this.state.loading = true;
      await this.axios
        .get(`getPillarsInProgram/${this.program.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.pillars = response.data;
            // console.log("Pillars", this.pillars)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
     async getCurrentProgram() {
      this.state.currentp = true;
      await this.axios
        .get("getCurrentProgram")
        .then((response) => {
          if (response.data) {
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
            this.fetchPillars();
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
    refresh(){
      this.state.refresh=true
      this.fetchPillars()
    }
  },
};
</script>

<style>
</style>