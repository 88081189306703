<template>
  <div>
    <top-banner :title="pageTitle" />
    <main
      role="main"
      class="col-sm-9 ml-sm-auto main-common col-md-10 pt-0 mb-8"
    >
      <div class="card card-main card-0 mt-4 mb-6 ml-0 mb-8">
        <div class="card-body pl-0 ml-0">
          <b-row>
            <div class="col-sm-6 col-md-9"></div>
            <div class="col-sm">
              <form>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control input-search rounded-left-10"
                    aria-label="Search"
                    placeholder="Search ..."
                    v-model="filter"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right-10">
                      <i class="bx bx-search text-success"></i>
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-sm text-right">
              <button
                @click="refresh"
                type="button"
                class="btn btn-secondary btn-sm rounded-10 border-none"
              >
                <b-container v-if="state.loading && state.refresh">
                  <b-spinner small></b-spinner>
                  <span class="text-13 ml-2">Refreshing...</span>
                </b-container>
                <span v-else class="text text-13">Refresh</span>
              </button>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm-10 col-md-10 justify-content-start">
                <h4 class="text-left ml-3 my-2">{{decodeInd.data.name}}</h4>
                <h6 class="text-left ml-3 my-2"><b>Indicator definition:</b> {{ decodeInd.data.Definition }}</h6>
                <h6 class="text-left ml-3 my-2"><b>Program Name:</b> {{decodeInd.data.ProgramName}} </h6>
                <h6 class="text-left ml-3 my-2"><b>TOC Level: </b> {{decodeInd.data.TOC.level}}</h6>
                <h6 class="text-left ml-3 my-2"><b>TOC Element:</b> {{decodeInd.data.TOC.element}}</h6>
            </div>
            <div class="col-sm col-md"></div>
          </b-row>
          <b-row>
            <div class="col-12 mt-5 mb-2">
              <h6 class="text-left">Contributions</h6>
            </div>
            <div class="col-sm col-md">
              <div>
                <b-container v-if="state.loading || state.currentp">
                  <a-skeleton
                    active
                    :loading="state.loading || state.currentp"
                    :paragraph="{ rows: 8 }"
                  ></a-skeleton>
                </b-container>
                <div v-else class="table-responsive">
                  <table data-v-42e4f965="" role="table" class="table b-table table-approvers text-13 indent-10 table-striped table-hover">
                    <thead  class="">
                      <tr class="tb-header">
                        <th role="" class=""><div>Project Name</div></th>
                        <th class=""><div>Indicator Definition</div></th>
                        <th class=""><div>Baseline</div></th>
                        <th class=""><div>Milestones</div></th>
                      </tr>
                    </thead>
                    <tbody class="tb-body indent-10">
                      <template v-for="(contrib, key) in contribs.AlignedIndicator">
                        <tr class="" :key="key">
                          <td class="align-middle" :rowspan="contrib.targetData.length">{{contrib.projectName}}</td>
                          <td class="align-middle" :rowspan="contrib.targetData.length">{{contrib.Title}}</td>
                          <td class="align-middle" :rowspan="contrib.targetData.length">{{contrib.baseline}}</td>
                          <td class="align-middle" :rowspan="contrib.targetData.length">
                            <tr v-for="(milestone, mileKey) in contrib.targetData" :key="mileKey">
                              <td>{{milestone.MilestoneTitle}}</td>
                              <td>{{milestone.achieved}}</td>
                            </tr>
                          </td>
                        </tr>
                        <tr v-for="index in 4" :key="`${index} ${key}`">
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!-- <b-table
                  table-class="table-approvers text-13 indent-10"
                  striped
                  hover
                  thead-tr-class="tb-header"
                  tbody-class="tb-body indent-10"
                  :items="contribs"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  show-empty
                  :fields="headers"
                >

                </b-table> -->
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm-8"></div>
            <div class="col-sm">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="text-13"
              ></b-pagination>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import decode from 'jwt-decode'
export default {
  props: {
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      pageTitle:"Indicator Details",
      state: {
        loading: false,
        currentp: false,
      },
      currentPage: 1,
      perPage: 10,
      filter: "",
      headers: ["projectName", "Title","indicatorDefinition","baseline", 'updates'],
      contribs:[],
      indicatrData: null
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    program() {
      return this.$store.state.current_program;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    rows() {
      return this.contribs ? this.contribs.length : 0;
    }, 
    urlId(){
      return this.$route.params.id;
    },
    decodeInd(){
      const sess = sessionStorage.getItem("indicator-data")
      var tokenData = null;
      if(sess){
        tokenData = decode(sess)
      }
      return tokenData;
    }
  },
  beforeMount() {
    this.getCurrentProgram();
    this.fetchContribs();
    // console.log("Decoded", this.decodeInd);
  },
  methods: {
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.currentp = true;
      await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if (response.data) {
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
    refresh() {
      this.state.refresh = true;
      this.fetchContribs();
    },
    async fetchContribs() {
      this.state.loading = true;
      await this.axios
        .get(`program/indicator_details/${this.urlId}`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.contribs = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.progress-wrapper {
  width: 260px !important;
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  background: #f3f3f3 !important;
}

.card-pillars {
  background: #3fb15d21 !important;
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}

.label-remain h6 {
  font-size: 12px !important;
  margin-right: 10px !important;
}

.label-completed h6 {
  font-size: 12px !important;
  margin-left: 10px !important;
}

.top-texts {
  font-size: 13px !important;
}

.knowledge-text {
  color: #001 !important;
  font-size: 15px !important;
}

.avatar {
  width: 60px;
  height: 60px;
  background: #eeeeee !important;
}

.report-status h6 {
  color: #e5e5e5 !important;
}

.price-1 button {
  background-color: #8dd070 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.price-2 button {
  background-color: #cecf71 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.price-3 button {
  background-color: #ffcdd3 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.row1 {
  border-left: 6px solid #8dd070 !important;
  border-spacing: 4px !important;
}

.row2 {
  border-left: 6px solid #cecf71 !important;
  border-spacing: 4px !important;
}

.row3 {
  border-left: 6px solid #ffcdd3 !important;
  border-spacing: 4px !important;
}

.card {
  text-align: center !important;
}

.dotted {
  background: #000000 !important;
  border-radius: 2px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  color: #fff !important;
}

@media screen and (max-width: 578px) {
  .big-border {
    width: 54.5px;
    height: 5px;
    background: #cecf71 !important;
  }

  .date-top-text {
    color: #000 !important;
  }

  .user-info,
  .report-status {
    width: 150px !important;
  }
}
</style>