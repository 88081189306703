var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5"},[_c('b-row',{staticClass:"row-chain"},[_c('div',{staticClass:"col-sm col-md col-lg"},[_c('div',{staticClass:"nav flex-column nav-result-chain",attrs:{"id":"v-pills-tab","role":"tablist","aria-orientation":"vertical"}},[_vm._l((_vm.levels),function(level,i){return _c('a',{key:i,staticClass:"tab-left nav-link text-capitalize",class:_vm.levelItem
              ? level.id == _vm.levelItem.id
                ? 'active'
                : null
              : _vm.levels[0].id == level.id
              ? 'active'
              : null,style:([
            _vm.levelItem
              ? level.id == _vm.levelItem.id
                ? {
                    color: ((level.color) + " !important"),
                    borderLeft: ("4px solid " + (level.color) + " !important"),
                  }
                : null
              : _vm.levels[0].id == level.id
              ? {
                  color: ((level.color) + " !important"),
                  borderLeft: ("4px solid " + (level.color) + " !important"),
                }
              : null ]),attrs:{"id":_vm.removeSpace(level.level),"data-toggle":"pill","href":"#v-pills-social-impact","role":"tab","aria-controls":("v-pills-" + (_vm.removeSpace(level.level))),"aria-selected":"true"},on:{"click":function($event){return _vm.onChangeLevel(level)}}},[_vm._v(_vm._s(level.level)+" "),_c('div',{staticClass:"clear-fix"}),_c('a-tooltip',{attrs:{"title":"Update level","placement":"bottom"}},[_c('small',{on:{"click":function($event){return _vm.onshowEdit(level)}}},[_c('span',[_c('b-img',{staticStyle:{"width":"17px","height":"17px"},attrs:{"src":_vm.edit}}),_vm._v(" edit")],1)])])],1)}),(_vm.projectDetails.rc_attachment && typeof(_vm.projectDetails.rc_attachment) == 'string')?_c('a',{staticClass:"btn btn-new-key btn-primary pl-5 mb-3 pr-5 text-dark mt-3",attrs:{"href":_vm.projectDetails.rc_attachment,"target":"_blank"}},[_vm._v("Preview")]):_vm._e()],2)]),_c('div',{staticClass:"col-sm-9 col-md-9"},[_c('div',{staticClass:"tab-content",attrs:{"id":"v-pills-tabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"v-pills-social-impact","role":"tabpanel","aria-labelledby":"v-pills-social-impact-tab"}},[_c('b-row',[_c('div',{staticClass:"col-sm-10 col-md-10"},[_c('b-row',[(_vm.state.loadElem)?_c('b-container',{staticClass:"my-5"},[_c('b-spinner',{attrs:{"type":"grow","small":""}}),_c('span',{staticClass:"text-13 ml-2"},[_vm._v("Loading RC boxes...")])],1):(_vm.elementsNum <= 0)?_c('b-container',{staticClass:"my-5"},[_c('span',[_vm._v(" No RC Box found at this level. ")])]):_vm._l((_vm.elements),function(el,j){return _c('div',{key:j,staticClass:"col-sm-6 col-md-6"},[_c('div',{staticClass:"card card-chain"},[_c('div',{staticClass:"card-header",style:([
                        { backgroundColor: ((_vm.levelItem.color) + " !important") } ])},[_c('p')]),_c('div',{staticClass:"card-body cb-scroll"},[_c('p',{staticClass:"text-left text-13"},[_c('strong',[_vm._v(_vm._s(el.element)+":")]),_vm._v(" "+_vm._s(el.Description)+" ")])])])])})],2)],1),_c('div',{staticClass:"col-sm-2 col-md-2"})])],1)])])]),_c('a-modal',{attrs:{"footer":null,"title":"Update result chain level"},model:{value:(_vm.visibleEditLevel),callback:function ($$v) {_vm.visibleEditLevel=$$v},expression:"visibleEditLevel"}},[_c('updateLevel',{attrs:{"leveldata":_vm.selectedLevel},on:{"dismiss":_vm.onDismiss}})],1),_c('a-modal',{attrs:{"footer":null,"title":"Update result chain box"},model:{value:(_vm.visibleEditElement),callback:function ($$v) {_vm.visibleEditElement=$$v},expression:"visibleEditElement"}},[_c('updateTOCElement',{attrs:{"elementdata":_vm.selectedElement},on:{"dismiss":_vm.onDismissElement}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }