<template>
  <div>
    <b-row>
      <div class="col-sm col-md">
        <h6 class="text-13">Keywords</h6>
        <div class="b-bottom w-25 my-2"></div>
        <b-form @submit.prevent="addProgramKeywords">
          <b-container v-if="load.loading">
            <a-skeleton
              :loading="load.static"
              :paragraph="{ rows: 4 }"
              active
            ></a-skeleton>
          </b-container>
          <table v-else class="table">
            <tr>
              <th>Keyword</th>
              <th>Value</th>
            </tr>
            <tr v-for="(item, i) in temp" :key="i">
              <td>
                <span>{{ item ? item.keyword : null }}</span>
              </td>
              <td>
                <input
                  id="pj"
                  placeholder="Keyword value"
                  rows="3"
                  resize
                  class="form-control"
                  v-model="item.value"
                />
              </td>
            </tr>
          </table>

          <b-form-group>
            <button
              type="submit"
              class="btn btn-primary rounded-20 float-left btn-sm"
            >
              <b-container v-if="load.adding">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Saving...</span>
              </b-container>
              <span v-if="!load.adding" class="text text-13"
                >Save Keywords</span
              >
            </button>
          </b-form-group>
        </b-form>
      </div>
      <!-- Hidden as its not needed now -->
      <div class="col-sm col-md">
        <h6 class="text-13">Program Targets</h6>
        <div class="b-bottom w-25 my-2"></div>
        <b-row class="my-2">
          <div class="col-sm col-md"></div>
          <div class="col-sm-8 col-md-8">
            <form>
              <div class="input-group mb-2">
                <input
                  type="search"
                  v-model="filter"
                  class="form-control input-search rounded-left-10"
                  aria-label="Search"
                  placeholder="Search milestones..."
                />
                <div class="input-group-append">
                  <span class="input-group-text rounded-right-10">
                    <i class="bx bx-search text-success"></i>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </b-row>
        <b-row class="mb-2">
          <div class="col-sm-9 col-md-9"></div>
          <div class="col-sm col-md text-right">
            <button
              @click="refresh"
              type="button"
              class="btn btn-secondary btn-sm rounded-10 border-none"
            >
              <b-container v-if="load.milestones && load.refresh">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Refreshing...</span>
              </b-container>
              <span v-else class="text text-13">Refresh</span>
            </button>
          </div>
        </b-row>
        <div class="table-responsive mt-2">
          <b-container v-if="load.milestones">
            <a-skeleton
              :loading="load.milestones"
              :paragraph="{ rows: 4 }"
              active
            ></a-skeleton>
          </b-container>
          <b-table
            v-else
            table-class="table table-knowledge rounded-20"
            thead-tr-class="theader-knowledge tb-head text-left indent-20"
            tbody-class="tb-body text-left indent-20"
            :items="milestones"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            show-empty
          >
            <template v-slot:cell(edit)="data">
              <span @click="showEdit(data.item)" class="cursor-pointer"
                ><i class="fa fa-edit text-success"></i
              ></span>
            </template>
            <template v-slot:cell(delete)="data">
              <span @click="deleteModal(data.item)" class="cursor-pointer"
                ><i class="fa fa-trash text-danger"></i
              ></span>
            </template>
            <template v-slot:cell(Due_date)="data">
              <span>{{ moment(data.item.Duedate).format("DD/MM/YYYY") }}</span>
            </template>
            <template v-slot:cell(creation_time)="data">
              <span>{{
                moment(data.item.created_at).format("DD/MM/YYYY H:mm:ss a")
              }}</span>
            </template>
          </b-table>
        </div>
        <b-row class="my-4">
          <div class="col-sm-8 col-md-8"></div>
          <div class="col-sm col-md">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              prev-class="text-13"
              next-class="text-13"
              first-class="text-13"
              last-class="text-13"
            ></b-pagination>
          </div>
        </b-row>
        <b-row v-if="false">
          <!-- Milestones addition not currently needed -->
          <b-col cols="12" sm="12" md="12">
            <div class="card">
              <div class="card-body">
                <b-form @submit.prevent="addMilestone">
                  <b-form-group>
                    <label class="float-left text-13">Milestone title</label>
                    <input
                      v-model="milestone.title"
                      required
                      type="text"
                      class="form-control"
                      placeholder="Milestone title"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label class="float-left">Due date</label>
                    <a-date-picker
                      v-model="defaultDate"
                      class="form-control"
                      @change="onChangeDate"
                    />
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      class="float-left"
                      :value="true"
                      :unchecked-value="false"
                      v-model="isreport"
                    >
                      Have a report
                    </b-form-checkbox>
                  </b-form-group>
                  <b-row v-if="isreport">
                    <b-col>
                      <b-form-group>
                        <label class="float-left text-13">Report title</label>
                        <input
                          v-model="nreport.Title"
                          :required="isreport"
                          type="text"
                          class="form-control"
                          placeholder="Report title"
                        />
                      </b-form-group>
                      <b-form-group>
                        <label class="float-left">Report deadline </label>
                        <input
                          v-model="nreport.DeadLine"
                          :required="isreport"
                          type="date"
                          class="form-control"
                          placeholder="Report due date"
                        />
                      </b-form-group>
                      <b-form-group>
                        <b-form-checkbox
                          class="float-left"
                          value="true"
                          unchecked-value="false"
                          v-model="nreport.narativeReport"
                        >
                          Narative report
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group>
                    <button
                      type="submit"
                      class="btn btn-primary rounded-20 float-left btn-sm"
                    >
                      <b-container v-if="state.addingMile">
                        <b-spinner small></b-spinner>
                        <span class="text-13 ml-2">Saving...</span>
                      </b-container>
                      <span v-if="!state.addingMile" class="text text-13"
                        >Submit</span
                      >
                    </button>
                  </b-form-group>
                </b-form>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-row>
    <!-- Update milestone -->
    <a-modal v-model="visibleEdit" :footer="null" title="Update Targets">
      <form @submit.prevent="changeMilestone(data)">
        <div class="form-group">
          <label>Milestone title</label>
          <!-- <input
            placeholder=""
            type="text"
            class=""
            required
            v-model=""
          /> -->
          <a-input v-model="edit.milestone" placeholder="Period title" />
        </div>
        <b-form-group>
          <label>Due date</label>
          <input v-model="edit.dueDate" class="form-control" type="date" />
          <!-- @change="onChangeEditDate" -->
        </b-form-group>
        <button type="submit" class="btn btn-success btn-block">
          <b-container v-if="load.updating">
            <b-spinner small></b-spinner>
            <span class="text-13 ml-2">Saving...</span>
          </b-container>
          <span v-if="!load.updating" class="text text-13">Save Changes</span>
        </button>
      </form>
    </a-modal>
    <!-- ****** -->
    <a-modal
      v-model="visibleDelete"
      :footer="null"
      title="Delete Program target"
    >
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to delete this information ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <a-popconfirm
            title="Are you sure delete this program target ?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteMilestone(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="load.deleting">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Deleting...</span>
              </b-container>
              <span v-if="!load.deleting" class="text text-13">DELETE</span>
            </button>
          </a-popconfirm>
        </div>
        <div class="inline-block">
          <button
            @click="handleCancel"
            type="submit"
            class="btn btn-secondary btn-block btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
      </b-row>
    </a-modal>
  </div>
</template>

<script>
import decode from "jwt-decode";
import moment from "moment";
export default {
  name: "program-milestones-config",
  data() {
    return {
      defaultDate: moment(new Date()).format("YYYY-MM-DD"),
      currentPage: 0,
      perPage: 8,
      fields: ["Title", "Due_date", "creation_time", "edit", "delete"],
      filter: "",
      titles: {
        pillarTitle: "Pillars",
        toc: "TOC",
      },
      load: {
        loading: false,
        refresh: false,
        adding: false,
        updating: false,
        deleting: false,
        static: false,
        milestones: false,
      },
      state: {
        currentp: false,
        addingMile: false,
      },
      keywords: null,
      milestones: [],
      types: null,
      temp: [],
      type: "",
      keyword: "",
      value: "",
      activeTab: true,
      pageTitle: "programs",
      show: false,
      data: null,
      visibleDelete: false,
      visibleEdit: false,
      collapse: false,
      milestone: {
        title: null,
        dueDate: null,
      },
      edit: {
        milestone: null,
        dueDate: null,
      },
      isreport: false,
      nreport: {
        Title: "Title",
        DeadLine: null,
        narativeReport: false,
      },
    };
  },
  computed: {
    programSaved() {
      return this.$store.state.program_data
        ? this.$store.state.program_data
        : null;
    },
    drafted() {
      const data = sessionStorage.getItem("draft");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return decoded;
    },
    program() {
      return this.$store.state.program_data
        ? this.$store.state.program_data
        : null;
    },
    programId() {
      const data = sessionStorage.getItem("requested");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.program ? this.program : decoded;
    },
    token() {
      return localStorage.getItem("token");
    },
    selectedID() {
      return this.drafted
        ? this.drafted.id
        : this.programId
        ? this.programId.programData.id
        : 0;
    },
    rows() {
      return this.milestones ? this.milestones.length : 0;
    },
    keyWordGetter() {
      return this.$store.getters.getCurrentPrograam
        ? this.$store.getters.getCurrentPrograam.keyWords
        : null;
    },
    keyWords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  mounted() {
    this.getCurrentProgram();
  },
  beforeMount() {
    if (this.programId) {
      this.getMilestones();
      this.getProgramStatics();
      this.getProgramKeyword();
    } else if (this.drafted) {
      this.getMilestones();
      this.getProgramStatics();
      this.getProgramKeyword();
    }
  },
  methods: {
    // milestones
    getMilestones() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.load.milestones = true;
      this.axios
        .get(`getProgramMilestones/${this.selectedID}`, config)
        .then((response) => {
          this.load.milestones = false;
          this.milestones = response.data;
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.load.milestones = false;
        });
    },
    async addMilestone() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        programId: this.drafted
          ? this.drafted.id
          : this.programId
          ? this.programId.programData.id
          : 0,
        Title: this.milestone.title,
        Duedate: this.milestone.dueDate
          ? this.milestone.dueDate
          : this.defaultDate,
        reportData: this.nreport,
      };
      this.state.addingMile = true;
      await this.axios
        .post("AddMilestone", requestData, config)
        .then((response) => {
          if (response.data.message) {
            this.state.addingMile = false;
            this.$message.error(`${response.data.message}`, 4);
            this.$notification["error"]({
              message: "ERROR!",
              description: `${response.data.message}`,
              duration: 5,
            });
          } else {
            this.state.addingMile = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Program milestone added successfully.`,
              duration: 5,
            });
            this.getMilestones();
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.addingMile = false;
        });
    },
    async addProgramKeywords() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        programId: this.drafted
          ? this.drafted.id
          : this.programId
          ? this.programId.programData.id
          : 0,
        keywords: this.temp,
      };
      this.load.adding = true;
      await this.axios
        .post("setupKeyword", requestData, config)
        .then((response) => {
          if (response.data.message) {
            this.load.adding = false;
            this.$message.error(`${response.data.message}`, 4);
            this.$notification["error"]({
              message: "ERROR!",
              description: `${response.data.message}`,
              duration: 5,
            });
          } else {
            this.load.adding = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Program keywords changes saved successfully.`,
              duration: 5,
            });
            this.getProgramKeyword();
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.load.adding = false;
        });
    },
    getProgramKeyword() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.load.loading = true;
      this.axios
        .get(`getProgramKeyWords/${this.selectedID}`, config)
        .then((response) => {
          this.load.loading = false;
          this.keywords = response.data;
          this.show = true;
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.load.loading = false;
        });
    },
    getProgramStatics() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.load.static = true;
      this.axios
        .get(`getProgram/${this.selectedID}`, config)
        .then((response) => {
          this.load.static = false;
          this.types = response.data[0].keywords;
          this.temp.push(
            { keyword: "approval", value: this.types.approvals },
            { keyword: "department", value: this.types.department },
            {
              keyword: "implementing partner",
              value: this.types["implementing partner"],
            },
            { keyword: "institution", value: this.types.institution },
            { keyword: "pillar", value: this.types.pillar },
            { keyword: "project", value: this.types.project },
            { keyword: "report", value: this.types.report },
            { keyword: "result chain", value: this.types["result chain"] },
            {
              keyword: "theory of change",
              value: this.types["theory of change"],
            },
            { keyword: "workflow", value: this.types.workflow }
          );
          // console.log("Data temp array", this.temp);
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.load.static = false;
        });
    },
    async changeMilestone(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        Title: this.edit.milestone,
        Duedate: this.edit.dueDate,
      };
      this.load.updating = true;
      await this.axios
        .put(`UpdateProgramMilestone/${param.id}`, requestData, config)
        .then((response) => {
          if (!response.data.message) {
            this.load.updating = false;
            this.visibleEdit = false;
            if (this.programId) {
              this.getMilestones();
            } else if (this.drafted) {
              this.getMilestones();
            }
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Program milestone has been updated successfully.`,
              duration: 5,
            });
            this.show = true;
          } else {
            this.load.updating = false;
            this.$notification["error"]({
              message: "ERROR!",
              description: `${response.data.message}`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.load.updating = false;
        });
    },
    deleteModal(data) {
      this.data = data;
      this.visibleDelete = true;
    },
    async deleteMilestone(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.load.deleting = true;
      await this.axios
        .delete(`DeleteMilestone/${param.id}`, config)
        .then((response) => {
          if (response) {
            this.load.deleting = false;
            this.show = true;
            this.visibleDelete = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Program milestone has been deleted successfully.`,
              duration: 4,
            });
            if (this.programId) {
              this.getMilestones();
            } else if (this.drafted) {
              this.getMilestones();
            }
          } else {
            this.load.deleting = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loading = false;
        });
    },
    handleCancel() {
      this.visibleDelete = false;
      this.show = true;
    },
    showEdit(data) {
      this.visibleEdit = true;
      this.data = data;
      this.edit.milestone = data.Title;
      this.edit.dueDate = moment(this.data.Duedate).format("YYYY-MM-DD");
    },
    cancel(e) {
      console.log(e);
      this.visibleDelete = false;
      this.$message.error("Deleting milestone has been canceled.");
    },
    refresh() {
      this.load.refresh = true;
      this.getMilestones();
      this.getProgramKeyword();
    },
    onChangeDate(date, dateString) {
      date;
      this.milestone.dueDate = dateString;
    },
    onChangeEditDate(date, dateString) {
      this.edit.dueDate = dateString;
    },
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.currentp = true;
      await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if (response.data) {
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
            this.steps[1].name = this.keyWords
              ? this.keyWords.pillar
                ? `${this.keyWords.pillar}s`
                : "Pillars"
              : "Pillars";
            this.steps[2].name = this.keyWords
              ? this.keyWords["theory of change"]
                ? `${this.keyWords["theory of change"]}`
                : "Theory of Change"
              : "Theory of Change";
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
  },
};
</script>

<style>
</style>