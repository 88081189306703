<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 main mb-5">
      <b-row class="mt-3 row-admin">
        <div class="col-sm col-md col-lg">
          <div class="card card-main rounded-20 mt-1 ml-0 mb-4">
            <div class="card-body pl-0 ml-0">
              <b-row>
                <div class="col-sm col-md col-lg">
                  <h5 class="text-primary float-left ml-4">
                    {{ keywords ? keywords.pillar ? `${keywords.pillar} Setup` : "Pillar Setup" : "Pillar Setup" }}
                    <div class="vm-active"></div>
                  </h5>
                </div>
              </b-row>
              <b-row>
                <div class="col-sm col-md col-lg">
                  <div class="container">
                    <d-stepper
                      :steps="steps"
                      :initial-state="{ name: 'Carlos', users: [] }"
                      @submit-pillar="submitPillar"
                    >
                      <template #fatal-error="{ errorMsg }">{{
                        errorMsg
                      }}</template>
                    </d-stepper>
                  </div>
                </div>
              </b-row>
            </div>
          </div>
        </div>
      </b-row>
    </main>
  </div>
</template>

<script>
import DStepper from "../../../components/stepper/Stepper.vue";
import Step1 from "./components/stepper/step1.vue";
import Step2 from "./components/stepper/step2.vue";
import Step3 from "./components/stepper/step3.vue";
export default {
  name: "create-project",
  components: {
    DStepper,
  },
  data() {
    return {
      steps: [
        {
          name: "Result Chain",
          disabled: false,
          active: true,
          component: Step1,
        },
        {
          name: "Measurement Plan",
          disabled: false,
          active: false,
          component: Step2,
        },
        {
          name: "Submission",
          disabled: false,
          active: false,
          component: Step3,
          confirm: "Confirm",
        },
      ],
      activeTab: true,
      pageTitle: this.$store.getters.getCurrentPrograam
        ? this.$store.getters.getCurrentPrograam.keyWords.pillar
        : "Pillars",
      state: {
        loading: false
      }
    };
  },
  computed: {
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    token(){
      return localStorage.getItem("token")
    }
  },
  beforeMount() {
    sessionStorage.setItem("creation", "pillar");
    this.$title("Create pillar");
    this.getCurrentProgram()
  },
  methods: {
      submitPillar(){
          this.$router.push({name: "home-pillars"});
      },
  async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
            this.pageTitle = this.$store.getters.getCurrentPrograam.keyWords.pillar;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
  },
};
</script>

<style scoped>
/* Top card styles */
.top-card {
  background-color: rgba(119, 119, 119, 0.507);
  border-radius: 10px !important;
  padding: 20px 12px !important;
  width: 234px !important;
  height: 123px !important;
}

.top-card h3 {
  font-size: 16px !important;
  padding-bottom: 3px !important;
}

.top-card p {
  font-size: 15px !important;
  padding-top: 2.5px !important;
}

.btn-top-create {
  background: #0fa958 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card ul li {
  list-style-type: none;
}

.card-stat {
  background-color: #fff !important;
}

/* icons */
.dash-img {
  width: 75px;
  height: 75px;
}
/* end of icons styles */

.btn-top-filter {
  background: #0fa958 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>