<template>
  <div>
    <top-banner :title="pageTitle" />
    <main
      role="main"
      class="col-sm-10 ml-sm-auto col-md-10 pt-0 main-export mb-8"
    >
      <b-container class="my-4 mt-5" v-if="state.loading">
        <a-skeleton
          active
          :loading="state.loading"
          :paragraph="{ rows: 12 }"
        ></a-skeleton>
      </b-container>
      <section v-else-if="data.length" class="overflow-auto h-scroll mt-5">
        <button class="btn btn-primary rounded-20 ml-2 float-right download-btn" @click="enterFullSCreen">Fullscreen</button>
        <button class="btn btn-primary rounded-20 ml-2 float-right download-btn" @click="downloadTOC">Download</button>
        <div v-for="(level, i) in data" :key="i" class="wrapper mt-5">
          <div v-if="level.levelNumber == 1 || level.levelNumber == 2" class="my-3">
            <div class="d-flex flex-fill content-3-flex">
              <div
                class="card card-level mt-2 ml-2 mb-2 rounded-20"
                :style="[{ backgroundColor: `${level.color} !important` }]"
              >
                <div class="card-body">
                  <h4 class="pt-5 pb-5 text-black text-15 text-center">{{ level.level }}</h4>
                </div>
              </div>

              <div class="d-flex flex-fill normal-element">
                <div
                  v-if="level.elements.length <= 3"
                  class="card card-element offset-element m-5 p-5 border-none"
                ></div>
              </div>
              <div>
                <div class="top-element">
                  <!-- Top levels -->
                  <div
                    v-for="(element, j) in level.elements.filter(elem => elem.isLevelTop == true)" :key="j"
                    :style="[{ backgroundColor: `${element.color} !important`, width: `${(15*(level.elements.length -1))}% !important`  }]"
                    class="card card-element mt-2 ml-2 top-element"
                    :id="`toc_${element.id}`"
                  >
                    <drag>
                      <div class="card-body p-2">
                        <p class="text-left text-black">
                          <strong v-if="element.element">{{ element.element }}:</strong>
                          <small class="text-13">
                            {{ element.Description }}
                          </small>
                        </p>
                      </div>
                    </drag>
                  </div>
                </div>
                <!-- Other levels -->
                <div class="d-flex flex-fill normal-element">
                  <div
                    v-for="(element, j) in level.elements.filter(elem => elem.isLevelTop != true)" :key="j"
                    :style="[{ backgroundColor: `${element.color} !important`}]"
                    class="card card-element ml-2 mb-2"
                    :id="`toc_${element.id}`"
                  >
                    <drag>
                      <div class="card-body p-2">
                        <p class="text-left text-black">
                          <strong v-if="element.element">{{ element.element }}:</strong>
                          <small class="text-13">
                            {{ element.Description }}
                          </small>
                        </p>
                      </div>
                    </drag>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- flexbox 2 -->
          <div v-if="level.levelNumber >= 3" class="my-3">
            <div class="d-flex flex-fill content-3-flex">
              <div
                :style="[{ backgroundColor: `${level.color} !important` }]"
               
                class="card card-level-4 rounded-20"
              >
                <div class="card-body">
                  <h4 class="pt-5 pb-5 text-black text-center text-15">
                    {{ level.level }}
                  </h4>
                </div>
              </div>
              <div
                v-for="(element, j) in level.elements"
                :key="j"
                :style="[{ backgroundColor: `${element.color} !important` }]"
                :id="`toc_${element.id}`"
                class="card card-element-3"
              >
                <div class="card-body p-2">
                  <p class="text-left">
                    <strong v-if="element.element">{{ element.element }}:</strong>
                    <small class="text-13">
                      {{ element.Description }}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <b-container v-else class="my-5">
          <span class="text-red">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> 
            Data not yet available
          </span>
      </b-container>
    </main>
  </div>
</template>

<script>
import Vue from 'vue';
import decode from "jwt-decode";
import * as arrowLine from 'arrow-line';
import VueDragDrop from 'vue-drag-drop';
Vue.use(VueDragDrop);
export default {
  component: {
  },
  data() {
    return {
      title: null,
      pageTitle: "Program TOC Preview",
      perPage: 8,
      currentPage: 1,
      filter: "",
      projects: [],
      arrows: [],
      state: {
        loading: false,
        refresh: false,
        saving: false,
        currentp: false
      },
      data: [],
    };
  },
  created () {
    // document.addEventListener('scroll', this.handleScroll);
    
  },
  
  beforeMount() {
    this.$title(`Export TOC elements`);
    this.getCurrentProgram()
      .then(() => {
        this.fetchTcomponents();
      })
  },
  mounted(){
    
    
  },
  beforeDestroy(){
    this.removeContributionArrows()
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    program() {
      let program = sessionStorage.getItem("prg");
      var resData = null;
      if (program) {
        resData = decode(program);
      }
      return resData;
    },
    currentSaved() {
      const data = sessionStorage.getItem("requested");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.programState ? this.programState : decoded;
    },
    programState() {
      return this.$store.state.program_data
        ? this.$store.state.program_data
        : null;
    },
    drafted() {
      const data = sessionStorage.getItem("draft");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return decoded;
    },
    programCollect() {
      return this.programState
        ? this.programState.programData
        : this.currentSaved
        ? this.currentSaved.programData
        : this.drafted
        ? this.drafted
        : null;
    },
    finProgram() {
      return this.programCollect
        ? this.programCollect
        : this.program
        ? this.program
        : this.currentProgram
        ? this.currentProgram
        : null;
    },
    currentProgram() {
      return this.$store.state.current_program;
    },
  },
  watch:{
    data(){
      console.log("changed", document.querySelector("section.h-scroll"))
      this.$nextTick(() => {
        console.log("triggered", document.querySelector("section.h-scroll"))
        document.querySelector("section.h-scroll").addEventListener('scroll', this.handleScroll);
      })
    }
  },
  methods: {
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.currentp = true;
      await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if (response.data) {
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
    async fetchTcomponents() {
      this.state.loading = true;
      await this.axios
        .get(`programExport/${this.currentProgram.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;

            let tocData = response.data
            tocData = tocData.map((elem) => {
              if(elem.levelNumber==1 || elem.levelNumber==2){
                  // Looop through elements
                  elem.elements.map((child) => {
                      if(child.Description == "Human Development Impact") child.isLevelTop = true
                      if(elem.levelNumber==2 && child.Description.search(/A financial system/i) > -1) child.isLevelTop = true

                      if(child.isLevelTop == true){
                        elem.hasTopElement = true
                      }
                      return child;
                  })
              }
              return elem
            })

            this.data = tocData;
            console.log("Exported data", this.data);
            this.$nextTick(function () {
              this.renderContributionArrows()
            })
           
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    renderContributionArrows(){
      // remove existing arrows
      this.removeContributionArrows();

      if(this.arrows.length > 0){
        console.log("can't be removed")
        return false;
      }

      this.data.forEach(level => {
        level.elements.forEach((element) => {
          element.contributions.forEach((contribution) => {
            console.log(contribution, element)

            let arrowOptions = {
              sourcePosition: 'topCenter',
              destinationPosition: 'bottomCenter',
              forceDirection: 'vertical'
            }
            if(level.levelNumber == contribution.levelNumber){
              // Same level contribution
              arrowOptions = {
                sourcePosition: 'middleLeft',
                destinationPosition: 'middleRight',
                forceDirection: 'horizontal'
              }
            }
            let arrow = arrowLine(`#toc_${element.id}`, `#toc_${contribution.id}`, {
              sourcePosition: arrowOptions.sourcePosition,
              destinationPosition: arrowOptions.destinationPosition,
              color: '#5B9BD5',
              thickness: 2,
              curvature: 0,
              forceDirection: arrowOptions.forceDirection,
            });
            this.arrows.push(arrow)
          })
          
        })
        document.getElementById(this.arrows[0].getParentSvgId()).setAttribute('height', 14400)
        
        console.log("all arrows", this.arrows)
      });
      
    },
    removeContributionArrows(){
      this.arrows.forEach((arrow) => {
        let t = arrow.remove()
        console.log("removed", t)
      })
      this.arrows = []
    },
    downloadTOC(){
      document.getElementById('app').classList.toggle("print-view")
      
      this.$nextTick(function () {
        this.renderContributionArrows()
        window.print();
      })
    },
    enterFullSCreen(){
      document.getElementById("manager-nav").classList.toggle("fullscreen")
    },
    handleScroll (event) {
      console.log("scrolled", event);
      this.renderContributionArrows()
    },
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.progress-wrapper {
  width: 260px !important;
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  background: #f3f3f3 !important;
}

.card-pillars {
  background: #3fb15d21 !important;
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}
</style>

<style lang="scss">
.fullscreen{
  #manager-nav, .download-btn{
    display: none;
  }
  main[role=main]{
    margin: 0 !important;
    flex-basis: 100%;
    max-width: 100%;
  }

}
  
</style>