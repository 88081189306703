<template>
  <div>
    <top-banner :title="pageTitle" />
    <main
      role="main"
      class="col-sm-10 ml-sm-auto col-md-10 pt-0 main-export-log-lg mb-8"
    >
      <b-container class="my-5"  v-if="state.loading">
          <a-skeleton active :loading="state.loading" :paragraph="{rows: 16}"></a-skeleton>
      </b-container>
      <div v-else class="overflow-auto h-scroll my-3 mt-5">
        <div class="content-logframe-box my-4">
          <table class="table table-bordered w-100 text-13">
            <thead>
              <tr>
                <th
                  style=""
                  scope="col"
                  class="bg-yellow-white"
                >
                  Levels
                </th>
                <th
                  class="bg-dark-gray"
                  scope="col"
                >
                  Ref
                </th>
                <th scope="col" colspan="100%">
                  AFR PHASE III LOGRAME 2021-2025
                </th>
                <th class="bg-brown-white b-bottom-none">  </th>
              </tr>
            </thead>
            <tbody v-for="(item,i) in data" :key="i">
              <tr>
                <td class="bg-blue-white">{{item.levelName}}</td>
                <td class="bg-dark-gray">{{item.element}}</td>
                <td style="width: 750px !important;" class="bg-yellow-white"> <span>AFR impact indicator</span> </td>
                <td style="width: 750px !important;" class="bg-yellow-white">Indicator Definition</td>
                <td class="bg-yellow-white" style="width: 140px !important;"></td>
                <td class="bg-green-white" style="width: 140px !important;">Baseline</td>
                <th class="bg-green-white" v-for="(element,k) in milestones[i]" :key="k" style="width: 140px !important;">{{element.MilestoneTitle}}</th>
                <th colspan="100%" class="bg-brown-white">Assumptions</th>
              </tr>
              <tr>
                <td>
                  {{ item.elemenDescription }}
                </td>
                <td class="bg-dark-gray"></td>
                <td style="width: 750px !important;" class="m-0 p-0">
                  <tr  style="height: 140px !important;width: 100% !important" v-for="(el,k) in item.indicatordata" :key="k">
                    <td style="width: 100% !important;" class="m-0 p-0 w-100">
                      {{ el.name }}
                    </td>
                  </tr>
                </td>
                <td style="width: 750px !important;"  class="m-0 p-0">
                  <tr  style="height: 140px !important;width: 100% !important;" v-for="(el,m) in item.indicatordata" :key="m">
                    <td style="width: 100% !important;" class="m-0 w-100 p-0">
                        {{ el.indicatorDefinition }}
                    </td>
                  </tr>
                </td>
                <td class="m-0 p-0" colspan="7">
                  <tbody v-for="(indicator,o) in item.indicatordata" :key="o">
                    <tr style="height: 70px !important;">
                      <td class="b-right-none text-left" style="width: 115px !important;">  <span class="pt-5">Planned</span> </td>
                      <td class="b-right-none" style="width: 140px !important;">  {{ indicator.baseline }} </td>
                      <td class="b-right-none" v-for="(item,f) in indicator.milestones" :key="f" style="width: 140px !important;"> {{item.target}} </td> 
                    </tr>
                    <tr style="height: 70px !important;">
                      <td class="text-left b-right-none" style="width: 115px !important;"> Achieved </td>
                      <td class="bg-dark-gray b-right-none"></td>
                      <td  class="b-right-none" style="width: 140px !important;" v-for="(ac,l) in indicator.milestones" :key="l"> {{ ac.achieved }} </td>
                    </tr>
                     <tr v-for="(ki,key) in indicator.milestones[o].Aggregation" :key="key" style="height: 30px !important;">
                      <td class="text-left b-right-none" style="width: 140px !important;" > <span>Planned</span> <span>{{ki.Title}}</span> </td>
                      <td class="bg-dark-gray b-right-none"></td>
                      <td v-for="(element,k) in indicator.milestones" :key="k" class="b-right-none" style="width: 115px !important;"> 
                        <!-- <span v-for="(loop,jk) in element.Aggregation" :key="jk">{{ loop.target[jk] }}</span> -->
                         <span>{{ element.Aggregation[key] ? element.Aggregation[key].target : '-' }}</span>
                      </td>
                    </tr>
                  </tbody>
                  <!-- v-for="(indicator,o) in item.indicatordata" :key="o" -->
                  <tr>
                    <td class="b-right-none" colspan="100%">Source</td>
                  </tr>
                  <tr>
                    <td class="b-right-none text-left" style="width: 140px !important;" colspan="100%">
                      <span><strong>Source:</strong> AFR impact assessments / evaluations</span>
                    </td>
                  </tr>
                </td>
                <td colspan="100%">
                  No assumption found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import decode from "jwt-decode";
export default {
  component: {},
  data() {
    return {
      title: null,
      pageTitle: "Program Logframe Preview",
      perPage: 8,
      currentPage: 1,
      filter: "",
      data: [],
      state: {
        loading: false,
        refresh: false,
        saving: false,
        currentp: false,
      },
      milestones: [],
      finalMiles: [],
      extra: [],
      indicatorData: [],
    };
  },
  beforeMount() {
    this.$title(`Preview program logframe`);
    this.getCurrentProgram();
  },
  mounted(){
    
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    program() {
      let project = sessionStorage.getItem("prg");
      const resData = decode(project);
      return resData;
    },
  },
  watch:{
    program(){
      if(this.program) this.getLogframData();
    }
  },
  methods: {
    async getCurrentProgram() {
      this.state.currentp = true;
      await this.axios
        .get("getCurrentProgram")
        .then((response) => {
          if (response.data) {
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
    async getLogframData() {
      this.state.loading = true;
      await this.axios
        .get(`ExportLogframe/${this.program.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.milestones = [];
            this.data = response.data;
            this.data.forEach((element) => {
              element.indicatordata.forEach((item) => {
                this.indicatorData.push(item);
                this.milestones.push(item.milestones);
              });
            });
            console.log("Logframe data", this.data)
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Arial, Helvetica, sans-serif !important;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.progress-wrapper {
  width: 260px !important;
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  background: #f3f3f3 !important;
}

.card-pillars {
  background: #3fb15d21 !important;
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}
</style>