<template>
  <div>
    <top-banner :title="pageTitle" />
    <main
      role="main"
      class="col-sm-9 ml-sm-auto main-export-indicator col-md-10 pt-0 mb-8"
    >
      <div class="card card-main card-0 mt-4 mb-6 ml-0 mb-8">
        <div class="card-body pl-0 ml-0">
          <b-row>
            <div class="col-sm">
              <b-row class="mt-4">
                <div class="col-sm-1"></div>
                <div class="col-sm">
                  <label class="float-left" for="">Filter by Level</label>
                  <b-select
                    class="custom-select bg-white text-13 mr-2"
                    v-model="filter"
                    required
                    :disabled="state.loading"
                  >
                    <template #first>
                      <b-select-option
                        value=""
                        selected
                        >Filter by Level
                      </b-select-option>
                    </template>
                    <b-select-option
                      v-for="item in levels"
                      :key="item.id"
                      :value="item.level"
                    >
                      {{ item.level }}
                    </b-select-option>
                  </b-select>
                </div>
              </b-row>
            </div>
            <div class="col-sm">
              <b-row class="mt-4 d-none">
                <div class="col-sm">
                  <label class="float-left" for="">Filter by Element</label>
                  <b-select
                    class="custom-select bg-white text-13 mr-2"
                    v-model="filter"
                    required
                    :disabled="state.loading"
                  >
                    <template #first>
                      <b-select-option
                        value="Filter indicator"
                        selected
                        disabled
                        >Filter by Element
                      </b-select-option>
                    </template>
                    <b-select-option
                      v-for="item in logframes"
                      :key="item.id"
                      :value="item.name"
                    >
                      {{ item.name }}
                    </b-select-option>
                  </b-select>
                </div>
              </b-row>
            </div>
            <div class="col-sm">
              <form>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control input-search rounded-left-10"
                    aria-label="Search"
                    placeholder="Search ..."
                    v-model="filter"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right-10">
                      <i class="bx bx-search text-success"></i>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </b-row>
          <b-row class="mb-2">
            <div class="col-sm-9 col-md-9"></div>
            <div class="col-sm col-md text-right">
              <button
                @click="refresh"
                type="button"
                class="btn btn-secondary btn-sm rounded-10 border-none"
              >
                <b-container v-if="state.loading && state.refresh">
                  <b-spinner small></b-spinner>
                  <span class="text-13 ml-2">Refreshing...</span>
                </b-container>
                <span v-else class="text text-13">Refresh</span>
              </button>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm col-md">
              <div class="table-responsive">
                <b-container v-if="state.loading || state.currentp">
                  <a-skeleton
                    active
                    :loading="state.loading || state.currentp"
                    :paragraph="{ rows: 8 }"
                  ></a-skeleton>
                </b-container>
                <b-table
                  v-else
                  table-class="table-approvers text-13 indent-10"
                  striped
                  hover
                  thead-tr-class="tb-header"
                  tbody-class="tb-body indent-10"
                  :items="logframes"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  show-empty
                  :fields="headers"
                >
                  <template v-slot:cell(name)="data">
                    <span> <a @click="redirectData(data.item)" class="link-text"> {{data.item.name}} </a> </span>
                  </template>
                  <template v-slot:cell(Level)="data">
                    <b-row>
                      <span> {{ data.item.TOC.level }}</span>
                    </b-row>
                  </template>
                  <template v-slot:cell(Element)="data">
                    <b-row>
                      <span>{{ data.item.TOC.element }}</span>
                    </b-row>
                  </template>
                  <template v-slot:cell(currentMilestone)="data">
                    <ul v-for="(item,i) in data.item.currentMilestone" :key="i">
                      <li><b>Milestone:</b> {{item.Milestone}}</li>
                      <li><b>Achieved:</b> {{item.achieved}}</li>
                    </ul>
                  </template>
                </b-table>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm-8"></div>
            <div class="col-sm">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="text-13"
              ></b-pagination>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import encode from 'jwt-encode';
export default {
  props: {
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      pageTitle:"Program Indicators",
      state: {
        loading: false,
        currentp: false,
      },
      logframes: [],
      currentPage: 1,
      perPage: 50,
      filter: "",
      headers: ["name", "Level", "Element", "baseline","currentMilestone"],
      levels:[]
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    program() {
      return this.$store.state.current_program;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    rows() {
      return this.logframes ? this.logframes.length : 0;
    },
    refinedIndicators() {
      return this.logframes.filter((item, index) => {
        return this.logframes.indexOf(item.TOC.id) == index;
      });
    },
    remDuplication() {
      var uniqueArr = this.logframes.filter((v,i,a)=>a.findIndex(t=>(t.TOC.name === v.TOC.name && t.TOC.name===v.TOC.name))===i)
      return uniqueArr;
    },
  },
  beforeMount() {
    this.getCurrentProgram();
  },
  methods: {
    redirectData(data){
      var key = "my-secret-key88******[[][22@@@hjhs";
      const jwt = encode({data: data}, key);
      sessionStorage.setItem("indicator-data",jwt)
      this.$router.push({name: 'indicator-contrib-prog',params: {id: data.id}})
    },
    async getProgramLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getTOClevelsAll/${this.program.id}`, config)
        .then((response) => {
          if (response.data) {
            this.levels=response.data;
            this.state.loading = false;
            this.$store.dispatch("setProgramToc",response.data);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.currentp = true;
      await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if (response.data) {
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
            this.getLogFrames();
            this.getProgramLevels();
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
    refresh() {
      this.state.refresh = true;
      this.getLogFrames();
    },
    async getLogFrames() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getLogframe/${this.program.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.logframes = response.data;
            console.log("Logframe", this.logframes)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.progress-wrapper {
  width: 260px !important;
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  background: #f3f3f3 !important;
}

.card-pillars {
  background: #3fb15d21 !important;
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}

.label-remain h6 {
  font-size: 12px !important;
  margin-right: 10px !important;
}

.label-completed h6 {
  font-size: 12px !important;
  margin-left: 10px !important;
}

.top-texts {
  font-size: 13px !important;
}

.knowledge-text {
  color: #001 !important;
  font-size: 15px !important;
}

.avatar {
  width: 60px;
  height: 60px;
  background: #eeeeee !important;
}

.report-status h6 {
  color: #e5e5e5 !important;
}

.price-1 button {
  background-color: #8dd070 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.price-2 button {
  background-color: #cecf71 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.price-3 button {
  background-color: #ffcdd3 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.row1 {
  border-left: 6px solid #8dd070 !important;
  border-spacing: 4px !important;
}

.row2 {
  border-left: 6px solid #cecf71 !important;
  border-spacing: 4px !important;
}

.row3 {
  border-left: 6px solid #ffcdd3 !important;
  border-spacing: 4px !important;
}

.card {
  text-align: center !important;
}

.dotted {
  background: #000000 !important;
  border-radius: 2px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  color: #fff !important;
}

@media screen and (max-width: 578px) {
  .big-border {
    width: 54.5px;
    height: 5px;
    background: #cecf71 !important;
  }

  .date-top-text {
    color: #000 !important;
  }

  .user-info,
  .report-status {
    width: 150px !important;
  }
}
</style>