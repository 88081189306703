<template>
  <b-row>
    <div class="col-sm col-md text-left main-wrapper col-lg">
      <b-container class="my-4" v-if="state.loading">
        <a-skeleton :loading="state.loading" :paragraph="{rows: 8}" active></a-skeleton>
      </b-container>
      <form v-else>
        <b-row>
          <div class="col-sm col-md-6">
            <div class="form-group">
              <label>Code</label>
              <b-input
                placeholder="Project unique code"
                type="text"
                maxlength="6"
                class="form-control"
                no-outer-focus
                v-model="code"
              />
              <small v-if="validations.code" class="text-danger">
                {{`The ${getKeyword('project')} code is required.`}}
              </small>
            </div>

            <div class="form-group">
              <label>
                {{`${capitalizeFirstLetter(getKeyword('project'))} title`}}
              </label>
              <input
                v-bind:class="validations.title ? 'is-invalid' : null"
                @keyup="onChangeTitle"
                placeholder="Enter title"
                type="text"
                class="form-control"
                no-outer-focus
                v-model="title"
              />
              <small v-if="validations.title" class="text-danger">
                {{`The ${getKeyword('project')} title is required.`}}
              </small>
            </div>

            <!-- Pillar -->
            <div class="form-group">
              <label>{{`Project ${getKeyword('pillar')}`}}</label>
              <b-input-group>
                <b-select
                  class="custom-select bg-white text-13 mr-2"
                  v-model="pillar"
                  required
                  :disabled="state.loadPillars"
                  v-bind:class="validations.pillar ? 'is-invalid' : null"
                  @change="onChangePillar"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled>
                      {{`Select ${getKeyword('project')} ${getKeyword('pillar')}`}}
                    </b-select-option>
                  </template>
                  <b-select-option
                    v-for="item in pillars"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.Title }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right"
                    font-scale="1.5"
                    :animation="state.loadPillars ? 'spin' : ''"
                    @click="getPillars"
                  />
                </b-input-group-append>
              </b-input-group>
              <small v-if="validations.pillar" class="text-danger">
                {{`The ${getKeyword('project')} ${getKeyword('pillar')} must be selected.`}}
              </small>
            </div>

            <!-- Project manager -->
            <div class="form-group">
              <label>{{`${getKeyword('project')} Manager`}}</label>
              <b-input-group>
                <b-select
                  class="custom-select bg-white text-13 mr-2"
                  v-model="pm"
                  required
                  :disabled="state.loadPm"
                  v-bind:class="validations.pm ? 'is-invalid' : null"
                  @change="onChangePm"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled>
                      <label>{{`Select ${getKeyword('project')} manager`}}</label>
                    </b-select-option>
                  </template>
                  <b-select-option
                    v-for="item in pms"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.fullnames }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right"
                    font-scale="1.5"
                    :animation="state.loadPm ? 'spin' : ''"
                    @click="getPMs"
                  />
                </b-input-group-append>
              </b-input-group>
              <small v-if="validations.pm" class="text-danger">
                {{`${getKeyword('project')} manager must be selected.`}}
              </small>
            </div>

            <!-- Description -->
            <b-form-group class="form-text-label" label-for="desc">
                <label for="desc">{{`${capitalizeFirstLetter(getKeyword('project'))} Description`}}
              </label>
              <b-form-textarea
                v-bind:class="validations.description ? 'is-invalid' : null"
                @keyup="onChangeDescription"
                id="desc"
                placeholder="Enter description"
                rows="3"
                resize
                class="form-text"
                v-model="description"
              ></b-form-textarea>
              <small v-if="validations.description" class="text-danger">
                {{`Enter ${getKeyword('project')} description`}}
              </small>
            </b-form-group>

            <!-- Approval date -->
            <div class="form-group">
              <label for="">Approval Date</label>
              <input type="date" v-model="approval_date" class="form-control">
            </div>
          </div>
          <div class="col-sm col-md-6">
             <b-form-group
              label="Implementation Partner">
              <b-input-group>
                <v-select
                  label="name"
                  class="bg-white text-13 w-90 mr-4"
                  placeholder="Select IP Institution"
                  multiple
                  v-model="selectedInstitutions"
                  :options="IPInstitutions" />
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right ml-n3"
                    font-scale="1.5"
                    :animation="state.loading ? 'spin' : ''"
                    @click="getIpInstitutions"
                  />
                </b-input-group-append>
              </b-input-group>
              
              <small v-if="validations.ip" class="text-danger">
              Select implemeting partner.
              </small>
             </b-form-group>
            <div class="form-group">
              <label class="float-left">IP Users</label>
              <b-input-group>
                <v-select label="fullnames" class="w-100" placeholder="Select IP User" multiple v-model="selectedIPUsers" :options="IPUsers" />
              </b-input-group>
            </div>

            <!-- Timeline -->
            <div class="form-group">
              <label for="">Timeline</label>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>From</label>
                    <input type="date" v-model="from_date" class="form-control">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>To</label>
                    <input type="date" v-model="to_date" class="form-control" >
                  </div>
                </div>
              </div>
            </div>

            <!-- Extensions -->
            <div class="form-group">
              <label for="">Extensions</label>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>First Extension</label>
                    <input type="date" v-model="extensions.first" class="form-control">
                  </div>
                  <div class="form-group">
                    <label>Second Extension</label>
                    <input type="date" v-model="extensions.second" class="form-control">
                  </div>
                  <div class="form-group">
                    <label>Third Extension</label>
                    <input type="date" v-model="extensions.third" class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-row>
      </form>
    </div>
  </b-row>
</template>

<script>
import encode from "jwt-encode";
import decode from "jwt-decode";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  props: ["store"],
  components: {
    vSelect,
  },
  data() {
    return {
      email: "",
      options: [],
      value: [],
      ips: [],
      IPInstitutions: [],
      IPUsers: [],
      selectedIPUsers: [],
      selectedInstitutions: [],
      ipUsersLookup: {},
      pms: [],
      ipData: [],
      state: {
        loading: false,
        loadPillars: false,
        loadPm: false,
      },
      selectedItem: [],
      pm: null,
      pillars: [],
      pillar: null,
      title: null,
      code: null,
      extensions:{
        first: null,
        second: null,
        third: null,
      },
      description: null,
      from_date: null,
      to_date: null,
      approval_date: null,
      validations: {
        ip: false,
        pm: false,
        pillar: false,
        description: false,
        title: false,
      },
      isSaved: false,
      data: null,
    };
  },
  computed: {
    availableOptions() {
      return this.options.filter((opt) => this.value.indexOf(opt) === -1);
    },
    token() {
      return localStorage.getItem("token");
    },
    currentSaved() {
      const data = sessionStorage.getItem("project");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.project ? this.project : decoded;
    },
    project() {
      return this.$store.state.project_data
        ? this.$store.state.project_data
        : null;
    },
    drafted(){
      var decoded = null;
      const storeData = sessionStorage.getItem("drafted-project")
      if(storeData){
        decoded = decode(storeData)
      }
      return decoded;
    },
    projectStore(){
      return this.currentSaved ? this.currentSaved : this.drafted ? this.drafted : this.drafted;
    },
    projectID(){
      return this.drafted ? this.drafted : this.currentSaved ? this.currentSaved.PojectData : null;
    }
  },
  watch: {
    value() {
      this.value.forEach((item) => {
        this.selectedItem = item;
      });
      this.ips.forEach((i2) => {
        if (i2.fullnames === this.selectedItem) {
          this.ipData.push({ id: i2.id });
        }
      });
    },
    selectedInstitutions(){
      let users = []
      if(!this.selectedInstitutions) return null
      this.selectedInstitutions.forEach(institution => {
        if(institution.users) users.push(...institution.users)
      })
      console.log("change", users)
      this.IPUsers = users
    },
    selectedIPUsers(){
      if(this.selectedIPUsers){
         this.validations.ip = false;
      }else{
         this.validations.ip = true;
      }
     
    }
  },
  beforeMount() {
    this.getPillars();
    this.getPMs();
    this.getIps();
    this.getIpInstitutions();
    this.getCurrentSaved();
    if(this.drafted){
      this.getProjectDetails();
    }
  },
  methods: {
    async getProjectDetails() {
      this.state.loading = true;
      await this.axios
        .get(`getProject/${this.drafted.id}`)
        .then((response) => {
            this.state.loading = false;
            this.data = response.data;
            this.title = this.data.Title;
            this.code = this.data.code;
            this.pillar = this.data.pillarId;
            this.from_date = this.data.from_date;
            this.extensions.first = this.data.extension1;
            this.extensions.second = this.data.extension2;
            this.extensions.third = this.data.extension3;
            this.to_date = this.data.to_date;
            this.approval_date = this.data.approval_date;
            this.description = this.data.Description;
            this.pm = this.data.projectManager.id;
            this.selectedIPUsers = this.data.projectIp
            this.selectedInstitutions = this.data.projectIPInstitutions
            // this.data.projectIp.map((item) => {
            //   this.value.push(`${item.name}`);
            // });
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    onChangePm() {
      if (!this.pm) {
        this.validations.pm = true;
      } else {
        this.validations.pm = false;
      }
    },
    onChangeIp() {
      // if (!this.value.length) {
      //   this.validations.ip = true;
      // } else {
      //   this.validations.ip = false;
      // }
    },
    onChangePillar() {
      if (!this.pillar) {
        this.validations.pillar = true;
      } else {
        this.validations.pillar = false;
      }
    },
    onChangeTitle() {
      if (!this.title) {
        this.validations.title = true;
      } else {
        this.validations.title = false;
      }
    },
    onChangeDescription() {
      if (!this.description) {
        this.validations.description = true;
      } else {
        this.validations.description = false;
      }
    },
    async getIpInstitutions() {
      this.state.loading = true;
      await this.axios
        .get(`institutions/implementing_partners`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.IPInstitutions = response.data?.data??[];
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getIps() {
      this.state.loading = true;
      await this.axios
        .get(`getiIps`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.ips = response.data;
            this.ips.map((item) => {
              this.options.push(`${item.fullnames}`);
            });
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getPMs() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadPm = true;
      await this.axios
        .get(`getProjectManagers`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadPm = false;
            this.pms = response.data;
          } else {
            this.state.loadPm = false;
          }
        })
        .catch(() => {
          this.state.loadPm = false;
        });
    },
    async getPillars() {
      this.state.loadPillars = true;
      await this.axios
        .get(`getPillarsInProgram`)
        .then((response) => {
          if (response.data) {
            this.state.loadPillars = false;
            this.pillars = response.data;
          } else {
            this.state.loadPillars = false;
          }
        })
        .catch(() => {
          this.state.loadPillars = false;
        });
    },
    getCurrentSaved() {
      this.title =
        this.currentSaved !== null ? this.currentSaved.data.Title : "";
      this.pillar =
        this.currentSaved !== null ? this.currentSaved.data.pillarId : null;
      this.description =
        this.currentSaved !== null ? this.currentSaved.data.Description : null;
      this.pm =
        this.currentSaved !== null
          ? this.currentSaved.data.projectManager
          : null;
      this.ipData =
        this.currentSaved !== null ? this.currentSaved.data.ProjectIps : [];
      this.isSaved =
        this.currentSaved !== null ? this.currentSaved.isSavedStep1 : false;
    },
    nextStep() {
      if (!this.title) {
        this.$emit("error", "Project title is required.");
        this.validations.title = true;
        this.$message.error("Project title is required.");
      } else if (!this.pillar) {
        this.$emit("error", "Project pillar field is required.");
        this.validations.pillar = true;
        this.$message.error("Project pillar field is required.");
      } else if (!this.description) {
        this.$emit("error", "Project description field is required.");
        this.validations.description = true;
        this.$message.error("Project description field is required.");
      } else if (!this.selectedIPUsers.length) {
        this.$emit("error", "Select implementing partiner is required.");
        this.validations.ip = true;
        this.$message.error("Select implementing partiner is required.");
      } else if (!this.pm) {
        this.$emit("error", "Project manager is required.");
        this.validations.pm = true;
        this.$message.error("Project manager is required.");
      } else {
        const requestData = {
          Title: this.title,
          code: this.code,
          pillarId: this.pillar,
          Description: this.description,
          from_date: this.from_date,
          to_date: this.to_date,
          approval_date: this.approval_date,
          projectManager: this.pm,
          ProjectIps: this.selectedIPUsers,
          extensions: this.extensions,
        };
        if (this.isSaved || this.drafted) {
          console.log(this.data)
          this.$emit("loading", true);
          this.axios.put(`project/update/${this.data.id}`, requestData)
            .then((response) => {
              if (response.data) {
                const currentSavedReq = {
                  data: requestData,
                  isSavedStep1: true,
                  PojectData: response.data,
                };
                this.$emit("loading", false);
                const secret = "tokenHashingProDet--afr0-1200x";
                const jwt = encode(currentSavedReq, secret);
                sessionStorage.setItem("project", jwt);
                this.$store.dispatch("setProject", currentSavedReq);
                this.getCurrentSaved();
                this.$emit("can-continue");
                this.$notification["success"]({
                  message: "SUCCESS!",
                  description: `Project details data saved.`,
                  duration: 5,
                });
              } else {
                this.$emit("loading", false);
                this.$message.error(
                  `An error occured while saving program details`,
                  4
                );
              }
            })
            .catch((error) => {
              this.$notification["error"]({
                message: "ERROR!",
                description: `${error.errorMessage}`,
                duration: 7,
              });
              this.$emit("loading", false);
            });

          // this.$emit("can-continue");
        } else {
          this.$emit("loading", true);
          this.axios.post("project/create", requestData)
            .then((response) => {
              if (response.data) {
                const currentSavedReq = {
                  data: requestData,
                  isSavedStep1: true,
                  PojectData: response.data,
                };
                this.$emit("loading", false);
                const secret = "tokenHashingProDet--afr0-1200x";
                const jwt = encode(currentSavedReq, secret);
                sessionStorage.setItem("project", jwt);
                this.$store.dispatch("setProject", currentSavedReq);
                this.getCurrentSaved();
                this.$emit("can-continue");
                this.$notification["success"]({
                  message: "SUCCESS!",
                  description: `Project details data saved.`,
                  duration: 5,
                });
              } else {
                this.$emit("loading", false);
                this.$message.error(
                  `An error occured while saving program details`,
                  4
                );
              }
            })
            .catch((error) => {
              this.$notification["error"]({
                message: "ERROR!",
                description: `${error.errorMessage}`,
                duration: 7,
              });
              this.$emit("loading", false);
            });
        }
      }
    },
  },
};
</script>

<style scoped>
.main-wrapper .form-group label,
.main-wrapper .form-group input::placeholder,
.form-text,
.form-text::placeholder,
.form-text-label,
.form-control,
.custom-select {
  font-size: 13px !important;
}
</style>
