<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto main col-md-10 pt-0">
      <div class="card card-main card-0 mt-4 mb-6 ml-0">
        <div class="card-body pl-0 ml-0">
          <b-row>
            <div class="col-sm-9"></div>
            <div class="col-sm">
              <form>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control input-search rounded-left-10"
                    aria-label="Search"
                    placeholder="Search ..."
                    v-model="filter"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right-10">
                      <i class="bx bx-search text-success"></i>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </b-row>
          <b-row class="mb-2">
            <div class="col-sm-9 col-md-9"></div>
            <div class="col-sm col-md text-right">
                <button
                @click="refresh"
                type="button"
                class="btn btn-secondary btn-sm rounded-10 border-none"
                >
                <b-container v-if="state.loading && state.refresh">
                    <b-spinner small></b-spinner>
                    <span class="text-13 ml-2">Refreshing...</span>
                </b-container>
                <span v-else class="text text-13">Refresh</span>
                </button>
            </div>
            </b-row>
          <b-row>
            <div class="col-sm col-md">
              <div class="table-responsive">
                <b-container v-if="state.loading">
                    <a-skeleton active :loading="state.loading" :paragraph="{rows: 8}" ></a-skeleton>
                </b-container>
                <b-table
                  v-else
                  table-class="table-approvers text-13 indent-10"
                  striped
                  hover
                  thead-tr-class="tb-header"
                  tbody-class="tb-body indent-10"
                  :items="approvers"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="headers"
                  show-empty
                >
                  <template v-slot:cell(#)="data">
                    {{data.index + 1}}
                  </template>
                  <template v-slot:cell(Title)="data">
                    <span>
                      {{data.item.Title}}
                    </span>
                  </template>
                  <template v-slot:cell(type)="data">
                      {{capitalizeFirstLetter(data.value)}}
                  </template>

                  <template v-slot:cell(workflow)="data">
                    <router-link :to="{
                      name: 'approvers-list',
                      params:{
                        element_id: data.item.id,
                        element_type: data.item.Type
                      }
                    }">{{data.item.workflowName}}</router-link>
                  </template>
                  <template v-slot:cell(status)="data">
                    <button
                      v-if="data.item.status == 'pending' || data.item.status == 'submitted'"
                      class="btn btn-sm btn-info text-white text-13"
                    >
                      Pending
                    </button>
                    <button
                      v-else-if="data.item.status == 'rejected'"
                      class="btn btn-sm btn-rose text-white text-13"
                    >
                      Rejected
                    </button>
                    <button
                      v-else-if="data.item.status == 'approved'"
                      class="btn btn-sm btn-success text-white text-13"
                    >
                     <i class="bx bx-check-double"></i>  Approved
                    </button>
                  </template>
                  <template
                    class="justify-content-center"
                    v-slot:cell(actions)="data"
                  >
                    <button
                      v-if="data.item.Type=='report'"
                      @click="viewReport(data.item)"
                      class=" btn btn-sm btn-success btn-report
                        text-11
                        btn-report-completed
                        text-white
                        pl-0
                        pr-0
                        pt-0
                        pb-0
                        rounded-20
                        mr-2
                      "
                    >
                      View report
                    </button>

                    <a-tooltip v-if="data.item.status=='pending' && data.item.is_approver" placement="bottom">
                      <template slot="title">
                        <span>Approve request</span>
                      </template>
                      <button
                       @click="showApproveModal(data.item)"
                        v-if="data.item.status=='pending'"
                        class="btn btn-sm btn-outline-success mr-2"
                      >
                        <i class="bx bx-check h text-bold"></i>
                      </button>
                    </a-tooltip>
                    <a-tooltip v-if="data.item.status=='submitted' && data.item.is_approver" placement="bottom">
                      <template slot="title">
                        <span>Approve request</span>
                      </template>
                      <button
                       @click="showApproveModal(data.item)"
                        v-if="data.item.status == 'submitted'"
                        class="btn btn-sm btn-outline-success mr-2"
                      >
                        <i class="bx bx-check text-bold"></i>
                      </button>
                    </a-tooltip>
                    
                    <a-tooltip v-if="data.item.status=='pending' && data.item.is_approver" placement="bottom">
                      <template slot="title">
                        <span>Reject request</span>
                      </template>
                      <button
                        @click="showRejectModal(data.item)"
                        v-if="data.item.status == 'pending'"
                        class="btn btn-sm btn-outline-danger ml-2"
                      >
                        <i class="bx bx-x"></i>
                      </button>
                    </a-tooltip>
                    <a-tooltip v-if="data.item.status=='submitted' && data.item.is_approver" placement="bottom">
                      <template slot="title">
                        <span>Reject request</span>
                      </template>
                      <button
                        @click="showRejectModal(data.item)"
                        v-if="data.item.status == 'submitted'"
                        class="btn btn-sm btn-outline-danger ml-2"
                      >
                        <i class="bx bx-x"></i>
                      </button>
                    </a-tooltip>
                  </template>
                </b-table>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm-8"></div>
            <div class="col-sm">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="text-13"
              ></b-pagination>
            </div>
          </b-row>
        </div>
      </div>
    </main>
    <a-modal v-model="visibleApprove" :footer="null" title="Approve Request">
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to approve this request ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <a-popconfirm
            title="Are you sure for this approval ?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="Approve(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-success btn-block btn-confirm-approve"
            >
              <b-container v-if="state.approve">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Waiting...</span>
              </b-container>
              <span v-if="!state.approve" class="text text-13">APPROVE</span>
            </button>
          </a-popconfirm>
        </div>
        <div class="inline-block">
          <button
            @click="visibleApprove=false"
            type="submit"
            class="btn btn-secondary btn-block text-13 btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
      </b-row>
    </a-modal>
    <a-modal v-model="visibleReject" :footer="null" title="Reject Approval Request">
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to reject this request ?
        </h6>
      </b-row>
      <b-row>
        <b-col>
          <div class="form-group">
            <label>Rejection reason</label>
            <b-form-textarea
              id="desc"
              placeholder="Enter rejection reason"
              rows="1"
              resize
              class="form-text-modal"
              v-model="rejection_message"
            ></b-form-textarea>
          </div>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center">
        
        <div class="inline-block pr-4">
          <button
            @click="visibleReject=false"
            type="submit"
            class="btn btn-secondary btn-block text-13 btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
        <div class="inline-block ">
          <a-popconfirm
            title="Are you sure for this rejection ?"
            cancel-text="No"
            ok-text="Yes"
            @confirm="Reject(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="state.reject">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Waiting...</span>
              </b-container>
              <span v-if="!state.reject" class="text text-13">REJECT</span>
            </button>
          </a-popconfirm>
        </div>
      </b-row>
    </a-modal>
  </div>
</template>

<script>
import encode from 'jwt-encode';
import decode from 'jwt-decode';
import arrowIcon from "../../components/icons/arrow.vue";
export default {
  component: {
    arrowIcon,
  },
  data() {
    return {
      pageTitle: "approvals",
      headers: [
        "#",
        "Title",
        "Type",
        "workflow",
        "status",
        "actions"
      ],
      perPage: 6,
      currentPage: 1,
      filter: "",
      approvers: [],
      rejection_message: null,
      state: {
          loading:false,
          refresh: false,
          approve: false,
          reject: false
      },
      data: null,
      visibleApprove: false,
      visibleReject: false,
    };
  },
  beforeMount() {
    this.$title("Approvals");
    this.fetchApprovals()
  },
  computed: {
    rows() {
      return this.approvers.length;
    },
     program() {
      let project = sessionStorage.getItem("prg");
      const resData = decode(project);
      return resData;
    },
    token() {
      return localStorage.getItem("token");
    },
  },
  methods: {
     viewReport(param) {
      const secret = "tokenHashingViv--afr0-101--12=+.dfeo33@##";
      const jwt = encode(param, secret);
      sessionStorage.setItem("get-report", jwt);
      this.$router.push({ name: 'view-report' });
    },
    goToApproval(param) {
      const secret = "tokenHashingViv--afr0-101--12=+.dfeo33@##";
      const jwt = encode(param, secret);
      sessionStorage.setItem("approval", jwt);
      this.$store.dispatch("setApproval", param);
      this.$router.push({ name: 'approvers-list' });
    },
    async fetchApprovals() {
      this.state.loading = true;
      await this.axios
        .get(`getApprovals`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.approvers = response.data;
            console.log("Approvers", this.approvers)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async Approve(param) {
      const reqData = {
        elementId: param.id,
        status :"approved",
        elementType : param.Type
      }
      this.state.approve = true;
      await this.axios
        .post(`Approve`, reqData)
        .then((response) => {
          this.state.approve = false;
          if (response.data.message) {
              this.$notification["error"]({
              message: "NOT APPROVED!",
              description: `${response.data.message}`,
              duration: 5,
            });
            this.visibleApprove=false;
            this.state.approve = false; 
          } else {
            this.state.approve = false;
            this.visibleApprove=false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Approved successful.`,
              duration: 5,
            });
            this.fetchApprovals();
          }
        })
        .catch(() => {
          this.state.approve = false;
        });
    },
     async Reject(param) {
      const reqData = {
        elementId: param.id,
        status :"rejected",
        message: this.rejection_message,
        elementType : param.Type
      }
      this.state.reject = true;
      await this.axios
        .post(`Approve`, reqData)
        .then((response) => {
          this.state.reject = false;
          if (response.data.message) {
              this.$notification["error"]({
                message: "ERROR!",
                description: `${response.data.message}`,
                duration: 5,
              });
              this.visibleReject=false;
              this.state.approve = false;
          } else {
            this.state.reject = false;
            this.visibleReject=false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Request is rejected successful.`,
              duration: 5,
            });
            this.fetchApprovals();
          }
        })
        .catch(() => {
          this.state.reject = false;
        });
    },
    showApproveModal(data){
      this.visibleReject=false;
      this.visibleApprove = true;
      this.data=data;
    },
    showRejectModal(data){
      this.visibleApprove=false;
      this.visibleReject=true;
      this.data=data;
    },
    refresh(){
        this.state.refresh=true;
        this.fetchApprovals();
    },
    cancel(e) {
      console.log(e);
      this.visibleApprove = false;
      this.visibleReject=false;
      this.$message.error("Request has been cancelled.");
    },
  }
};
</script>

<style scoped lang="scss">
</style>