export default {
    namespaced: true,
    state: () => ({
        activeTab: null,
        isActive: false,
        logFrameTab: false,
        data: null
    }),
    mutations: {
        mutateUserData(state, param){
            state.data = param;
        },
        mutateNavbarTabs(state, param){
            state.activeTab = param;
        },
        mutateSidebar(state, data){
            state.isActive = data;
        },
        mutateLogFrameTab(state,param){
            state.logFrameTab=param;
        }
    },
    actions: {
        setUserData(context,payload){
            context.commit("mutateUserData", payload)
        },
        setNavBarActiveTab(context, payload){
            context.commit("mutateNavbarTabs", payload);
        },
        setSidebarAction(context, payload){
            context.commit("mutateSidebar", payload);
        },
        setLogFrameTab(context,payload){
            context.commit("mutateLogFrameTab", payload);
        }
    },
    getters: {}
}