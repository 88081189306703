<template>
  <b-row>
    <b-col>
      <h6 class="text-13">Insert Disaggregation</h6>
      <div class="b-bottom w-25 my-2"></div>
      <b-form @submit.prevent="addAggregation">
        <b-row>
          <b-col class="my-4">
            <label>Disaggregation name</label>
            <b-form-group>
              <input
                type="text"
                placeholder="Disaggregation"
                class="form-control"
                v-model="title"
                required
              />
              <a-tooltip title="Add disaggregation elements" placement="bottom">
                <span class="cursor-pointer">
                  <i
                    class="fas fa-plus-circle mt-2 bx-sm text-success"
                    @click="add(k)"
                  ></i>
                </span>
              </a-tooltip>
            </b-form-group>
            <div class="form-group my-2" v-for="(input, k) in inputs" :key="k">
            <input :required="inputs.length" type="text" :placeholder="`Enter disaggregation element ${k+1}`" class="form-control" v-model="input.Title" />
              <span class="my-4 cursor-pointer">
                <i
                  class="fas fa-minus-circle bx-sm mt-2 text-danger"
                  @click="remove(k)"
                ></i>
              </span>
            </div>
            <b-form-group>
              <button
                type="submit"
                class="btn btn-primary rounded-20 float-left btn-sm"
              >
                <b-container v-if="state.adding">
                  <b-spinner small></b-spinner>
                  <span class="text-13 ml-2">Saving...</span>
                </b-container>
                <span v-if="!state.adding" class="text text-13">Submit</span>
              </button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <b-col>
      <h6 class="text-13">Program Disaggregation</h6>
      <div class="b-bottom w-25 my-2"></div>
      <b-row class="my-2">
        <div class="col-sm col-md"></div>
        <div class="col-sm-8 col-md-8">
          <form>
            <div class="input-group mb-2">
              <input
                type="search"
                v-model="filter"
                class="form-control input-search rounded-left-10"
                aria-label="Search"
                placeholder="Search Disaggregations..."
              />
              <div class="input-group-append">
                <span class="input-group-text rounded-right-10">
                  <i class="bx bx-search text-success"></i>
                </span>
              </div>
            </div>
          </form>
        </div>
      </b-row>
      <b-row class="mb-2">
        <div class="col-sm-9 col-md-9"></div>
        <div class="col-sm col-md text-right">
          <button
            @click="refresh"
            type="button"
            class="btn btn-secondary btn-sm rounded-10 border-none"
          >
            <b-container v-if="state.loading && state.refresh">
              <b-spinner small></b-spinner>
              <span class="text-13 ml-2">Refreshing...</span>
            </b-container>
            <span v-else class="text text-13">Refresh</span>
          </button>
        </div>
      </b-row>
      <div class="table-responsive mt-2">
        <b-container v-if="state.loading">
          <a-skeleton
            :loading="state.loading"
            :paragraph="{ rows: 4 }"
            active
          ></a-skeleton>
        </b-container>
        <b-table
          v-else
          table-class="table table-knowledge rounded-20"
          thead-tr-class="theader-knowledge tb-head text-left indent-20"
          tbody-class="tb-body text-left indent-20"
          :items="aggregations"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          show-empty
        >
          <template v-slot:cell(delete)="data">
            <a-popconfirm
              title="Are you sure delete this program disaggregation ?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="deleteAggregation(data.item)"
              @cancel="cancel"
          >
            <button class="btn btn-sm btn-light rounded-50">
              <span class="cursor-pointer"
              ><i class="fa fa-trash text-danger"></i
            ></span>
            </button>
          </a-popconfirm>
          </template>
          <template v-slot:cell(elements)="data">
              <ul>
                <li v-for="(elem,i) in data.item.elements" :key="i"> {{ elem.Title }} </li>
              </ul>
          </template>
        </b-table>
      </div>
      <b-row class="my-4">
        <div class="col-sm-8 col-md-8"></div>
        <div class="col-sm col-md">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
            prev-class="text-13"
            next-class="text-13"
            first-class="text-13"
            last-class="text-13"
          ></b-pagination>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import decode from 'jwt-decode'
export default {
  name: "aggregations-config",
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      aggregations: [],
      filter: "",
      fields: ["name","elements","delete"],
      state: {
        loading: false,
        refresh: false,
        adding: false,
      },
      inputs: [],
      title: "",
    };
  },
  computed: {
     programSaved() {
      return this.$store.state.program_data
        ? this.$store.state.program_data
        : null;
    },
    drafted() {
      const data = sessionStorage.getItem("draft");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return decoded;
    },
    program() {
      return this.$store.state.program_data
        ? this.$store.state.program_data
        : null;
    },
    programId() {
      const data = sessionStorage.getItem("requested");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.program ? this.program : decoded;
    },
    token() {
      return localStorage.getItem("token");
    },
    selectedID() {
      return this.drafted
        ? this.drafted.id
        : this.programId
        ? this.programId.programData.id
        : 0;
    },
    rows() {
      return this.keywords ? this.keywords.length : 0;
    },
    keyWordGetter() {
      return this.$store.getters.getCurrentPrograam
        ? this.$store.getters.getCurrentPrograam.keyWords
        : null;
    },
    keyWords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  beforeMount(){
    this.getAggregations()
  },
  methods: {
    add(index) {
      this.inputs.push({ Title: "" });
      console.log(index);
    },
    remove(index) {
      this.inputs.splice(index, 1);
    },
    refresh() {
      this.state.refresh=true;
      this.getAggregations()
    },
    cancel(e) {
      console.log(e);
      this.$message.error("Deleting aggregation has been canceled.");
    },
    async deleteAggregation(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.state.deleting = true;
      await this.axios
        .delete(`deleteAggregator/${param.id}`, config)
        .then((response) => {
          if (response) {
            this.state.deleting = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Program aggregation has been deleted successfully.`,
              duration: 4,
            });
            if (this.programId) {
              this.getAggregations();
            } else if (this.drafted) {
              this.getAggregations();
            }
          } else {
            this.state.deleting = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loading = false;
        });
    },
     getAggregations() {
      this.state.loading = true;
      this.axios
        .get(`getAggragator/${this.selectedID}`)
        .then((response) => {
          this.state.loading = false;
          this.aggregations = response.data;
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.state.loading = false;
        });
    },
    addAggregation(){
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        name: this.title,
        ProgramId:this.selectedID,
        fields: this.inputs
      };
      this.state.adding = true;
      this.axios
        .post("AddAggregator", requestData, config)
        .then((response) => {
          if (response.data.message) {
            this.state.adding = false;
            this.$message.error(`${response.data.message}`, 4);
            this.$notification["error"]({
              message: "ERROR!",
              description: `${response.data.message}`,
              duration: 5,
            });
          } else {
            this.state.adding = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Program aggregation added successfully.`,
              duration: 5,
            });
            this.getAggregations()
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
           this.state.adding = false;
        });
    }
  },
};
</script>

<style>
</style>