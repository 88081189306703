<template>
  <div>
    <b-row class="">
      <div class="col-sm col-md">
        <div class="card rounded-20 card-2 mt-2">
          <div class="card-body">
            <div class="d-flex justify-content-start">
              <div>
                <h6 class="knowledge-text ml-2 text-black">
                  Change Account Settings
                </h6>
                <div class="b-bottom ml-2 my-2"></div>
              </div>
            </div>
          </div>
          <b-row>
            <div class="col-sm-6 col-md-6">
              <section class="section container">
                <h6 class="knowledge-text ml-2 text-black text-left my-3">
                  <i class="fa fa-key" aria-hidden="true"></i> Password settings
                </h6>
                <b-form @submit.prevent="updatePassword">
                  <div class="form-group">
                    <label class="float-left text-muted text-12"
                      >New password</label
                    >
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control form-input"
                        v-model="password"
                        placeholder="New password"
                        v-if="showPassword"
                        required
                        v-bind:class="
                          validations.password
                            ? 'is-invalid'
                            : validations.length
                            ? 'is-invalid'
                            : null"
                        @keyup="onchangePassword"
                      />
                      <input
                        type="password"
                        class="form-control form-input"
                        placeholder="New password"
                        v-model="password"
                        required
                        v-else
                        v-bind:class="
                          validations.password
                            ? 'is-invalid'
                            : validations.length
                            ? 'is-invalid'
                            : null"
                        @keyup="onchangePassword"
                      />
                      
                      <div class="input-group-append rounded-right-10">
                        <span
                          @click="toggleShow"
                          class="input-group-text rounded-right-10"
                          id="basic-addon2"
                        >
                          <i
                            class="fas"
                            :class="{
                              'fa-eye-slash': showPassword,
                              'fa-eye': !showPassword,
                            }"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <small v-if="validations.password" class="text-danger">
                        Please enter password.
                      </small>
                      <small v-else-if="validations.length" class="text-danger">
                        Password must be at least six(6) characters long.
                      </small>
                  </div>
                  <div class="form-group">
                    <label class="float-left text-muted text-12"
                      >Confirm password</label
                    >
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control form-input"
                        v-model="password2"
                        placeholder="Confirm password"
                        v-if="showPassword2"
                        required
                        v-bind:class="
                          validations.password2
                            ? 'is-invalid'
                            : validations.match
                            ? 'is-invalid'
                            : null"
                        @keyup="onchangePassword2"
                      />
                      <input
                        type="password"
                        class="form-control form-input"
                        placeholder="Confirm password"
                        v-model="password2"
                        required
                        v-else
                        v-bind:class="
                          validations.password2
                            ? 'is-invalid'
                            : validations.match
                            ? 'is-invalid'
                            : null"
                        @keyup="onchangePassword2"
                      />
                      <div class="input-group-append rounded-right-10">
                        <span
                          @click="toggleShow2"
                          class="input-group-text rounded-right-10"
                          id="basic-addon2"
                        >
                          <i
                            class="fas"
                            :class="{
                              'fa-eye-slash': showPassword2,
                              'fa-eye': !showPassword2,
                            }"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <small v-if="validations.password2" class="text-danger">
                        Please confirm your password.
                    </small>
                    <small v-else-if="validations.match" class="text-danger">
                    Passwords don't match.
                    </small>
                  </div>
                  <b-form-group>
                    <button
                      :disabled="state.updating"
                      type="submit"
                      class="btn btn-success btn-confirm btn-sm btn-block"
                    >
                      <b-container v-if="state.updating">
                        <b-spinner small></b-spinner>
                        <span class="text-13 ml-2">Saving...</span>
                      </b-container>
                      <span v-if="!state.updating" class="text text-13"
                        >Save Changes</span
                      >
                    </button>
                  </b-form-group>
                </b-form>
              </section>
            </div>
          </b-row>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "change-settings-tab",
  data() {
    return {
      state: {
        loading: false,
        deleting: false,
        updating: false,
      },
      showPassword: false,
      showPassword2: false,
      password: null,
      password2: null,
      validations: {
        password: false,
        password2: false,
        length: false,
        match: false,
      },
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    profile() {
      return this.$store.state.user_data;
    },
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  mounted() {},
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    },
    onchangePassword() {
      if (this.password) {
        this.validations.password = false;
      } else {
        this.validations.password = true;
      }
      if (this.password.length > 5) {
        this.validations.length = false;
      } else {
        this.validations.length = true;
      }
    },
    onchangePassword2() {
      if (this.password2) {
        this.validations.password2 = false;
      } else {
        this.validations.password2 = true;
      }
      if (this.password == this.password2) {
        this.validations.match = false;
      } else {
        this.validations.match = true;
      }
    },
    async updatePassword() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        password: this.password,
      };

      this.state.updating = true;
      this.valid = false;
      if (!this.password) {
        this.validations.password = true;
        this.$message.error("Password field is required.");
      } else if (!this.password2) {
        this.validations.password2 = true;
        this.$message.error("Password confrimation is required.");
      } else if (this.password.length < 6) {
        this.validations.length = true;
        this.$message.error(
          "Password must be at least six(6) characters long."
        );
      } else if (this.password != this.password2) {
        this.validations.match = true;
        this.$message.error("Passwords don't match.");
      } else {
        await this.axios
          .put(`getUserProfile/${this.profile.user.id}`, requestData, config)
          .then((response) => {
            if (response) {
              this.state.updating = false;
              this.visible1 = false;
              this.$notification["success"]({
                message: "SUCCESS!",
                description: `Password changed successfully.`,
                duration: 5,
              });
            }
          })
          .catch((error) => {
            this.$notification["error"]({
              message: "ERROR!",
              description: `${error.errorMessage}`,
              duration: 3,
            });
            this.state.updating = false;
          });
      }
    },
  },
};
</script>

<style>
</style>