<template>
  <b-container class="mb-6">
    <b-row class="mr-4 first-child-card">
      <div class="col-sm-6 col-md-6 mt-2">
        <div class="card card-1">
          <div class="card-title">
            <h4 class="text-15 text-left p-3 pb-0">Project Description</h4>
          </div>
          <div class="card-body pt-2">
            <template v-if="projectDetails">
              <p class="text-left mb-3">
                {{project.Description}}
              </p>
              <p v-if="projectDetails">Approval date: <b><em>{{projectDetails.approval_date_text}}</em></b></p>
              <p>Project Manager: <b><em>{{projectDetails.projectManager.name}}</em></b></p>
              <p>Implementing Partners: <b><em>{{formatProjectIPs()}}</em></b></p>
              <p>Pillar: <b><em>{{projectDetails.pillar_name}}</em></b></p>
            </template>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-12">
        <b-row>
          <div class="col-sm col-md mt-2">
            <div class="card card-2 card-1">
              <div class="card-body">
                <div class="d-block card-amount-used">
                  <b-row>
                    <div class="col-sm mt-0 pt-0 col-md">
                      <div class="d-flex justify-content-start ml-4 mb-3">
                        <h6 class="title-h6 text-15">Project Timeline</h6>
                      </div>
                    </div>
                  </b-row>
                  <template v-if="projectDetails">
                    <div class="d-flex justify-content-start ml-2 mb-2 mt-5">
                      <span class="text-progress-timeline text-left"
                        >{{projectDetails.completion_percentage}}% to complete</span
                      >
                    </div>
                    <b-progress
                      height="18px"
                      class="ml-2 pt-0 progress-wrapper"
                      :max="100"
                    >
                      <b-progress-bar
                        style="background: #1fa7db !important"
                        class="af-progress"
                        :value="projectDetails.completion_percentage"
                        :label="`${projectDetails.completion_percentage.toFixed(2)}%`"
                      ></b-progress-bar>
                    </b-progress>
                    <div class="d-flex justify-content-between mt-2">
                      <span class="ml-2 text-below-timeline">{{projectDetails.from_date_text}}</span>
                      <span class="text-below-timeline">{{projectDetails.to_date_text}}</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm col-md mt-2" v-if="false">
            <div class="card card-1">
              <div class="card-title">
                <h4 class="text-15 text-center pt-3">Peformance</h4>
              </div>
              <div class="card-body pt-2">
                <div class="d-flex justify-content-center mb-2">
                  <b-img class="card-img-1" fluid :src="image1"></b-img>
                </div>
                <div class="d-flex justify-content-center">
                  <div class="border-remain"></div>
                  <div class="border-completed"></div>
                </div>
                <div class="d-flex justify-content-center">
                  <span class="label-remain"><h6>Remain</h6></span>
                  <span class="label-completed"><h6>Completed</h6></span>
                </div>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import decode from 'jwt-decode'
import image1 from "../../../../../assets/images/project-manager/project/project-dash-chart1.png";
export default {
  data() {
    return {
      image1: image1,
      visibleCreateComment: false,
      projectDetails: null,
    };
  },
  methods: {
    createCommentModal() {
      this.visibleCreateComment = true;
    },
    cancelCommentModal() {
      this.visibleCreateComment = false;
    },
    formatProjectIPs(){
      // just formatting the ips
      let ips = this.projectDetails.projectIPInstitutions??[];

      return ips.map(ip => ip.name).join(", ");
    }
  },
  computed: {
    project(){
      const storeData = sessionStorage.getItem("pjt")
      const projectData = this.$store.state.project_data;
      var decoded = null;
      if(storeData){
        decoded = decode(storeData)
      }
      let tempData = projectData ? projectData : decoded;
      return tempData
    }
  },
  mounted(){
    console.log(this.project)
     if(this.project.id){
        this.axios
        .get(`getProject/${this.project.id}`)
        .then((response) => {
            // this.state.loading = false;
            this.projectDetails = response.data
        })
        .catch(() => {
          // this.state.loading = false;
        });
      }
  },
};
</script>

<style scoped>
.af-progress {
  background: #1fa7db !important;
}
.status-btn {
  border-radius: 20px !important;
  width: 60% !important;
  height: 20px !important;
  padding-top: 0 !important;
  font-size: 11px !important;
}

.status-pending {
  color: #cecf71 !important;
}

.status-rejected {
  color: #fbbebe !important;
}
.btn-rejected {
  background-color: #1fa7db !important;
}
.btn {
  font-size: 12px !important;
}

.title-h6 {
  font-size: 13px !important;
  color: #69737f !important;
}
/* Progre bar */
.progress-wrapper {
  padding-top: -0.5% !important;
  margin-top: -0.5% !important;
}
.af-progress {
  background: #8dd070 !important;
}

.progress-remaining {
  color: #69737f !important;
  padding-top: 12px !important;
  text-align: center;
  margin-left: 5rem !important;
}

/* end for progressbar */

.btn-comment {
  background: #ffffff !important;
  border: 1px solid #e5e5e5 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  font-size: 13px !important;
}

.btn-proj {
  background: #cecf71 !important;
  border: 1px solid #cecf71 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  font-size: 13px !important;
}

.card-2 .card-img-1 {
  width: 120px !important;
  height: 120px !important;
}

.card-2 {
  border: none !important;
}

.card-1 {
  height: 100% !important;
  border-radius: 20px !important;
  border: 1px solid #69737f !important;
}

.card-3 {
  width: 75% !important;
  height: 100% !important;
  border-radius: 20px !important;
  border: 1px solid #0fa958 !important;
}

.card-4 {
  width: 75% !important;
  height: 100% !important;
  border-radius: 20px !important;
  border: 1px solid #fbbebe !important;
}

.card-amount-used h1,
.card-amount-used small {
  color: #fbbebe !important;
}

</style>