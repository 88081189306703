<template>
    <a-skeleton v-if="state.indicatorData" :loading="state.indicatorData" active :paragraph="{rows: 18}"/>
    <form @submit.prevent="updateIndicator" v-else>
      <b-row>
        <div class="col-sm col-md mt-n2 mb-3">
          <a-tooltip title="Refresh project data" placement="bottom">
            <button
              @click="refresh"
              type="button"
              class="
                btn
                mr-3
                btn-secondary btn-sm
                rounded-20
                float-right
                border-none
              "
            >
              <b-icon-arrow-repeat
                font-scale="1.6"
                :animation="state.refresh && state.loading ? 'spin' : ''"
              />
            </button>
          </a-tooltip>
        </div>
      </b-row>
      <b-row>
        <div class="col-sm-5 col-md-5 main-wrapper col-lg-5 mr-5">
          <div class="form-group">
            <label class="float-left text-13"
              >Alignment - Program,
              {{
                keywords
                  ? keywords.project
                    ? keywords.project
                    : "Projects"
                  : "Projects"
              }}
            </label>
            <select
              v-model="alignment"
              v-bind:class="validations.alignment ? 'is-invalid' : null"
              @change="onChangeAlignment"
              class="custom-select bg-white text-13"
              required
            >
              <option selected :value="null" disabled>
                Indicator Alignment
              </option>
              <option value="program">Program</option>
              <option value="project">
                {{
                  keywords
                    ? keywords.project
                      ? keywords.project
                      : "Project"
                    : "Project"
                }}
              </option>
            </select>
            <small v-if="validations.alignment" class="text-danger float-left">
              Indicator alignment must be selected.
            </small>
          </div>
          <div class="form-group">
            <input
              v-model="title"
              placeholder="Title"
              type="text"
              class="form-control"
              v-bind:class="validations.title ? 'is-invalid' : null"
              @keyup="onChangeTitle"
              required
            />
            <small v-if="validations.title" class="text-danger float-left">
              Title is required
            </small>
          </div>
          <div class="form-group">
            <b-input-group>
              <b-select
                class="custom-select bg-white text-13 mr-2"
                v-model="toclevel"
                required
                :disabled="state.loadTOClevels"
                v-bind:class="validations.toclevel ? 'is-invalid' : null"
                @change="onChangeToc"
                v-if="alignment == 'program'"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled
                    >Select program theory of change level
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in toclevels"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.level }}
                </b-select-option>
              </b-select>
              <b-select
                v-else
                class="custom-select bg-white text-13 mr-2"
                v-model="rclevel"
                required
                :disabled="state.loadRClevels"
                v-bind:class="validations.rclevel ? 'is-invalid' : null"
                @change="onChangeRc"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled>
                    {{
                      keywords
                        ? keywords.project
                          ? `Select ${keywords.project} result chain level`
                          : "Select project result chain level"
                        : "Select project result chain level"
                    }}
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in rclevels"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.level }}
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-icon-arrow-repeat
                  v-if="alignment == 'program'"
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loadTOClevels ? 'spin' : ''"
                  @click="getTOCLevels"
                />
                <b-icon-arrow-repeat
                  v-else
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loadRClevels ? 'spin' : ''"
                  @click="getRCLevels"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="validations.toclevel && alignment == 'program'"
              class="text-danger"
            >
              Program theory of change level must be selected.
            </small>
            <small
              v-else-if="validations.rclevel && alignment == 'project'"
              class="text-danger"
            >
              Result chain level must be selected.
            </small>
          </div>
          <!-- Elements -->
          <div v-if="alignment == 'project'" class="form-group">
            <b-input-group>
              <b-select
                class="custom-select bg-white text-13 mr-2"
                v-model="rcelement"
                required
                :disabled="state.loadRCelements"
                v-bind:class="validations.rcelements ? 'is-invalid' : null"
                @change="onChangeRcElement"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled>
                    {{
                      keywords
                        ? keywords.project
                          ? `Select ${keywords.project} result chain element`
                          : "Select project result element"
                        : "Select project result chain element"
                    }}
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in rcelements"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.element }}
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-icon-arrow-repeat
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loadRCelements ? 'spin' : ''"
                  @click="getRCLevels"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="validations.rcelement && alignment == 'project'"
              class="text-danger"
            >
              Result chain element must be selected.
            </small>
          </div>
          <!-- Elements end -->
          <div class="form-group">
            <b-input-group v-if="alignment == 'program'">
              <b-select
                class="custom-select bg-white text-13 mr-2"
                v-model="alignedIndicator"
                required
                :disabled="state.loadingLogFrame"
                v-bind:class="
                validations.alignedIndicator ? 'is-invalid' : null"
                @change="onChangeLogFrame"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled
                    >Select aligned Indicator(s)
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in logframes"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.Title }}
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-icon-arrow-repeat
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loadingLogFrame ? 'spin' : ''"
                  @click="getProgramIndicators"
                />
              </b-input-group-append>
            </b-input-group>
            <small v-if="validations.alignedIndicator" class="text-danger">
              Aligned indicator must be selected.
            </small>
          </div>
          <b-row>
            <label class="float-left ml-3">Indicator Aggregation</label>
            <div class="col-md-12 col-sm-12">
              <b-form-group label-for="tags-component-select">
                <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
                <b-form-tags
                  id="tags-component-select"
                  v-model="value"
                  size="lg"
                  class="mb-2 form-select-role"
                  add-on-change
                  no-outer-focus
                >
                  <template
                    v-slot="{ tags, inputAttrs, inputHandlers}"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-2"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-inline-item mt-1"
                      >
                        <b-form-tag
                          :title="tag"
                          variant="success text-13 pb-2"
                          :disabled="value[0] == 'none'"
                          no-remove
                        >
                          <span class="pr-2">{{ tag }}</span>
                            <i v-if="tag !='none'" @click="removeAggragator(tag)" class="bx bx-x cursor-pointer bx-sm"></i>
                          </b-form-tag
                        >
                      </li>
                    </ul>
                    <b-input-group>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                        :disabled="state.loadIndicator || availableOptions.length === 0"
                        :options="availableOptions"
                        class="mr-4"
                        v-bind:class="
                          validations.indicatorAggregation ? 'is-invalid' : null"
                        @change="onchangeAggregation"
                      >
                        <template #first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option disabled selected :value="null">
                            Select Disaggregation
                          </option>
                        </template>
                      </b-form-select>
                      <b-input-group-append>
                        <b-icon-arrow-repeat
                          class="bi-con-right ml-n3"
                          font-scale="1.5"
                          :animation="state.loadIndicator ? 'spin' : ''"
                          @click="getIndicatorTypes"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-if="validations.indicatorAggregation"
                      class="text-danger"
                    >
                      Indicator Aggregation is required.
                    </small>
                  </template>
                </b-form-tags>
              </b-form-group>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm col-md">
              <div class="form-group">
                <input
                  v-model="baseline"
                  placeholder="Baseline"
                  type="number"
                  class="form-control"
                />
              </div>
            </div>
          </b-row>
          <div class="form-group">
            <label class="float-left">Reporting responsiblity</label>
            <b-input-group>
              <b-select
                class="custom-select bg-white text-13 mr-2"
                v-model="repoters"
                required
                :disabled="state.loadUsers"
                v-bind:class="validations.repoters ? 'is-invalid' : null"
                @change="onChangeReporter"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled
                    >Select reporter
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in users"
                  :key="item.id"
                  :value="item.id"
                >
                  <span class="text-capitalize">{{ item.fullnames }}</span>
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-icon-arrow-repeat
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loadUsers ? 'spin' : ''"
                  @click="getUsers"
                />
              </b-input-group-append>
            </b-input-group>
            <small v-if="validations.repoters" class="text-danger">
              Reporter must be selected.
            </small>
          </div>
          <b-form-group class="form-text-label" label-for="desc">
            <label class="float-left" for="">Indicator definition</label>
            <b-form-textarea
              v-model="indicatorDefinition"
              id="desc"
              placeholder="Enter definition for indicator"
              rows="1"
              resize
              class="form-text"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="col-sm col-md col-lg">
          <b-row class="mt-3">
            <div class="col-sm-9 col-md-9">
              <div class="form-group">
                <label class="text-13 float-left">Data Collection Method</label>
                <select
                  v-bind:class="
                    validations.collectionMethods ? 'is-invalid' : null"
                  @change="onChangeDataCollection"
                  v-model="collectionMethods"
                  class="custom-select bg-white text-13"
                >
                  <option selected :value="null" disabled>
                    Data collection method
                  </option>
                  <option value="Data collection tools">
                    Data collection tools
                  </option>
                </select>
                <small v-if="validations.collectionMethods" class="text-danger">
                  Data collection method must be selected.
                </small>
              </div>
            </div>
          </b-row>
          <b-row class="mt-3">
            <div class="col-sm-9 col-md-9">
              <div class="form-group">
                <label class="text-13 float-left">Frequency</label>
                <select
                  v-bind:class="validations.frequency ? 'is-invalid' : null"
                  @change="onChangeFrequency"
                  v-model="frequency"
                  class="custom-select bg-white text-13"
                >
                  <option selected :value="null" disabled>Frequency</option>
                  <option class="text-capitalize" value="weekly">weekly</option>
                  <option class="text-capitalize" value="monthly">
                    monthly
                  </option>
                  <option class="text-capitalize" value="annualy">
                    annualy
                  </option>
                </select>
                <small v-if="validations.frequency" class="text-danger">
                  Data collection method must be selected.
                </small>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm col-md">
              <div class="form-group">
                <b-container v-if="state.milestones">
                  <a-skeleton
                    active
                    :paragraph="{ rows: 6 }"
                    :loading="state.milestones"
                  ></a-skeleton>
                </b-container>
                <b-row v-else>
                  <div class="col-sm col-md col-lg">
                    <div class="float-left">
                      <p class="text-left">Reporting Period</p>
                      <p>Target, Due date, value</p>
                    </div>
                  </div>
                  <b-col cols="10" sm="12" md="12">
                    <b-row v-for="(input, i) in inputs" :key="i" class="my-3">
                      <b-col>
                        <input
                          @keyup="onKeyInputs"
                          readonly
                          type="text"
                          v-model="input.title"
                          class="form-control"
                          disabled
                        />
                      </b-col>
                      <b-col>
                        <input
                          @keyup="onKeyInputs"
                          readonly
                          type="text"
                          v-model="input.Duedate"
                          class="form-control"
                          disabled
                        />
                      </b-col>
                      <b-col>
                        <input
                          @keyup="onKeyInputs"
                          type="number"
                          v-model="input.target"
                          class="form-control"
                          placeholder="Target"
                          required
                        />
                      </b-col>
                    </b-row>
                    <small
                      v-if="validations.target"
                      class="text-danger float-left d-none"
                    >
                      Project target is required.
                    </small>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-row>
          <b-row class="mt-3">
            <div class="col-sm col-md">
              <b-form-group
                class="form-text-label"
                label-for="learning-questions"
              >
                <b-form-textarea
                  id="learning-questions"
                  placeholder="Learning questions"
                  rows="1"
                  resize
                  class="form-text"
                  @keyup="onChangeLQuestions"
                  v-model="lquestions"
                  v-bind:class="validations.lquestions ? 'is-invalid' : null"
                ></b-form-textarea>
                <small
                  v-if="validations.lquestions"
                  class="text-danger float-left"
                >
                  Enter learning questions.
                </small>
              </b-form-group>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm col-md">
              <b-form-group
                class="form-text-label"
                label-for="contribution-strategy"
              >
                <b-form-textarea
                  @keyup="onChangeAcstrategies"
                  v-model="acstrategies"
                  v-bind:class="validations.acstrategies ? 'is-invalid' : null"
                  id="contribution-strategy"
                  placeholder="Attribution  and Contribution Strategy"
                  rows="1"
                  resize
                  class="form-text"
                ></b-form-textarea>
                <small
                  v-if="validations.acstrategies"
                  class="text-danger float-left"
                >
                  Enter Attribution and Contribution Strategy.
                </small>
              </b-form-group>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm col-md">
              <b-form-group class="form-text-label" label-for="data-collection">
                <b-form-textarea
                  @keyup="onChangeCollectionLimit"
                  v-model="collectionLimit"
                  v-bind:class="
                    validations.collectionLimit ? 'is-invalid' : null"
                  id="data-collection"
                  placeholder="Data collection limitation"
                  rows="1"
                  resize
                  class="form-text"
                ></b-form-textarea>
                <small
                  v-if="validations.collectionLimit"
                  class="text-danger float-left"
                >
                  Enter Data collection limitation.
                </small>
              </b-form-group>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm">
              <b-form-group>
                <button
                  type="submit"
                  class="btn btn-primary rounded-20 float-left btn-sm"
                >
                  <b-container v-if="state.adding">
                    <b-spinner small></b-spinner>
                    <span class="text-13 ml-2">Saving...</span>
                  </b-container>
                  <span v-if="!state.adding" class="text text-13"
                    >Save Changes
                  </span>
                </button>
              </b-form-group>
            </div>
          </b-row>
        </div>
      </b-row>
    </form>
</template>

<script>
import moment from "moment";
import axios from 'axios';
export default {
  props: ["indicator","project"],
  data() {
    return {
      indicatorData: null,
      alignment: null,
      title: null,
      rclevel: null,
      toclevel: null,
      rcelement: null,
      alignedIndicator: null,
      indicatorAggregation: [],
      baseline: null,
      repoters: null,
      indicatorDefinition: null,
      collectionMethods: null,
      frequency: null,
      program: null,
      lquestions: null,
      acstrategies: null,
      collectionLimit: null,
      validations: {
        alignment: false,
        title: false,
        rclevel: false,
        alignedIndicator: false,
        indicatorAggregation: false,
        baseline: false,
        repoters: false,
        indicatorDefinition: false,
        collectionMethods: false,
        frequency: false,
        toclevel: false,
        program: false,
        lquestions: false,
        acstrategies: false,
        collectionLimit: false,
      },
      isSaved: false,
      state: {
        loadUsers: false,
        loading: false,
        loadTOClevels: false,
        loadRClevels: false,
        loadRCelements:false,
        loadIndicator: false,
        loadingLogFrame: false,
        loadingMI: false,
        adding: false,
        milestones: false,
        indicatorData: false,
        removeAgg:false,
        addAgg:false
      },
      users: [],
      programs: [],
      toclevels: [],
      rclevels: [],
      rcelements: [],
      tocelements: [],
      indicators: null,
      logframes: [],
      mIndicators: [],
      perPage: 4,
      currentPage: 1,
      filter: "",
      fields: ["No", "Title", "resultchain","edit","delete"],
      inputs: [],
      inputValues: [],
      milestones: [],
      aggregations: [],
      options : [],
      value : ['none'],
      selectedItem: [],
      userId:null,
      removingItem: [],
      aggragationId: null,
    };
  },
   computed: {
    availableOptions() {
      return this.options.filter((opt) => this.value.indexOf(opt) === -1);
    },
    token() {
      return localStorage.getItem("token");
    },
    proj() {
      const results = this.$store.state.project_data;
      return results ? results : null;
    },
    projectID() {
      return this.project ? this.project : 0;
    },
    rows() {
      return this.mIndicators.length;
    },
    programData() {
      return this.current_program ? this.current_program : null;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    current_program() {
      return this.$store.state.current_program;
    },
  },
  watch: {
    alignment() {
      if (this.alignment == "project") {
        this.getRCLevels();
      } else {
        this.getTOCLevels();
      }
    },
    program() {
      this.getTOCLevels();
    },
    toclevel(){
       this.getProgramIndicators();
    },
    rclevel(){
      this.getRcElements(this.rclevel)
    },
    inputs() {
      this.inputValues = [];
      this.inputs.forEach((item) => {
        this.inputValues.push({ MilestoneId: item.id, target: item.target });
      });
    },
    value(){
      if(this.value[0]=='none') {
        this.value.shift()
      }else if(this.value.length < 1){
        this.value.push('none')
      }else{
        this.value.forEach((item) => {
          this.selectedItem = item;
        });
        this.indicators.forEach((i2) => {
          if (i2.level === this.selectedItem) {
            this.aggragationId = i2.id;
            this.aggregations.push({ Aggragation: i2.id });
          }
        });
        setTimeout(()=> {
          this.addAggregator(this.aggragationId)
          // console.log("This is ID looped once", this.aggragationId)
        },2000)
      }
    }
  },
  beforeMount() {
    this.$emit("loading", false);
    this.getUsers();
    this.getIndicatorTypes();
    this.getMeasurementIndicators();
    this.getMilestones();
    this.getSelectedIndicator()
  },
  methods: {
    async getSelectedIndicator(){
          const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.indicatorData = true;
      await this.axios
        .get(`getIndicator/${this.indicator.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.indicatorData = false;
            this.indicatorData = response.data[0];
            this.alignment = this.indicatorData.AlignedTo;
            this.title = this.indicatorData.Title,
            this.alignment == 'program' ? this.toclevel=this.indicatorData.RCTOCid.id : this.toclevel=this.indicatorData.RCTOCid.id;
            this.alignment == 'project' ? this.rcelement=this.indicatorData.RCTOCElement.id : null;
            this.indicatorData.Aggragation.forEach((item)=> {
                this.value.push(item.name)
            })
            this.alignment == 'program' ? this.alignedIndicator = this.indicatorData.AllignedIndicator.id : [];
            this.baseline = this.indicatorData.baseLine;
            this.indicatorDefinition = this.indicatorData.indicatorDefinition;
            this.collectionMethods = this.indicatorData.dataCollection;
            this.frequency = this.indicatorData.frequency;
            this.lquestions = this.indicatorData.Lquestions;
            this.acstrategies = this.indicatorData.ACstrategy;
            this.collectionLimit = this.indicatorData.dataCollectionLimit;
            this.repoters = this.indicatorData.userId.id; 
            this.inputs=[]
            this.inputValues=[]
            this.indicatorData.Milestone.forEach((item) => {
                this.inputs.push({
                    id: item.MilestoneId,
                    title: item.MilestoneTitle,
                    Duedate: moment(item.Duedate).format("DD/MM/YYYY"),
                    target: item.target,
                })
                this.inputValues.push({ MilestoneId: item.MilestoneId, target: item.target });
            })
          } else {
            this.state.indicatorData = false;
          }
        })
        .catch(() => {
          this.state.indicatorData = false;
        });
     },
     async addAggregator(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        indicatorId:this.indicator.id,
        Aggragation:param
      }
      this.state.addAgg = true;
      await this.axios
        .post(`AddAggragation`, requestData,config)
        .then((response) => {
          if (response.data) {
            this.state.addAgg = false;
            this.getIndicatorTypes()
            this.$message.success('Aggregation added successful.',4)
          } else {
            this.state.addAgg = false;
          }
        })
        .catch(() => {
          this.state.addAgg = false;
        });
    },
    async removeAggragator(param) {
        this.indicators.forEach((i2) => {
          if (i2.level === param) {
            this.removingItem = i2.id;
          }
        });
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
        params: {
          indicatorId: this.indicator.id,
          Aggragation: this.removingItem
        }
      };
      
      this.state.removeAgg = true;
      await axios
        .delete(`${process.env.VUE_APP_API_ENDPOINT}deletAggragation`,config)
        .then((response) => {
           if (response.data) {
            this.state.removeAgg = false;
            this.getIndicatorTypes()
            this.$message.success('Aggregation removed successful.',4)
          } else {
            this.state.removeAgg = false;
            this.$message.error(`${response.data.message}`,4)
          }
        })
        .catch((error) => {
          this.$message.error(`${error.errorMessage}`,4)
          this.state.removeAgg = false;
        });
    },
    // milestones
    getMilestones() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.milestones = true;
      this.state.loading = true;
      this.axios
        .get(`getProjectMilestone/${this.projectID.id}`, config)
        .then((response) => {
          this.state.loading = false;
          this.state.milestones = false;
          this.milestones = response.data;
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.state.milestones = false;
          this.state.loading = true;
        });
    },
    onKeyInputs() {
      this.inputValues = [];
      this.inputs.forEach((item) => {
        this.inputValues.push({ MilestoneId: item.id, target: item.target });
      });
      console.log("inputs", this.inputs)
    },
    async getPrograms() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.program = true;
      await this.axios
        .get(`getPrograms`, config)
        .then((response) => {
          if (response.data) {
            this.state.program = false;
            this.programs = response.data;
          } else {
            this.state.program = false;
          }
        })
        .catch(() => {
          this.state.program = false;
        });
    },
    async getMeasurementIndicators() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadingMI = true;
      await this.axios
        .get(`getProjectIndicators/${this.projectID.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadingMI = false;
            this.mIndicators = response.data;
          } else {
            this.state.loadingMI = false;
          }
        })
        .catch(() => {
          this.state.loadingMI = false;
        });
    },
    async getProgramIndicators() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadingLogFrame = true;
      await this.axios
        .get(`getIndicatorByElement/${this.toclevel}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadingLogFrame = false;
            this.logframes = response.data;
          } else {
            this.state.loadingLogFrame = false;
          }
        })
        .catch(() => {
          this.state.loadingLogFrame = false;
        });
    },
    async getIndicatorTypes() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadIndicator = true;
      await this.axios
        .get(`getIndicatorType`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadIndicator = false;
            this.indicators = response.data;
            this.options=[];
            this.indicators.map((item) => {
              this.options.push(`${item.level}`);
            })
          } else {
            this.state.loadIndicator = false;
          }
        })
        .catch(() => {
          this.state.loadIndicator = false;
        });
    },
    async getUsers() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadUsers = true;
      await this.axios
        .get("getUsers", config)
        .then((response) => {
          if (response.data) {
            this.state.loadUsers = false;
            this.users = response.data;
          } else {
            this.state.loadUsers = false;
          }
        })
        .catch(() => {
          this.state.loadUsers = false;
        });
    },
    async getTOCLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadTOClevels = true;
      await this.axios
        .get(`getTocLevels/${this.programData.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadTOClevels = false;
            this.toclevels = response.data;
          } else {
            this.state.loadTOClevels = false;
          }
        })
        .catch(() => {
          this.state.loadTOClevels = false;
        });
    },
     async getRCLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadRClevels = true;
      await this.axios
        .get(`getAllProjectResultchains/${this.projectID.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadRClevels = false;
            this.rclevels = response.data;
          } else {
            this.state.loadRClevels = false;
          }
        })
        .catch(() => {
          this.state.loadRClevels = false;
        });
    },

   async getRcElements() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      }; 
      this.state.loadRCelements = true;
      await this.axios
        .get(`allResultchainLevels/${this.rclevel}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadRCelements = false;
            this.rcelements = response.data;
          } else {
            this.state.loadRCelements = false;
          }
        })
        .catch(() => {
          this.state.loadRCelements = false;
        });
    },

    onChangeLQuestions() {
      if (!this.lquestions) {
        this.validations.lquestions = true;
      } else {
        this.validations.lquestions = false;
      }
    },
    onChangeAcstrategies() {
      if (!this.acstrategies) {
        this.validations.acstrategies = true;
      } else {
        this.validations.acstrategies = false;
      }
    },
    onChangeCollectionLimit() {
      if (!this.collectionLimit) {
        this.validations.collectionLimit = true;
      } else {
        this.validations.collectionLimit = false;
      }
    },
    onChangeAlignment() {
      if (!this.alignment) {
        this.validations.alignment = true;
      } else {
        this.validations.alignment = false;
      }
    },
    onChangeProgram() {
      if (!this.program) {
        this.validations.program = true;
      } else {
        this.validations.program = false;
      }
    },
    onChangeLogFrame() {
      if (!this.alignedIndicator) {
        this.validations.alignedIndicator = true;
      } else {
        this.validations.alignedIndicator = false;
      }
    },
    onchangeAggregation() {
      if (!this.indicatorAggregation) {
        this.validations.indicatorAggregation = true;
      } else {
        this.validations.indicatorAggregation = false;
      }
    },
    onChangeTitle() {
      if (!this.title) {
        this.validations.title = true;
      } else {
        this.validations.title = false;
      }
    },
    onChangeReporter() {
      if (this.repoters != null || this.repoters != "") {
        this.validations.repoters = false;
      } else {
        this.validations.repoters = true;
      }
    },
    onChangeToc() {
      if (!this.toclevel) {
        this.validations.toclevel = true;
      } else {
        this.validations.toclevel = false;
      }
    },
    onChangeRc() {
      if (!this.rclevel) {
        this.validations.rclevel = true;
      } else {
        this.validations.rclevel = false;
      }
    },
    onChangeRcElement() {
      if (!this.rcelement) {
        this.validations.rcelement = true;
      } else {
        this.validations.rcelement = false;
      }
    },
    onChangeDataCollection() {
      if (!this.collectionMethods) {
        this.validations.collectionMethods = true;
      } else {
        this.validations.collectionMethods = false;
      }
    },
    onChangeFrequency() {
      if (!this.frequency) {
        this.validations.frequency = true;
      } else {
        this.validations.frequency = false;
      }
    },
    updateIndicator() {
      if (!this.alignment) {
        this.$emit("error", "Indicator alignment is required.");
        this.validations.alignment = true;
        this.$message.error("Indicator alignment is required.");
      } else if (!this.title) {
        this.$emit("error", "Title is required.");
        this.validations.title = true;
        this.$message.error("project title is required.");
      }else if (!this.toclevel && this.alignment=='program') {
        this.$emit("error", "Theory of change level is required.");
        this.validations.toclevel = true;
        this.$message.error("Theory of change level is required.");
      } else if (!this.rclevel && this.alignment=='project') {
        this.$emit("error", "Result chain level is required.");
        this.validations.rclevel = true;
        this.$message.error("Result chain level is required.");
      } else if (!this.alignedIndicator && this.alignment == "program") {
        this.$emit("error", "Aligned indicator is required.");
        this.validations.alignedIndicator = true;
        this.$message.error("Aligned indicator is required.");  
      }
       else if (!this.baseline) {
        this.$emit("error", "Baseline is required.");
        this.validations.baseline = true;
        this.$message.error("Baseline is required.");
      } else if (!this.repoters) {
        this.$emit("error", "Reporting responsiblity is required.");
        this.validations.repoters = true;
        this.$message.error("Reporting responsiblity is required.");
      } else if (!this.indicatorDefinition) {
        this.$emit("error", "Indicator definition is required.");
        this.validations.indicatorDefinition = true;
        this.$message.error("Indicator definition is required.");
      } else if (!this.collectionMethods) {
        this.$emit("error", "Data collection method is required.");
        this.validations.collectionMethods = true;
        this.$message.error("Data collection method is required.");
      } else if (!this.frequency) {
        this.$emit("error", "Frequency is required.");
        this.validations.frequency = true;
        this.$message.error("Frequency is required.");
      } else {
        const config = {
          headers: {
            Authorization: `Bearer ${this.token}`,
            Accept: "application/json",
          },
        };
        const requestData = {
          AlignedTo: this.alignment,
          Title: this.title,
          RCTOCid: this.toclevel ? this.toclevel : this.rclevel ? this.rclevel : 0,
          RCTCElem: this.rclevel ? this.rcelement : 0,
          IndicatorAggragation: this.value[0]=='none' ? "none" : "",
          AllignedIndicator: this.alignment=='project' ? 0 : this.alignedIndicator,
          baseline: this.baseline,
          indicatorDefinition: this.indicatorDefinition,
          dataCollection: this.collectionMethods,
          frequency: this.frequency,
          projectId: this.projectID.id,
          userId: this.repoters,
          Lquestions: this.lquestions,
          ACstrategy: this.acstrategies,
          dataCollectionLimit: this.collectionLimit,
          Milestones: this.inputValues
        };
        
        console.log("Requested body", this.inputValues);

        if (
          this.inputValues.filter(
            (item) => item.target === null || item.target === ""
          ).length > 0
        ) {
          this.$message.error(
            "Some targets is missing please fill all form fields corretly.",
            5
          );
        } else {
          this.state.adding = true;
          this.axios
            .put(`updateIndicator/${this.indicator.id}`, requestData, config)
            .then((response) => {
              if (response.data) {
                this.state.adding = false;
                this.getMeasurementIndicators();
                this.$notification["success"]({
                  message: "SUCCESS!",
                  description: `Project indicator updated.`,
                  duration: 5,
                });
              } else {
                this.state.adding = false;
                this.$message.error(
                  `An error occured while updating project indicator`,
                  4
                );
              }
            })
            .catch((error) => {
              this.$notification["error"]({
                message: "ERROR!",
                description: `${error.errorMessage}`,
                duration: 7,
              });
              this.state.adding = false;
            });
        }
      }
    },
    refresh() {
      this.state.refresh = true;
      this.getProgramIndicators();
      this.getMilestones();
      this.getRCLevels();
      this.getTOCLevels();
      this.getIndicatorTypes();
      this.getMeasurementIndicators();
      this.getSelectedIndicator()
    },
  },
};
</script>

<style>
</style>