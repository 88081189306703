<template>
  <div>
    <div class="card card-main card-0 ml-0 mb-4">
      <div class="card-body pl-0 ml-0">
        <b-row class="mb-3">
          <div class="col-sm col-md">
            <div class="d-flex justify-content-end">
              <router-link
                class="btn btn-primary rounded-20 pl-4 pr-4 text-12"
                role="button"
                :to="{name:'export-logframe'}"
              >
                Preview Logframe
              </router-link>
            </div>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm col-md">
            <b-row class="ml-2">
              <div class="table-responsive">
                <b-container class="my-5" v-if="loading">
                  <a-skeleton active :loading="loading" :paragraphs="{rows: 6}"></a-skeleton>
                </b-container>
                <table
                  v-else
                  class="
                    table
                    text-13
                    w-100
                    table-logframe
                    text-left
                    table-striped
                  "
                >
                  <thead>
                    <tr class="tb-head">
                      <th>Indicator</th>
                      <th>TOC</th>
                      <th>Baseline</th>
                      <th>Milestones </th>
                    </tr>
                  </thead>
                  <tbody class="tb-body">
                    <tr v-for="(item,i) in logframes" :key="i">
                      <td>{{item.name}}</td>
                      <td class="">
                        <span class="clearfix">{{ item.TOC.level }}</span>
                        <span> {{ item.TOC.element }} </span>
                      </td>
                      <td>
                        <span class="clearfix">{{item.baseline}}</span>
                      </td>
                      <td v-for="(item,i) in item.targets" :key="i">
                        <span class="clearfix">{{item.target}}</span>
                        <span>{{item.achieved}}</span>
                      </td>
                      <td class="max-td">
                        <tr>
                          <td v-for="(row,k) in item.milestones" :key="k">
                            <b-row>
                              <b-col>
                                <small class="border-bottom"><strong>Milestone</strong></small>
                                <div class="clearfix my-1"></div>
                                {{ row.MilestoneTitle }}
                              </b-col>
                              <b-col>
                                <small class="border-bottom"><strong>Target</strong></small>
                                <div class="clearfix my-1"></div>
                                {{ row.target }}
                              </b-col>
                              <b-col>
                                <small class="border-bottom"><strong>Achieved</strong></small>
                                <div class="clearfix my-1"></div>
                                {{ row.achieved }}
                              </b-col>
                            </b-row>
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-row>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import decode from "jwt-decode";
export default {
  props: {
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      state: {
        loading: false,
      },
    };
  },
  computed: {
    program() {
      let project = sessionStorage.getItem("prg");
      const resData = decode(project);
      return resData;
    },
    token() {
      return localStorage.getItem("token");
    },
    programYears(){
      const from =  parseInt(this.program.from);
      const to = parseInt(this.program.to);
      let years = [];
      for(let currentYear = from; currentYear <= to; currentYear++){
        if(new Date(currentYear, 0, 1).getDay() === 0){
            // console.log(currentYear);
        }
        years.push(currentYear)
      }
      return years;
    },
    logframes(){
      return this.$store.state.selected_program.program_logframe;
    }
    
  },
  beforeMount() {},
  methods: {
    refresh(){
      this.$emit('refresh')
    }
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.progress-wrapper {
  width: 260px !important;
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  background: #f3f3f3 !important;
}

.card-pillars {
  background: #3fb15d21 !important;
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}

.label-remain h6 {
  font-size: 12px !important;
  margin-right: 10px !important;
}

.label-completed h6 {
  font-size: 12px !important;
  margin-left: 10px !important;
}

.top-texts {
  font-size: 13px !important;
}

.knowledge-text {
  color: #001 !important;
  font-size: 15px !important;
}

.avatar {
  width: 60px;
  height: 60px;
  background: #eeeeee !important;
}

.report-status h6 {
  color: #e5e5e5 !important;
}

.price-1 button {
  background-color: #8dd070 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.price-2 button {
  background-color: #cecf71 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.price-3 button {
  background-color: #ffcdd3 !important;
  border-radius: 50px !important;
  height: 25px !important;
  width: 6.5rem !important;
}

.row1 {
  border-left: 6px solid #8dd070 !important;
  border-spacing: 4px !important;
}

.row2 {
  border-left: 6px solid #cecf71 !important;
  border-spacing: 4px !important;
}

.row3 {
  border-left: 6px solid #ffcdd3 !important;
  border-spacing: 4px !important;
}

.card {
  text-align: center !important;
}

.dotted {
  background: #000000 !important;
  border-radius: 2px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  color: #fff !important;
}

@media screen and (max-width: 578px) {
  .big-border {
    width: 54.5px;
    height: 5px;
    background: #cecf71 !important;
  }

  .date-top-text {
    color: #000 !important;
  }

  .user-info,
  .report-status {
    width: 150px !important;
  }
}
</style>