<template>
  <div>
    <top-banner :title="pageTitle" />
    <main
      role="main"
      class="col-sm-10 ml-sm-auto col-md-10 pt-0 main-export-log mb-6"
    >
      <b-container class="my-5" v-if="state.loading">
        <a-skeleton
          active
          :loading="state.loading"
          :paragraph="{ rows: 16 }"
        ></a-skeleton>
      </b-container>
      <div v-else class="overflow-auto h-scroll my-3 mt-5">
        <div class="content-logframe-box my-4">
          <table class="table table-bordered text-13 table-exports">
            <thead>
              <tr>
                <th class="border-0"></th>
                <th class="border-0" style="width: 400px !important;"></th>
                <th class="border-0"></th>
                <th class="border-0" style="width: 1000px !important;"></th>
                <th colspan="7"></th>
                <th colspan="100%">Period: Aug-2020 2022</th>
              </tr>
              <tr>
                <th colspan="11"></th>
                <!-- <th></th> -->
                <th style="width:200px !importatnt;" v-for="(item,i) in milestones[0]" :key="i"> {{ item.MilestoneTitle }} </th>
                <th>Cumulative</th>
              </tr>
              <tr>
                <th></th>
                <th class="w-10">Result Chain Box</th>
                <th class="w-20">Indicator</th>
                <th style="width: 1000px !important;">Indicator Definition</th>
                <th class="w-10">Source of data</th>
                <th class="w-25">Data collection method</th>
                <th>Frequency</th>
                <th style="width: 15rem !importatant;">Responsible for Data Collection</th>
                <th>Baseline</th>
                <th>Overall Target</th>
                <th>Disaggregation</th>
                <th class="bg-green"></th>
                <th class="bg-green"></th>
                <th class="bg-green"></th>
                <th class="bg-green"></th>
                <th class="bg-green"></th>
              </tr>
            </thead>
            <tbody v-for="(item, i) in data" :key="i">
              <tr>
                <td :style="[{ backgroundColor: `${item.color} !important` }]" class="bg-dark-gray">{{item.element}}</td>
                <td style="width: 400px !important;" class="m-0 p-0 h-100">
                  <tr>
                      <td class="h-100 pt-5 border-none" style="width: 400px !important;">{{ item.elementDescription }}</td>
                  </tr>
                </td>
                <td class="m-0 p-0">
                    <tr v-for="(element, i) in item.indicatordata" :key="i">
                      <td class="m-0 p-0 pl-2 border-0" style="height: 125px !important;">{{element.Title}}</td>
                    </tr>
                </td>
                <td  class="m-0 p-0 w-75">
                    <tr v-for="(element, i) in item.indicatordata" :key="i">
                      <td class="m-0 p-0 pl-2 border-0" style="height: 125px !important;">{{element.indicatorDefinition}}</td>
                    </tr>
                </td>
                <td style="width: 220px !important;" class="m-0 p-0">
                    <tr v-for="(element, i) in item.indicatordata" :key="i">
                      <td class="m-0 p-0 pl-2 border-0" style="width:200px !important;height: 125px !important;">{{element.sourceofdata}}</td>
                    </tr>
                </td>
                <td class="m-0 p-0">
                    <tr v-for="(element, i) in item.indicatordata" :key="i">
                      <td class="pl-2 border-0" style="height:125px !important;">{{element.dataCollection}}</td>
                    </tr>
                </td>
                 <td class="m-0 p-0">
                    <tr v-for="(element, i) in item.indicatordata" :key="i">
                      <td class="pl-2 border-0" style="height:125px !important;width: 200px !important;">{{element.frequency}}</td>
                    </tr>
                </td>
                 <td class="m-0 p-0">
                    <tr v-for="(element, i) in item.indicatordata" :key="i">
                      <td class="pl-2 border-0" style="height:125px !important;"><span class="text-capitalize">{{element.resposibleFordataCollection}}</span></td>
                    </tr>
                </td>
                <td class="m-0 p-0">
                    <tr v-for="(element, i) in item.indicatordata" :key="i">
                      <td class="pl-2 border-0" style="height:125px !important;width: 200px !important;">{{element.baseline}}</td>
                    </tr>
                </td>
                <td class="m-0 p-0">
                  <tr v-for="(element, i) in item.indicatordata" :key="i">
                    <td class="pl-2 border-0" style="height:125px !important;width: 200px !important;">{{element.overAllTarget}}</td>
                  </tr>
                </td>
                <td class="m-0 p-0">
                  <tr v-for="(element, i) in item.indicatordata" :key="i">
                    <td class="pl-2 border-0" v-if="!Array.isArray(element.Aggregation)" style="height:125px !important;width: 200px !important;">{{element.Aggregation}}</td>
                    <td class="pl-2 border-0" style="height:125px !important;width: 200px !important;">{{element.Aggregation.map((aggregation) => aggregation.name).join(', ')}}</td>
                  </tr>
                </td>
                <template v-for="(m, index) in item.indicatordata">
                  <td class="m-0 p-0 tdj pl-2 border-0" v-for="(item,k) in m.Milestones" :key="`${index} ${k}`" style="width:200px !importatnt;">
                    <tr :key="k">
                      <td class="pl-2 border-0" style="height:125px; width: 200px !important;">
                        <span > {{ item.achieved }}</span>
                      </td>
                    </tr> 
                  </td>
                </template>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import decode from "jwt-decode";
export default {
  component: {},
  data() {
    return {
      title: null,
      pageTitle: "Project",
      perPage: 8,
      currentPage: 1,
      filter: "",
      data: [],
      state: {
        loading: false,
        refresh: false,
        saving: false,
        currentp: false,
      },
      milestones: [],
      finalMiles: [],
      extra: [],
      indicatorData: [],
    };
  },
  beforeMount() {
    this.$title(`Export Project Measurement Plan`);
    this.getCurrentProgram();
    if(this.project){
         this.getProjectData();
    }else {
        this.$router.replace({name:'user-projects'})
    }
  },
  computed: {
      token() {
        return localStorage.getItem("token");
      },
      project(){
        const storeData = sessionStorage.getItem("pjt")
        const projectData = this.$store.state.project_data;
        var decoded = null;
        if(storeData){
          decoded = decode(storeData)
        }
        return projectData ? projectData : decoded;
      }
  },
  methods: {
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.currentp = true;
      await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if (response.data) {
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
    async getProjectData() {
      this.state.loading = true;
      await this.axios
        .get(`ExportProjectmeasurementPlan/${this.project.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.data = response.data;
            this.milestones = [];
            this.data.forEach((element) => {
              element.indicatordata.forEach((item) => {
                this.milestones.push(item.Milestones);
              });
            });
            console.log("Mplan data", this.data)
            console.log("Milestones", this.milestones)
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Arial, Helvetica, sans-serif !important;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.progress-wrapper {
  width: 260px !important;
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  background: #f3f3f3 !important;
}

.card-pillars {
  background: #3fb15d21 !important;
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}
</style>