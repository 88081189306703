var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticClass:"mt-2 main-card"},[_c('b-col',[_c('div',{staticClass:"card card-2 card-100 ml-2 w-100"},[_c('div',{staticClass:"card-body"},[_c('b-row',{staticClass:"row-chain"},[_c('div',{staticClass:"col-sm col-md col-lg"},[_c('div',{staticClass:"nav flex-column nav-result-chain",attrs:{"id":"v-pills-tab","role":"tablist","aria-orientation":"vertical"}},[(_vm.loading)?_c('b-container',{staticClass:"my-4"},[_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 3 },"loading":_vm.loading}})],1):_vm._l((_vm.levels),function(level,i){return _c('a',{key:i,staticClass:"tab-left nav-link text-capitalize",class:_vm.levelItem
                      ? level.id == _vm.levelItem.id
                        ? 'active'
                        : null
                      : _vm.levels[0].id == level.id
                      ? 'active'
                      : null,style:([
                    _vm.levelItem
                      ? level.id == _vm.levelItem.id
                        ? {
                            color: ((level.color) + " !important"),
                            borderLeft: ("4px solid " + (level.color) + " !important"),
                          }
                        : null
                      : _vm.levels[0].id == level.id
                      ? {
                          color: ((level.color) + " !important"),
                          borderLeft: ("4px solid " + (level.color) + " !important"),
                        }
                      : null ]),attrs:{"id":_vm.removeSpace(level.level),"data-toggle":"pill","href":"#v-pills-social-impact","role":"tab","aria-controls":("v-pills-" + (_vm.removeSpace(level.level))),"aria-selected":"true"},on:{"click":function($event){return _vm.onChangeLevel(level)}}},[_vm._v(" "+_vm._s(level.level)+" "),_c('div',{staticClass:"clear-"}),_c('a-tooltip',{attrs:{"title":"Update level","placement":"bottom"}},[_c('small',{on:{"click":function($event){return _vm.onshowEdit(level)}}},[_c('span',[_c('b-img',{staticStyle:{"width":"17px","height":"17px"},attrs:{"src":_vm.edit}}),_vm._v(" edit")],1)])])],1)}),_c('router-link',{staticClass:"\n                    btn btn-new-key btn-outline-primary\n                    pl-5\n                    mb-3\n                    pr-5\n                    text-dark\n                    mt-3\n                  ",attrs:{"to":{ name: 'export-toc' }}},[_vm._v("Preview ")])],2)]),_c('div',{staticClass:"col-sm-9 col-md-9"},[_c('div',{staticClass:"tab-content",attrs:{"id":"v-pills-tabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"v-pills-social-impact","role":"tabpanel","aria-labelledby":"v-pills-social-impact-tab"}},[_c('b-row',[_c('div',{staticClass:"col-sm-10 col-md-10"},[_c('b-row',[(_vm.state.loadingElement)?_c('b-container',{staticClass:"my-5"},[_c('b-spinner',{attrs:{"type":"grow","small":""}}),_c('span',{staticClass:"text-13 ml-2"},[_vm._v("Loading elements...")])],1):(_vm.elementsNum <= 0)?_c('b-container',{staticClass:"my-5"},[_c('span',[_vm._v(" No box found at this level. ")])]):_vm._l((_vm.elements),function(el,j){return _c('div',{key:j,staticClass:"col-sm-6 col-md-6"},[_c('div',{staticClass:"card card-chain"},[_c('div',{staticClass:"card-header",style:([
                                {
                                  backgroundColor: ((_vm.levelItem.color) + " !important"),
                                } ])},[_c('div',{staticClass:"text-right"},[_c('a-tooltip',{staticClass:"mr-2",attrs:{"placement":"bottom","title":"Update element"}},[_c('b-icon-pencil-square',{staticClass:"text-white cursor-pointer",attrs:{"scale":"1.8"},on:{"click":function($event){return _vm.onShowEditElement(el)}}})],1),_c('a-tooltip',{staticClass:"ml-2",attrs:{"placement":"bottom","title":"Delete element"}},[_c('b-icon-trash',{staticClass:"text-white cursor-pointer",attrs:{"scale":"1.8"}})],1)],1)]),_c('div',{staticClass:"card-body cb-scroll"},[_c('p',{staticClass:"text-left text-13"},[_c('strong',[_vm._v(_vm._s(el.element)+":")]),_vm._v(" "+_vm._s(el.Description)+" ")])])])])})],2)],1),_c('div',{staticClass:"col-sm-2 col-md-2"})])],1)])])])],1)])])],1),_c('a-modal',{attrs:{"footer":null,"title":("" + (_vm.keywords['theory of change']))
        ? ("Update " + (_vm.keywords['theory of change']) + " Level")
        : "Update Theory of change level"},model:{value:(_vm.visibleEditLevel),callback:function ($$v) {_vm.visibleEditLevel=$$v},expression:"visibleEditLevel"}},[_c('updateLevel',{attrs:{"leveldata":_vm.selectedLevel},on:{"dismiss":_vm.onDismiss}})],1),_c('a-modal',{attrs:{"footer":null,"title":("" + (_vm.keywords['theory of change']))
        ? ("Update " + (_vm.keywords['theory of change']) + " box")
        : "Update Theory of change box"},model:{value:(_vm.visibleEditElement),callback:function ($$v) {_vm.visibleEditElement=$$v},expression:"visibleEditElement"}},[_c('updateTOCElement',{attrs:{"elementdata":_vm.selectedElement},on:{"dismiss":_vm.onDismissElement}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }