import Vue from "vue";
import VueRouter from "vue-router";
import RouteGuard from "../helpers/route-guard";
import Login from "../pages/login.vue";
import ResetPassword from "../pages/reset_password.vue";
import NewPassword from "../pages/new_password.vue";
import Login2 from '../pages/login-user.vue';
import userRoutes from '../dashboards/project-manager/manager-routes.js';
import logout from '../pages/logout.vue';
import adminRoutes from '../dashboards/admin/admin-routes.js';
import store from '../vuex/index';
import NotFound from '../pages/errors/404.vue';
import ServerError from '../pages/errors/500.vue';
Vue.use(VueRouter);

const AppRoutes = [
  {
    path: "/",
    name: 'login',
    component: Login,
    meta: {
      guest: true,
    }
  },
  {
    path:'/login',
    component: Login2
  },
  {
    path:'/reset_password',
    name:'reset_password',
    component: ResetPassword,
    meta: {
      guest: true,
    }
  },
  {
    path:'/reset_password/new',
    name:'reset_password_enter_new',
    component: NewPassword,
    meta: {
      guest: true,
    }
  },
  {
    path: '/logout',
    component: logout,
    meta: { helper: true }
  },
  { 
    path: '*', 
    redirect: '/404',
    meta: {
      helper: true,
    }
  },
  { path: '/404', 
    component: NotFound,
    meta: {
      notFound: true,
    }
  },
  { path: '/500', 
    component: ServerError,
    meta: {
      serverError: true,
    }
  },
    
];

const routes = [
  ...AppRoutes,
  ...userRoutes,
  ...adminRoutes
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("CANCEL_PENDING_REQUESTS");
  RouteGuard(to, from, next);
});

export default router;
