<template>
  <div>
    <b-row>
      <div class="col-sm col-md col-lg"></div>
      <div class="col-sm-10 col-md-10 col-lg-10">
        <form class="col-md-6 mb-5">
          <div class="form-group">
            <label for="" class="text-13">Approval workflow</label>
            <b-input-group>
              <b-select
                class="custom-select bg-white text-13 mr-2"
                v-model="workflow"
                required
                :disabled="state.loading"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled
                    >Select Workflow
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in workflows"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-icon-arrow-repeat
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loading ? 'spin' : ''"
                  @click="getWorkflows"
                />
              </b-input-group-append>
            </b-input-group>
            <p class="mt-2 pl-1 text-muted">Select the appropriate approval workflow for this project</p>
          </div>
        </form>

        <div class="card card-2">
          <div class="card-body">
            <div class="table-responsive mt-2">
              <b-container class="my-5" v-if="state.loadApprovers">
                <b-spinner type="grow" small></b-spinner>
                <span class="text-13 ml-2"
                  >Waiting for workflow approvers...</span
                >
              </b-container>
              <b-table
                v-else
                table-class="table table table-indicators rounded-20"
                thead-tr-class="theader-indicators text-left indent-20"
                tbody-class="tb-body text-left indent-20"
                :items="approvers"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="fields"
                show-empty
              >
                <template v-slot:cell(No)="data">
                  <span>{{ data.index + 1 }}</span>
                </template>
                <template v-slot:cell(full_name)="data">
                  <span>{{ data.item.fullnames }}</span>
                </template>
              </b-table>
            </div>
            <b-row class="my-4">
              <div class="col-sm-8 col-md-8"></div>
              <div class="col-sm col-md">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                  prev-class="text-13"
                  next-class="text-13"
                  first-class="text-13"
                  last-class="text-13"
                ></b-pagination>
              </div>
            </b-row>
          </div>
        </div>
      </div>
      <div class="col-sm col-md col-lg"></div>
    </b-row>
  </div>
</template>

<script>
import decode from "jwt-decode";
export default {
  props: ["store"],
  data() {
    return {
      workflows: [],
      workflow: null,
      state: {
        loading: false,
        loadApprovers: false,
        saving: false
      },
      approvers: [],
      filter:'',
      perPage: 5,
      currentPage: 1,
      fields: ["No","full_name","Role","Department","level"]
    };
  },
  mounted() {
    this.getWorkflows();
  },
  watch: {
    workflow(){
      this.getApprovals()
    }
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    proj() {
      const results = this.$store.state.project_data;
      return results ? results : null;
    },
    project() {
      const data = sessionStorage.getItem("project");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.proj ? this.proj : decoded;
    },
    rows(){
      return this.approvers.length;
    },
    drafted(){
      var decoded = null;
      const storeData = sessionStorage.getItem("drafted-project")
      if(storeData){
        decoded = decode(storeData)
      }
      return decoded;
    },
    projectID(){
      return this.drafted ? this.drafted : this.project ? this.project.PojectData : null;
    },
  },
  methods: {
    async nextStep() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        workflowId: this.workflow,
        projectId: this.projectID.id
      }
      this.state.saving = true;
      
      this.$emit("loading", true);
       await this.axios
        .post("addworkflowOnProject", requestData,config)
        .then((response) => {
          if (response.data.message) {
            this.$emit("loading", false);
            this.$emit("can-continue");
            sessionStorage.removeItem("project");
            this.$store.dispatch("setProject",null);
            sessionStorage.removeItem("drafted-project");
            setTimeout(()=> {
              this.$router.push({name: 'user-projects'})
            }, 2000)
            this.$notification["success"]({
              message: "SAVED!",
              description: `Wrokflow saved and project setup has been completed successfully.`,
              duration: 5,
            });
          } else {
            this.$emit("loading", false);
          }
        })
        .catch((error) => {
          this.$message.error(`${error.errorMessage}`)
          this.$emit("loading", false);
        });
    },
     async getApprovals() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadApprovers = true;
      await this.axios
        .get(`getApproverList/${this.workflow}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadApprovers = false;
            this.approvers = response.data;
          } else {
            this.state.loadApprovers = false;
            this.$notification["error"]({
              message: "ERROR!",
              description: `Something wrong went getting workflows`,
              duration: 5,
            });
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getWorkflows() {
      this.state.loading = true;
      await this.axios
        .get("getWorkFlows")
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.workflows = response.data;
          } else {
            this.state.loading = false;
            this.$notification["error"]({
              message: "ERROR!",
              description: `Something wrong when getting workflows`,
              duration: 5,
            });
          }
        })
        .catch(() => {
          // this.$notification["error"]({
          //   message: "ERROR!",
          //   description: `${error.errorMessage}`,
          //   duration: 5,
          // });
          this.state.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.main-wrapper .form-group label,
.main-wrapper .form-group input::placeholder,
.form-text,
.form-text::placeholder,
.form-text-label,
.form-control,
.custom-select {
  font-size: 13px !important;
}

.table-indicators th {
  font-size: 13px !important;
  border-top: none !important;
}

.table-indicators td {
  font-size: 13px !important;
}

.card-2 {
  border: none !important;
  border-radius: 20px !important;
}
</style>
