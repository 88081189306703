var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{},[_c('div',{staticClass:"col-sm col-md"},[_c('div',{staticClass:"card rounded-20 card-2 mt-2"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',[_c('h6',{staticClass:"knowledge-text ml-2 text-black"},[_vm._v(" Change Account Settings ")]),_c('div',{staticClass:"b-bottom ml-2 my-2"})])])]),_c('b-row',[_c('div',{staticClass:"col-sm-6 col-md-6"},[_c('section',{staticClass:"section container"},[_c('h6',{staticClass:"knowledge-text ml-2 text-black text-left my-3"},[_c('i',{staticClass:"fa fa-key",attrs:{"aria-hidden":"true"}}),_vm._v(" Password settings ")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"float-left text-muted text-12"},[_vm._v("New password")]),_c('div',{staticClass:"input-group mb-3"},[(_vm.showPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control form-input",class:_vm.validations.password
                          ? 'is-invalid'
                          : _vm.validations.length
                          ? 'is-invalid'
                          : null,attrs:{"type":"text","placeholder":"New password","required":""},domProps:{"value":(_vm.password)},on:{"keyup":_vm.onchangePassword,"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control form-input",class:_vm.validations.password
                          ? 'is-invalid'
                          : _vm.validations.length
                          ? 'is-invalid'
                          : null,attrs:{"type":"password","placeholder":"New password","required":""},domProps:{"value":(_vm.password)},on:{"keyup":_vm.onchangePassword,"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{staticClass:"input-group-append rounded-right-10"},[_c('span',{staticClass:"input-group-text rounded-right-10",attrs:{"id":"basic-addon2"},on:{"click":_vm.toggleShow}},[_c('i',{staticClass:"fas",class:{
                            'fa-eye-slash': _vm.showPassword,
                            'fa-eye': !_vm.showPassword,
                          }})])])]),(_vm.validations.password)?_c('small',{staticClass:"text-danger"},[_vm._v(" Please enter password. ")]):(_vm.validations.length)?_c('small',{staticClass:"text-danger"},[_vm._v(" Password must be at least six(6) characters long. ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"float-left text-muted text-12"},[_vm._v("Confirm password")]),_c('div',{staticClass:"input-group mb-3"},[(_vm.showPassword2)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password2),expression:"password2"}],staticClass:"form-control form-input",class:_vm.validations.password2
                          ? 'is-invalid'
                          : _vm.validations.match
                          ? 'is-invalid'
                          : null,attrs:{"type":"text","placeholder":"Confirm password","required":""},domProps:{"value":(_vm.password2)},on:{"keyup":_vm.onchangePassword2,"input":function($event){if($event.target.composing){ return; }_vm.password2=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password2),expression:"password2"}],staticClass:"form-control form-input",class:_vm.validations.password2
                          ? 'is-invalid'
                          : _vm.validations.match
                          ? 'is-invalid'
                          : null,attrs:{"type":"password","placeholder":"Confirm password","required":""},domProps:{"value":(_vm.password2)},on:{"keyup":_vm.onchangePassword2,"input":function($event){if($event.target.composing){ return; }_vm.password2=$event.target.value}}}),_c('div',{staticClass:"input-group-append rounded-right-10"},[_c('span',{staticClass:"input-group-text rounded-right-10",attrs:{"id":"basic-addon2"},on:{"click":_vm.toggleShow2}},[_c('i',{staticClass:"fas",class:{
                            'fa-eye-slash': _vm.showPassword2,
                            'fa-eye': !_vm.showPassword2,
                          }})])])]),(_vm.validations.password2)?_c('small',{staticClass:"text-danger"},[_vm._v(" Please confirm your password. ")]):(_vm.validations.match)?_c('small',{staticClass:"text-danger"},[_vm._v(" Passwords don't match. ")]):_vm._e()]),_c('b-form-group',[_c('button',{staticClass:"btn btn-success btn-confirm btn-sm btn-block",attrs:{"disabled":_vm.state.updating,"type":"submit"}},[(_vm.state.updating)?_c('b-container',[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"text-13 ml-2"},[_vm._v("Saving...")])],1):_vm._e(),(!_vm.state.updating)?_c('span',{staticClass:"text text-13"},[_vm._v("Save Changes")]):_vm._e()],1)])],1)],1)])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }