<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto main-export col-md-10 pt-0">
      <div class="card card-main card-0 mt-5 ml-0">
        <div class="card-body pl-0 ml-0">
          <b-row class="mb-2">
            <div class="col-sm-9 col-md-9"></div>
              <div class="col-sm col-md text-right">
                <button
                  @click="refresh"
                  type="button"
                  class="btn btn-secondary btn-sm rounded-10 border-none"
                >
                  <b-container v-if="state.loading && state.refresh">
                    <b-spinner small></b-spinner>
                    <span class="text-13 ml-2">Refreshing...</span>
                  </b-container>
                  <span v-else class="text text-13">Refresh</span>
                </button>
              </div>
            </b-row>
          <b-row>
            <div class="col-sm col-md">
              <div class="table-responsive ml-2">
                <b-container v-if="state.loading">
                    <a-skeleton :loading="state.loading" active :paragraph="{rows: 8}"></a-skeleton>
                </b-container>
                <table v-else class="table table-reports text-13">
                  <thead>
                    <tr class="tb-header indent-10">
                      <th class="th" scope="col">Report Title</th>
                      <th class="b-left" scope="col">Element Name</th>
                      <th class="b-left" scope="col">Sent by</th>
                      <th class="b-left" scope="col">Deadline</th>
                      <th class="b-left" scope="col">Status</th>
                      <th class="b-left text-center" scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody class="tb-body indent-10">
                    <tr v-for="(item,i) in reports" :key="i">
                      <td scope="row"><span class="text-capitalize">{{item.Title}}</span></td>
                      <td class="b-left"><span class="text-capitalize">{{item.entity_name}}</span></td>
                      <td class="b-left">{{item.sentBy}}</td>
                      <td class="b-left b-right">{{moment(item.DeadLine).format("DD/MM/YYYY")}}</td>
                      <td class="b-right text-capitalize">
                        <label class="status-pending" v-if="item.status=='pending'">{{item.status}}</label>
                        <label class="status-approved" v-else-if="item.status=='submitted'">{{item.status}}</label>
                        <label class="status-rejected" v-else-if="item.status=='rejected'">{{item.status}}</label>
                        <label class="status-completed" v-else-if="item.status=='completed'">{{item.status}}</label>
                        <label class="status-pending" v-else>{{item.status}}</label>
                      </td>
                      <td>
                          <button
                            v-if="(item.status=='pending' || item.status=='draft' ) && item.is_responsibile"
                            @click="submitReport(item)"
                            class="btn btn-sm rounded-20 btn-report text-11 pl-0 pr-0 pt-0 pb-0 btn-warning text-dark"
                          >
                            SUBMIT
                          </button>
                          <button
                            v-else-if="item.status=='submitted45'"
                            class="btn btn-sm btn-success btn-report text-11 btn-report-approved pl-0 pr-0 pt-0 pb-0 rounded-20"
                          >
                            View report
                          </button>
                          <a-tooltip title="Report submitted" v-else-if="item.status=='submitted'">
                            <label class="status-approved cursor-pointer"> <i class="bx bx-check-double bx-sm"></i> </label>
                          </a-tooltip>
                        <button
                          v-else-if="item.status=='completed'"
                          class="
                            btn btn-sm btn-success btn-report
                            text-11
                            btn-report-completed
                            text-white
                            pl-0
                            pr-0
                            pt-0
                            pb-0
                            rounded-20
                          "
                        >
                          View report
                        </button>
                        <button
                          v-else-if="item.status=='rejected'"
                          class="
                            btn btn-sm btn-info btn-report
                            text-11
                            btn-report-rejected
                            text-white
                            pl-0
                            pr-0
                            pt-0
                            pb-0
                            rounded-20
                          "
                        >
                          View comments
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-row>
           <b-row class="mb-6 d-none my-3">
                <div class="col-sm col-md">
                  <div class="d-flex justify-content-end">
                    <router-link
                      class="btn btn-primary rounded-20 pl-4 pr-4 text-12"
                      role="button"
                      :to="{ name: 'submit-report' }"
                    >
                      {{
                        keywords
                          ? `Add ${keywords.report}`
                          : "Add Project"
                      }}
                    </router-link>
                  </div>
                </div>
              </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import encode from 'jwt-encode';
import arrowIcon from "../../components/icons/arrow.vue";
export default {
  component: {
    arrowIcon,
  },
  data() {
    return {
      pageTitle: "reports",
      reports: [],
      state: {
          loading: false,
          refresh: false
      }
    };
  },
  beforeMount() {
    this.$title("Reports");
     this.getReports()
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  methods: {
    checkURL() {
      const activeURL = this.$route.path;
      this.$store.dispatch("manager/setNavBarActiveTab", activeURL, {
        root: true,
      });
      // console.log("Active TITLE from Vuex", this.$store.state.manager.title);
    },
    submitReport(param) {
      const secret = "tokenHashingViv--afr0-10***&&&&&34221-6534bjhdvw-+++5##";
      const jwt = encode(param, secret);
      sessionStorage.setItem("report", jwt);
      this.$store.dispatch("setReport",param);
      this.$router.replace({ name: "submit-report-all" });
    },
    async getReports() {
      this.state.loading = true;
      await this.axios
        .get(`getReports`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.reports = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    refresh(){
      this.state.refresh=true;
      this.getReports()
    }
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

.b-left {
  border-left: 1px solid #e5e5e5 !important;
}

.b-right {
  border-right: 1px solid #e5e5e5 !important;
}
</style>