<template>
  <div>
    <top-banner :title="pageTitle" />
    <main
      role="main"
      class="col-sm-10 ml-sm-auto col-md-10 pt-0 main-export mb-8"
    >
      <b-container class="my-4" v-if="state.loading">
        <a-skeleton
          active
          :loading="state.loading"
          :paragraph="{ rows: 12 }"
        ></a-skeleton>
      </b-container>
      <div v-else v-for="(level, i) in data" :key="i" class="wrapper">
        <b-row v-if="level.levelNumber == 1" class="mt-5">
          <b-col cols="2" sm="2">
            <div
              class="card card-level mt-2 ml-2 mb-2 rounded-20"
              :style="[{ backgroundColor: `${level.color} !important` }]"
            >
              <div class="card-body">
                <h4 class="pt-5 pb-5 text-dark text-15">{{ level.level }}</h4>
              </div>
            </div>
          </b-col>
          <b-col cols="2"></b-col>
          <b-col class="justify-content-center">
            <b-row>
              <b-col>
                <div :style="[{ backgroundColor: `${level.color} !important` }]" class="component-header ml-2">
                  <h4 class="text-dark text-bold text-14 pt-2 pb-2">
                    Human Development Impact
                  </h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-for="(element, j) in level.elements" :key="j">
                <div
                  :style="[{ backgroundColor: `${element.color} !important` }]"
                  class="card card-element mt-2 ml-2 mb-2"
                >
                  <div class="card-body">
                    <p class="text-left text-dark">
                      <strong>{{ element.element }}:</strong>
                      <small class="text-13">
                        {{ element.Description }}
                      </small>
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row v-if="level.levelNumber == 2">
          <b-col cols="2" sm="2">
            <div
              :style="[{ backgroundColor: `${level.color} !important` }]"
              class="card card-level-2 mt-2 ml-2 mb-2 rounded-20"
            >
              <div class="card-body">
                <h4 class="pt-3 pb-3 text-dark text-15">
                  {{ level.level }}
                </h4>
              </div>
            </div>
          </b-col>
          <b-col cols="2"></b-col>
          <b-col class="justify-content-center">
            <b-row>
              <b-col>
                <div :style="[{ backgroundColor: `${level.color} !important` }]" class="component-header-1 ml-2">
                  <h4 class="text-dark text-bold text-13 pt-2 pb-2">
                    A financial System that delivers real Value to the economy
                    and people, in particular to women and marginalized groups
                  </h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-for="(element, j) in level.elements" :key="j">
                <div
                  :style="[{ backgroundColor: `${element.color} !important` }]"
                  class="card card-element-1 mt-2 ml-2 mb-2"
                >
                  <div class="card-body">
                    <p class="text-left text-dark">
                      <strong>{{ element.element }}:</strong>
                      <small class="text-13">
                        {{ element.Description }}
                      </small>
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
        <div v-if="level.levelNumber == 3" class="overflow-auto h-scroll my-3">
          <div class="d-flex flex-fill content-3-flex">
            <div
              :style="[{ backgroundColor: `${level.color} !important` }]"
              class="card card-level-3 rounded-20"
            >
              <div class="card-body">
                <h4 class="pt-5 pb-5 text-dark text-15">
                  {{ level.level }}
                </h4>
              </div>
            </div>
            <div
              v-for="(element, j) in level.elements"
              :key="j"
              :style="[{ backgroundColor: `${element.color} !important` }]"
              class="card card-element-2"
            >
              <div class="card-body">
                <p class="text-left">
                  <strong>{{ element.element }}:</strong>
                  <small class="text-13">
                    {{ element.Description }}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- flexbox 2 -->
        <div v-if="level.levelNumber == 4" class="overflow-auto h-scroll my-3">
          <div class="d-flex flex-fill content-3-flex">
            <div
              :style="[{ backgroundColor: `${level.color} !important` }]"
              class="card card-level-4 rounded-20"
            >
              <div class="card-body">
                <h4 class="pt-5 pb-5 text-dark text-15">
                  {{ level.level }}
                </h4>
              </div>
            </div>
            <div
              v-for="(element, j) in level.elements"
              :key="j"
              :style="[{ backgroundColor: `${element.color} !important` }]"
              class="card card-element-3"
            >
              <div class="card-body">
                <p class="text-left">
                  <strong>{{ element.element }}:</strong>
                  <small class="text-13">
                    {{ element.Description }}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- flexbox 3 -->
        <div v-if="level.levelNumber == 5" class="overflow-auto h-scroll my-3">
          <div class="d-flex flex-fill content-3-flex">
            <div
              :style="[{ backgroundColor: `${level.color} !important` }]"
              class="card card-level-5 rounded-20"
            >
              <div class="card-body">
                <h4 class="pt-5 pb-5 text-dark text-15">
                  {{ level.level }}
                </h4>
              </div>
            </div>
            <div
              v-for="(element, j) in level.elements"
              :key="j"
              :style="[{ backgroundColor: `${element.color} !important` }]"
              class="card card-element-4"
            >
              <div class="card-body">
                <p class="text-left">
                  <strong>{{ element.element }}:</strong>
                  <small class="text-13">
                    {{ element.Description }}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import decode from "jwt-decode";
export default {
  component: {},
  data() {
    return {
      title: null,
      pageTitle: "Export pillar Result chain",
      perPage: 8,
      currentPage: 1,
      filter: "",
      projects: [],
      state: {
        loading: false,
        refresh: false,
        saving: false,
      },
      data: [],
    };
  },
  beforeMount() {
    this.$title(`Export Pillar Result Chain Elements`);
    this.fetchTcomponents();
    console.log("Final pillar", this.finPillar);
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    program(){
      return this.$store.state.current_program;
    },
     pillar() {
      const storeData = sessionStorage.getItem("pl");
      const pillarData = this.$store.state.pillar_data;
      var decoded = null;
      if (storeData) {
        decoded = decode(storeData);
      }
      return pillarData ? pillarData : decoded;
    },
    drafted(){
      var decoded = null;
      const storeData = sessionStorage.getItem("drafted-pillar")
      if(storeData){
        decoded = decode(storeData)
      }
      return decoded;
    },
    finPillar(){
      return this.pillar ? this.pillar : this.drafted ? this.drafted : null;
    },
  },
  methods: {
    async fetchTcomponents() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`pillarExport/${this.finPillar.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.data = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.progress-wrapper {
  width: 260px !important;
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  background: #f3f3f3 !important;
}

.card-pillars {
  background: #3fb15d21 !important;
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}
</style>