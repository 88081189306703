<template>
  <div>
      <div class="card bg-white border-none mt-3 ml-0 mb-4">
        <div class="card-body pl-0 ml-0">
          <b-row>
            <div class="col-sm col-md">
              <b-row class="ml-2">
                <div class="table-responsive">
                  <table
                    class="
                      table
                      text-13                   
                      table-program-measurements
                      text-left
                      w-100
                    "
                  >
                    
                    <tbody class="tb-body">
                      <tr v-for="(item,i) in measurement" :key="i">
                        <template>
                          <td colspan="6">
                            <tr class="tb-head">
                              <th>Result</th>
                              <th>Indicator</th>
                              <th>Targets</th>
                              <th>Source</th>
                              <th>Frequency</th>
                              <th>Methods</th>
                              <th>Data collections</th>
                            </tr>
                            <tr v-for="(el,i) in item.indicatordata" :key="i">
                              <td class="w-10"># {{item.element}}</td>
                              <td class="w-10">
                                <span>{{el.Title}}</span>
                              </td>
                              <td class="w-10">
                                <span>{{el.target}}</span>
                              </td>
                              <td class="w-10">
                                <span>Source</span>
                              </td>
                              <td>{{el.frequency}}</td>
                              <td> <span>Methods</span> </td>
                              <td><span>{{el.dataCollection}}</span></td>
                            </tr>
                            
                          </td>
                        </template>
                    </tr>                           
                    </tbody>
                  </table>
                </div>
              </b-row>
            </div>
          </b-row>
        </div>
      </div>
  </div>
</template>

<script>
import decode from 'jwt-decode';
export default {
    name:'measurement-tab',
    data(){
      return {
        state: {
          loading: false
        },
        filter:''
      }
    },
    computed: {
      pillar(){
      const storeData = sessionStorage.getItem("pl")
      const pillarData = this.$store.state.pillar_data;
      var decoded = null;
      if(storeData){
        decoded = decode(storeData)
      }
      return pillarData ? pillarData : decoded;
    },
    token(){
      return localStorage.getItem("token");
      },
      measurement(){
        return this.$store.state.selected_pillar.pillar_mplan;
      }
    },
    beforeMount(){
      this.refresh()
    },
    methods: {
      refresh(){
        this.$emit('refresh');
      }
    }
}
</script>

<style scoped>
  .table-all{
    border: 1px solid #cdd0d1 !important;
    border-radius: 4px !important;
    font-size: 12px !important;
  }

  .cursor-pointer{
    cursor: pointer !important;
  }
  
  

  .table-all .tbody-row td{
    text-align: left;
  }

  .row-1{
    border: 1px solid #F5F6F7 !important;
  }

  .card-2{
        border: none !important;
        background-color: transparent !important;
    }

  .table-all .top-panel td span{
    font-size: 13px !important;
  }

  .table-all .theader-projects th{
    font-size: 13px !important;
  }

  .table-all th{
    text-align: left !important;
    
  }

  @media (max-width: 768px) {
    .table-all .theader-projects .table-search{
      width: 165px !important;
    }
  }
</style>