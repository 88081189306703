import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "../vuex/index";
import router from "../router/index";
import { notification } from 'ant-design-vue';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
});

axiosInstance.interceptors.request.use(
  (config) => {
    //  Generate cancel token source
    let source = axios.CancelToken.source();

    // Set cancel token on this request
    config.cancelToken = source.token;

    // Add to vuex to make cancellation available from anywhere
    store.commit("ADD_CANCEL_TOKEN", source);

    // const token = localStorage.getItem("token");

    // if (token) {
    //   config.headers["X-Authorization"] = token;
    // }else{
    //     config.headers["X-Authorization"] = null;
    // }
    const token = localStorage.getItem("token");
    config.headers.Authorization =  `Bearer ${token}`;
    config.headers.Accept =  "application/json";

    return config;
  },

  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    try {
      if (error.response && error.response.status === 500){
       
        let errorData = error.response.data
        console.log("Hello there", errorData.message)

        notification.error({
          message: 'There was an error',
          description:errorData.message,
          onClick: () => {
            console.log('Thank you');
          }
        });
      }
      else if (error.response && error.response.status === 401){
        localStorage.removeItem("token");
        router.push("/logout");
      }
      else if (error.response && error.response.status === 404)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          return Promise.reject({
            errorMessage: error.response.data.message,
          });
        else
          return Promise.reject({
            errorMessage: "can't find the resource you are searching for!",
          });
      else if (error.code === "ECONNABORTED")
        return Promise.reject({
          errorMessage: "Your connection is weak! try again",
        });
      else if (axios.isCancel(error)) return;
      else
        return Promise.reject({
          errorMessage: error.response.data.message || error,
        });
    } catch {
      return Promise.reject({
        errorMessage: "something is wrong! try refreshing this page",
      });
    }
  }
);
Vue.use(VueAxios, axiosInstance);
