<template>
  <div class="main">
    <div class="card card-2 m-0 p-0">
      <div class="card-body p-0 m-0">
        <b-row>
          <div class="col-sm col-md">
            <div class="float-left">
              <span class="label1 cursor-pointer"
                >Label <i class="bx bx-chevron-down"></i
              ></span>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Edit table data columns</span>
                </template>
                <span class="ml-2 pl-2 cursor-pointer"
                  ><i class="bx bx-menu-alt-left"></i> Edit Columns</span
                >
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Edit table filters</span>
                </template>
                <span class="ml-2 pl-2 cursor-pointer"
                  ><i class="bx bx-menu-alt-right"></i> Edit Filters</span
                >
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Export data from table.</span>
                </template>
                <span class="ml-2 pl-2 cursor-pointer"
                  ><i class="bx bxs-square-rounded"></i> Export</span
                >
              </a-tooltip>
            </div>
          </div>
          <div class="col-sm col-md">
            <div class="float-right">
              <span class="ml-n5 cursor-pointer">
                <a-input
                  v-model="filter"
                  style="width: 180px"
                  placeholder="Search"
                >
                  <a-icon slot="prefix" type="search" />
                </a-input>
              </span>
              <span class="ml-3 label2 cursor-pointer"
                >Label <i class="bx bx-chevron-down"></i
              ></span>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Week View</span>
                </template>
                <span class="ml-3 cursor-pointer"
                  ><i class="fas fa-grip-lines-vertical"></i
                  ><i class="fas fa-grip-lines-vertical"></i
                ></span>
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>List daily view</span>
                </template>
                <span class="ml-3 cursor-pointer"
                  ><i class="fas fa-list"></i
                ></span>
              </a-tooltip>
            </div>
          </div>
        </b-row>
        <div class="table-responsive mt-2">
          <b-container class="my-5" v-if="loading">
            <a-skeleton :paragraph="{ rows: 2 }" :loading="loading" />
          </b-container>
          <b-table
            v-else
            table-class="table-logframe text-13"
            striped
            hover
            thead-tr-class="tb-head text-left indent-20"
            tbody-class="tb-body text-left indent-20"
            :items="risks"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            show-empty
          >
             <template v-slot:cell(edit)="data">
              <button
                @click="showEditModal(data.item)"
                class="btn btn-sm btn-outline-success"
              >
                <i class="bx bx-edit-alt"></i>
              </button>
            </template>
            <template v-slot:cell(delete)="data">
              <button
                @click="deleteModal(data.item)"
                class="btn btn-sm btn-outline-danger"
              >
                <i class="bx bx-x"></i>
              </button>
            </template>
          </b-table>
        </div>
        <b-row class="my-4">
          <div class="col-sm">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
            ></b-pagination>
          </div>
          <div class="col-sm-4"></div>
        </b-row>
        <b-row>
          <div class="col-md col-lg"></div>
          <div class="col-sm-3 col-md-3 col-lg-3">
            <a
              @click="createCategoryModal"
              type="button"
              class="
                btn btn-secondary btn-top-create
                float-right
                mr-2
                pr-4
                pl-4
                pt-2
                pb-2
                mb-3
              "
              role="button"
            >
              Add risk Category
            </a>
          </div>
          <div class="col-sm-3 col-md-3 col-lg-3">
            <a
              @click="createConfirmModal"
              type="button"
              class="
                btn btn-secondary btn-top-create
                float-right
                mr-2
                pr-4
                pl-4
                pt-2
                pb-2
                mb-3
              "
              role="button"
            >
              Add risk
            </a>
          </div>
        </b-row>
      </div>
    </div>
    <a-modal v-model="visibleCreate" :footer="null" title="Create risk">
      <form @submit.prevent="addRisk">
        <b-row>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <input
                placeholder="Title"
                type="text"
                class="form-control form-input-modal text-13"
                v-model="risk.title"
              />
            </div>
            <b-form-group>
              <b-input-group>
                <b-select
                  class="rounded-20 select-modal bg-gray-white text-13 mr-3"
                  v-model="risk.category"
                  required
                  :disabled="state.category"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled
                      >Select risk category</b-select-option
                    >
                  </template>
                  <b-select-option
                    v-for="item in categories"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.Title }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right ml-n2"
                    font-scale="1.5"
                    :animation="state.category ? 'spin' : ''"
                    @click="getRidkCategories"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <b-form-textarea
                  id="desc"
                  placeholder="Description"
                  rows="3"
                  resize
                  class="form-text-modal text-13"
                  v-model="risk.description"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="form-group">
              <label for="">Risk rate</label>
              <select
                class="
                  rounded-20
                  custom-select
                  select-modal
                  bg-gray-white
                  text-13
                "
                v-model="risk.rate"
              >
                <option selected value="" disabled>Choose ratings</option>
                <option v-for="(num, i) in 25" :value="i" :key="i">
                  {{ i }}
                </option>
              </select>
            </div>
           
            
            <div class="form-group">
              <label for="">Likelihood</label>
              <select
                class="
                  rounded-20
                  custom-select
                  select-modal
                  bg-gray-white
                  text-13
                "
                v-model="risk.likelihood"
              >
                <option selected value="" disabled>Choose likelihood</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Impact</label>
              <select
                class="
                  rounded-20
                  custom-select
                  select-modal
                  bg-gray-white
                  text-13
                "
                v-model="risk.impact"
              >
                <option selected value="" disabled>Choose impact</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Appetite</label>
              <select
                class="
                  rounded-20
                  custom-select
                  select-modal
                  bg-gray-white
                  text-13
                "
                v-model="risk.appetite"
              >
                <option selected value="" disabled>Choose appetite</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
             <div class="form-group">
              <label for="">Risk treatment</label>
              <select
                class="
                  rounded-20
                  custom-select
                  select-modal
                  bg-gray-white
                  text-13
                "
                v-model="risk.treatment"
              >
                <option selected value="" disabled>Choose treatment</option>
                <option
                  v-for="el in treatments"
                  :value="el.name"
                  :key="el.name"
                >
                  {{ el.name }}
                </option>
              </select>
            </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <div class="inline-block pr-4">
            <button
              type="submit"
              class="btn btn-primary btn-block btn-confirm text-13"
            >
              <b-container v-if="state.adding">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Saving...</span>
              </b-container>
              <span v-if="!state.adding" class="text text-13">Ok</span>
            </button>
          </div>
          <div class="inline-block">
            <a
              @click="cancelConfirmModal"
              type="button"
              class="btn btn-secondary btn-block btn-cancel text-13"
              >CANCEL</a
            >
          </div>
        </b-row>
      </form>
    </a-modal>
    <a-modal
      v-model="visibleCreateCtg"
      :footer="null"
      title="Create risk category"
    >
      <form @submit.prevent="addRiskCategory">
        <b-row>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <input
                placeholder="Title"
                type="text"
                class="form-control form-input-modal text-13"
                v-model="title"
              />
            </div>
            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <b-form-textarea
                  id="desc"
                  placeholder="Description"
                  rows="3"
                  resize
                  class="form-text-modal text-13"
                  v-model="description"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <div class="inline-block pr-4">
            <button
              type="submit"
              class="btn btn-primary btn-block btn-confirm text-13"
            >
              <b-container v-if="state.adding">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Saving...</span>
              </b-container>
              <span v-if="!state.adding" class="text text-13">Ok</span>
            </button>
          </div>
          <div class="inline-block">
            <a
              @click="cancelCreateCtg"
              type="button"
              class="btn btn-secondary btn-block btn-cancel text-13"
              >CANCEL</a
            >
          </div>
        </b-row>
      </form>
    </a-modal>
    <!-- Update risk modal -->
    <a-modal v-model="visibleEdit" :footer="null" title="Update risk">
      <form @submit.prevent="updateRisk(data)">
        <b-row>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <input
                placeholder="Title"
                type="text"
                class="form-control form-input-modal text-13"
                v-model="risk.title"
              />
            </div>
            <b-form-group>
              <b-input-group>
                <b-select
                  class="rounded-20 select-modal bg-gray-white text-13 mr-3"
                  v-model="risk.category"
                  required
                  :disabled="state.category"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled
                      >Select risk category</b-select-option
                    >
                  </template>
                  <b-select-option
                    v-for="item in categories"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.Title }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right ml-n2"
                    font-scale="1.5"
                    :animation="state.category ? 'spin' : ''"
                    @click="getRidkCategories"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
           
            <div class="form-group">
              <label for="">Risk treatment</label>
              <select
                class="
                  rounded-20
                  custom-select
                  select-modal
                  bg-gray-white
                  text-13
                "
                v-model="risk.treatment"
              >
                <option selected value="" disabled>Choose treatment</option>
                <option
                  v-for="el in treatments"
                  :value="el.name"
                  :key="el.name"
                >
                  {{ el.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <b-form-textarea
                  id="desc"
                  placeholder="Description"
                  rows="3"
                  resize
                  class="form-text-modal text-13"
                  v-model="risk.description"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="form-group">
              <label for="">Likelihood</label>
              <select
                class="
                  rounded-20
                  custom-select
                  select-modal
                  bg-gray-white
                  text-13
                "
                v-model="risk.likelihood"
              >
                <option selected value="" disabled>Choose likelihood</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Impact</label>
              <select
                class="
                  rounded-20
                  custom-select
                  select-modal
                  bg-gray-white
                  text-13
                "
                v-model="risk.impact"
              >
                <option selected value="" disabled>Choose impact</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Appetite</label>
              <select
                class="
                  rounded-20
                  custom-select
                  select-modal
                  bg-gray-white
                  text-13
                "
                v-model="risk.appetite"
              >
                <option selected value="" disabled>Choose appetite</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
             <div class="form-group">
              <label for="">Risk rate</label>
              <select
                class="
                  rounded-20
                  custom-select
                  select-modal
                  bg-gray-white
                  text-13
                "
                v-model="risk.rate"
              >
                <option selected value="" disabled>Choose ratings</option>
                <option v-for="(num, i) in 25" :value="i" :key="i">
                  {{ i }}
                </option>
              </select>
            </div>
          </div>
        </b-row>
       
        <b-row class="d-flex justify-content-center">
          <div class="inline-block pr-4">
             <button
              :disabled="state.saving"
              type="submit"
              class="btn btn-primary btn-block btn-confirm"
            >
              <b-container v-if="state.saving">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Updating...</span>
              </b-container>
              <span v-if="!state.saving" class="text text-13">Update risk</span>
            </button>
          </div>
          <div class="inline-block">
            <a
              @click="visibleEdit = false"
              type="button"
              class="btn btn-secondary btn-block btn-cancel text-13"
              >CANCEL</a
            >
          </div>
        </b-row>
      </form>
    </a-modal>
    <!-- Delete risk modal -->
    <a-modal v-model="visibleDelete" :footer="null" title="Delete risk">
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to delete this information ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <a-popconfirm
            title="Are you sure delete this risk?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteRisk(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="state.deleting">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Deleting...</span>
              </b-container>
              <span v-if="!state.deleting" class="text text-13">DELETE</span>
            </button>
          </a-popconfirm>
        </div>
        <div class="inline-block">
          <button
            @click="visibleDelete = false"
            type="submit"
            class="btn btn-secondary btn-block btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
      </b-row>
    </a-modal>
  </div>
</template>

<script>
import decode from "jwt-decode";
export default {
  props: {
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      visibleCreate: false,
      visibleCreateCtg: false,
      visibleDelete: false,
      visibleEdit: false,
      title: null,
      description: null,
      state: {
        adding: false,
        loading: false,
        category: false,
        deleting: false,
        saving: false,
        refresh: false
      },
      risk: {
        title: "",
        description: "",
        likelihood: null,
        impact: null,
        rate: null,
        category: null,
        treatment: null,
        appetite: null,
      },
      treatments: [
        { name: "accept" },
        { name: "avoid" },
        { name: "mitigate" },
        { name: "transfer" },
      ],
      categories: [],
      fields: [
        "Title",
        "Description",
        "likelihood",
        "impact",
        "rate",
        "treatment",
        "appetite",
        "edit",
        "delete",
      ],
      perPage: 8,
      currentPage: 1,
      filter: "",
      data: null,
    };
  },
  computed: {
    rows() {
      return this.risks.length;
    },
    pillar(){
      const storeData = sessionStorage.getItem("pl")
      const pillarData = this.$store.state.pillar_data;
      var decoded = null;
      if(storeData){
        decoded = decode(storeData)
      }
      return pillarData ? pillarData : decoded;
    },
    token() {
      return localStorage.getItem("token");
    },
    risks(){
      return this.$store.state.selected_pillar.pillar_risk;
    }
  },
  beforeMount() {
    this.getRidkCategories();
    this.refresh()
  },
  methods: {
    async getRidkCategories() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.category = true;
      await this.axios
        .get(`getRiskCategories`, config)
        .then((response) => {
          if (response.data) {
            this.state.category = false;
            this.categories = response.data;
          } else {
            this.state.category = false;
          }
        })
        .catch(() => {
          this.state.category = false;
        });
    },
    async addRiskCategory() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        Title: this.title,
        Description: this.description,
      };
      this.state.adding = true;
      await this.axios
        .post("AddRiskCategory", requestData, config)
        .then((response) => {
          if (response.data.message) {
            this.state.adding = false;
            this.$message.error(`${response.data.message}`, 4);
          } else {
            this.state.adding = false;
            this.visibleCreateCtg = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Risk category has been added successfully.`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.adding = false;
        });
    },
    async addRisk() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        Title: this.risk.title,
        Description: this.risk.description,
        likelihood: this.risk.likelihood,
        impact: this.risk.impact,
        rate: this.risk.rate,
        CategoryId: this.risk.category,
        treatment: this.risk.treatment,
        appetite: this.risk.appetite,
        elementId: this.pillar.id,
        scope:"pillar",
      };
      this.state.adding = true;
      await this.axios
        .post(`AddRisk`, requestData, config)
        .then((response) => {
          if (response.data.message) {
            this.getRisks();
            this.state.adding = false;
            this.$message.error(`${response.data.message}`, 4);
          } else {
            this.refresh();
            this.state.adding = false;
            this.visibleCreate = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Risk has been added successfully.`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.adding = false;
        });
    },
    async updateRisk(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      const reqData = {
        Title: this.risk.title,
        Description: this.risk.description,
        likelihood: this.risk.likelihood,
        impact: this.risk.impact,
        rate: this.risk.rate,
        CategoryId: this.risk.category,
        treatment: this.risk.treatment,
        appetite: this.risk.appetite,
        elementId: this.pillar.id
      }

      this.state.saving = true;
      await this.axios
        .put(`updateRisk/${param.id}`,reqData,config)
        .then((response) => {
          if (response) {
            this.state.saving = false;
            this.visibleEdit = false;
            this.refresh();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Risk changed successfully.`,
              duration: 5,
            });
          } else {
            this.state.saving = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.saving = false;
        });
    },
    async deleteRisk(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.state.deleting = true;
      await this.axios
        .delete(`deleteRisk/${param.id}`, config)
        .then((response) => {
          if (response) {
            this.state.deleting = false;
            this.visibleDelete = false;
            this.refreshrefresh();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Risk deleted successfully.`,
              duration: 5,
            });
          } else {
            this.state.deleting = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.deleting = false;
        });
    },
    createConfirmModal() {
      this.visibleCreate = true;
    },
    cancelConfirmModal() {
      this.visibleCreate = false;
    },
    createCategoryModal() {
      this.visibleCreateCtg = true;
    },
    cancelCreateCtg() {
      this.visibleCreateCtg = false;
    },
    deleteModal(param) {
      this.data = param;
      this.visibleDelete = true;
    },
    showEditModal(param) {
      this.data = param;
      this.visibleEdit = true;
      this.risk.title = param.Title;
      this.risk.description = param.Description;
      this.risk.appetite = param.appetite;
      this.risk.likelihood = param.likelihood;
      this.risk.impact = param.impact;
      this.risk.rate = param.rate;
      this.risk.treatment = param.treatment;
    },
    cancel(e) {
      console.log(e);
      this.visibleDelete = false;
      this.$message.error("Deleting risk has been canceled.");
    },
    refresh(){
      this.$emit('refresh');
    }
  },
};
</script>

<style scoped>
.table-all {
  border: 1px solid #cdd0d1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-top-create {
  background: #0fa958 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.table-all .tbody-row td {
  text-align: left;
}

.row-1 {
  border: 1px solid #f5f6f7 !important;
}

.card-2 {
  border: none !important;
  background-color: transparent !important;
}

.table-all .top-panel td span {
  font-size: 13px !important;
}

.table-all .theader-projects th {
  font-size: 13px !important;
}

.table-all th {
  text-align: left !important;
}

@media (max-width: 768px) {
  .table-all .theader-projects .table-search {
    width: 165px !important;
  }
}
</style>