<template>
  <div>
      <div class="card b-none border-none">
        <div class="card-body">
            <b-row class="mt-5 mb-4">
              <div class="col-sm-2 col-md-2 col-lg-2">
                  <div class="d-flex justify-content-start ml-0 mt-2">
                    <h6 class="title-h6">Reports</h6>
                </div>
              </div>
              <div class="col-sm col-md col-lg">
                    <div class="btn btn-light btn-ip btn-sm btn-block mt-2 text-white">Reporting template(IP)</div>
              </div>
              <div class="col-sm col-md col-lg">
                    <div class="btn btn-light btn-pm btn-sm btn-block mt-2 text-white">Reporting template(PM)</div>
              </div>
              <div class="col-sm col-md col-lg"></div>
          </b-row>
          <b-row class="mt-4">
              <div class="table-responsive ml-2">
                    <table class="table bg-white table-indicators table-reports">
                        <thead>
                            <tr class="theader-indicator">
                                <th class="text-left tborder" scope="col">
                                    <span class="ml-3">Report titles</span>
                                </th>
                                <th class="text-left tborder" scope="col">
                                    <span class="ml-3">Sent by</span>
                                </th>
                                <th class="text-left tborder" scope="col">
                                    <span class="ml-3">Deadline</span>
                                </th>
                                <th class="text-left tborder" scope="col">
                                    <span class="ml-3">Status</span>
                                </th>
                                <th class="text-left" scope="col">
                                    <span class="ml-3">Action</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="tborder"><span class="ml-3">Pillar Quately Report</span></td>
                                <td class="tborder"><span class="ml-3">Maximillian</span></td>
                                <td class="tborder"><span class="ml-3">20, Aug 2022</span></td>
                                <td class="tborder"><span class="status-pending ml-3">Pending</span></td>
                                <td>
                                    <span class="text-center">
                                        <button class="btn btn-sm rounded-20 text-11 btn-block btn-report status-btn btn-warning">SUBMIT</button>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="tborder"><span class="ml-3">Monthly Project Report</span></td>
                                <td class="tborder"><span class="ml-3">Maximillian</span></td>
                                <td class="tborder"><span class="ml-3">20, Aug 2022</span></td>
                                <td class="tborder"><span class="text-success ml-3">Approved</span></td>
                                <td>
                                    <span class="text-center">
                                        <button class="btn btn-sm rounded-20 text-11 btn-block btn-report text-white btn-success">View report</button>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="tborder"><span class="ml-3">SACCO Weekly Report</span></td>
                                <td class="tborder"><span class="ml-3">Maximillian</span></td>
                                <td class="tborder"><span class="ml-3">20, Aug 2022</span></td>
                                <td class="tborder"><span class="status-completed ml-3">Completed</span></td>
                                <td>
                                    <span class="text-center">
                                        <button class="btn btn-sm rounded-20 text-11 btn-block btn-report btn-success">View report</button>
                                        </span>
                                    </td>
                            </tr>
                            <tr>
                                <td class="tborder"><span class="ml-3">Pillar 2 Quately Report</span></td>
                                <td class="tborder"><span class="ml-3">Maximillian</span></td>
                                <td class="tborder"><span class="ml-3">20, Aug 2022</span></td>
                                <td class="tborder"><span class="status-rejected ml-3">Rejected</span></td>
                                <td>
                                    <span class="text-center">
                                        <button class="btn btn-sm btn-block text-11 rounded-20 btn-report btn-rose text-dark btn-rejected">View Comments</button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
              </div>
          </b-row>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    name:'program-donors-tab'
}
</script>

<style>

</style>