import { render, staticRenderFns } from "./approvers-list.vue?vue&type=template&id=3eda7f87&scoped=true&"
import script from "./approvers-list.vue?vue&type=script&lang=js&"
export * from "./approvers-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eda7f87",
  null
  
)

export default component.exports