<template>
  <div>
    <adminTopBar :title="title" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0">
      <div class="container-fluid main-container">
        <div class="jumbotron pl-0 pr-0 pt-0 mt-0 b-none">
          <b-row class="mt-0 row-admin mb-4">
            <div class="col-sm-4 col-xs-4 col-md-4 mt-3">
              <div
                class="card bg-gray border-none rounded mt-3 rounded-10"
              >
                <div class="card-body p-2 mt-2 ml-2">
                  <div class="d-flex justify-content-between">
                    <div class="text-left">
                      <h5 class="card-title text-dark">All roles</h5>
                    </div>
                    <div class="p-0">
                      <p>{{all_numbers ? all_numbers.allroles : 0}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-xs-4 col-md-4 mt-3">
              <div
                class="card rounded bg-gray border-none mt-3 rounded-10"
              >
                <div class="card-body p-2 mt-2 ml-2">
                  <div class="d-flex justify-content-between">
                    <div class="text-left">
                      <h5 class="card-title text-dark">All permissions</h5>
                    </div>
                    <div class="p-0">
                      <p>10</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-xs-4 col-md-4 mt-3">
              <div
                class="card rounded bg-gray border-none mt-3 rounded-10"
              >
                <div class="card-body p-2 mt-2 ml-2">
                  <div class="d-flex justify-content-between">
                    <div class="text-left">
                      <h5 class="card-title text-dark">All users</h5>
                    </div>
                    <div class="p-0">
                      <p>{{all_numbers ? all_numbers.allusers : 0}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm">
              <div class="card bg-gray border-none rounded-20 table-data">
                <div class="card-header rounded-20 bg-gray border-none">
                  <div class="row d-flex justify-content-between">
                    <div class="text-left left-label col-xs">
                      <h6 class="mt-2 ml-4">All Roles</h6>
                    </div>
                    <div class="p-2 col-xs">
                      <router-link :to="{name:'admin-roles'}">
                        <button class="btn btn-outline-primary mr-4 btn-custom-primary btn-add">
                          BACK <i class="bx bx-left-arrow-alt"></i>
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <b-row class="d-flex justify-content-start">
                    <h5 class="text-success text-left ml-4">CEO</h5>
                  </b-row>
                  <b-row>
                    <div class="col-sm col-md">
                      <div class="accordion" id="accordionExample">
                        <div class="card card-role">
                          <div class="card-header" id="headingOne">
                            <h2 class="mb-0 d-flex justify-content-between">
                              <button
                                @click="changeCollapse"
                                class="btn btn-link text-dark text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                Users
                              </button>
                              <span v-if="collapse" class="">
                                <label class="toggle-label p-1 mr-2"
                                  >Enabled</label
                                >
                                <a-switch
                                  style="opacity: 1 !important"
                                  class="bg-success switch"
                                  default-checked
                                  :disabled="true"
                                  checked-children="ON"
                                  un-checked-children="OFF"
                                />
                              </span>
                              <span v-if="collapse" class="">
                                <label class="toggle-label p-1 mr-2"
                                  >Disabled</label
                                >
                                <a-switch
                                  style="opacity: 1 !important"
                                  default-un-checked
                                  :disabled="true"
                                  checked-children="ON"
                                  un-checked-children="OFF"
                                />
                              </span>
                              <span
                                class=""
                                @click="changeCollapse"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                              >
                                <i
                                  v-if="!collapse"
                                  class="
                                    text-success
                                    bx bx-down-arrow-circle bx-md
                                    cursor-pointer
                                  "
                                ></i>
                                <i
                                  v-else
                                  class="
                                    text-success
                                    bx bx-up-arrow-circle bx-md
                                    cursor-pointer
                                  "
                                ></i>
                              </span>
                            </h2>
                          </div>

                          <div
                            id="collapseOne"
                            class="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <b-row class="d-flex justify-content-between">
                                <div class="permissions">
                                  <ul class="text-left">
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Create new user
                                    </li>
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Read all users
                                    </li>
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Edit user
                                    </li>
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Delete user
                                    </li>
                                  </ul>
                                </div>
                                <div class="toggles">
                                  <ul>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        default-checked
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        :default-checked="false"
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        default-checked
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        default-checked
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </b-row>
                            </div>
                          </div>
                        </div>
                        <div class="card card-role mt-2">
                          <div class="card-header" id="headingTwo">
                            <h2 class="mb-0 d-flex justify-content-between">
                              <button
                                @click="changeCollapse1"
                                class="btn btn-link text-dark text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                              >
                                Project
                              </button>
                              <span v-if="collapse1" class="">
                                <label class="toggle-label p-1 mr-2"
                                  >Enabled</label
                                >
                                <a-switch
                                  style="opacity: 1 !important"
                                  class="bg-success switch"
                                  default-checked
                                  :disabled="true"
                                  checked-children="ON"
                                  un-checked-children="OFF"
                                />
                              </span>
                              <span v-if="collapse1" class="">
                                <label class="toggle-label p-1 mr-2"
                                  >Disabled</label
                                >
                                <a-switch
                                  style="opacity: 1 !important"
                                  default-un-checked
                                  :disabled="true"
                                  checked-children="ON"
                                  un-checked-children="OFF"
                                />
                              </span>
                              <span
                                class=""
                                @click="changeCollapse1"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                              >
                                <i
                                  v-if="!collapse1"
                                  class="
                                    text-success
                                    bx bx-down-arrow-circle bx-md
                                    cursor-pointer
                                  "
                                ></i>
                                <i
                                  v-else
                                  class="
                                    text-success
                                    bx bx-up-arrow-circle bx-md
                                    cursor-pointer
                                  "
                                ></i>
                              </span>
                            </h2>
                          </div>
                          <div
                            id="collapseTwo"
                            class="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <b-row class="d-flex justify-content-between">
                                <div class="permissions">
                                  <ul class="text-left">
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Create project
                                    </li>
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Read all users
                                    </li>
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Edit user
                                    </li>
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Delete user
                                    </li>
                                  </ul>
                                </div>
                                <div class="toggles">
                                  <ul>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        default-checked
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        :default-checked="false"
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        default-checked
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        default-checked
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </b-row>
                            </div>
                          </div>
                        </div>
                        <div class="card card-role mt-2">
                          <div class="card-header" id="headingThree">
                            <h2 class="mb-0 d-flex justify-content-between">
                              <button
                                @click="changeCollapse2"
                                class="btn btn-link text-dark text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                                aria-expanded="true"
                                aria-controls="collapseThree"
                              >
                                Project plan
                              </button>
                              <span v-if="collapse2" class="">
                                <label class="toggle-label p-1 mr-2"
                                  >Enabled</label
                                >
                                <a-switch
                                  style="opacity: 1 !important"
                                  class="bg-success switch"
                                  default-checked
                                  :disabled="true"
                                  checked-children="ON"
                                  un-checked-children="OFF"
                                />
                              </span>
                              <span v-if="collapse2" class="">
                                <label class="toggle-label p-1 mr-2"
                                  >Disabled</label
                                >
                                <a-switch
                                  style="opacity: 1 !important"
                                  default-un-checked
                                  :disabled="true"
                                  checked-children="ON"
                                  un-checked-children="OFF"
                                />
                              </span>
                              <span
                                class=""
                                @click="changeCollapse2"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                              >
                                <i
                                  v-if="!collapse2"
                                  class="
                                    text-success
                                    bx bx-down-arrow-circle bx-md
                                    cursor-pointer
                                  "
                                ></i>
                                <i
                                  v-else
                                  class="
                                    text-success
                                    bx bx-up-arrow-circle bx-md
                                    cursor-pointer
                                  "
                                ></i>
                              </span>
                            </h2>
                          </div>
                          <div
                            id="collapseThree"
                            class="collapse"
                            aria-labelledby="headingThree"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <b-row class="d-flex justify-content-between">
                                <div class="permissions">
                                  <ul class="text-left">
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Create new user
                                    </li>
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Read all users
                                    </li>
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Edit user
                                    </li>
                                    <li class="mt-3">
                                      <i class="bx bx-cog text-secondary"></i>
                                      Delete user
                                    </li>
                                  </ul>
                                </div>
                                <div class="toggles">
                                  <ul>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        default-checked
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        :default-checked="false"
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        default-checked
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                    <li class="mt-3 mr-4">
                                      <a-switch
                                        class=""
                                        default-checked
                                        :disabled="false"
                                        checked-children="ON"
                                        un-checked-children="OFF"
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </b-row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-row>
                </div>
              </div>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      collapse: true,
      collapse1: false,
      collapse2: false,
      title: "assigned roles"
    };
  },
  computed: {
     all_numbers(){
      return this.$store.state.numbers;
    }
  },
  beforeMount(){
      this.$title("ADMIN - Assigned Roles")
  },
  methods: {
    changeCollapse() {
      this.collapse = !this.collapse;
      if (this.collapse) {
        this.collapse1 = false;
        this.collapse2 = false;
      }
    },
    changeCollapse1() {
      this.collapse1 = !this.collapse1;
      if (this.collapse1) {
        this.collapse = false;
        this.collapse2 = false;
      }
    },
    changeCollapse2() {
      this.collapse2 = !this.collapse2;
      if (this.collapse2) {
        this.collapse = false;
        this.collapse1 = false;
      }
    },
  },
};
</script>

<style scoped>
*,
html,
body {
  font-family: Ubuntu;
}
/* Permission styles */
.permissions ul li,
.toggles ul li {
  list-style-type: none;
}

</style>