<template>
  <div>
    <adminTopBar :title="title" />
    <main role="main" class="col-sm-9 ml-sm-auto main-home col-md-10 pt-0">
      <b-row class="mt-5" v-if="state.loading">
        <b-col cols="12">
          <a-skeleton active :loading="state.loading" />
          <a-skeleton active :loading="state.loading" />
          <a-skeleton active :loading="state.loading" />
        </b-col>
      </b-row>
      <div v-else>
        <b-row class="mt-3 row-admin">
          <div class="col-sm">
            <router-link to="users">
              <div class="card mt-3 shadow p-2">
                <div class="card-body">
                  <b-row class="d-flex justify-content-between">
                    <div class="d-inline ml-3">
                      <h5 class="card-title">Users</h5>
                      <p class="card-text">
                        {{ all_numbers ? all_numbers.allusers : 0 }}
                      </p>
                    </div>
                    <div class="d-inline">
                      <b-img
                        class="dash-img"
                        :src="
                          require('../../../assets/images/admin/dashboard/user.png')"
                      ></b-img>
                    </div>
                  </b-row>
                </div>
                <small class="d-inline ml-n5 text-dark"
                  ><label class="text-success">7.43%</label>
                  <i class="bx bx-right-top-arrow-circle text-success"></i> more
                  than last month</small
                >
              </div>
            </router-link>
          </div>

          <div class="col-sm">
            <router-link to="roles">
              <div class="card mt-3 shadow p-2">
                <div class="card-body">
                  <b-row class="d-flex justify-content-between">
                    <div class="d-inline ml-3">
                      <h5 class="card-title">Roles</h5>
                      <p class="card-text">
                        {{ all_numbers ? all_numbers.allroles : 0 }}
                      </p>
                    </div>
                    <div class="d-inline">
                      <b-img
                        class="dash-img"
                        :src="
                          require('../../../assets/images/admin/dashboard/roles.png')"
                      ></b-img>
                    </div>
                  </b-row>
                </div>
                <small
                  style="visiblity: hidden"
                  class="d-inline ml-n5 text-white"
                  ><label class="text-white">71.15%</label>
                  <i class="bx bx-right-top-arrow-circle text-white"></i> more
                  than last month</small
                >
              </div>
            </router-link>
          </div>
          <div class="col-sm">
            <div class="card mt-3 shadow p-2">
              <div class="card-body">
                <b-row class="d-flex justify-content-between">
                  <div class="d-inline ml-3">
                    <h5 class="card-title text-capitalize">{{ keywords.project ? `${keywords.project}s` : 'Projects' }}</h5>
                    <p class="card-text">
                      {{ all_numbers ? all_numbers.projects : 0 }}
                    </p>
                  </div>
                  <div class="d-inline">
                    <b-img
                      class="dash-img"
                      :src="
                        require('../../../assets/images/admin/dashboard/projects.png')"
                    ></b-img>
                  </div>
                </b-row>
              </div>
              <small class="d-inline ml-n5 text-dark"
                ><label class="text-success">27.80%</label>
                <i class="bx bx-right-top-arrow-circle text-success"></i> more
                than last month</small
              >
            </div>
          </div>
          <div class="col-sm">
            <router-link to="workflows">
              <div class="card mt-3 shadow p-2">
                <div class="card-body">
                  <b-row class="d-flex justify-content-between">
                    <div class="d-inline ml-3">
                      <h5 class="card-title text-capitalize">{{ keywords.workflow ? `${keywords.workflow}s` : 'Workflows' }}</h5>
                      <p class="card-text ml-0">
                        {{ all_numbers ? all_numbers.allworkflows : 0 }}
                      </p>
                    </div>
                    <div class="d-inline">
                      <b-img
                        class="dash-img"
                        :src="
                          require('../../../assets/images/admin/dashboard/workflows.png')"
                      ></b-img>
                    </div>
                  </b-row>
                </div>
                <small class="d-inline ml-n5 text-dark"
                  ><label class="text-success">22.05%</label>
                  <i class="bx bx-right-top-arrow-circle text-success"></i> more
                  than last month</small
                >
              </div>
            </router-link>
          </div>
        </b-row>

        <div class="container-fluid statistics-wrapper">
          <div class="jumbotron b-none">
            <b-row class="mt-n5">
              <div class="col-sm-8">
                <div class="card mt-3 shadow p-3">
                  <div class="card-body">
                    <h6 class="card-title text-left ml-5">
                      Information statistics for One year
                    </h6>
                    <b-img
                      style="height: 200px"
                      fluid
                      :src="
                        require('../../../assets/images/admin/charts/chart1.png')"
                    ></b-img>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card mt-3 shadow p-3">
                  <div class="card-body">
                    <h6 class="card-title text-left ml-5">Daily statistics</h6>
                    <b-img
                      style="height: 200px"
                      fluid
                      :src="
                        require('../../../assets/images/admin/charts/chart-2-1.png')"
                    ></b-img>
                  </div>
                </div>
              </div>
            </b-row>
            <div class="card mt-3">
              <div class="card-body">
                <h6 class="card-title text-14 text-left ml-5">Set current program</h6>
                <div class="b-bottom w-25 ml-5"></div>
                <b-form class="pl-5 pr-5">
                  <b-row class="mt-4">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="float-left text-muted text-12">Selected current program</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            readonly
                            :value="program ? program.name : null"
                            class="form-control form-input"
                            aria-describedby="basic-addon2"
                          />
                          <a-popover
                            title="Change the current program"
                            trigger="click"
                          >
                            <template #content>
                              <b-form @submit.prevent="setCurrentProgramConfirm">
                                <b-input-group>
                                  <b-select
                                    class="
                                      select-modal
                                      bg-gray-white
                                      rounded-20
                                      text-13
                                      mr-3
                                    "
                                    v-model="edit.program"
                                    required
                                    :disabled="state.programs"
                                  >
                                    <template #first>
                                      <b-select-option
                                        :value="null"
                                        selected
                                        disabled
                                        >Select the program</b-select-option
                                      >
                                    </template>
                                    <b-select-option
                                      v-for="item in programs"
                                      :key="item.id"
                                      :value="item"
                                    >
                                      {{ item.name }}
                                    </b-select-option>
                                  </b-select>
                                  <b-input-group-append>
                                    <b-icon-arrow-repeat
                                      class="bi-con-right ml-n2"
                                      font-scale="1.5"
                                      :animation="state.programs ? 'spin' : ''"
                                      @click="getPrograms"
                                    />
                                  </b-input-group-append>
                                </b-input-group>
                                <b-form-group>
                                    <button
                                    :disabled="state.updating"
                                    type="submit"
                                    class="
                                      btn
                                      btn-success
                                      btn-sm
                                      btn-confirm
                                      btn-block
                                      mt-3
                                    "
                                  >
                                    <b-container
                                      v-if="state.updating"
                                    >
                                      <b-spinner small></b-spinner>
                                      <span class="text-13 ml-2"
                                        >Saving...</span
                                      >
                                    </b-container>
                                    <span
                                      v-if="!state.updating"
                                      class="text text-13"
                                      >Save Changes</span
                                    >
                                  </button>
                                </b-form-group>
                              </b-form>
                            </template>
                            <div class="input-group-append rounded-right-10">
                              <span
                                class="input-group-text rounded-right-10"
                                id="basic-addon2"
                              >
                                <b-container
                                    v-if="state.updating"
                                    >
                                      <b-spinner small></b-spinner>
                                      <span class="text-13 ml-2"
                                        >Saving...</span
                                      >
                                    </b-container>
                                <a v-else href="#!" class="text-success text-12">
                                  Change the program
                                  </a>
                              </span>
                            </div>
                          </a-popover>
                        </div>
                      </div>
                    </div>
                  </b-row>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import arrowIcon from "../components/icons/arrow.vue";
export default {
  component: {
    arrowIcon,
  },
  data() {
    return {
      active: true,
      title: "dashboard",
      numbers: null,
      state: {
        loading: false,
        programs: false,
        updating: false
      },
      programs: null,
      program: null,
      edit: {
        program: null
      }
    };
  },
  beforeMount() {
    this.$title("ADMIN - Dashboard");
    this.getNumbers();
    this.getPrograms();
    this.getCurrentProgram();
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    all_numbers() {
      return this.$store.state.numbers;
    },
    keywords(){
      return this.$store.state.current_program ? this.$store.state.current_program.keyWords : null;
    }
  },
  watch: {},
  methods: {
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if (response.data) {
            this.program = response.data[0];
            this.$store.dispatch("setCurrentProgram", response.data[0])
            console.log("Current program", response.data[0])
          }
        })
        .catch(() => {
          // this.$notification["error"]({
          //   message: "ERROR!",
          //   description: `${error.errorMessage}`,
          //   duration: 5,
          // });
        });
    },
    async setCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const reqData = {
        programId: this.edit.program ? this.edit.program.id : 0,
      };
      this.state.updating = true;
      await this.axios
        .post("selectCurrentProgram", reqData, config)
        .then(() => {
          this.state.updating = false;
          this.$notification["success"]({
            message: "SUCCESS!",
            description: `Current program set successful.`,
            duration: 5,
          });
          this.getCurrentProgram()
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
          this.state.updating = false;
        });
    },
    async getNumbers() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getNumbers", config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setNumbers", response.data);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          // this.$notification["error"]({
          //   message: "ERROR!",
          //   description: `${error.errorMessage}`,
          //   duration: 5,
          // });
          this.state.loading = false;
        });
    },
    async getPrograms() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.programs = true;
      await this.axios
        .get("getPrograms", config)
        .then((response) => {
          if (response.data) {
            this.state.programs = false;
            this.programs = response.data;
          } else {
            this.state.programs = false;
          }
        })
        .catch(() => {
          this.state.programs = false;
        });
    },
    setCurrentProgramConfirm() {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to change the current program.`,
          {
            title: "Please Confirm",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES, confirm",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            this.setCurrentProgram();
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
          this.$notify({
            title: "ERROR!",
            message: "Oops, an error occured.",
            status: "danger",
          });
        });
    },
    cancel(e) {
      console.log(e);
      this.$message.error("Changes has been canceled.");
    },
  },
};
</script>

<style scoped>
* {
  font-family: Ubuntu;
}
body {
  font-family: Ubuntu;
  background-color: #f6f6f6 !important;
}

.j1-admin {
  background-image: url("../../../assets/images/admin/bread-cumb.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-position: 0 50px !important;
  background-color: #fff !important;
}

.statistics-wrapper {
  /* margin-top: -5%; */
}

/* icons */
.dash-img {
  width: 75px;
  height: 75px;
}
/* end of icons styles */

.j1-admin h3 {
  font-size: 20px;
}

.j1-admin p {
  font-size: 16px;
}

.ct-admin {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.card {
  border-radius: 20px !important;
  text-align: center !important;
}

.row-admin {
  margin-top: 10px !important;
}

.card h5 {
  font-style: normal;
  font-family: Ubuntu !important;
  font-size: 14px !important;
  color: #69737f !important;
}

.card p {
  font-style: normal;
  font-family: Ubuntu !important;
  font-size: 40px !important;
  color: #69737f !important;
  font-weight: bold;
}

@media screen and (max-width: 578px) {
  .j1-admin {
    background-color: contain;
    background-image: none;
    background-color: #0fa958 !important;
  }

  .j1-admin .today {
    padding-top: 10px;
  }

  .j1-admin .j1-row {
    padding: 10px;
    margin-top: 1rem;
  }

  .j1-admin h3 {
    font-size: 16px;
  }

  .j1-admin p {
    font-size: 13px;
  }
}

.collapse .show {
  background-color: #0fa958 !important;
}
</style>