<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto main col-md-10 pt-0">
      <div class="card card-main card-0 mt-4 mb-6 ml-0">
        <div class="card-body pl-0 ml-0">
          <b-row>
              <b-col class="text-left mb-2">
                <h6 v-if="workFlowName || elementTitle" class="text-bold ml-2">
                  {{workFlowName}} / {{ elementTitle }}
                </h6>
              </b-col>
            </b-row>
          <b-row>
            <div class="col-sm-9"></div>
            <div class="col-sm">
              <form>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control input-search rounded-left-10"
                    aria-label="Search"
                    placeholder="Search ..."
                    v-model="filter"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right-10">
                      <i class="bx bx-search text-success"></i>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </b-row>
          <b-row class="mb-2">
            <div class="col-sm-9 col-md-9"></div>
            <div class="col-sm col-md text-right">
                <button
                @click="refresh"
                type="button"
                class="btn btn-secondary btn-sm rounded-10 border-none"
                >
                <b-container v-if="state.loading && state.refresh">
                    <b-spinner small></b-spinner>
                    <span class="text-13 ml-2">Refreshing...</span>
                </b-container>
                <span v-else class="text text-13">Refresh</span>
                </button>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm col-md">
              <div class="table-responsive">
                <b-container v-if="state.loading">
                    <a-skeleton active :loading="state.loading" :paragraph="{rows: 8}" ></a-skeleton>
                </b-container>
                <b-table
                  v-else
                  table-class="table-approvers text-13 indent-10"
                  striped
                  hover
                  thead-tr-class="tb-header"
                  tbody-class="tb-body indent-10"
                  :items="approversList"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="headers"
                  show-empty
                >
                  <template v-slot:cell(approver_name)="data">
                      <span>{{data.item.fullnames}}</span>
                  </template>
                  <template v-slot:cell(status)="data">
                    <button
                      v-if="data.item.status == 'pending'"
                      class="btn btn-sm btn-info text-white text-13"
                    >
                      Pending
                    </button>
                    <button
                      v-else-if="data.item.status == 'rejected'"
                      class="btn btn-sm btn-rose text-white text-13"
                    >
                      Rejected ({{data.item.message}})
                    </button>
                    <button
                      v-else-if="data.item.status == 'approved'"
                      class="btn btn-sm btn-success text-white text-13"
                    >
                     <i class="bx bx-check-double"></i>  Approved
                    </button>
                  </template>
                  <template
                    class="justify-content-center"
                    v-slot:cell(approvers)="data"
                  >
                    <a-tooltip placement="bottom">
                      <template slot="title">
                        <span>Approve request</span>
                      </template>
                      <button
                        v-if="data.item.status == 'pending'"
                        class="btn btn-sm btn-outline-success mr-2"
                      >
                        <i class="bx bx-check text-bold"></i>
                      </button>
                    </a-tooltip>
                    <a-tooltip placement="bottom">
                      <template slot="title">
                        <span>Reject request</span>
                      </template>
                      <button
                        v-if="data.item.status == 'pending'"
                        class="btn btn-sm btn-outline-danger ml-2"
                      >
                        <i class="bx bx-x"></i>
                      </button>
                    </a-tooltip>
                  </template>
                </b-table>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm-8"></div>
            <div class="col-sm">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="text-13"
              ></b-pagination>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import decode from 'jwt-decode';
import arrowIcon from "../../components/icons/arrow.vue";
// import axios  from 'axios';
export default {
  component: {
    arrowIcon,
  },
  data() {
    return {
      pageTitle: "Report approvers review",
      headers: [
        "approver_name",
        "Department",
        "Role",
        "level",
        "status",
      ],
      perPage: 6,
      currentPage: 1,
      filter: "",
      elementId: null,
      elementType: null,
      approversList: [],
      state: {
          loading:false,
          refresh: false
      },
      elementTitle: "",
      workFlowName: ""
    };
  },
  beforeMount() {
    this.$title("Report Approvers Review");

    this.elementId = this.$route.params.element_id
    this.elementType = this.$route.params.element_type

    this.fetchApproverList()
  },
  computed: {
    rows() {
      return this.approversList.length;
    },
    token() {
      return localStorage.getItem("token");
    },
  },
  methods: {
    async fetchApproverList() {
       const config = {
        params: {
          elementId: this.elementId,
          elementType: this.elementType
        }
      }; 
      this.state.loading = true;
      await this.axios
        .get(`getApproverLists`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.approversList = response.data;
            this.elementTitle=response.data[1].ElementName;
            this.workFlowName=response.data[1].workflowName;
            console.log("App data", this.approversList);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    refresh(){
        this.refresh=true;
        this.fetchApproverList();
    }
  }
};
</script>

<style scoped lang="scss">
</style>