<template>
  <form>
    <b-row>
      <div class="col-sm-5 col-md-5 main-wrapper col-lg-5 mr-5">
        <div class="form-group">
          <label class="float-left">Alignment - Program, {{ keywords ? keywords.pillar ? keywords.pillar : 'Pillar' : 'Pillar' }} </label>
          <select
            v-model="alignment"
            v-bind:class="validations.alignment ? 'is-invalid' : null"
            @change="onChangeAlignment"
            class="custom-select bg-white text-13"
          >
            <option selected :value="null" disabled>Indicator Alignment</option>
            <option value="program">Program</option>
            <option value="pillar">{{ keywords ? keywords.pillar ? keywords.pillar : 'Pillar' : 'Pillar' }}</option>
          </select>
          <small v-if="validations.alignment" class="text-danger float-left">
            Indicator alignment must be selected.
          </small>
        </div>
        <div class="form-group">
          <input
            v-model="title"
            placeholder="Title"
            type="text"
            class="form-control"
            v-bind:class="validations.title ? 'is-invalid' : null"
            @keyup="onChangeTitle"
          />
          <small v-if="validations.title" class="text-danger float-left">
            Title is required
          </small>
        </div>
        <div class="form-group">
          <b-input-group>
            <b-select
              class="custom-select bg-white text-13 mr-2"
              v-model="toclevel"
              required
              :disabled="state.loadTOC"
              v-bind:class="validations.toclevel ? 'is-invalid' : null"
              @change="onChangeToc"
              v-if="alignment == 'program'"
            >
              <template #first>
                <b-select-option :value="null" selected disabled>
                  {{ `Select program ${capitalizeFirstLetter(getKeyword('theory of change'))} level`}}
                </b-select-option>
              </template>
              <b-select-option
                v-for="item in toclevels"
                :key="'toc_' + item.id"
                :value="item.id"
              >
                {{ item.level }}
              </b-select-option>
            </b-select>
            <b-select
              v-else
              class="custom-select bg-white text-13 mr-2"
              v-model="rclevel"
              required
              :disabled="state.loadRC"
              v-bind:class="validations.rclevels ? 'is-invalid' : null"
              @change="onChangeRc"
            >
              <template #first>
                <b-select-option :value="null" selected disabled
                  >
                   {{ keywords ? keywords.pillar ? `Select ${keywords.pillar} result chain level` : "Select pillar result chain level" : "Select pillar result chain level" }}
                </b-select-option>
              </template>
              <b-select-option
                v-for="item in rclevels"
                :key="item.id"
                :value="item.id"
              >
                {{ item.level }}
              </b-select-option>
            </b-select>
            <b-input-group-append>
              <b-icon-arrow-repeat
                v-if="alignment == 'program'"
                class="bi-con-right"
                font-scale="1.5"
                :animation="state.loadTOC ? 'spin' : ''"
                @click="getTOCLevels"
              />
              <b-icon-arrow-repeat
                v-else
                class="bi-con-right"
                font-scale="1.5"
                :animation="state.loadRC ? 'spin' : ''"
                @click="getRCLevels"
              />
            </b-input-group-append>
          </b-input-group>
          <small
            v-if="validations.toclevel && alignment == 'program'"
            class="text-danger"
          >
            Program theory of change level must be selected.
          </small>
          <small
            v-else-if="validations.rclevel && alignment == 'pillar'"
            class="text-danger"
          >
            Result chain level must be selected.
          </small>
        </div>
        <div class="form-group">
          <b-input-group v-if="alignment == 'program'">
            <b-select
              class="custom-select bg-white text-13 mr-2"
              v-model="alignedIndicator"
              required
              :disabled="state.loadingLogFrame"
              v-bind:class="validations.alignedIndicator ? 'is-invalid' : null"
              @change="onChangeLogFrame"
            >
              <template #first>
                <b-select-option :value="null" selected disabled
                  >Select aligned indicator
                </b-select-option>
              </template>
              <b-select-option
                v-for="item in logframes"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </b-select-option>
            </b-select>
            <b-input-group-append>
              <b-icon-arrow-repeat
                class="bi-con-right"
                font-scale="1.5"
                :animation="state.loadingLogFrame ? 'spin' : ''"
                @click="getProgramIndicators"
              />
            </b-input-group-append>
          </b-input-group>
          <small v-if="validations.alignedIndicator" class="text-danger">
            Aligned indicator must be selected.
          </small>
        </div>
        <div class="form-group">
          <b-input-group>
            <b-select
              class="custom-select bg-white text-13"
              v-model="indicatorAggregation"
              required
              :disabled="state.indicatorAggregation"
              v-bind:class="
                validations.indicatorAggregation ? 'is-invalid' : null"
              @change="onchangeAggregation"
            >
              <template #first>
                <b-select-option :value="null" selected disabled>Select disaggregation  -</b-select-option>
              </template>
              <b-select-option
                v-for="item in indicators"
                :key="item.id"
                :value="item.id"
              >
                {{ item.level }}
              </b-select-option>
            </b-select>
            <b-input-group-append>
              <b-icon-arrow-repeat
                class="bi-con-right ml-1"
                font-scale="1.5"
                :animation="state.loadIndicator ? 'spin' : ''"
                @click="getIndicatorTypes"
              />
            </b-input-group-append>
          </b-input-group>
          <small
            v-if="validations.indicatorAggregation"
            class="text-danger float-left"
          >
            Disaggregation is required.
          </small>
        </div>
        <b-row>
          <div class="col-sm col-md">
            <div class="form-group">
              <input
                v-model="baseline"
                placeholder="Baseline"
                type="number"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-sm col-md">
            <div class="form-group">
              <input
                v-model="target"
                placeholder="Target"
                type="number"
                class="form-control"
              />
            </div>
          </div>
        </b-row>
        <!-- <div class="form-group">
          <label class="float-left">Reporting responsiblity</label>
          <b-input-group>
            <b-select
              class="custom-select bg-white text-13 mr-2"
              v-model="repoters"
              required
              :disabled="state.loadUsers"
              v-bind:class="validations.repoters ? 'is-invalid' : null"
              @change="onChangeReporter"
            >
              <template #first>
                <b-select-option :value="null" selected disabled
                  >Select reporters
                </b-select-option>
              </template>
              <b-select-option
                v-for="item in users"
                :key="item.id"
                :value="item.id"
              >
                {{ item.fullnames }}
              </b-select-option>
            </b-select>
            <b-input-group-append>
              <b-icon-arrow-repeat
                class="bi-con-right"
                font-scale="1.5"
                :animation="state.loadUsers ? 'spin' : ''"
                @click="getUsers"
              />
            </b-input-group-append>
          </b-input-group>
          <small v-if="validations.repoters" class="text-danger">
            Reporter must be selected.
          </small>
        </div> -->
        <b-form-group class="form-text-label" label-for="desc">
          <label class="float-left" for="">Indicator definition</label>
          <b-form-textarea
            v-model="indicatorDefinition"
            id="desc"
            placeholder="Enter definition for indicator"
            rows="1"
            resize
            class="form-text"
          ></b-form-textarea>
        </b-form-group>
          <b-form-group>
          <button @click.prevent="saveIndicators" type="submit" class="btn btn-primary rounded-20 float-left btn-sm">
            <b-container v-if="state.adding">
              <b-spinner small></b-spinner>
              <span class="text-13 ml-2">Saving...</span>
            </b-container>
            <span v-if="!state.adding" class="text text-13">Add Indicator</span>
          </button>
        </b-form-group>
      </div>
      <div class="col-sm col-md col-lg">
        <b-row class="mt-3">
          <div class="col-sm-9 col-md-9">
            <div class="form-group">
              <label class="text-13 float-left">Data Collection Method</label>
              <select
                v-bind:class="validations.collectionMethods ? 'is-invalid' : null"
                @change="onChangeDataCollection"
                v-model="collectionMethods"
                class="custom-select bg-white text-13"
              >
                <option selected :value="null" disabled>
                  Data collection method
                </option>
                <option v-for="(method, indKey) in getDatacollectionMethods()" class="text-capitalize" :key="indKey" :value="method">{{method}}</option>
              </select>
              <small v-if="validations.collectionMethods" class="text-danger">
                Data collection method must be selected.
              </small>
            </div>
          </div>
        </b-row>
        <b-row class="mt-3">
          <div class="col-sm-9 col-md-9">
            <div class="form-group">
              <label class="text-13 float-left">Frequency</label>
              <select
                v-bind:class="validations.frequency ? 'is-invalid' : null"
                @change="onChangeFrequency"
                v-model="frequency"
                class="custom-select bg-white text-13"
              >
                <option selected :value="null" disabled>Frequency</option>
                <option v-for="(freq, indKey) in getReportingFrequency()" class="text-capitalize" :key="indKey" :value="freq">{{freq}}</option>
              </select>
              <small v-if="validations.frequency" class="text-danger">
                Data collection method must be selected.
              </small>
            </div>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm col-md justify-content-start">
            <b-form-group>
             <b-form-checkbox
              id="checkbox-1"
              v-model="featured"
              name="featured-1"
              value="on"
              unchecked-value="off"
              class="text-left"
            >
              Featured
            </b-form-checkbox>
          </b-form-group>
          </div>
        </b-row>
      <div class="col-sm col-md col-lg">
        <div class="card ml-n4 card-2">
          <div class="card-body">
            <div class="table-responsive mt-2">
              <b-container class="my-5" v-if="state.loadingMI">
                <b-spinner type="grow" small></b-spinner>
                <span class="text-13 ml-2"
                  >Waiting for measurement plan indicators...</span
                >
              </b-container>
              <b-table
                v-else
                table-class="table table table-indicators rounded-20"
                thead-tr-class="theader-indicators text-left indent-20"
                tbody-class="tb-body text-left indent-20"
                :items="mIndicators"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="fields"
                show-empty
              >
                <template v-slot:cell(No)="data">
                  <span>{{ data.index + 1 }}</span>
                </template>
              </b-table>
            </div>
            <b-row class="my-4">
              <div class="col-sm-8 col-md-8"></div>
              <div class="col-sm col-md">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                  prev-class="text-13"
                  next-class="text-13"
                  first-class="text-13"
                  last-class="text-13"
                ></b-pagination>
              </div>
            </b-row>
          </div>
        </div>
      </div>
      </div>
    </b-row>
  </form>
</template>

<script>
import encode from "jwt-encode";
import decode from "jwt-decode";
export default {
  props: ["store"],
  data() {
    return {
      alignment: null,
      title: null,
      rclevel: null,
      toclevel: null,
      alignedIndicator: null,
      indicatorAggregation: null,
      baseline: null,
      target: null,
      repoters: null,
      indicatorDefinition: null,
      collectionMethods: null,
      frequency: null,
      validations: {
        alignment: false,
        title: false,
        rclevel: false,
        alignedIndicator: false,
        indicatorAggregation: false,
        baseline: false,
        target: false,
        repoters: false,
        indicatorDefinition: false,
        collectionMethods: false,
        frequency: false,
        toclevel: false,
      },
      isSaved: false,
      state: {
        loadUsers: false,
        loading: false,
        loadRC: false,
        loadTOC: false,
        loadIndicator: false,
        loadingLogFrame: false,
        loadingMI: false,
        adding: false
      },
      users: [],
      toclevels: [],
      rclevels: [],
      indicators: null,
      logframes: [],
      mIndicators:[],
      perPage:4,
      currentPage:1,
      filter:'',
      fields:["No", "Title", "resultchain"],
      featured: false,
    };
  },
  computed: {
    mplan() {
      return this.$store.state.strategies;
    },
    currentSaved() {
      const data = sessionStorage.getItem("mplan");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.mplan ? this.mplan : decoded;
    },
    token() {
      return localStorage.getItem("token");
    },
    pillar() {
      const storeData = sessionStorage.getItem("pl");
      const pillarData = this.$store.state.pillar_data;
      var decoded = null;
      if (storeData) {
        decoded = decode(storeData);
      }
      return pillarData ? pillarData : decoded;
    },
    drafted(){
      var decoded = null;
      const storeData = sessionStorage.getItem("drafted-pillar")
      if(storeData){
        decoded = decode(storeData)
      }
      return decoded;
    },
    finPillar(){
      return this.pillar ? this.pillar : this.drafted ? this.drafted : null;
    },
    rows(){
      return this.mIndicators.length;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    program(){
      return this.$store.state.current_program;
    },
  },
  watch: {
    alignment() {
      if (this.alignment == "program") {
        this.getProgramIndicators();
        this.getTOCLevels();
      } else if (this.alignment == "pillar") {
        this.getRCLevels();
      }
    },
  },
  mounted() {
    this.getCurrentSaved();
    this.getUsers();
    this.getIndicatorTypes();
    this.getMeasurementIndicators();
  },
  methods: {
    async getMeasurementIndicators() {
      this.state.loadingMI = true;
      this.$emit("loading", true);
      await this.axios
        .get(`pillar/${this.finPillar.id}/indicators`)
        .then((response) => {
          if (response.data) {
            this.state.loadingMI = false;
            this.$emit("loading", false);
            this.mIndicators = response.data;
          } else {
            this.state.loadingMI = false;
            this.$emit("loading", false);
          }
        })
        .catch(() => {
          this.state.loadingMI = false;
          this.$emit("loading", false);
        });
    },
    async getProgramIndicators(){
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadingLogFrame = true;
      await this.axios
        .get(`getLogframe/${this.program.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadingLogFrame = false;
            this.logframes = response.data;
          } else {
            this.state.loadingLogFrame = false;
          }
        })
        .catch(() => {
          this.state.loadingLogFrame = false;
        });
    },
    async getIndicatorTypes() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadIndicator = true;
      await this.axios
        .get(`getIndicatorType`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadIndicator = false;
            this.indicators = response.data;
          } else {
            this.state.loadIndicator = false;
          }
        })
        .catch(() => {
          this.state.loadIndicator = false;
        });
    },
    async getUsers() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadUsers = true;
      await this.axios
        .get("getUsers", config)
        .then((response) => {
          if (response.data) {
            this.state.loadUsers = false;
            this.users = response.data;
          } else {
            this.state.loadUsers = false;
          }
        })
        .catch(() => {
          this.state.loadUsers = false;
        });
    },
    async getTOCLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadTOC = true;
      await this.axios
        .get(`getTocLevels/${this.program.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadTOC = false;
            this.toclevels = response.data;
          } else {
            this.state.loadTOC = false;
          }
        })
        .catch(() => {
          this.state.loadTOC = false;
        });
    },

    async getRCLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadRC = true;
      await this.axios
        .get(`getResultChainLevels/${this.finPillar.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadRC = false;
            this.rclevels = response.data;
          } else {
            this.state.loadRC = false;
          }
        })
        .catch(() => {
          this.state.loadRC = false;
        });
    },
    onChangeAlignment() {
      if (!this.alignment) {
        this.validations.alignment = true;
      } else {
        this.validations.alignment = false;
      }
    },
    onChangeLogFrame() {
      if (!this.alignedIndicator) {
        this.validations.alignedIndicator = true;
      } else {
        this.validations.alignedIndicator = false;
      }
    },
    onchangeAggregation() {
      if (!this.indicatorAggregation) {
        this.validations.indicatorAggregation = true;
      } else {
        this.validations.indicatorAggregation = false;
      }
    },
    onChangeTitle() {
      if (!this.title) {
        this.validations.title = true;
      } else {
        this.validations.title = false;
      }
    },
    onChangeReporter() {
      if (this.repoters != null || this.repoters != "") {
        this.validations.repoters = false;
      } else {
        this.validations.repoters = true;
      }
    },
    onChangeToc() {
      if (!this.toclevel) {
        this.validations.toclevel = true;
      } else {
        this.validations.toclevel = false;
      }
    },
    onChangeRc() {
      if (!this.rclevel) {
        this.validations.rclevel = true;
      } else {
        this.validations.rclevel = false;
      }
    },
    onChangeDataCollection() {
      if (!this.collectionMethods) {
        this.validations.collectionMethods = true;
      } else {
        this.validations.collectionMethods = false;
      }
    },
    onChangeFrequency() {
      if (!this.frequency) {
        this.validations.frequency = true;
      } else {
        this.validations.frequency = false;
      }
    },
    getCurrentSaved() {
      this.alignment =
        this.currentSaved !== null ? this.currentSaved.data.AlignedTo : null;
      this.title =
        this.currentSaved !== null ? this.currentSaved.data.Title : null;
      this.rclevel =
        this.currentSaved !== null ? this.currentSaved.data.RCTOCid : null;
      this.indicatorAggregation =
        this.currentSaved !== null
          ? this.currentSaved.data.IndicatorAggragation
          : null;
      this.baseline =
        this.currentSaved !== null ? this.currentSaved.data.baseline : null;
      this.target =
        this.currentSaved !== null ? this.currentSaved.data.target : null;
      this.repoters =
        this.currentSaved !== null ? this.currentSaved.data.userId : null;
      this.indicatorDefinition =
        this.currentSaved !== null
          ? this.currentSaved.data.indicatorDefinition
          : null;
      this.collectionMethods =
        this.currentSaved !== null
          ? this.currentSaved.data.dataCollection
          : null;
      this.frequency =
        this.currentSaved !== null ? this.currentSaved.data.frequency : null;
      this.isSaved =
        this.currentSaved !== null ? this.currentSaved.isSavedStep2 : false;
    },
    saveIndicators() {
      if (!this.alignment) {
        this.$emit("error", "Indicator alignment is required.");
        this.validations.alignment = true;
        this.$message.error("Indicator alignment is required.");
      } else if (!this.title) {
        this.$emit("error", "Title is required.");
        this.validations.title = true;
        this.$message.error("Pillar title is required.");
      } else if (!this.rclevel && this.alignment == 'pillar') {
        this.$emit("error", "Result chain level is required-.");
        this.validations.alignedIndicator = true;
      } else if (!this.alignedIndicator && this.alignment=='program') {
        this.$emit("error", "Aligned indicator is required.");
        this.validations.alignedIndicator = true;
        this.$message.error("Aligned indicator is required.");
      } else if (!this.indicatorAggregation) {
        this.$emit("error", "Indicator disaggregation is required.");
        this.validations.indicatorAggregation = true;
        this.$message.error("Indicator disaggregation is required.");
      } else if (!this.baseline) {
        this.$emit("error", "Baseline is required.");
        this.validations.baseline = true;
        this.$message.error("Baseline is required.");
      } else if (!this.target) {
        this.$emit("error", "Target is required.");
        this.validations.baseline = true;
        this.$message.error("Target is required.");
      } else if (!this.indicatorDefinition) {
        this.$emit("error", "Indicator definition is required.");
        this.validations.indicatorDefinition = true;
        this.$message.error("Indicator definition is required.");
      } else if (!this.collectionMethods) {
        this.$emit("error", "Data collection method is required.");
        this.validations.collectionMethods = true;
        this.$message.error("Data collection method is required.");
      } else if (!this.frequency) {
        this.$emit("error", "Frequency is required.");
        this.validations.frequency = true;
        this.$message.error("Frequency is required.");
      } else {
        const requestData = {
          AlignedTo: this.alignment,
          Title: this.title,
          RCTOCid: this.rclevel,
          IndicatorAggragation: this.indicatorAggregation,
          baseline: this.baseline,
          target: this.target,
          indicatorDefinition: this.indicatorDefinition,
          dataCollection: this.collectionMethods,
          frequency: this.frequency,
          pillarId: this.finPillar.id,
          userId: this.repoters,
          featured: this.featured
        };
          this.state.adding = true;
          this.axios
            .post("AddMeasurementPlan", requestData)
            .then((response) => {
              if (response.data) {
                const currentSavedReq = {
                  data: requestData,
                  isSavedStep2: true,
                };
                this.state.adding = false;
                const secret =
                  "tokenHashingProDet--afr0-1200x..**#dfs%%^^^```~~~~pok000";
                const jwt = encode(currentSavedReq, secret);
                sessionStorage.setItem("mplans", jwt);
                this.$store.dispatch("setStrategies", currentSavedReq);
                this.getCurrentSaved();
                this.getMeasurementIndicators();
                this.$notification["success"]({
                  message: "SUCCESS!",
                  description: `Pillar indicator data saved.`,
                  duration: 5,
                });
              } else {
                this.state.adding = false;
                this.$message.error(
                  `An error occured while saving indicator details`,
                  4
                );
              }
            })
            .catch((error) => {
              this.$notification["error"]({
                message: "ERROR!",
                description: `${error.errorMessage}`,
                duration: 7,
              });
              this.state.adding = false;
            });
      }
    },
    nextStep() {
      if(!this.mIndicators.length){
        this.$emit("error",'You must add an indicator');
      }else{
        this.$emit('can-continue');
      }
    },
  },
};
</script>

<style scoped>
.main-wrapper .form-group label,
.main-wrapper .form-group input::placeholder,
.form-text,
.form-text::placeholder,
.form-text-label,
.form-control,
.custom-select {
  font-size: 13px !important;
}

.main-wrapper .form-group label {
  text-align: left !important;
}

.table-indicators th {
  font-size: 13px !important;
  border-top: none !important;
}

.table-indicators td {
  font-size: 13px !important;
}

.card-2 {
  border: none !important;
  border-radius: 20px !important;
}
</style>
