<template>
  <div id="app">
      <transition name="component-fade">
          <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  color: #2c3e50;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  padding: 0;
  margin: 0;
}
a{
  cursor: pointer;
  text-decoration: none;
}
</style>
