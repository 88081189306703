<template>
  <div>
      <div class="card b-none border-none">
        <div class="card-body">
            <b-row class="mt-5 mb-4">
              <div class="col-sm-2 col-md-2 col-lg-2">
                  <div class="d-flex justify-content-start ml-0 mt-2">
                    <h6 class="title-h6">Reports</h6>
                </div>
              </div>
              <div class="col-sm col-md col-lg">
                    <div class="btn btn-light btn-ip btn-sm btn-block mt-2 text-white">Reporting template(IP)</div>
              </div>
              <div class="col-sm col-md col-lg">
                    <div class="btn btn-light btn-pm btn-sm btn-block mt-2 text-white">Reporting template(PM)</div>
              </div>
              <div class="col-sm col-md col-lg"></div>
              <div class="col-12">
              </div>
          </b-row>
          <b-row class="mt-4">
              <div class="table-responsive ml-2">
                    <table class="table bg-white table-indicators table-reports">
                        <thead>
                            <tr class="theader-indicator">
                                <th class="text-left tborder" scope="col">
                                    <span class="ml-3">Report titles</span>
                                </th>
                                <th class="text-left tborder" scope="col">
                                    <span class="ml-3">Sent by</span>
                                </th>
                                <th class="text-left tborder" scope="col">
                                    <span class="ml-3">Deadline</span>
                                </th>
                                <th class="text-left tborder" scope="col">
                                    <span class="ml-3">Status</span>
                                </th>
                                <th class="text-left" scope="col">
                                    <span class="ml-3">Action</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(report, index) in reports" :key="index">
                                <td class="tborder"><span class="ml-3">{{report.Title}}</span></td>
                                <td class="tborder"><span class="ml-3">{{report.sentBy}}</span></td>
                                <td class="tborder"><span class="ml-3">{{report.DeadLine}}</span></td>
                                <td class="tborder"><span class="ml-3" :class="{'status-pending':report.status=='pending', 'text-success':report.status=='approved', 'text-info':report.status=='submitted', 'text-danger':report.status=='denied'}">{{report.status}}</span></td>
                                <td>
                                    <span class="text-center">
                                        <button v-if="report.status == 'pending'" class="btn btn-sm rounded-20 text-11 btn-block btn-report status-btn btn-warning">SUBMIT</button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
              </div>
          </b-row>
        </div>
      </div>
  </div>
</template>

<script>
import decode from 'jwt-decode';
export default {
    name:'project-report-tab',
    data(){
        return {
            reports: [],
            state:{
                refresh: false,
            }
        }
    },
    computed:{
        project(){
            const storeData = sessionStorage.getItem("pjt")
            const projectData = this.$store.state.project_data;
            var decoded = null;
            if(storeData){
                decoded = decode(storeData)
            }
            return projectData ? projectData : decoded;
        },
    },
    beforeMount() {
        this.$title("Project reports");
        this.fetchProjectReports()
    },
    methods: {
        async fetchProjectReports() {
        this.state.loading = true;
        await this.axios
            .get(`/reports/project/${this.project.id}`)
            .then((response) => {
                if (response.data) {
                    this.state.loading = false;
                    this.reports = response.data;
                } else {
                    this.state.loading = false;
                }
            })
            .catch(() => {
            this.state.loading = false;
            });
        },
        refresh(){
            this.refresh=true;
            this.fetchProjectReports();
        },
  }
}
</script>

<style>

</style>