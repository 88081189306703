<template>
  <b-container>
    <b-row class="mt-2 main-card">
      <b-col>
      <div class="card card-2 card-100 ml-2 w-100">
        <div class="card-body">
          <b-row class="row-chain">
            <div class="col-sm col-md col-lg">
              <div
                class="nav flex-column nav-result-chain"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <b-container class="my-4" v-if="loading">
                  <a-skeleton active :paragraph="{ rows: 3 }" :loading="loading" ></a-skeleton>
                </b-container>
                <a
                  v-else
                  v-for="(level, i) in levels"
                  :key="i"
                  class="tab-left nav-link text-capitalize"
                  :id="removeSpace(level.level)"
                  @click="onChangeLevel(level)"
                  :class="
                    levelItem
                      ? level.id == levelItem.id
                        ? 'active'
                        : null
                      : levels[0].id == level.id
                      ? 'active'
                      : null
                  "
                  :style="[
                    levelItem
                      ? level.id == levelItem.id
                        ? { color: `${level.color} !important`, borderLeft: `4px solid ${level.color} !important` }
                        : null
                      : levels[0].id == level.id
                      ? { color: `${level.color} !important`, borderLeft: `4px solid ${level.color} !important` }
                      : null,
                  ]"
                  data-toggle="pill"
                  href="#v-pills-social-impact"
                  role="tab"
                  :aria-controls="`v-pills-${removeSpace(level.level)}`"
                  aria-selected="true"
                  >{{ level.level }} 
                    <div class="clear-"></div>
                    <a-tooltip title="Update level" placement="bottom">
                      <small @click="onshowEdit(level)">
                        <span> <b-img style="width: 17px;height:17px;" :src="edit"></b-img> edit</span>
                      </small>
                    </a-tooltip>                  
                  </a
                >
                <a
                  class="
                    btn btn-new-key btn-outline-primary
                    pl-5
                    mb-3
                    pr-5
                    text-dark
                    mt-3
                  "
                  target="_blank"
                  v-if="program.toc_attachment"
                  :href="program.toc_attachment"
                  >Preview
                </a>
                <!-- <router-link
                  class="
                    btn btn-new-key btn-outline-primary
                    pl-5
                    mb-3
                    pr-5
                    text-dark
                    mt-3
                  "
                  :to="{name: 'export-current-toc'}"
                  >Preview Autogenerated
                </router-link> -->
              </div>
            </div>
            <div class="col-sm-9 col-md-9">
              <div class="tab-content" id="v-pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="v-pills-social-impact"
                  role="tabpanel"
                  aria-labelledby="v-pills-social-impact-tab"
                >
                  <b-row>
                    <div class="col-sm-10 col-md-10">
                      <b-row>
                        <b-container class="my-5" v-if="state.loadingElement">
                          <b-spinner type="grow" small></b-spinner>
                          <span class="text-13 ml-2">Loading boxes...</span>
                        </b-container>
                        <b-container class="my-5" v-else-if="elementsNum <= 0">
                          <span> No box found at this level. </span>
                        </b-container>
                        <div
                          v-else
                          v-for="(el, j) in elements"
                          :key="j"
                          class="col-sm-6 col-md-6"
                        >
                          <div class="card card-chain">
                            <div
                              :style="[{ backgroundColor: `${levelItem.color} !important`}]"
                              class="card-header"
                            >
                               <div class="text-right">
                                  <a-tooltip class="mr-2" placement="bottom" title="Update box">
                                    <b-icon-pencil-square scale="1.8" @click="onShowEditElement(el)" class="text-white cursor-pointer"></b-icon-pencil-square>
                                  </a-tooltip>
                                  <a-tooltip class="ml-2" placement="bottom" title="Delete box">
                                    <b-icon-trash scale="1.8" class="text-white cursor-pointer"></b-icon-trash>
                                  </a-tooltip>
                              </div>
                            </div>
                            <div class="card-body cb-scroll">
                              <p class="text-left text-13">
                                <strong v-if="el.element">{{ el.element }}:</strong>
                                {{ el.Description }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </b-row>
                    </div>
                    <div class="col-sm-2 col-md-2"></div>
                  </b-row>
                </div>
              </div>
            </div>
          </b-row>
        </div>
      </div>
      </b-col>
    </b-row>
    <a-modal
      v-model="visibleEditLevel"
      :footer="null"
      :title="
        `${keywords['theory of change']}`
          ? `Update ${keywords['theory of change']} Level`
          : `Update Theory of change level`">
        <updateLevel @dismiss="onDismiss" :leveldata="selectedLevel"/>
    </a-modal>
    <a-modal
      v-model="visibleEditElement"
      :footer="null"
      :title="
        `${keywords['theory of change']}`
          ? `Update ${keywords['theory of change']} box`
          : `Update Theory of change box`">
        <updateTOCElement @dismiss="onDismissElement" :elementdata="selectedElement"/>
    </a-modal>
  </b-container>
</template>

<script>
import image1 from "../../../../../assets/images/project-manager/project/project-dash-chart1.png";
import editIcon from '../../../../../assets/images/icons/edit.svg';
import updateLevel from '../individual-program/program-changes/update-toc-level.vue';
import updateTOCElement from '../individual-program/program-changes/update-toc-element.vue'
export default {
  props: {
    loading: { type: Boolean, default: false }
  },
  components: {
    updateLevel,
    updateTOCElement
  },
  data() {
    return {
      image1: image1,
      visibleCreateKey: false,
      visibleEditLevel: false,
      visibleEditElement: false,
      visibleChain: false,
      state: {
        loading: false,
        loadingElement: false,
      },
      elements: [],
      levelItem: null,
      selectedLevel: null,
      selectedElement: null,
      edit: editIcon
    };
  },
  beforeMount() {
    if(this.levels){
      this.onChangeLevel(this.levels[0]);
    }
    sessionStorage.removeItem("prg")
  },
  watch: {
    levels: function () {
      if(this.levels.length){
        this.onChangeLevel(this.levels[0]);
      }
    },
  },
  computed: {
    program(){
      return this.$store.state.current_program;
    },
    token() {
      return localStorage.getItem("token");
    },
    elementsNum() {
      return this.elements.length;
    },
    levels(){
      return this.$store.state.selected_program.program_toc;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  methods: {
    onDismissElement(){
      this.visibleEditElement=false;
    },
    onShowEditElement(data){
      this.visibleEditElement=true;
      this.selectedElement = data;
    },
    onDismiss(){
      this.visibleEditLevel=false;
    },
     onshowEdit(data){
      this.selectedLevel = data;
      this.visibleEditLevel=true;
    },
    removeSpace(title) {
      return title.replace(/\s/g, "-");
    },
    async getProgramElement(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loadingElement = true;
      await this.axios
        .get(`getTOCElementsAll/${param.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loadingElement = false;
            this.elements = response.data;
          } else {
            this.state.loadingElement = false;
          }
        })
        .catch(() => {
          this.state.loadingElement = false;
        });
    },
    onChangeLevel(value) {
      this.levelItem = value;
      this.getProgramElement(value);
    },
    createKeyModal() {
      this.visibleCreateKey = true;
    },
    createChainModal() {
      this.visibleChain = true;
    },
    cancelKeyModal() {
      this.visibleCreateKey = false;
    },
    cancelChainModal() {
      this.visibleChain = false;
    },
  },
};
</script>

<style scoped>
*,
body {
  font-family: Ubuntu !important;
}

.card-chain {
  background: #e5e5e5 !important;
  border-radius: 12px !important;
  border: none !important;
  height: 90% !important;
  font-size: 13px !important;
  overflow-y: scroll;
  max-height: 200px;
}

.card-chain .card-header {
  background: #0fa958 !important;
  border: none !important;
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.table-indicators th {
  font-size: 13px !important;
  border-top: none !important;
}

.table-indicators td {
  font-size: 13px !important;
}

.card-2 {
  border: none !important;
  border-radius: 20px !important;
}

.btn-new-key {
  border-radius: 20px !important;
  font-size: 13px !important;
}

@media (min-width: 600px) {
  .card-chain {
    margin-right: 0 !important;
  }
  .row-chain {
    margin-right: 0 !important;
  }
}
</style>