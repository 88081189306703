<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 main mb-6">
      <div class="card rounded-20 card-main mt-3 ml-0 mb-8">
        <b-row no-gutters class="mb-3 p-2">
          <div class="col-sm-3 col-md-3 col-lg-3">
            <div class="ml-2 mt-4 text-left top-label">
              <h4 class="text-16 text-capitalize">{{ pillar.Title }}</h4>
            </div>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-4">
            <div class="text-left top-label">
              <button class="btn btn-success ml-3 text-white mt-2">
                <span class="text-white clearfix mb-0 pb-0 text-17">{{pillar.projects}}</span>
                <span class="text-white text-12">{{capitalizeFirstLetter(getKeyword("project"))}}s</span>
              </button>
            </div>
          </div>
          <div class="col-sm-5 col-md-5">
            <div class="justify-content-">
              <b-row>
                <div class="col-sm col-md col-lg">
                  <div class="d-flex mr-2 pr-5  pl-5 pt-2 pb-2 mb-3 m-3">
                    <router-link
                        class="btn btn-primary rounded-20 mr-3"
                        role="button"
                        :to="{name: 'create-project'}"
                      >
                      {{ `Add ${capitalizeFirstLetter(getKeyword("project"))}` }}
                    </router-link>
                    <router-link
                      :to="{ name: 'create-pillar' }"
                      class="btn btn-outline-primary rounded-20 mr-3"
                      role="button"
                    >
                      Setup Strategy
                    </router-link>
                    <a-tooltip
                      title="Refresh pillar data"
                      placement="bottom"
                      :color="'#87d068'"
                    >
                      <button
                        @click="refresh"
                        type="button"
                        class="
                          btn
                          mr-3
                          mt-1
                          btn-secondary btn-sm
                          rounded-20
                          border-none
                        "
                      >
                        <b-icon-arrow-repeat
                          font-scale="1.6"
                          :animation="
                          state.refresh && state.loading ? 'spin' : ''"
                          @click="refresh"
                        />
                      </button>
                    </a-tooltip>
                  </div>
                </div>
              </b-row>
            </div>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm-5 col-md-5 mb-3 mt-3 ml-2">
            <div class="accordion ml-2" id="accordionExample">
              <div class="card p-0 b-none">
                <div class="card-header p-0" id="headingTwo">
                  <h2 class="mb-0">
                    <button
                      class="
                        btn btn-link
                        program-collapsed
                        btn-block
                        text-left text-dark
                        collapsed
                      "
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span class="text-capitalize">{{capitalizeFirstLetter(getKeyword("pillar"))}}</span> Details
                      <span class="float-right">
                        <i class="bx bx-menu"></i><br />
                      </span>
                      <span class="float-right">
                        <i class="bx bx-caret-down"></i>
                      </span>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-2 text-left">
                    <h4 class="text-17"><span class="text-capitalize">{{capitalizeFirstLetter(getKeyword("pillar"))}}</span> Details</h4>
                    <p class="text-13">
                      {{ pillar.Description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-row>
        <div class="card-header bg-white rounded-top-left-20 rounded-top-right-20">
          <b-row no-gutters>
            <div class="col-sm col-md">
              <div class="table-responsive">
                <div class="all-tabs">
                  <div
                    class="tab text-12"
                    :class="{ active: selectedTabIndex == i }"
                    v-for="(tab, i) in shownTabs"
                    :key="i"
                    @click="setTab(i)"
                  >
                    {{
                      tab == "ResultChain"
                        ? "Results Chain"
                        : tab == "Projects"
                        ?  keywords ? keywords.project ? `${keywords.project}s` : 'Projects' : 'Projects' 
                        : tab == "MeasurementPlan"
                        ? "Measurement Plan"
                        : tab == "Risk"
                        ? "Risk Register"
                        : tab == "KnowledgeBase"
                        ? "Knowledge Base"
                        : tab
                    }}
                  </div>
                </div>
              </div>
            </div>
          </b-row>
        </div>
        <div class="card-body pl-0 ml-0">
          <b-row no-gutters class="pb-3 px-2">
            <div class="table-responsive">
              <transition name="tabs-fade">
                <keep-alive :max="3">
                  <Component
                    :is="shownTabs[selectedTabIndex]"
                    :tabName="compID"
                    @refresh="refresh"
                    :loading="state.loading"
                  />
                </keep-alive>
              </transition>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import Projects from "./tabs/pillar-projects.vue";
import ResultChain from "./tabs/pillar-result-chain.vue";
import MeasurementPlan from "./tabs/pillar-measurement-plan.vue";
import Risk from "./tabs/pillar-risk.vue";
import KnowledgeBase from "./tabs/pillar-knowledgebase.vue";
import Reports from "./tabs/pillar-reports.vue";
import Approvals from "./tabs/pillar_approvals";
import decode from "jwt-decode";
import axios from 'axios';
export default {
  name: "project-dashboard",
  components: {
    Projects,
    ResultChain,
    MeasurementPlan,
    Risk,
    KnowledgeBase,
    Reports,
    Approvals
  },
  data() {
    return {
      pageTitle: this.$store.getters.getCurrentPrograam
            ? `${this.capitalizeFirstLetter(this.$store.getters.getCurrentPrograam.keyWords.project)}`
            : "pillar",
      compID: uuidv4(),
      selectedTabIndex: 0,
      tabs: [
        "Projects",
        "ResultChain",
        "MeasurementPlan",
        "Risk",
        "Reports",
        "KnowledgeBase",
        "Approvals"
      ],
      state: {
        loading: false,
        refresh: false,
        currentp: false
      },
    };
  },
  computed: {
    shownTabs() {
      return this.tabs;
    },
    pillar() {
      const storeData = sessionStorage.getItem("pl");
      const pillarData = this.$store.state.pillar_data;
      var decoded = null;
      if (storeData) {
        decoded = decode(storeData);
      }
      return pillarData ? pillarData : decoded;
    },
    token(){
      return localStorage.getItem("token");
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  beforeMount() {
    this.getProjects();
    this.getResultChainLevels();
    this.getMeasurementPlan();
    this.getRisks();
    this.getKnowledgeBase();
    this.getCurrentProgram();
  },
  methods: {
    setTab(index) {
      if (isNaN(index)) return;
      if (index == this.selectedTabIndex) return;
      this.$set(this, "selectedTabIndex", index);
    },
    // Pillar synchronizing data functions
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.currentp = true;
     await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          if(response.data){
            this.state.currentp = false;
            this.$store.dispatch("setCurrentProgram", response.data[0]);
            this.pageTitle = this.keywords ? this.keywords.pillar ? `${this.keywords.pillar}` : "Pillar" : "Pillar";
          }
        })
        .catch(() => {
          this.state.currentp = false;
        });
    },
    async getProjects() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getPillarProjects/${this.pillar.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setPillarProjects",response.data)
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getResultChainLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getResultChainLevels/${this.pillar.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setPillarLevels", response.data);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getMeasurementPlan() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(`getMeasumentPlan/${this.pillar.id}`, config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setPillarMplan", response.data);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
     async getRisks() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
        params: {
          scope: "pillar",
          elementId: this.pillar.id
        }
      }; 
      this.state.loading = true;
      await axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}getRisks`,config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setPillarRisks", response.data);
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async getKnowledgeBase() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
        params: {
          scope: "pillar",
          elementId: `${this.pillar.id}`
        }
      }; 
      this.state.loading = true;
      await axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}getKnoledgeBase`,config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.$store.dispatch("setPillarKnowledge",response.data)
          } else {
            this.state.loading = false;
          }
        })
        .catch((error) => {
          this.state.loading = false;
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 5,
          });
        });
    },
    refresh() {
      this.state.refresh = true;
      this.getProjects();
      this.getResultChainLevels();
      this.getMeasurementPlan();
      this.getRisks();
      this.getKnowledgeBase();
    },
  },
};
</script>

<style scoped>
*,
html,
body {
  font-family: Ubuntu;
}

@media (min-width: 600px) {
  .table-responsive {
    overflow-x: hidden !important;
  }
}
</style>