import Vue from 'vue';
import Vuex from 'vuex';
import manager from '../dashboards/project-manager/manager-store.js';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        login_email: "",
        isInternetConnected: false,
        cancelTokens: [],
        user_data: null,
        current_program: null,
        numbers: null,
        program_data: null,
        pillar_data: null,
        strategies: null,
        project_data: null,
        ips: null,
        selected_project: {
            project_levels:null,
            project_assumptions: null,
            project_mplan:null,
            project_risks: null,
            project_knowledge: null
        },
        selected_pillar: {
            pillar_projects: null,
            pillar_levels: null,
            pillar_mplan: null,
            pillar_risk:null,
            pillar_knowledge: null
        },
        selected_program: {
            program_toc: null,
            program_logframe: null,
            program_risks: null,
            program_projects_risks: [],
            program_knowledge: null
        },
        approval_data: null,
        report_data: null
    },
    getters: {
        cancelTokens(state) {
            return state.cancelTokens;
        },      
        getLoginEmail(state) {
            return state.login_email;
        },
        setIsInternetConnected: state => {
            return state.isInternetConnected;
        },
        getUserData: state => {
            return state.data;
        },
        getIps: state => {
            return state.data;
        },
        getSelectedProject: state => {
            return state.selected_project;
        },
        getSelectedPillar: state => {
            return state.selected_pillar;
        },
        getSelectedProgram: (state) => {
            return state.selected_program;
        },
        getCurrentPrograam: (state) => {
            return state.current_program;
        },
        getApproval: (state) =>{
            return state.approval_data;
        },
        getReport:(state) => {
            return state.report_data=state;
        }
    },
    mutations: {
        setLoginEmail: (state) => {
            state.login_email;
        },
        setIsInternetConnected: state => {
            state.isInternetConnected;
        },
        ADD_CANCEL_TOKEN: (state, token) => {
            state.cancelTokens.push(token);
        },
        CLEAR_CANCEL_TOKENS(state) {
            if (state.cancelTokens.length < 1) return;
            state.cancelTokens = [];
        },
        mutateUserData(state, data) {
            state.user_data = data;
        },
        mutateNumbers(state,numbers){
            state.numbers=numbers;
        },
        mutateProgramData(state,program){
            state.program_data = program;
        },
        mutatePillarData(state, pillar){
            state.pillar_data = pillar;
        },
        mutateStrategies(state, strategies){
            state.strategies = strategies;
        },
        mutateProject(state, param){
            state.project_data = param;
        },
        mutateIps(state,param){
            state.ips = param;
        },
        mutateSelectedProg(state, params){
            state.selected_program = params;
        },
        // selected project mutations
        mutateProjectLevels(state, params){
            state.selected_project.project_levels = params;
        },
        mutateProjectAssumptions(state,params){
            state.selected_project.project_assumptions = params;
        },
        mutateProjectMeasurementPlan(state,params){
            state.selected_project.project_mplan = params;
        },
        mutateProjectRisks: (state,params) => {
            state.selected_project.project_risks = params;
        },
        mutateProjectKnowledge: (state, params) =>{
            state.selected_project.project_knowledge = params;
        },
        // selected pillar mutations
        mutatePillarProjects: (state,param) => {
            state.selected_pillar.pillar_projects = param;
        },
        mutatePillarLevels: (state,params) => {
            state.selected_pillar.pillar_levels=params;
        },
        mutatePillarMplan:(state,param) => {
            state.selected_pillar.pillar_mplan=param;
        },
        mutatePillarRisks: (state,param)=>{
            state.selected_pillar.pillar_risk=param;
        },
        mutatePillarKnowledge: (state, param) => {
            state.selected_pillar.pillar_knowledge=param;
        },
        // Seleceted program mutations
        mutateToc(state,param){
            state.selected_program.program_toc = param;
        },
        mutateProgramLogframe: (state, params) => {
            state.selected_program.program_logframe = params;
        },
        mutateProgramRisk: (state,param)=> {
            state.selected_program.program_risks = param;
        },
        mutateProgramProjectsRisk: (state,param)=> {
            state.selected_program.program_projects_risks = param;
        },
        
        mutateProgramKnowledge: (state, param)=> {
            state.selected_program.program_knowledge = param;
        },
        mutateCurrentProgram(state,param){
            state.current_program = param;
        },
        mutateApprovalData(state, param){
            state.approval_data = param;
        },
        mutateReport: (state, params)=> {
            state.report_data=params;
        }
    },
    actions: {
        CANCEL_PENDING_REQUESTS(context) {
            // Cancel all request where a token exists
            context.state.cancelTokens.forEach((request) => {
              if (request.cancel) {
                request.cancel();
              }
            });
            // Reset the cancelTokens store
            context.commit("CLEAR_CANCEL_TOKENS");
          },      
        setLoginEmail: (context, payload) => {
            context.commit('setLoginEmail', payload);
        },
        setUserData: (context, payload) => {
            context.commit("mutateUserData", payload);
        },
        setNumbers(context,payload){
            context.commit("mutateNumbers", payload);
        },
        setProgram(context,payload){
            context.commit("mutateProgramData", payload)
        },
        setPillar(context, payload){
            context.commit("mutatePillarData", payload);
        },
        setStrategies(context, payload){
            context.commit("mutateStrategies", payload);
        },
        setProject(context, payload){
            context.commit("mutateProject", payload);
        },
        setIps(context,payload){
            context.commit("mutateIps", payload);
        },
        setPrg(context, payload){
            context.commit("mutateSelectedProg", payload)
        },
        // Selected project actions
        setProjectLevels(context, payload){
            context.commit("mutateProjectLevels", payload)
        },
        setProjectAssumptions(context, payload){
            context.commit("mutateProjectAssumptions", payload)
        },
        setProjectMplans(context, payload){
            context.commit("mutateProjectMeasurementPlan", payload)
        },
        setProjectRisks: (context, payload) => {
            context.commit("mutateProjectRisks", payload);
        },
        setProjectKnowledge: (context, payload) => {
            context.commit("mutateProjectKnowledge", payload);
        },
        // Selected Pillar actions
        setPillarProjects: (context, payload) => {
            context.commit("mutatePillarProjects", payload);
        },
        setPillarLevels:(context,payload)=> {
            context.commit("mutatePillarLevels",payload);
        },
        setPillarMplan: (context, payload) => {
            context.commit("mutatePillarMplan", payload);
        },
        setPillarRisks: (context, payload) => {
            context.commit("mutatePillarRisks", payload)
        },
        setPillarKnowledge: (context,payload) => {
            context.commit("mutatePillarKnowledge", payload)
        },
        // Selected program action creators
        setProgramToc: (context,payload) =>{
            context.commit("mutateToc",payload)
        },
        setProgramLogfram: (context, payload) => {
            context.commit("mutateProgramLogframe", payload)
        },
        setProgramRisk: (context, payload) => {
            context.commit("mutateProgramRisk", payload);
        },
        setProgramProjectRisk: (context, payload) => {
            context.commit("mutateProgramProjectsRisk", payload);
        },
        setKnowledgeBase: (context, payload) => {
            context.commit("mutateProgramKnowledge", payload);
        },
        setCurrentProgram(context, payload){
            context.commit("mutateCurrentProgram", payload);
        },
        setApproval: (context, payload) => {
            context.commit("mutateApprovalData", payload)
        },
        setReport: (context, payload) => {
            context.commit("mutateReport", payload);
        }
    },
    modules: {
        manager
    }
});