<template>
  <b-row>
    <div class="col-sm-12 col-md-12 text-left main-wrapper col-lg-12">
      <b-container v-if="state.loading">
        <a-skeleton active :paragraph="{ rows: 7 }" :loading="state.loading" />
      </b-container>
      <form v-else @submit="nextStep">
        <b-row>
          <div class="col-sm col-md">
            <div class="form-group">
              <label>Program Title</label>
              <input
                placeholder="Enter title"
                type="text"
                class="form-control"
                v-model="title"
                required
                v-bind:class="validations.title ? 'is-invalid' : null"
                @keyup="onchangeTitle"
              />
              <small v-if="validations.title" class="text-danger">
                Program title is required.
              </small>
            </div>

            <b-form-group
              class="form-text-label"
              label-for="desc"
              label="AFR Program Brief"
            >
              <b-form-textarea
                id="desc"
                placeholder="Enter description"
                rows="3"
                resize
                class="form-text"
                v-model="description"
                required
                v-bind:class="validations.description ? 'is-invalid' : null"
                @keyup="onChangeDesc"
              ></b-form-textarea>
              <small v-if="validations.description" class="text-danger">
                Program AFR description brief is required.
              </small>
            </b-form-group>

            <div class="form-group">
              <label>Program Structure Levels</label>
              <input
                placeholder="Enter level number"
                type="number"
                class="form-control"
                v-model="levels"
                required
                v-bind:class="validations.levels ? 'is-invalid' : null"
                @keyup="onChangeLevels"
              />
              <small v-if="validations.levels" class="text-danger">
                Program level number is required.
              </small>
            </div>
          </div>
          <div class="col-sm col-md">
            <div class="form-group">
              <label>Program Timeline</label>
              <b-row>
                <div class="col-sm col-md col-lg">
                  <input
                    placeholder="From"
                    type="number"
                    min="1900"
                    max="2099"
                    step="1"
                    class="form-control"
                    v-model="from"
                    required
                    v-bind:class="validations.from ? 'is-invalid' : null"
                    @keyup="onChangeFrom"
                  />
                  <small v-if="validations.from" class="text-danger">
                    Program timeline <b><i>From</i></b> is required.
                  </small>
                </div>
                <div class="col-sm col-md col-lg">
                  <input
                    placeholder="To"
                    type="number"
                    class="form-control"
                    v-model="to"
                    required
                    v-bind:class="validations.to ? 'is-invalid' : null"
                    @keyup="onChangeTo"
                  />
                  <small v-if="validations.to" class="text-danger">
                    Program timeline <b><i>To</i></b> is required.
                  </small>
                </div>
              </b-row>
            </div>
            <div class="form-group">
              <label>Program Budget</label>
              <input
                placeholder="Enter amount"
                type="number"
                class="form-control"
                v-model="budget"
                required
                v-bind:class="validations.budget ? 'is-invalid' : null"
                @keyup="onChangeBudget"
              />
              <small v-if="validations.budget" class="text-danger">
                Program budget is required.
              </small>
            </div>

            <div class="row">
              <div class="col-12 mb-2">Donor contributions</div>
              <div class="col-md-6">
                <div class="form-group">
                   <label>Select Donor</label>
                  <b-input-group>
                    <b-form-select v-model="new_donor_contribution.donor" text-field="name" value-field="id" :options="donors"></b-form-select>
                  </b-input-group>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Amount</label>
                  <input
                    placeholder="Enter amount"
                    type="number"
                    class="form-control"
                    v-model="new_donor_contribution.amount"
                    required
                    v-bind:class="validations.budget ? 'is-invalid' : null"
                    @keyup="onChangeBudget"
                  />
                  <small v-if="validations.budget" class="text-danger">
                    Contribution amount is required.
                  </small>
                </div>
              </div>
              <div class="col-md-2">
                <button type="button" @click="addDonorFunding" class="btn btn-outline-primary mt-4">Add</button>
              </div>
              <div class="col-md-12">
                <table class="table table-striped table-hover" v-if="donor_contributions && donor_contributions.length > 0" >
                  <tr>
                    <th>ID</th>
                    <th>Donor</th>
                    <th>Amount</th>
                  </tr>
                  <template v-for="(donor_contribution, dkey) in donor_contributions.filter(donor => donor != null)">
                      <tr v-if="donor_contribution" :key="dkey">
                        <td>{{dkey+1}}</td>
                        <td>{{donor_contribution.donor_name}}</td>
                        <td>{{donor_contribution.amount}}</td>
                      </tr>
                  </template>
                  
                </table>
              </div>
            </div>
          </div>
        </b-row>
      </form>
    </div>
  </b-row>
</template>

<script>
import encode from "jwt-encode";
import decode from "jwt-decode";
export default {
  props: ["store"],
  data() {
    return {
      title: null,
      from: null,
      to: null,
      budget: null,
      
      description: null,
      isSaved: false,
      donor_contributions: [],
      donor_lookup_table: {},
      donors: [],
      new_donor_contribution: {
        amount: 0,
        donor: null,
      },

      validations: {
        title: false,
        from: false,
        to: false,
        budget: false,
        description: false,
        levels: false,
      },
      state: {
        loading: false,
        adding: false,
        donors: false,
      },
      data: null,
      pillar_keyword: null,
      project_keyword: null,
      levels: null,
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    currentSaved() {
      const data = sessionStorage.getItem("requested");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.program ? this.program : decoded;
    },
    program() {
      return this.$store.state.program_data
        ? this.$store.state.program_data
        : null;
    },
    drafted() {
      const data = sessionStorage.getItem("draft");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return decoded;
    },
  },
  beforeMount() {
    this.getCurrentSaved();
    if (this.drafted && this.drafted.status == "draft") {
      this.getProgramDetails();
    } else if (this.drafted) {
      this.getProgramDetails();
    }
  },
  mounted(){
    this.getInstitutions()
  },
  methods: {
    async getProgramDetails() {
      this.state.loading = true;
      await this.axios
        .get(`getProgram/${this.drafted.id}`)
        .then((response) => {
          this.state.loading = false;
          this.data = response.data[0];
          this.title = this.data.Title;
          this.from = this.data.from;
          this.to = this.data.to;
          this.budget = this.data.Budget;
          this.description = this.data.description;

          if(this.data.donors){

            this.data.donors.forEach(donor_contribution => {
              this.donor_contributions[donor_contribution.donor] = {
                amount: donor_contribution.amount,
                id: donor_contribution.donor,
                donor_name: donor_contribution.donor_name
              }
            })
            
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    getCurrentSaved() {
      this.title =
        this.currentSaved !== null ? this.currentSaved.data.Title : "";
      this.from =
        this.currentSaved !== null ? this.currentSaved.data.from : null;
      this.to = this.currentSaved !== null ? this.currentSaved.data.to : null;
      this.budget =
        this.currentSaved !== null ? this.currentSaved.data.Budget : null;
      this.description =
        this.currentSaved !== null ? this.currentSaved.data.description : "";
      this.isSaved =
        this.currentSaved !== null ? this.currentSaved.isSavedStep1 : false;
      this.levels =
        this.currentSaved !== null
          ? this.currentSaved.data.levels
          : this.drafted
          ? this.drafted.levels
          : 0;
    },
    async getInstitutions() {
      this.state.donors = true;
      await this.axios
        .get("getInstitutions?type=donor")
        .then((response) => {
          if (response.data.data) {
            this.state.loading = false;
            this.donors = response.data.data;

            // Keep id indexed object for lookup
            this.donors.forEach((donor) => {this.donor_lookup_table[donor.id] = donor})
          } else {
            this.state.donors = false;
            this.$notification["error"]({
              message: "ERROR!",
              description: `Something wrong when getting donors`,
              duration: 5,
            });
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async addDonorFunding(){
      // check if amount and donor are added
      let total = 0;
      Object.keys(this.donor_contributions).forEach((donor_id) => total + parseInt(this.donor_contributions[donor_id].amount))

      if(!this.new_donor_contribution.amount || !this.new_donor_contribution.donor){
        this.$message.error("Please fill amount and selector donor");
        return false;
      }

      if(this.new_donor_contribution.amount && (this.new_donor_contribution.amount + total) > this.budget){
        this.$emit("error", "");
        this.$message.error("Amount exceeds total budget");
        return false;
      }
      console.log(this.donor_lookup_table)
      this.donor_contributions[this.new_donor_contribution.donor] = {
        amount: this.new_donor_contribution.amount,
        id: this.new_donor_contribution.donor,
        donor_name: this.donor_lookup_table[this.new_donor_contribution.donor].name
      }

      this.new_donor_contribution = {
        amount: 0,
        donor: null,
      }

    },
    nextStep() {
      if (!this.title) {
        this.$emit("error", "Program title is required.");
        this.validations.title = true;
        this.$message.error("Program title is required.");
      } else if (!this.from) {
        this.$emit("error", "Program timeline field is required.");
        this.validations.from = true;
        this.$message.error("Program timeline field is required.");
      } else if (!this.to) {
        this.$emit("error", "Program timeline field is required.");
        this.validations.to = true;
        this.$message.error("Program timeline field is required.");
      } else if (!this.budget) {
        this.$emit("error", "Program budget is required.");
        this.validations.budget = true;
        this.$message.error("Program budget is required.");
      } else if (!this.description) {
        this.$emit("error", "Program AFR brief description is required.");
        this.validations.description = true;
        this.$message.error("Program AFR brief description is required.");
      } else if (!this.levels) {
        this.$emit("error", "Program level number is required.");
        this.validations.levels = true;
        this.$message.error("Program level number is required.");
      } else {
        const requestData = {
          Title: this.title,
          Budget: this.budget,
          description: this.description,
          from: this.from,
          to: this.to,
          levels: this.levels,
          donors: this.donor_contributions,
        };

        if (this.isSaved || this.drafted) {
          this.$emit("can-continue");
        } else {
          this.$emit("loading", true);
          this.axios
            .post("AddProgram", requestData)
            .then((response) => {
              if (response.data) {
                const currentSavedReq = {
                  data: requestData,
                  isSavedStep1: true,
                  programData: response.data,
                };
                this.$emit("loading", false);
                const secret = "tokenHashingProDet--afr0-1200x";
                const jwt = encode(currentSavedReq, secret);
                sessionStorage.setItem("requested", jwt);
                this.$store.dispatch("setProgram", currentSavedReq);
                this.getCurrentSaved();
                this.$emit("can-continue");
                this.$notification["success"]({
                  message: "SUCCESS!",
                  description: `Program details data saved.`,
                  duration: 5,
                });
              } else {
                this.$emit("loading", false);
                this.$message.error(
                  `An error occured while saving program details`,
                  4
                );
              }
            })
            .catch((error) => {
              this.$notification["error"]({
                message: "ERROR!",
                description: `${error.errorMessage}`,
                duration: 7,
              });
              this.$emit("loading", false);
            });
        }
      }
    },
    onchangeTitle() {
      if (this.title != null || this.title != "") {
        this.validations.title = false;
      } else {
        this.validations.title = true;
      }
    },
    onChangeLevels() {
      if (this.levels) {
        this.validations.levels = false;
      } else {
        this.validations.levels = true;
      }
    },
    onChangeFrom() {
      if (this.validations.from != null) {
        this.validations.from = false;
      } else {
        this.validations.from = true;
      }
    },
    onChangeTo() {
      if (this.validations.to != null) {
        this.validations.to = false;
      } else {
        this.validations.to = true;
      }
    },
    onChangeBudget() {
      if (this.validations.budget != null) {
        this.validations.budget = false;
      } else {
        this.validations.budget = true;
      }
    },
    onChangeDesc() {
      if (this.validations.description != "" || this.description != null) {
        this.validations.description = false;
      } else {
        this.validations.description = true;
      }
    },
  },
  watch:{
  },
};
</script>

<style scoped>
.main-wrapper .form-group label,
.main-wrapper .form-group input::placeholder,
.form-text,
.form-text::placeholder,
.form-text-label,
.form-control,
.custom-select {
  font-size: 13px !important;
}
</style>
