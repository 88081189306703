<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-12 ml-sm-auto col-md-12 pt-0 main mb-5">
      <div class="card card-main card-0 mt-3 rounded-20 m-0 m-0">
        <div class="card-body p-0 m-0">
          <b-row>
            <div class="col-sm col-md">
              <b-row class="my-1">
                <div class="col-sm co-md">
                  <b-select class="bg-white" placeholder="Filter pillar" :options="pillars" v-model="filterPillar"></b-select>
                </div>
                <div class="col-sm-4 col-md-4">
                  <form action="">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control p-3 rounded-10 search-box indent-10 text-13"
                        :placeholder="`Search ${getKeyword('project')} Name or pillar filter`"
                        v-model="filter"
                      />
                    </div>
                  </form>
                </div>
                <div class="col-sm-2">
                   <div class="d-flex justify-content-end">
                    <a
                      class="btn btn-primary rounded-20 pl-4 pr-4 text-12"
                      role="button"
                      @click="createProject"
                    >
                      {{`Add ${getKeyword('project')}`}}
                    </a>
                  </div>
                </div>
                <div class="col-sm-1">
                  <button
                    @click="refresh"
                    type="button"
                    class="btn btn-secondary btn-sm rounded-10 border-none"
                  >
                    <b-container v-if="state.loading && state.refresh">
                      <b-spinner small></b-spinner>
                      <span class="text-13 ml-2">Refreshing...</span>
                    </b-container>
                    <span v-else class="text text-13">Refresh</span>
                  </button>
                </div>
              </b-row>
              <b-row class="ml-2">
                <div class="table-responsive">
                  <b-container class="my-5" v-if="state.loading">
                    <a-skeleton
                      active
                      :paragraph="{ rows: 8 }"
                      :loading="state.loading"
                    ></a-skeleton>
                  </b-container>
                  <b-table
                    v-else
                    table-class="table-programs text-13"
                    striped
                    hover
                    thead-tr-class="tb-head text-left indent-20"
                    tbody-class="tb-body text-left indent-20"
                    :items="projects"
                    :filter="filter"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    show-empty
                  >
                    <template v-slot:cell(Title)="data">
                      <a-tooltip v-if="data.item.status =='drafted'" title="This project is not completed while setup, when it cliked you will be redirected to complete the project setup." placement="bottom" :color="'#87d068'">
                        <span @click="unCompleteGo(data.item)" class="text-capitalize link-text cursor-pointer">{{data.item.Title}}</span>
                      </a-tooltip>
                      <a-tooltip v-else title="Go to project" placement="bottom" :color="'#87d068'">
                        <span @click="goToProject(data.item)" class="text-capitalize link-text cursor-pointer">{{data.item.Title}}</span>
                      </a-tooltip>
                    </template>
                    <template v-slot:cell(edit)="data">
                      <a-tooltip v-if="data.item.status != 'drafted' && data.item.status != 'Completed'" title="Edit project" placement="bottom">
                        <span @click="EditProject(data.item)" class="text-capitalize link-text cursor-pointer"> <button class="btn btn-light rounded-20"> <i class='bx bxs-edit-alt text-success'></i> </button> </span>
                      </a-tooltip>
                    </template>
                    <template v-slot:cell(status)="data">
                        <span v-if="data.item.status =='drafted'"><a type="button" @click="unCompleteGo(data.item)" class="btn-sm btn btn-warning rounded-20 pr-3">Draft</a></span>
                        <span v-else-if="data.item.status =='Completed'"><a type="button" class="btn-sm btn btn-primary rounded-20 pr-3">{{data.item.status}}</a></span>
                        <span v-else><a type="link" class="text-success rounded-20 pr-3">Active</a></span>
                    </template>
                    <template v-slot:cell(completion_percentage)="data">
                      <b-progress
                        height="9px"
                        class="ml-1 pt-0 progress-wrapper"
                        :max="100"
                      >
                        <b-progress-bar
                          class="af-progress"
                          :value="data.value"
                        ></b-progress-bar>
                      </b-progress>
                    </template>
                  </b-table>
                </div>
              </b-row>
              <b-row>
                <div class="col-sm-8"></div>
                <div class="col-sm">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    class="ml-2 float-right"
                  ></b-pagination>
                </div>
              </b-row>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import encode from "jwt-encode";
export default {
  component: {},
  data() {
    return {
      title: null,
      pageTitle: "Projects",
      fields: ["code", "Title", 'pillar', {label: 'Timeline', key: 'completion_percentage'}, {key:"status", label:"Status"}, "edit"],
      perPage: 8,
      currentPage: 1,
      filter: "",
      filterPillar: null,
      projects: [],
      pillars: [],
      state: {
        loading: true,
        refresh: false,
        saving: false,
        loadPillars: false,

      },
    };
  },
  beforeMount() {
    this.$title("Projects");
  },
  mounted(){
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    rows() {
      return this.projects.length;
    },
    program() {
      return this.$store.state.current_program;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
  },
  watch:{
    filterPillar(){
      this.filter = this.filterPillar
    },
    program(newValue){
      console.log("new program value", newValue, this.projects)

      if(newValue && this.projects.length < 1){
        this.getProjects();
        this.getPillars();
      }
    }
  },
  methods: {
    createProject(){
      sessionStorage.removeItem("project")
      sessionStorage.removeItem("pjt")
      sessionStorage.removeItem("drafted-project")
      this.$router.replace({ name: 'create-project' });
    },
    goToProject(param) {
      const secret = "tokenHashingViv--afr0-101-6534bjhdvw-+++5##";
      const jwt = encode(param, secret);
      sessionStorage.setItem("pjt", jwt);
      sessionStorage.removeItem("drafted-project")
      this.$router.push({ name: "project-dashboard" });
    },
    async getPillars(){
      this.state.loadPillars = true;
      await this.axios
        .get(`getPillarsInProgram`)
        .then((response) => {
          if (response.data) {
            this.state.loadPillars = false;
            // Transform the data
            let pillars = response.data.map( (pillar) => { return {value: pillar.Title, text: pillar.Title} } );
            // add default value
            pillars = [{value:null, text: 'Select pillar/cross cutting'}].concat( pillars )
            this.pillars = pillars
            
          } else {
            this.state.loadPillars = false;
          }
        })
        .catch(() => {
          this.state.loadPillars = false;
        });
    },
    EditProject(data){
      const secret = 'tokenHashingVi56621**;jn]mpj[```11//1--v--afr0-101';
      const jwt = encode(data,secret);
      sessionStorage.removeItem("pjt")
      sessionStorage.removeItem("project")
      sessionStorage.setItem("drafted-project",jwt);
      this.$store.dispatch("setProject", null)
      this.$router.push({name:'create-project'})
    },
     unCompleteGo(data){
      const secret = 'tokenHashingVi56621**```11//1--v--afr0-101';
      const jwt = encode(data,secret);
      sessionStorage.removeItem("pjt")
      sessionStorage.removeItem("project")
      sessionStorage.setItem("drafted-project",jwt);
      this.$store.dispatch("setProject", null)
      this.$router.push({name:'create-project'})
    },
    async getProjects() {
      this.state.loading = true;
      await this.axios
        .get(`program/projects/${this.program.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.projects = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    refresh() {
      this.state.refresh = true;
      this.getProjects();
    },
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

.program-collapsed {
  border: 1px solid #bfbfbf !important;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.progress-wrapper {
  width: 260px !important;
}

.card-1 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 2px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.card-btn-footer {
  background: #f6f6f6 !important;
  border: 1px solid #000 !important;
}

.card-log-frame {
  background: #f3f3f3 !important;
}

.card-pillars {
  background: #3fb15d21 !important;
}

.card-0 {
  background: #f6f6f6 !important;
}

.border-remain {
  background: #69737f !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.border-completed {
  background: #6fcf97 !important;
  height: 6.6px !important;
  width: 56px !important;
  border-radius: 20px !important;
}
</style>