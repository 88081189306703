<template>
  <section id="logout"></section>
</template>

<script>
export default {
  name: "logout",
  mounted() {
    if (sessionStorage.token || sessionStorage.token2) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("token2");
      this.$router.go(0);
    } else {
      this.$router.replace("/");
    }
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
  },
  methods: {
    async logOutUser() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      await this.axios
        .post(`logout`, config)
        .then((response) => {
          if (response.data) {
              if (sessionStorage.token) {
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("token2");
              this.$router.go(0);
            } else {
              this.$router.replace("/");
            }
          } else {
            this.$message.error(`Something went wrong while logging out.`);

          }
        })
        .catch((error) => {
          this.$message.error(`${error.errorMessage}`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#logout {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>