<template>
   <nav class="navbar fixed-top navbar-light">
        <router-link class="navbar-brand logo-brand" to="/user">
            <b-img class="logo" :src="logo"  alt="AFR logo"/>
        </router-link>
    </nav>
</template>

<script>
import logo from '../../../assets/images/logo.png';
import avatar from '../../../assets/images/icons/user.png';
export default {
    data(){
        return{
            logo: logo,
            avatar: avatar
        }
    },
    methods: {
        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
            return this.$route.path.indexOf(path) === 0 // current path starts with this path string
            })
        }
    }
}
</script>

<style scoped>
    *,html,body{
        font-family: Ubuntu;
    }
    .logo{
        width: 40%;
        height: 40%;
    }
    .navtop{
        height: 45px !important;
    }

    .avatar-b{
        width: 100px;
        height:100px
    }
    .vm-active{
        padding-right: 1.2rem !important;
        padding-left: 1.2rem !important;
        padding-bottom: 1rem !important;
        border-bottom: 4px solid #0FA958 !important;
    }
    .navbar-nav .nav-item{
        padding: 20px 30px !important;
    }

    button:focus{
        outline: 3px auto #0FA958 !important;
    }
    @media screen and (max-width: 578px) {

       #navbarCollapse.collapsing,
        #navbarCollapse.show {
            backdrop-filter: blur(10px);
            box-shadow: 0 1px 6px 0 #0fa9578a;
            background-color: #0fa957a4 !important;
        }

       #navbarCollapse ul li{
           float: left;
           text-align: left;
           padding: 4px 5px !important;
       }

       #navbarCollapse ul li .active{
           float: left;
           text-align: left;
       }

       #navbarCollapse ul li a {
           color: #fff;
           font-weight: bold;
       }

       .vm-active{
            padding-right: 1.2rem !important;
            padding-left: 1.2rem !important;
            padding-bottom: 1rem !important;
            border-bottom: 4px solid #ffffff !important;
        }

        .right-side{
            padding-left: 40px;
        }

        .right-side span a i{
            color: #fff !important;
            font-size: 30px !important;
        }

        .avatar-b{
            width:50px;
            height: 50px;
        }
        
        
    }

    @media (max-width: 600px){
       #navbarCollapse.collapsing,
        #navbarCollapse.show {
            backdrop-filter: blur(2px);
            box-shadow: 0 1px 6px 0 #0fa9578a;
            background-color: #0fa957a4 !important;
        }

       #navbarCollapse ul li{
           float: left;
           text-align: left;
           padding: 4px 5px !important;
       }

       #navbarCollapse ul li .active{
           float: left;
           text-align: left;
       }

       #navbarCollapse ul li a {
           color: #fff;
           font-weight: bold;
       }

       .vm-active{
            padding-right: 1.2rem !important;
            padding-left: 1.2rem !important;
            padding-bottom: 1rem !important;
            border-bottom: 4px solid #ffffff !important;
        }

        .right-side{
            padding-left: 1rem;
        }

        .right-side span a i{
            color: #fff !important;
            font-size: 28px !important;
        }
    }
    
    @media (min-width: 768px){
        .navtop{
            height: 95px !important;
        }
        .navbar-expand-md .navbar-nav {
                flex-direction: row !important;
                margin-left: -1% !important;
                padding-right: 0 !important;
                margin-right:2% !important;
            }
        .right-side{
                margin-left: 1.4rem !important;
                padding-left:1.4rem !important;
            }
        .avatar-b{
                width: 50px !important;
                height: 50px !important;
            }
        .logo {
                width: 65px !important;
                height: 35px !important;
            }
        
    }

    @media (min-width: 1024px){
        .navbar-expand-md .navbar-nav {
                flex-direction: row !important;
                /* margin-left:  !important; */
                padding-right: 15px !important;
                margin-right: 15px !important;
            }
        .right-side{
                margin-left: 6.7rem !important;
                padding-left: 6.7rem !important;
                justify-content: left !important;
                float: left !important;
            }
        .avatar-b{
                width: 50px !important;
                height: 50px !important;
            }
        .logo {
                width: 65px !important;
                height: 35px !important;
            }
        
    }

    @media (min-width: 1280px){
        .navbar-expand-md .navbar-nav {
                flex-direction: row !important;
                /* margin-left:  !important; */
                padding-right: 15px !important;
                margin-right: 15px !important;
            }
        .right-side{
                margin-left: 11rem !important;
                padding-left: 11rem !important;
                justify-content: left !important;
                float: left !important;
            }
        .avatar-b{
                width: 50px !important;
                height: 50px !important;
            }
        .logo {
                width: 65px !important;
                height: 35px !important;
            }
        
    }
</style>