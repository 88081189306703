<template>
  <div class="main mb-5">
          <div class="card card-2 m-0 p-0">
                <div class="card-body p-0 m-0">
                  <b-row>
                      <div class="col-sm col-md col-lg">
                          <a 
                            @click="createConfirmModal" 
                            type="button" 
                            class="btn btn-secondary btn-top-create float-right mr-2 pr-4 pl-4 pt-2 pb-2 mb-3" 
                            role="button"> Create new </a>  
                      </div>                      
                  </b-row>
                    <b-row>
                      <div class="col-sm col-md">
                          <div class="float-left">
                            <span class="label1 cursor-pointer">Label <i class='bx bx-chevron-down' ></i></span>
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>Edit table data columns</span>
                              </template>
                                <span class="ml-2 pl-2 cursor-pointer"><i class='bx bx-menu-alt-left'></i> Edit Columns</span>
                            </a-tooltip>
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>Edit table filters</span>
                              </template>
                                <span class="ml-2 pl-2 cursor-pointer"><i class='bx bx-menu-alt-right'></i> Edit Filters</span>
                            </a-tooltip>
                            <a-tooltip placement="top">
                              <template slot="title">
                                <span>Export data from table.</span>
                              </template>
                              <span class="ml-2 pl-2 cursor-pointer"><i class='bx bxs-square-rounded'></i> Export</span>
                            </a-tooltip>
                        </div>
                      </div>
                      <div class="col-sm col-md">
                        <div class="float-right">
                          <span class="ml-n5 cursor-pointer">
                            <a-input style="width: 180px;" placeholder="Search">
                              <a-icon slot="prefix" type="search" />
                            </a-input>
                          </span>
                          <span class="ml-3 label2 cursor-pointer">Label <i class='bx bx-chevron-down' ></i></span>
                          <a-tooltip placement="top">
                            <template slot="title">
                              <span>Week View</span>
                            </template>
                            <span class="ml-3 cursor-pointer"><i class="fas fa-grip-lines-vertical"></i><i class="fas fa-grip-lines-vertical"></i></span>
                          </a-tooltip>
                          <a-tooltip placement="top">
                            <template slot="title">
                              <span>List daily view</span>
                            </template>
                            <span class="ml-3 cursor-pointer"><i class="fas fa-list"></i></span>
                          </a-tooltip>
                        </div>
                      </div>
                    </b-row>
                    <div class="table-responsive mt-2">
                        <table class="table bg-white table-striped table-all">
                            <thead>
                                <tr class="theader-projects">
                                    <th>Column Header</th>
                                    <th>Ratings</th>
                                    <th>Category</th>
                                    <th>Treatment</th> 
                                    <th>Column Header</th>
                                </tr>
                                <tr class="theader-projects">
                                    <th>
                                      <div class="d-flex">
                                        <a-input class="table-search" placeholder=""/>
                                        <i class='bx bx-dots-horizontal-rounded pl-1 pt-1 bx-sm cursor-pointer'></i>
                                      </div>
                                    </th>
                                    <th>
                                      <div class="d-flex table-search">
                                        <a-input placeholder=""/>
                                        <i class='bx bx-dots-horizontal-rounded pl-1 pt-1 bx-sm cursor-pointer'></i>
                                      </div>
                                    </th>
                                    <th>
                                      <div class="d-flex table-search">
                                        <a-input placeholder=""/>
                                        <i class='bx bx-dots-horizontal-rounded pl-1 pt-1 bx-sm cursor-pointer'></i>
                                      </div>
                                    </th>
                                    <th>
                                        <div class="d-flex table-search">
                                          <a-input placeholder=""/>
                                          <i class='bx bx-dots-horizontal-rounded pl-1 pt-1 bx-sm cursor-pointer'></i>
                                        </div>  
                                    </th> 
                                    <th>
                                        <div class="d-flex table-search">
                                          <a-input placeholder=""/>
                                          <i class='bx bx-dots-horizontal-rounded pl-1 pt-1 bx-sm cursor-pointer'></i>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="tbody-row">
                                    <td>Risk title</td>
                                    <td>Risk rating</td>
                                    <td>Category</td>
                                    <td>Accept</td>
                                    <td><span>Edit</span>|<span>View</span>|<span>Delete</span></td>
                                </tr>
                                <tr class="tbody-row">
                                    <td>Risk title</td>
                                    <td>Risk rating</td>
                                    <td>Category</td>
                                    <td>Mitgate</td>
                                    <td><span>Edit</span>|<span>View</span>|<span>Delete</span></td>
                                </tr>
                               <tr class="tbody-row">
                                    <td>Risk title</td>
                                    <td>Risk rating</td>
                                    <td>Category</td>
                                    <td>Avoid</td>
                                    <td><span>Edit</span>|<span>View</span>|<span>Delete</span></td>
                                </tr>
                                <tr class="tbody-row">
                                    <td>Risk title</td>
                                    <td>Risk rating</td>
                                    <td>Category</td>
                                    <td>Accept</td>
                                    <td><span>Edit</span>|<span>View</span>|<span>Delete</span></td>
                                </tr>
                                <tr class="tbody-row">
                                    <td>Risk title</td>
                                    <td>Risk rating</td>
                                    <td>Category</td>
                                    <td>Accept</td>
                                    <td><span>Edit</span>|<span>View</span>|<span>Delete</span></td>
                                </tr>
                                <tr class="tbody-row">
                                    <td>Risk title</td>
                                    <td>Risk rating</td>
                                    <td>Category</td>
                                    <td>Accept</td>
                                    <td><span>Edit</span>|<span>View</span>|<span>Delete</span></td>
                                </tr>
                                <tr class="tbody-row">
                                    <td>Risk title</td>
                                    <td>Risk rating</td>
                                    <td>Category</td>
                                    <td>Accept</td>
                                    <td><span>Edit</span>|<span>View</span>|<span>Delete</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <a-modal v-model="visibleCreate" :footer="null" title="Create risk">
              <form>
                  <b-row>
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                          <input placeholder="Title"  type="text" class="form-control form-input-modal text-13">
                      </div>
                      <div class="form-group">
                          <input placeholder="Ratings"  type="text" class="form-control form-input-modal text-13">
                      </div>
                      <div class="form-group">
                          <b-form-group class="form-text-label" label-for="desc">
                            <b-form-textarea
                              id="desc"
                              placeholder="Description"
                              rows="3"
                              resize
                              class="form-text-modal text-13"
                            ></b-form-textarea>
                        </b-form-group>
                      </div>
                    </div>
                </b-row>
                <b-row class="d-flex justify-content-center">
                    <div class="inline-block pr-4">
                        <a @click="cancelConfirmModal" type="button" class="btn btn-primary btn-block btn-confirm text-13">OK</a>
                    </div>
                    <div class="inline-block">
                        <a @click="cancelConfirmModal" type="button" class="btn btn-secondary btn-block btn-cancel text-13">CANCEL</a>
                    </div>
                </b-row>
              </form>
          </a-modal>
        </div>
</template>

<script>
export default {
    name:'projects-tab',
    data(){
      return {
        visibleCreate: false,
      }
    },
    methods: {
      createConfirmModal() {
            this.visibleCreate=true;
      },
      cancelConfirmModal(){
          this.visibleCreate=false;
      },
    }
}
</script>

<style scoped>
  .table-all{
    border: 1px solid #cdd0d1 !important;
    border-radius: 4px !important;
    font-size: 12px !important;
  }

  .cursor-pointer{
    cursor: pointer !important;
  }
  
  .btn-top-create{
        background: #0FA958 !important;
        border-radius: 20px !important;
        font-size: 13px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

  .table-all .tbody-row td{
    text-align: left;
  }

  .row-1{
    border: 1px solid #F5F6F7 !important;
  }

  .card-2{
        border: none !important;
        background-color: transparent !important;
    }

  .table-all .top-panel td span{
    font-size: 13px !important;
  }

  .table-all .theader-projects th{
    font-size: 13px !important;
  }

  .table-all th{
    text-align: left !important;
    
  }

  @media (max-width: 768px) {
    .table-all .theader-projects .table-search{
      width: 165px !important;
    }
  }
</style>