<template>
<div>
  <form>
    <b-row>
      <div class="col-sm col-md mt-n2 mb-3">
        <a-tooltip title="Refresh project data" placement="bottom">
          <button
            @click="refresh"
            type="button"
            class="btn mr-3 btn-secondary btn-sm rounded-20 float-right border-none"
          >
            <b-icon-arrow-repeat
              font-scale="1.6"
              :animation="state.refresh && state.loading ? 'spin' : ''"
            />
          </button>
        </a-tooltip>
      </div>
    </b-row>
    <b-row>
      <div class="col-sm-5 col-md-5 main-wrapper col-lg-5 mr-5">
        
        <div class="form-group">
          <label class="float-left text-13">Indicator title</label>
          <input
            v-model="title"
            placeholder="Title"
            type="text"
            class="form-control"
            v-bind:class="validations.title ? 'is-invalid' : null"
            @keyup="onChangeTitle"
          />
          <small v-if="validations.title" class="text-danger float-left">
            Title is required
          </small>
        </div>
        <div class="form-group">
          <label class="float-left text-13">
            Alignment - Program, Pillar, {{capitalizeFirstLetter(getKeyword("project"))}}
          </label>
          <select
            v-model="alignment"
            v-bind:class="validations.alignment ? 'is-invalid' : null"
            @change="onChangeAlignment"
            class="custom-select bg-white text-13"
          >
            <option selected :value="null" disabled>Indicator Alignment</option>
            <option value="program">Program</option>
            <option value="pillar">Pillar</option>
            <option value="project">{{capitalizeFirstLetter(getKeyword("project"))}}</option>
          </select>
          <small v-if="validations.alignment" class="text-danger float-left">
            Indicator alignment must be selected.
          </small>
        </div>
        <!-- Program alignment details -->
        <template v-if="alignment == 'program'">
          <div class="form-group" >
            <label class="text-right">Program aligned TOC Level</label>
            <b-input-group>
              <b-select
                class="custom-select bg-white text-13 mr-2"
                v-model="toclevel"
                required
                :disabled="state.loadTOClevels"
                v-bind:class="validations.toclevel ? 'is-invalid' : null"
                @change="onChangeToc"
                v-if="alignment == 'program'"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled>
                    Select program theory of change level
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in toclevels"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.level }}
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-icon-arrow-repeat
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loadTOClevels ? 'spin' : ''"
                  @click="getTOCLevels"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="validations.toclevel && alignment == 'program'"
              class="text-danger"
            >
              Program theory of change level must be selected.
            </small>
            <small
              v-else-if="validations.rclevel && alignment == 'project'"
              class="text-danger"
            >
              Result chain level must be selected.
            </small>
          </div>
          <div class="form-group">
            <label class="text-right">Aligned Indicator</label>
            <b-input-group>
              <b-select
                class="custom-select bg-white text-13 mr-2"
                v-model="alignedIndicator"
                required
                :disabled="state.loadingLogFrame"
                v-bind:class="validations.alignedIndicator ? 'is-invalid' : null"
                @change="onChangeLogFrame"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled
                    >Select aligned Indicator(s)
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in logframes"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.Title }}
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-icon-arrow-repeat
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loadingLogFrame ? 'spin' : ''"
                  @click="getProgramIndicators"
                />
              </b-input-group-append>
            </b-input-group>
            <small v-if="validations.alignedIndicator" class="text-danger">
              Aligned indicator must be selected.
            </small>
          </div>

          <b-form-group>
             <b-form-checkbox
              v-model="auxiliaryPillarAlignment"
              name="featured-1"
              value="1"
              unchecked-value="off"
              class="text-left"
            >
              Also aligned to a pillar?
            </b-form-checkbox>
          </b-form-group>

        </template>

        <!-- Pillar alignment details -->
        <template v-if="alignment == 'pillar' || auxiliaryPillarAlignment">
          <div class="form-group">
            <label class="text-right">Select pillar</label>
            <b-input-group>
              <b-select
                class="custom-select bg-white text-13 mr-2"
                v-model="alignedPillar"
                required
                :disabled="state.loadPillars"
                v-bind:class="validations.alignedPillar ? 'is-invalid' : null"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled>
                    Select pillar
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in pillars"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.Title }}
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-icon-arrow-repeat
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loadPillars ? 'spin' : ''"
                  @click="fetchPillars"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="validations.alignedPillar "
              class="text-danger"
            >
              Aligned pillar must be selected.
            </small>
          </div>

          <div class="form-group" v-if="alignedPillar">
            <label class="text-right">Aligned Indicator</label>
            <b-input-group>
              <b-select
                class="custom-select bg-white text-13 mr-2"
                v-model="alignedPillarIndicator"
                required
                :disabled="state.loadingPillarIndicators"
                v-bind:class="validations.alignedIndicator ? 'is-invalid' : null"
                @change="onChangeLogFrame"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled
                    >Select aligned Indicator(s)
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in pillarIndicators"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.Title }}
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-icon-arrow-repeat
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loadingLogFrame ? 'spin' : ''"
                  @click="getPillarIndicators"
                />
              </b-input-group-append>
            </b-input-group>
            <small v-if="validations.alignedIndicator" class="text-danger">
              Aligned indicator must be selected. {{alignedPillarIndicator}}
            </small>
          </div>

        </template>


        <!-- Project alignment details -->
        <template v-if="alignment == 'project'">
        
          <div class="form-group">
            <label class="text-right">Aligned RC Level</label>
            <b-input-group>
              <b-select
                class="custom-select bg-white text-13 mr-2"
                v-model="rclevel"
                required
                :disabled="state.loadRClevels"
                v-bind:class="validations.rclevel ? 'is-invalid' : null"
                @change="onChangeRc"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled>
                    {{"Select project result chain level"}}
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in rclevels"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.level }}
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-icon-arrow-repeat
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loadRClevels ? 'spin' : ''"
                  @click="getRCLevels"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="validations.rclevel && alignment == 'project'"
              class="text-danger"
            >
              Result chain level must be selected.
            </small>
          </div>
          <!-- Elements -->
          <div class="form-group">
            <label class="text-right">Aligned RC Box</label>
            <b-input-group>
              <b-select
                class="custom-select bg-white text-13 mr-2"
                v-model="rcelement"
                required
                :disabled="state.loadRCelements"
                v-bind:class="validations.rcelements ? 'is-invalid' : null"
                @change="onChangeRcElement"
              >
                <template #first>
                  <b-select-option :value="null" selected disabled>
                    {{`Select ${getKeyword('project')} result chain box`}}
                  </b-select-option>
                </template>
                <b-select-option
                  v-for="item in rcelements"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.element }}
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-icon-arrow-repeat
                  class="bi-con-right"
                  font-scale="1.5"
                  :animation="state.loadRCelements ? 'spin' : ''"
                  @click="getRCLevels"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="validations.rcelement && alignment == 'project'"
              class="text-danger"
            >
              Result chain element must be selected.
            </small>
          </div>
        </template>
        
        <b-row>
          <div class="col-6">
            <label class="">Indicator Disaggregation</label>
            <!-- <p>Selected: {{selectedDisaggregations}}</p>
            <p>Aligned: {{alignedIndicatorDisaggregations}}</p> -->
          </div>

          <div class="col-md-12 col-sm-12">
            <template v-if="alignment != 'project'">
              <div class="float-left">
                <div class="text-info text-small">Dissagregations will be inherited</div>
                <p v-if="alignedIndicatorDisaggregations">
                  <span>{{alignedIndicatorDisaggregations.map(disag => disag.name).join(', ')}}</span>
                </p>
              </div>
            </template>
            <b-form-group v-else>
              <v-select v-if="indicators"
                :disabled="alignment != 'project'"
                label="name" class="w-100"
                placeholder="select indicator disaggregation"
                multiple
                v-model="selectedDisaggregations"
                :options="indicators" />
            </b-form-group>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm col-md">
            <div class="form-group">
              <label class="float-left text-13">Baseline</label>
              <input
                v-model="baseline"
                placeholder="Baseline"
                type="number"
                class="form-control"
              />
            </div>
            <template v-if="selectedDisaggregations">
              <div v-for="(disaggregation, disKey) in selectedDisaggregations" :key="disKey">
                <!-- loop into elements -->
                <b-row v-for="(element, disElemKey) in disaggregation.elements" :key="disElemKey">
                  <b-col>
                    <input
                      readonly
                      type="text"
                      :value="element.Title"
                      class="form-control"
                      disabled
                    />
                    <hr/>
                  </b-col>
                  <b-col>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Value"
                      :max="baseline"
                      @keyup="onBaselineDisaggregationChange({
                        disaggregation: disaggregation.id,
                        element: element.id
                      })"
                      v-model="baselineDisaggregation[`${element.id}`]"
                      required
                    />
                    <hr/>
                  </b-col>
                </b-row>
              </div>
            </template>
          </div>
        </b-row>
        <div class="form-group">
          <label class="float-left">Reporting responsiblity</label>
          <b-input-group>
            <v-select label="fullnames" class="w-100" placeholder="select reporters" multiple v-model="reporters" :options="potentialReporters" />
          </b-input-group>
        </div>
        <b-form-group class="form-text-label" label-for="desc">
          <label class="float-left" for="">Indicator definition</label>
          <b-form-textarea
            v-model="indicatorDefinition"
            id="desc"
            placeholder="Enter definition for indicator"
            rows="1"
            resize
            class="form-text"
          ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="col-sm col-md col-lg">
        <b-row class="mt-3">
          <div class="col-sm-9 col-md-9">
            <div class="form-group">
              <label class="text-13 float-left">Data Collection Method</label>
              <select
                v-bind:class="validations.collectionMethods ? 'is-invalid' : null"
                @change="onChangeDataCollection"
                v-model="collectionMethods"
                class="custom-select bg-white text-13"
              >
                <option selected :value="null" disabled>
                  Data collection method
                </option>
                <option v-for="(method, indKey) in getDatacollectionMethods()" class="text-capitalize" :key="indKey" :value="method">{{method}}</option>
              </select>
              <small v-if="validations.collectionMethods" class="text-danger">
                Data collection method must be selected.
              </small>
            </div>
          </div>
        </b-row>
        <b-row class="mt-3">
          <div class="col-sm-9 col-md-9">
            <div class="form-group">
              <label class="text-13 float-left">Frequency</label>
              <select
                v-bind:class="validations.frequency ? 'is-invalid' : null"
                @change="onChangeFrequency"
                v-model="frequency"
                class="custom-select bg-white text-13"
              >
                <option selected :value="null" disabled>Frequency</option>
                 <option v-for="(freq, indKey) in getReportingFrequency()" class="text-capitalize" :key="indKey" :value="freq">{{freq}}</option>
              </select>
              <small v-if="validations.frequency" class="text-danger">
                Data collection method must be selected.
              </small>
            </div>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm col-md">
            <div class="form-group">
              <b-container v-if="state.milestones">
                <a-skeleton
                  active
                  :paragraph="{ rows: 6 }"
                  :loading="state.milestones"
                ></a-skeleton>
              </b-container>
              <b-row v-else>
                <div class="col-sm col-md col-lg">
                  <div class="float-left">
                    <p class="text-left font-weight-bold">Targets</p>
                  </div>
                </div>
                <b-col cols="10" sm="12" md="12">
                      <b-row>
                        <b-col cols="3">Reporting Period</b-col>
                        <b-col cols="3">Due date</b-col>
                        <b-col cols="3">Value</b-col>
                        <b-col cols="2">Skip reporting</b-col>
                      </b-row>
                   </b-col>
                <b-col cols="10" sm="12" md="12">
                  <b-row v-for="(input, i) in inputs" :key="i" class="my-3">
                    <b-col>
                      <input
                        readonly
                        type="text"
                        v-model="input.title"
                        class="form-control"
                        disabled
                      />
                    </b-col>
                    <b-col>
                      <input
                        readonly
                        type="text"
                        v-model="input.Duedate"
                        class="form-control"
                        disabled
                      />
                    </b-col>
                    <b-col>
                      <input
                        @keyup="onKeyInputs"
                        type="number"
                        v-model="input.target"
                        class="form-control"
                        placeholder="Target"
                        required
                      />
                    </b-col>
                    <b-col>
                      <input
                        @keyup="onKeyInputs"
                        type="checkbox"
                        v-model="input.skip_report"
                        class="form-control"
                        required
                      />
                    </b-col>
                    <b-col cols="12" v-for="(aggregationsList, aggregationId) in input.aggregation" :key="aggregationId" class="my-1">
                      <b-row cols="12" v-for="(aggregationElement, aggregationElementKey) in aggregationsList" :key="aggregationElementKey">
                        <b-col></b-col>
                        <b-col>
                          <input
                            readonly
                            type="text"
                            :value="aggregationElement.Title"
                            class="form-control"
                            disabled
                          />
                          <hr/>
                        </b-col>
                        <b-col>
                          <input
                            @keyup="onKeyTargetInput(`${input.id}-${aggregationElement.id}`, aggregationId, input)"
                            type="number"
                            class="form-control"
                            placeholder="Value"
                            v-model="aggregationResponses[`${input.id}-${aggregationElement.id}`]"
                            required
                          />
                          <hr/>
                        </b-col>
                          <b-col></b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <small
                    v-if="validations.target"
                    class="text-danger float-left d-none"
                  >
                    Project target is required.
                  </small>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm">
            <b-form-group>
              <button
                @click.prevent="formMode == 'add' ? saveIndicator() : updateIndicator()"
                type="submit"
                class="btn btn-primary rounded-20 float-left btn-sm"
              >
                <b-container v-if="state.adding">
                  <b-spinner small></b-spinner>
                  <span class="text-13 ml-2">Saving...</span>
                </b-container>
                <span v-if="!state.adding" class="text text-13">
                  {{capitalizeFirstLetter(formMode)}} indicator
                </span>
              </button>
            </b-form-group>
          </div>
        </b-row>
        <div class="col-sm col-md col-lg">
          <div class="card ml-n4 card-2">
            <div class="card-body">
              <div class="table-responsive mt-2">
                <b-container class="my-5" v-if="state.loadingMI">
                  <b-spinner type="grow" small></b-spinner>
                  <span class="text-13 ml-2">Waiting for project indicators...</span>
                </b-container>
                <b-table
                  v-else
                  table-class="table table table-indicators rounded-20"
                  thead-tr-class="theader-indicators text-left indent-20"
                  tbody-class="tb-body text-left indent-20"
                  :items="mIndicators"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="fields"
                  show-empty
                >
                  <template v-slot:cell(No)="data">
                    <span>{{ data.index + 1 }}</span>
                  </template>
                  <template v-slot:cell(edit)="data">
                    <a-tooltip placement="bottom" title="Edit indicator">
                      <span @click="showEditModal(data.item)" class="cursor-pointer"> <button type="button" class="btn btn-light btn-sm rounded"><i class='bx bxs-edit text-info'></i></button></span>
                    </a-tooltip>
                  </template>
                  <template v-slot:cell(delete)="data">
                    <a-tooltip placement="bottom" title="Delete indicator">
                      <span @click="showDelete(data.item)" class="cursor-pointer"><button type="button" class="btn btn-light btn-sm rounded"><i class='bx bxs-trash-alt text-danger'></i></button></span>
                    </a-tooltip>
                  </template>
                </b-table>
              </div>
              <b-row class="my-4">
                <div class="col-sm-8 col-md-8"></div>
                <div class="col-sm col-md">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    prev-class="text-13"
                    next-class="text-13"
                    first-class="text-13"
                    last-class="text-13"
                  ></b-pagination>
                </div>
              </b-row>
            </div>
          </div>
        </div>
        <b-row class="mt-3">
          <div class="col-sm col-md">
            <b-form-group
              class="form-text-label"
              label-for="learning-questions"
            >
              <b-form-textarea
                id="learning-questions"
                placeholder="Learning questions"
                rows="1"
                resize
                class="form-text"
                @keyup="onChangeLQuestions"
                v-model="lquestions"
                v-bind:class="validations.lquestions ? 'is-invalid' : null"
              ></b-form-textarea>
              <small
                v-if="validations.lquestions"
                class="text-danger float-left"
              >
                Enter learning questions.
              </small>
            </b-form-group>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm col-md">
            <b-form-group
              class="form-text-label"
              label-for="contribution-strategy"
            >
              <b-form-textarea
                @keyup="onChangeAcstrategies"
                v-model="acstrategies"
                v-bind:class="validations.acstrategies ? 'is-invalid' : null"
                id="contribution-strategy"
                placeholder="Attribution  and Contribution Strategy"
                rows="1"
                resize
                class="form-text"
              ></b-form-textarea>
              <small
                v-if="validations.acstrategies"
                class="text-danger float-left"
              >
                Enter Attribution and Contribution Strategy.
              </small>
            </b-form-group>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm col-md">
            <b-form-group class="form-text-label" label-for="data-collection">
              <b-form-textarea
                @keyup="onChangeCollectionLimit"
                v-model="collectionLimit"
                v-bind:class="validations.collectionLimit ? 'is-invalid' : null"
                id="data-collection"
                placeholder="Data collection limitation"
                rows="1"
                resize
                class="form-text"
              ></b-form-textarea>
              <small
                v-if="validations.collectionLimit"
                class="text-danger float-left"
              >
                Enter Data collection limitation.
              </small>
            </b-form-group>
          </div>
        </b-row>
      </div>
    </b-row>
  </form>
  <!-- Delete indicator -->
  <a-modal v-model="visibleDelete" :footer="null" title="Delete project indicator">
    <b-row>
      <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
        Are you sure you want to delete this information ?
      </h6>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <div class="inline-block pr-4">
        <a-popconfirm
          title="Are you sure delete this indicator ?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteProjectIndicator(data)"
          @cancel="cancel"
        >
          <button
            type="button"
            class="btn btn-danger btn-block btn-confirm-delete"
          >
            <b-container v-if="state.deleting">
              <b-spinner small></b-spinner>
              <span class="text-13 ml-2">Deleting...</span>
            </b-container>
            <span v-if="!state.deleting" class="text text-13">DELETE</span>
          </button>
        </a-popconfirm>
      </div>
      <div class="inline-block">
        <button
          @click="visibleDelete=false"
          type="submit"
          class="btn btn-secondary btn-block btn-reject-delete"
        >
          CANCEL
        </button>
      </div>
    </b-row>
  </a-modal>
  <b-modal v-model="showEdit" size="xl" title="Update project indicator" hide-footer>
    <updateIndicator :indicator="editProject" :project="projectID"/>
  </b-modal>
</div>
</template>

<script>
import decode from "jwt-decode";
import moment from "moment";
import updateIndicator from '../../../update/update-measurement-plan.vue';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  props: ["store"],
  components: {
    updateIndicator,
    vSelect,
  },
  data() {
    return {
      formMode: 'add',
      alignment: null,
      title: null,
      rclevel: null,
      toclevel: null,
      rcelement: null,
      alignedIndicator: null,
      alignedIndicatorDisaggregations: null,
      alignedPillarIndicator: null,
      indicatorAggregation: [],
      aggregationResponses: {},
      baselineDisaggregation: {},
      baseline: null,
      reporters: [],
      pillars: [],
      alignedPillar: null,
      auxiliaryPillarAlignment: false,
      pillarIndicators: [],
      indicatorDefinition: null,
      collectionMethods: null,
      frequency: null,
      program: null,
      lquestions: null,
      acstrategies: null,
      collectionLimit: null,
      validations: {
        alignment: false,
        title: false,
        rclevel: false,
        alignedIndicator: false,
        indicatorAggregation: false,
        baseline: false,
        reporters: false,
        indicatorDefinition: false,
        collectionMethods: false,
        frequency: false,
        toclevel: false,
        program: false,
        lquestions: false,
        acstrategies: false,
        collectionLimit: false,
      },
      isSaved: false,
      state: {
        loadUsers: false,
        loadPillars: false,
        loadPillarIndicators: false,
        loading: false,
        loadTOClevels: false,
        loadRClevels: false,
        loadRCelements:false,
        loadIndicator: false,
        loadingLogFrame: false,
        loadingMI: false,
        adding: false,
        milestones: false,
        deleting:false,
      },
      editIndicatorData: {},
      potentialReporters: [],
      programs: [],
      toclevels: [],
      rclevels: [],
      rcelements: [],
      tocelements: [],
      indicators: null,
      logframes: [],
      mIndicators: [],
      perPage: 4,
      currentPage: 1,
      filter: "",
      fields: ["No", "Title", "resultchain","edit","delete"],
      inputs: [],
      inputValues: [],
      milestones: [],
      aggregations: [],
      options : [],
      value : ['none'],
      selectedDisaggregations : [],
      selectedItem: [],
      showEdit: false,
      editProject: null,
      data: null,
      visibleDelete: false
    };
  },
  computed: {
    availableOptions() {
      return this.options.filter((opt) => this.value.indexOf(opt) === -1);
    },
    token() {
      return localStorage.getItem("token");
    },
    proj() {
      const results = this.$store.state.project_data;
      return results ? results : null;
    },
    project() {
      const data = sessionStorage.getItem("project");
      var decoded = null;
      if (data) {
        decoded = decode(data);
      }
      return this.proj ? this.proj : decoded;
    },
    drafted() {
      var decoded = null;
      const storeData = sessionStorage.getItem("drafted-project");
      if (storeData) {
        decoded = decode(storeData);
      }
      return decoded;
    },
    projectID() {
      return this.drafted
        ? this.drafted
        : this.project
        ? this.project.PojectData
        : null;
    },
    rows() {
      return this.mIndicators.length;
    },
    programData() {
      return this.current_program ? this.current_program : null;
    },
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    current_program() {
      return this.$store.state.current_program;
    },
  },
  watch: {
    alignment() {
      if (this.alignment == "project") {
        this.getRCLevels();
      } else {
        this.getTOCLevels();
      }
    },
    program() {
      this.getTOCLevels();
    },
    programData() {
      // Load disaggregations when program changes and there not in array
      if(!this.indicators){
        this.getIndicatorTypes();
      }

      if(!this.pillars || this.pillars.length < 1){
        this.fetchPillars()
      }
    },
    alignedPillar(){
      // When pillar changes, fetch its indicators
      this.getPillarIndicators()
    },
    toclevel(){
      this.getProgramIndicators();
    },
    rclevel(){
      this.getRcElements(this.rclevel)
    },
    alignedIndicator(){
      // fetch indicator data
      this.fetchIndicatorDisaggregations()
    },
    inputs() {
      this.inputValues = [];
      this.inputs.forEach((item) => {
        this.inputValues.push({ MilestoneId: item.id, target: item.target });
      });
    },
    selectedDisaggregations(){
      console.log(this.selectedDisaggregations)

      // reset aggregations
      this.inputs = this.inputs.map((input) => {
        return {
          ...input,
          aggregation: {}
        }
      })

      this.selectedDisaggregations.forEach((disaggregation) => {
        let disaggregationElements = disaggregation.elements??[]

        let aggregatorSubsTemp = [];
        disaggregationElements.forEach((item, key) => {
          // let temp = {
          //   id: item.id,
          //   Title: item.Title,
          //   targetValue: null
          // };
          // this.inputAgg.push(temp);
          aggregatorSubsTemp[key]=  {
            ...item,
            // targetValue: null
          }
        });

        this.aggregatorSubs = aggregatorSubsTemp;
           
        this.inputs.forEach((_, index) => {
          let aggregationId = disaggregation.id
          if(!this.inputs[index].aggregation){
            this.inputs[index].aggregation[aggregationId] = {}
          }
          this.inputs[index].aggregation[aggregationId] = [...this.aggregatorSubs]
        });
      })


    },
    value(){
      if(this.value[0]=='none') {
        this.value.shift()
      }else if(this.value.length < 1){
        this.value.push('none')
      }else{
        this.value.forEach((item) => {
          this.selectedItem = item;
        });
        
        this.indicators.forEach((i2) => {
          if (i2.level === this.selectedItem) {
            this.aggregations.push({ Aggragation: i2.id });
          }
        });
      }
    }
  },
  beforeMount() {
    this.$emit("loading", false);
    this.getPotentialReporters();
    this.getMeasurementIndicators();
    this.getMilestones();
  },
  mounted(){
    this.$title("Measurement plan setup");

    if(this.programData){
      this.getIndicatorTypes()
      this.fetchPillars()
    }
  },
  methods: {
    showEditModal(data){
      this.formMode = 'update'
      console.log(data)
      this.title = data.Title,
      this.editIndicatorData.id = data.id

      if(!data.id){
        this.$notification["error"]({
          message: "WARNING!",
          description: `Wait 3 seconds`,
          duration: 3,
        });
        return false
      }
      this.state.loading = true;
      this.axios
        .get(`project/indicator_details/${data.id}`)
        .then((response) => {
          this.state.loading = false;
          let editIndicatorData = response.data
          this.title = editIndicatorData.Title
          this.baseline = editIndicatorData.baseline
          this.alignment = editIndicatorData.AlignedTo
          this.indicatorDefinition = editIndicatorData.indicatorDefinition
          this.collectionMethods = editIndicatorData.dataCollection
          this.frequency = editIndicatorData.frequency

          this.rclevel = editIndicatorData.rc_level
          this.rcelement = editIndicatorData.rc_element
          this.toclevel = editIndicatorData.toc_level
          this.alignedIndicator = editIndicatorData.AlignedIndicator
          this.reporters = editIndicatorData.reporters
          this.selectedDisaggregations = editIndicatorData.disaggregations

          //populate the data
          let existingMilestoneData = editIndicatorData.milestones??[]
          this.inputs.forEach((input, key) => {
            let milestoneId = input.id
            let existingValue = existingMilestoneData.find(milestone => milestone.MilestoneId == milestoneId)
            console.log('found', milestoneId, existingValue)
            this.inputs[key].target = existingValue.target
          })

          // Populate the disaggregations
          existingMilestoneData.forEach(milestone => {
            let disaggregations = milestone.disaggregations??[]
            disaggregations.forEach(disaggregation => {
              this.aggregationResponses[`${milestone.MilestoneId}-${disaggregation.disaggregation_element}`] = disaggregation.target
            })
          })

          // Populate baseline disaggregations
          editIndicatorData.baselineDisaggregations.forEach((baselineDisag) => {
            this.baselineDisaggregation[baselineDisag.disaggregation_element_id] = baselineDisag.baseline
          })
          

          console.log(response.data)
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.state.loading = true;
        });
      // this.editProject=data;
      // this.showEdit=true;
    },
    // milestones
    getMilestones() {
      this.state.milestones = true;
      this.state.loading = true;
      this.axios
        .get(`getProjectMilestone/${this.projectID.id}`)
        .then((response) => {
          this.state.loading = false;
          this.state.milestones = false;
          this.milestones = response.data;
          this.inputs = [];
          this.milestones.forEach((item) => { 
            this.inputs.push({
              id: item.id,
              title: item.Title,
              Duedate: moment(item.Duedate).format("DD/MM/YYYY"),
              target: null,
              aggregation: {},
            });
          });
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 3,
          });
          this.state.milestones = false;
          this.state.loading = true;
        });
    },
    async fetchIndicatorDisaggregations() {
      this.state.loading = true;
      await this.axios
        .get(`program/indicator_details/${this.alignedIndicator}`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.alignedIndicatorDisaggregations = response.data?.disaggregation;
            this.selectedDisaggregations = this.alignedIndicatorDisaggregations
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    async fetchPillars() {
      console.log("fetching pillars")
      this.state.pillars = true;
      await this.axios
        .get(`getPillarsInProgram/${this.programData.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loadPillars = false;
            this.pillars = response.data;
            // console.log("Pillars", this.pillars)
          } else {
            this.state.loadPillars = false;
          }
        })
        .catch(() => {
          this.state.loadPillars = false;
        });
    },
    async getPillarIndicators() {
      this.state.loadPillarIndicators = true;
      await this.axios
        .get(`pillar/${this.alignedPillar}/indicators`)
        .then((response) => {
          if (response.data) {
            this.state.loadPillarIndicators = false;
            this.pillarIndicators = response.data;
          } else {
            this.state.loadPillarIndicators = false;
          }
        })
        .catch(() => {
          this.state.loadingMI = false;
          this.$emit("loading", false);
        });
    },
    onKeyInputs(){
      this.inputValues = [];
      this.inputs.forEach((item) => {
        this.inputValues.push({ MilestoneId: item.id, target: item.target });
      });
    },
    onBaselineDisaggregationChange(currentDisaggregation){
      // Validate the baseline disaggregation
      console.log("changed", this.baselineDisaggregation, currentDisaggregation)

      // Find the current disaggregation and elements
      let disaggregation = this.selectedDisaggregations.find(disag => disag.id == currentDisaggregation.disaggregation )

      if(disaggregation && disaggregation.elements){
        // Find the total
        let elements = disaggregation.elements
        var total = 0;
        elements.forEach(element => {
          console.log("vlue", this.baselineDisaggregation[element.id]?? 0)
          total += (parseFloat(this.baselineDisaggregation[element.id] ?? 0) )
        })

        if(total > this.baseline){
          this.$notification["error"]({
            message: "ERROR!",
            description: `The total of baseline disaggregations exceeds total baseline`,
            duration: 10,
          });
        }
      }
    },
    onKeyTargetInput(milestoneAggElemId, aggregationId, aggInput){

      // Validate total of aggregation categories not to exceed target
      let target = aggInput.target;

      let aggregationItems = aggInput.aggregation[aggregationId]??[]

      console.log("items", aggregationItems)
      // get total of available aggregation elemnts in this agg
      var total = aggregationItems.reduce((prevValue, aggItem) => {
        let currentValue = this.aggregationResponses[`${aggInput.id}-${aggItem.id}`]
        console.log("inside", currentValue, aggInput.id, aggItem.id)
        if(currentValue){
           return prevValue + parseInt(currentValue)
        }else{
          return prevValue
        }
      }, 0);

      if(total > target){
        this.$notification["error"]({
          message: "ERROR!",
          description: `The total of disaggregations exceeds target`,
          duration: 10,
        });
      }
      return false
    },
    showDelete(data){
      this.data=data;
      this.visibleDelete=true;
    },
    async deleteProjectIndicator(param) {

      this.state.deleting = true;
      await this.axios
        .delete(`deleteIndicatorProject/${param.id}`)
        .then((response) => {
          if (response) {
            this.state.deleting = false;
            this.visibleDelete = false;
            this.getMeasurementIndicators();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Indicator  deleted successfully.`,
              duration: 5,
            });
          } else {
            this.state.deleting = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.deleting = false;
        });
    },
    async getPrograms() {
      this.state.program = true;
      await this.axios
        .get(`getPrograms`)
        .then((response) => {
          if (response.data) {
            this.state.program = false;
            this.programs = response.data;
          } else {
            this.state.program = false;
          }
        })
        .catch(() => {
          this.state.program = false;
        });
    },
    async getMeasurementIndicators() {
      this.state.loadingMI = true;
      await this.axios
        .get(`getProjectIndicators/${this.projectID.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loadingMI = false;
            this.mIndicators = response.data;
          } else {
            this.state.loadingMI = false;
          }
        })
        .catch(() => {
          this.state.loadingMI = false;
        });
    },
    async getProgramIndicators() {
      this.state.loadingLogFrame = true;
      await this.axios
        .get(`getIndicatorByElement/${this.toclevel}`)
        .then((response) => {
          if (response.data) {
            this.state.loadingLogFrame = false;
            this.logframes = response.data;
          } else {
            this.state.loadingLogFrame = false;
          }
        })
        .catch(() => {
          this.state.loadingLogFrame = false;
        });
    },
    async getIndicatorDetails() {
      this.state.loadingLogFrame = true;
      await this.axios
        .get(`getIndicatorByElement/${this.toclevel}`)
        .then((response) => {
          if (response.data) {
            this.state.loadingLogFrame = false;
            this.logframes = response.data;
          } else {
            this.state.loadingLogFrame = false;
          }
        })
        .catch(() => {
          this.state.loadingLogFrame = false;
        });
    },
    async getIndicatorTypes() {
      this.state.loadIndicator = true;
      await this.axios
        .get(`getAggragator/${this.programData.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loadIndicator = false;
            this.indicators = response.data;
            this.options=[];
            this.indicators.map((item) => {
              this.options.push(`${item.name}`);
            })
            console.log("Indicators 1", this.indicators) 
            console.log("Indicator names only", this.options)
          } else {
            this.state.loadIndicator = false;
          }
        })
        .catch(() => {
          this.state.loadIndicator = false;
        });
    },
    async getPotentialReporters() {
      this.state.loadUsers = true;
      await this.axios
        .get(`project/${this.projectID.id}/potential_reporters`)
        .then((response) => {
          if (response.data) {
            this.state.loadUsers = false;
            this.potentialReporters = response.data;
          } else {
            this.state.loadUsers = false;
          }
        })
        .catch(() => {
          this.state.loadUsers = false;
        });
    },
    async getTOCLevels() {
      this.state.loadTOClevels = true;
      await this.axios
        .get(`getTocLevels/${this.programData.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loadTOClevels = false;
            this.toclevels = response.data;
          } else {
            this.state.loadTOClevels = false;
          }
        })
        .catch(() => {
          this.state.loadTOClevels = false;
        });
    },
    async getRCLevels() {
      this.state.loadRClevels = true;
      await this.axios
        .get(`getAllProjectResultchains/${this.projectID.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loadRClevels = false;
            this.rclevels = response.data;
          } else {
            this.state.loadRClevels = false;
          }
        })
        .catch(() => {
          this.state.loadRClevels = false;
        });
    },

    async getRcElements() {
      if(!this.rclevel){
        console.log("RC Level not ready")
        return false
      }
      this.state.loadRCelements = true;
      await this.axios
        .get(`allResultchainLevels/${this.rclevel}`)
        .then((response) => {
          if (response.data) {
            this.state.loadRCelements = false;
            this.rcelements = response.data;
          } else {
            this.state.loadRCelements = false;
          }
        })
        .catch(() => {
          this.state.loadRCelements = false;
        });
    },

    onChangeLQuestions() {
      if (!this.lquestions) {
        this.validations.lquestions = true;
      } else {
        this.validations.lquestions = false;
      }
    },
    onChangeAcstrategies() {
      if (!this.acstrategies) {
        this.validations.acstrategies = true;
      } else {
        this.validations.acstrategies = false;
      }
    },
    onChangeCollectionLimit() {
      if (!this.collectionLimit) {
        this.validations.collectionLimit = true;
      } else {
        this.validations.collectionLimit = false;
      }
    },
    onChangeAlignment() {
      if (!this.alignment) {
        this.validations.alignment = true;
      } else {
        this.validations.alignment = false;
      }
    },
    onChangeProgram() {
      if (!this.program) {
        this.validations.program = true;
      } else {
        this.validations.program = false;
      }
    },
    onChangeLogFrame() {
      // Retrieve the indicator details(mostly disagregation)
      if (!this.alignedIndicator && !this.alignedPillarIndicator) {
        this.validations.alignedIndicator = true;
      } else {
        this.validations.alignedIndicator = false;
      }
    },
    onchangeAggregation() {
      if (!this.indicatorAggregation) {
        this.validations.indicatorAggregation = true;
      } else {
        this.validations.indicatorAggregation = false;
      }
    },
    onChangeTitle() {
      if (!this.title) {
        this.validations.title = true;
      } else {
        this.validations.title = false;
      }
    },
    onChangeReporter() {
      if (this.reporters != null || this.reporters != "") {
        this.validations.reporters = false;
      } else {
        this.validations.reporters = true;
      }
    },
    onChangeToc() {
      if (!this.toclevel) {
        this.validations.toclevel = true;
      } else {
        this.validations.toclevel = false;
      }
    },
    onChangeRc() {
      if (!this.rclevel) {
        this.validations.rclevel = true;
      } else {
        this.validations.rclevel = false;
      }
    },
    onChangeRcElement() {
      if (!this.rcelement) {
        this.validations.rcelement = true;
      } else {
        this.validations.rcelement = false;
      }
    },
    onChangeDataCollection() {
      if (!this.collectionMethods) {
        this.validations.collectionMethods = true;
      } else {
        this.validations.collectionMethods = false;
      }
    },
    onChangeFrequency() {
      if (!this.frequency) {
        this.validations.frequency = true;
      } else {
        this.validations.frequency = false;
      }
    },
    saveIndicator() {
      if (!this.alignment) {
        this.$emit("error", "Indicator alignment is required.");
        this.validations.alignment = true;
        this.$message.error("Indicator alignment is required.");
      } else if (!this.title) {
        this.$emit("error", "Title is required.");
        this.validations.title = true;
        this.$message.error("project title is required.");
      }else if (!this.toclevel && this.alignment=='program') {
        this.$emit("error", "Theory of change level is required.");
        this.validations.toclevel = true;
        this.$message.error("Theory of change level is required.");
      } else if (!this.rclevel && this.alignment=='project') {
        this.$emit("error", "Result chain level is required.");
        this.validations.rclevel = true;
        this.$message.error("Result chain level is required.");
      } else if ((!this.alignedIndicator && this.alignment == "program") || (!this.alignedPillarIndicator && this.alignment == "pillar")) {
        this.$emit("error", "Aligned indicator is required.");
        this.validations.alignedIndicator = true;
        this.$message.error("Aligned indicator is required.");  
      }else if (this.value.length <= 0) {
        this.$emit("error", "Indicator aggregation is required.");
        this.validations.indicatorAggregation = true;
        this.$message.error("Indicator aggregation is required.");
      }
       else if (!this.baseline) {
        this.$emit("error", "Baseline is required.");
        this.validations.baseline = true;
        this.$message.error("Baseline is required.");
      } else if (!this.reporters) {
        this.$emit("error", "Reporting responsiblity is required.");
        this.validations.reporters = true;
        this.$message.error("Reporting responsiblity is required.");
      } else if (!this.indicatorDefinition) {
        this.$emit("error", "Indicator definition is required.");
        this.validations.indicatorDefinition = true;
        this.$message.error("Indicator definition is required.");
      } else if (!this.collectionMethods) {
        this.$emit("error", "Data collection method is required.");
        this.validations.collectionMethods = true;
        this.$message.error("Data collection method is required.");
      } else if (!this.frequency) {
        this.$emit("error", "Frequency is required.");
        this.validations.frequency = true;
        this.$message.error("Frequency is required.");
      } else {
        /*if if (!this.lquestions) {
        this.$emit("error", "Learning questions is required.");
        this.validations.lquestions = true;
        this.$message.error("Learning questions is required.");
      } else if (!this.acstrategies) {
        this.$emit(
          "error",
          "Atribution and contribution strategy is required."
        );
        this.validations.acstrategies = true;
        this.$message.error(
          "Attribution and contribution strategy is required."
        );
      } else if (!this.collectionLimit) {
        this.$emit("error", "Please enter data collection limitation.");
        this.validations.collectionLimit = true;
        this.$message.error("Please enter data collection limitation.");
        
      } else {*/
        let milestoneData = this.inputValues;

        let objKeys = Object.keys(this.aggregationResponses)
        let aggregationFiltered = []
        objKeys.forEach(key => {
          // console.log(key)
          let split = key.split("-")
          let milestoneId = split[0]
          if(!aggregationFiltered[milestoneId]) aggregationFiltered[milestoneId] = []
          aggregationFiltered[milestoneId][split[1]] = this.aggregationResponses[key]
        })

        // Add aggregation data into milestone
        milestoneData.forEach((milestone, key) => {
          if(!milestoneData[key].aggregation) milestoneData[key].aggregation = []
          milestoneData[key].aggregation = aggregationFiltered[milestone.MilestoneId]??[].map((elem, key) =>{
            return {
              AggregatorTarget: elem,
              AggregatorId: key,
            }
          })
          
        })
        const requestData = {
          Title: this.title,
          RCTOCid: this.toclevel ? this.toclevel : this.rclevel ? this.rclevel : 0,
          rc_element: this.rcelement,
          IndicatorAggragation: this.value[0]=='none' ? "none" : "",
          disaggregations: this.selectedDisaggregations??this.alignedIndicatorDisaggregations,
          AlignedTo: this.alignment,
          auxiliaryPillarAlignment: this.auxiliaryPillarAlignment,
          AlignedIndicator: this.alignment=='project' ? 0 : this.alignedIndicator,
          alignedPillarIndicator: this.alignedPillarIndicator,
          baseline: this.baseline,
          baselineDisaggregation: this.baselineDisaggregation,
          indicatorDefinition: this.indicatorDefinition,
          dataCollection: this.collectionMethods,
          frequency: this.frequency,
          projectId: this.projectID.id,
          userId: this.reporters[0].id,
          reporting_responsibility: this.reporters.map(reporter => reporter.id),
          Lquestions: this.lquestions,
          ACstrategy: this.acstrategies,
          dataCollectionLimit: this.collectionLimit,
          Milestones: milestoneData
        };
        console.log(requestData, this.alignment)
        if (
          this.inputValues.filter(
            (item) => item.target === null || item.target === ""
          ).length > 0
        ) {
          this.$message.error(
            "Some targets is missing please fill all form fields corretly.",
            5
          );
       } else if(!this.milestones.length){
          this.$message.error("You must configure project milestones.", 5);
        } else {
          this.state.adding = true;
          this.axios
            .post("project/add_indicator", requestData)
            .then((response) => {
              if (response.data) {
                this.state.adding = false;
                this.getMeasurementIndicators();
                this.$notification["success"]({
                  message: "SUCCESS!",
                  description: `Project indicator saved.`,
                  duration: 5,
                });
                this.resetIndicatorData()
                setInterval(() => {
                  location = location.origin + location.pathname + "?step=2"
                }, 1)
              } else {
                this.state.adding = false;
                this.$message.error(`An error occured while saving project indicator`, 4);
              }
            })
            .catch((error) => {
              this.$notification["error"]({
                message: "ERROR!",
                description: `${error.errorMessage}`,
                duration: 7,
              });
              this.state.adding = false;
            });
        }
      }
    },
    updateIndicator() {
      if (!this.alignment) {
        this.$emit("error", "Indicator alignment is required.");
        this.validations.alignment = true;
        this.$message.error("Indicator alignment is required.");
      } else if (!this.title) {
        this.$emit("error", "Title is required.");
        this.validations.title = true;
        this.$message.error("project title is required.");
      }else if (!this.toclevel && this.alignment=='program') {
        this.$emit("error", "Theory of change level is required.");
        this.validations.toclevel = true;
        this.$message.error("Theory of change level is required.");
      } else if (!this.rclevel && this.alignment=='project') {
        this.$emit("error", "Result chain level is required.");
        this.validations.rclevel = true;
        this.$message.error("Result chain level is required.");
      } else if (!this.alignedIndicator && this.alignment == "program") {
        this.$emit("error", "Aligned indicator is required.");
        this.validations.alignedIndicator = true;
        this.$message.error("Aligned indicator is required.");  
      }else if (this.value.length <= 0) {
        this.$emit("error", "Indicator aggregation is required.");
        this.validations.indicatorAggregation = true;
        this.$message.error("Indicator aggregation is required.");
      }
       else if (!this.baseline) {
        this.$emit("error", "Baseline is required.");
        this.validations.baseline = true;
        this.$message.error("Baseline is required.");
      } else if (!this.reporters) {
        this.$emit("error", "Reporting responsiblity is required.");
        this.validations.reporters = true;
        this.$message.error("Reporting responsiblity is required.");
      } else if (!this.indicatorDefinition) {
        this.$emit("error", "Indicator definition is required.");
        this.validations.indicatorDefinition = true;
        this.$message.error("Indicator definition is required.");
      } else if (!this.collectionMethods) {
        this.$emit("error", "Data collection method is required.");
        this.validations.collectionMethods = true;
        this.$message.error("Data collection method is required.");
      } else if (!this.frequency) {
        this.$emit("error", "Frequency is required.");
        this.validations.frequency = true;
        this.$message.error("Frequency is required.");
      } else {
        let milestoneData = this.inputValues;

        let objKeys = Object.keys(this.aggregationResponses)
        let aggregationFiltered = []
        objKeys.forEach(key => {
          // console.log(key)
          let split = key.split("-")
          let milestoneId = split[0]
          if(!aggregationFiltered[milestoneId]) aggregationFiltered[milestoneId] = []
          aggregationFiltered[milestoneId][split[1]] = this.aggregationResponses[key]
        })

        // Add aggregation data into milestone
        milestoneData.forEach((milestone, key) => {
          if(!milestoneData[key].aggregation) milestoneData[key].aggregation = []
          milestoneData[key].aggregation = aggregationFiltered[milestone.MilestoneId]??[].map((elem, key) =>{
            return {
              AggregatorTarget: elem,
              AggregatorId: key,
            }
          })
        })

        const requestData = {
          AlignedTo: this.alignment,
          Title: this.title,
          RCTOCid: this.toclevel ? this.toclevel : this.rclevel ? this.rclevel : 0,
          rc_element: this.rcelement,
          disaggregations: this.aggregations,
          AlignedIndicator: this.alignedIndicator,
          alignedPillarIndicator: this.alignment=='pillar' ? null : this.alignedPillarIndicator,
          auxiliaryPillarAlignment: this.auxiliaryPillarAlignment,
          baseline: this.baseline,
          baselineDisaggregation: this.baselineDisaggregation,
          indicatorDefinition: this.indicatorDefinition,
          dataCollection: this.collectionMethods,
          frequency: this.frequency,
          projectId: this.projectID.id,
          reporting_responsibility: this.reporters.map(reporter => reporter.id),
          Lquestions: this.lquestions,
          ACstrategy: this.acstrategies,
          dataCollectionLimit: this.collectionLimit,
          Milestones: milestoneData
        };
        if (
          this.inputValues.filter(
            (item) => item.target === null || item.target === ""
          ).length > 0
        ) {
          this.$message.error(
            "Some targets is missing please fill all form fields corretly.", 5
          );
       } else if(!this.milestones.length){
          this.$message.error("You must configure project milestones.", 5);
        
        } else {
          this.state.adding = true;
          this.axios
            .put(`project/update_indicator/${this.editIndicatorData.id}`, requestData)
            .then((response) => {
              if (response.data) {
                this.state.adding = false;
                this.getMeasurementIndicators();
                this.$notification["success"]({
                  message: "SUCCESS!",
                  description: `Project indicator updated.`,
                  duration: 5,
                });
                this.resetIndicatorData()
                setInterval(() => {
                  // location = location.origin + location.pathname + "?step=2"
                }, 1)
              } else {
                this.state.adding = false;
                this.$message.error(`An error occured while updating project indicator`, 4);
              }
            })
            .catch((error) => {
              this.$notification["error"]({
                message: "ERROR!",
                description: `${error.errorMessage}`,
                duration: 7,
              });
              this.state.adding = false;
            });
        }
      }
    },
    resetIndicatorData(){
      this.alignment=null,
      this.title=null;
      this.toclevel=null;
      this.rclevel=null
      this.aggregations=[]
      this.alignment=null;
      this.baseline = null;
      this.indicatorDefinition=null;
      this.collectionMethods=null
      this.frequency=null
      this.reporters=[]
      this.lquestions=null
      this.acstrategies=null
      this.collectionLimit=null
    },
    nextStep() {
      if (!this.mIndicators.length) {
        this.$emit("error", "Error");
        this.$message.error("Please add an indicator.");
      } else {
        this.$emit("can-continue");
      }
    },
    cancel(e) {
      console.log(e);
      this.visibleDelete = false;
      this.$message.error("Deleting indicator has been canceled.");
    },
    refresh() {
      this.state.refresh = true;
      this.getProgramIndicators();
      this.getMilestones();
      this.getRCLevels();
      this.getTOCLevels();
      this.getIndicatorTypes();
      this.getMeasurementIndicators();
    },
  },
};
</script>

<style scoped>
.main-wrapper .form-group label,
.main-wrapper .form-group input::placeholder,
.form-text,
.form-text::placeholder,
.form-text-label,
.form-control,
.custom-select {
  font-size: 13px !important;
}

.main-wrapper .form-group label {
  text-align: left !important;
}

.table-indicators th {
  font-size: 13px !important;
  border-top: none !important;
}

.table-indicators td {
  font-size: 13px !important;
}

.card-2 {
  border: none !important;
  border-radius: 20px !important;
}
</style>
<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
