<template>
  <form>
        <b-row>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <input
                placeholder="Box title"
                type="text"
                class="form-control form-input-modal"
                v-model="element.name"
                required
              />
            </div>
            <div class="form-group">
              <b-input-group>
                <b-select
                  class="
                    custom-select
                    select-modal
                    rounded-20
                    text-13
                    bg-gray-white
                  "
                  v-model="toc"
                  required
                  :disabled="state.loadUsers"
                >
                  <template #first>
                    <b-select-option :value="null" selected disabled>
                      {{
                        keywords["theory of change"]
                          ? `Select ${keywords["theory of change"]}`
                          : "Select theory of change"
                      }}</b-select-option
                    >
                  </template>
                  <b-select-option
                    v-for="item in levels"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.level }}
                  </b-select-option>
                </b-select>
                <b-input-group-append>
                  <b-icon-arrow-repeat
                    class="bi-con-right"
                    font-scale="1.5"
                    :animation="state.loading ? 'spin' : ''"
                    @click="getProgramLevels"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="form-group">
              <b-form-group class="form-text-label" label-for="desc">
                <b-form-textarea
                  id="desc"
                  placeholder="Description"
                  rows="3"
                  resize
                  class="form-text-modal"
                  required
                  v-model="element.description"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <div class="inline-block pr-4">
            <button
              type="submit"
              class="btn text-13 btn-primary rounded-20 btn-block btn-confirm"
            >
              <b-container v-if="state.loadElem">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Saving...</span>
              </b-container>
              <span v-if="!state.loadElem" class="text text-13">OK</span>
            </button>
          </div>
          <div class="inline-block">
            <a
              @click="$emit('dismiss')"
              type="button"
              class="btn btn-secondary btn-block text-13 btn-cancel"
              >CANCEL</a>
          </div>
        </b-row>
      </form>
</template>

<script>
import decode from "jwt-decode";
export default {
  props: ["store","elementdata"],
  data() {
    return {
      visibleCreateKey: false,
      visibleTOCContributionModal: false,
      visibleChain: false,
      visibleContribute: false,
      color: "",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      visiblePicker: false,
      levels: [],
      state: {
        loading: false,
        loadLevel: false,
        loadElem: false,
        contribute: false,
        refresh: false,
        loadCont: false
      },
      contLevel:null,
      level: "",
      abbr: "",
      description: "",
      levelItem: null,
      element: {
        name: "",
        description: "",
      },
      elements: [],
      toc: null,
      colors: {
        hex: "",
      },
      levelNumber: null,
      colorValue: "",
      displayPicker: false,
      data: null,
      currentPage:1,
      perPage: 5,
      filter:"",
      plevels: null,
      programTempArray: []
    };
  },
  computed: {
    token(){
      return localStorage.getItem("token");
    },
    levelsNumber() {
      return this.levels.length ? this.levels.length : 0;
    },
    // drafted() {
    //   const data = sessionStorage.getItem("draft");
    //   var decoded = null;
    //   if (data) {
    //     decoded = decode(data);
    //   }
    //   return decoded;
    // },
    currentp(){
      return this.$store.state.current_program;
    },
    storagep() {
      let project = sessionStorage.getItem("prg");
      const resData = decode(project);
      return resData;
    },
    program(){
        return this.currentp ? this.currentp : this.storagep ? this.storagep : null;
    },
    keywords(){
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    rows(){
      return this.allelements ? this.allelements : 0;
    },
    user(){
      return this.$store.state.user_data.user;
    },
  },
  beforeMount() {
    this.getProgramLevels()
    if(this.program) {
      for (let i = 1; i <= parseInt(this.program.levels); i++) {
        this.programTempArray.push(i)
      }
    }
    console.log("Element", this.elementdata)
  },
  mounted() {
    this.setColor(this.color || "")
  },
  watch: {
    colorValue() {
      this.colors.hex = this.colorValue.hex;
    },
  },
  methods: {
   async addElement() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        element: this.element.name,
        Description: this.element.description,
        TOCid: this.toc,
      };
      this.state.loadElem = true;
      await this.axios
        .post("AddTOCelement", requestData, config)
        .then((response) => {
          if (response.data) {
            this.state.loadElem = false;
            this.visibleCreateKey = false;
            this.getProgramElement(this.levelItem);
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Level element added successfully.`,
              duration: 5,
            });
          } else {
            this.$message.error(`Something wrong when adding level.`, 4);
            this.state.loadElem = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loadElem = false;
        });
    },
    
    removeSpace(title) {
      return title.replace(/\s/g, "-");
    },
    async getProgramLevels() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get(
          `getTocLevels/${this.program.id}`,
          config
        )
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.levels = response.data;
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    createKeyModal() {
      this.visibleCreateKey = true;
    },
    createChainModal() {
      this.visibleChain = true;
    },
    cancelKeyModal() {
      this.visibleCreateKey = false;
    },
    createTOCContributionModal() {
      this.visibleTOCContributionModal = true;
    },

    cancelChainModal() {
      this.visibleChain = false;
    },
    openPicker() {
      this.visiblePicker = !this.visiblePicker;
    },
    changeColor(color) {
      const {
        rgba: { r, g, b, a },
      } = color;
      this.color = `rgba(${(r, g, b, a)})`;
    },
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if (color.slice(0, 1) == "#") {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == "rgba") {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(","),
          hex =
            "#" +
            (
              (1 << 24) +
              (parseInt(rgba[0]) << 16) +
              (parseInt(rgba[1]) << 8) +
              parseInt(rgba[2])
            )
              .toString(16)
              .slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener("click", this.documentClick);
      this.displayPicker = true;
    },
    showContribute(params) {
      this.visibleContribute = true;
      this.data = params;
      this.element1 = params.id;
      this.getContributionData(params)
    },
    hidePicker() {
      document.removeEventListener("click", this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue =
          "rgba(" +
          color.rgba.r +
          ", " +
          color.rgba.g +
          ", " +
          color.rgba.b +
          ", " +
          color.rgba.a +
          ")";
      }
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
    color1(color) {
      this.colors.hex = color;
    },
    refresh(){
      this.state.refresh=true;
    }
  },
};
</script>

<style>
</style>