<template>
  <div>
    <top-banner :title="pageTitle" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0 main mb-5">
      <div class="card card-main mt-3 ml-0 mb-5">
        <div class="card-header rounded-top-left-20 rounded-top-right-20">
          <div class="container">
            <b-row no-gutters>
              <div class="col-sm col-md">
                <div class="table-responsive">
                  <div class="all-tabs">
                    <div
                      class="tab"
                      :class="{ active: selectedTabIndex == i }"
                      v-for="(tab, i) in shownTabs"
                      :key="i"
                      @click="setTab(i)"
                    >
                      {{`All ${getKeyword('project')}s`}}
                    </div>
                  </div>
                </div>
              </div>
            </b-row>
          </div>
        </div>
        <div class="card-body pl-0 ml-0">
          <b-row no-gutters class="pb-3 mb-5 px-2">
            <div class="table-responsive">
              <transition name="tabs-fade">
                <keep-alive :max="3">
                  <Component
                    :is="shownTabs[selectedTabIndex]"
                    :tabName="compID"
                  />
                </keep-alive>
              </transition>
            </div>
          </b-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Projects from "./projects.vue";
import { v4 as uuidv4 } from "uuid";
export default {
  components: {
    Projects,
  },
  data() {
    return {
      compID: uuidv4(),
      selectedTabIndex: 0,
      tabs: ["Projects"],
      pageTitle: "Projects",
    };
  },
  computed: {
    shownTabs() {
      return this.tabs;
    },
    keywords(){
      return this.$store.state.current_program ? this.$store.state.current_program.keyWords : null;
    }
  },
  methods: {
    setTab(index) {
      if (isNaN(index)) return;
      if (index == this.selectedTabIndex) return;
      this.$set(this, "selectedTabIndex", index);
    },
  },
};
</script>

<style scoped lang="scss">
*,
html,
body {
  font-family: Ubuntu;
}

/* Top card styles */
.top-card {
  background-color: rgba(119, 119, 119, 0.507);
  border-radius: 10px !important;
  padding: 20px 12px !important;
  width: 234px !important;
  height: 123px !important;
}

.top-card h3 {
  font-size: 16px !important;
  padding-bottom: 3px !important;
}

.top-card p {
  font-size: 15px !important;
  padding-top: 2.5px !important;
}
/* End for styles */

@media (min-width: 768px) {
  .table-responsive {
    overflow-x: hidden;
  }
}

.card ul li {
  list-style-type: none;
}

.card-stat {
  background-color: #fff !important;
}

/* icons */
.dash-img {
  width: 75px;
  height: 75px;
}
/* end of icons styles */

.j1-admin h3 {
  font-size: 20px;
}

.j1-admin p {
  font-size: 16px;
}

.ct-admin {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.btn-top {
  background: #e5e5e5 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
}

.btn-top-filter {
  background: #0fa958 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card {
  border-radius: 20px !important;
  text-align: center !important;
}

.row-admin {
  margin-top: 30px !important;
}

.top-buttons {
  margin-left: -8rem !important;
}

.card-main {
  background-color: #f6f6f6 !important;
  border: none !important;
}

.card-2 {
  border: none !important;
}

.card h5 {
  font-style: normal;
  font-family: Ubuntu !important;
  font-size: 14px !important;
  color: #69737f !important;
}

.card p {
  font-style: normal;
  font-family: Ubuntu !important;
  font-size: 25px !important;
  color: #69737f !important;
  font-weight: bold;
}

@media screen and (max-width: 578px) {
  .big-border {
    width: 54.5px;
    height: 5px;
    background: #cecf71 !important;
  }

  .date-top-text {
    color: #000 !important;
  }
}

.collapse .show {
  background-color: #0fa958 !important;
}
</style>