<template>
  <div class="container-fluid">
    <div class="row mt-5">
      <nav
        v-bind:class="{ 'sidebar-hidden': isActive }"
        class="col-sm-3 col-md-2 d-sm-block bg-light sidebar"
      >
        <router-link class="navbar-brand mt-n5" to="/user">
            <b-img :src="logo" class="logo" alt="AFR logo"></b-img>
          </router-link>
        <b-container class="my-5" v-if="state.loading">
          <a-skeleton
            active
            :paragraph="{ rows: 8 }"
            :loading="state.loading"
          ></a-skeleton>
        </b-container>
        <section v-else>
          <ul class="nav nav-pills text-left flex-column mt-3 text-white">
            <li class="nav-item">
              <button
                @click="sideMenuActions"
                class="btn text-white float-right btn-humburger mt-3"
              >
                <i class="bx bx-x fa-2x"></i>
              </button>
            </li>
            <li class="nav-item">
              <router-link
                exact-active-class="vm-link"
                :class="{ 'vm-link': subIsActive('/user/main') }"
                class="nav-link nav-link2 ml-n2"
                to="/user/main"
              >
                <div class="cont pl-1">
                  <div class="d-flex justify-content-start link-item">
                    <span>
                      <i class="bx bx-grid-alt fa-2x"></i>
                    </span>
                    <span class="mt-1 ml-3">Dashboard</span>
                  </div>
                </div>
              </router-link>
            </li>
            <li class="nav-item" v-if="role.toLowerCase() != 'implementing partner' && role.toLowerCase() != 'project manager' && role.toLowerCase() != 'head of pillar'">
              <router-link
                exact-active-class="vm-link"
                class="nav-link nav-link2 ml-n2"
                to="/user/programs/current"
                :class="{ 'vm-link': subIsActive('/user/programs') }"
              >
                <div class="cont pl-1">
                  <div class="d-flex justify-content-start link-item">
                    <span>
                      <i class="bx bxs-color fa-2x"></i>
                    </span>
                    <span class="mt-1 ml-3">Program</span>
                  </div>
                </div>
              </router-link>
            </li>
            <li class="nav-item" v-if="role.toLowerCase() != 'implementing partner' && role.toLowerCase() != 'project manager'">
              <router-link
                exact-active-class="vm-link"
                class="nav-link nav-link2 ml-n2"
                :to="{ name: 'program-pillars' }"
                :class="{ 'vm-link': subIsActive('/user/pillars') }"
              >
                <div class="cont pl-1">
                  <div class="d-flex justify-content-start link-item">
                    <span>
                      <i class="bx bx-building fa-2x"></i>
                    </span>
                    <span class="mt-1 ml-3 text-capitalize">{{
                      keywords ? `${keywords.pillar}s` : "Pillars"
                    }}</span>
                  </div>
                </div>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                exact-active-class="vm-link"
                class="nav-link nav-link2 ml-n2"
                to="/user/projects"
                :class="{ 'vm-link': subIsActive('/user/projects') }"
              >
                <div class="cont pl-1">
                  <div class="d-flex justify-content-start link-item">
                    <span>
                      <i class="bx bx-trending-up fa-2x"></i>
                    </span>
                    <span class="mt-1 ml-3 text-capitalize">
                      {{
                        keywords.project ? `${keywords.project}s` : "Projects"
                      }}
                    </span>
                  </div>
                </div>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                exact-active-class="vm-link"
                class="nav-link nav-link2 ml-n2"
                :to="{ name: 'project-approvers' }"
                :class="{ 'vm-link': subIsActive('/user/approvers') }"
              >
                <div class="cont pl-1">
                  <div class="d-flex justify-content-start link-item">
                    <span>
                      <i class="bx bx-group fa-2x"></i>
                    </span>
                    <span class="mt-1 ml-3 text-capitalize"
                      >{{
                        keywords.approver
                          ? `${keywords.approver}s`
                          : "Approvals"
                      }}
                    </span>
                  </div>
                </div>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                exact-active-class="vm-link"
                class="nav-link nav-link2 ml-n2"
                :to="{ name: 'all-reports' }"
                :class="{ 'vm-link': subIsActive('/user/reports') }"
              >
                <div class="cont pl-1">
                  <div class="d-flex justify-content-start link-item">
                    <span>
                      <i class="bx bx-file fa-2x"></i>
                    </span>
                    <span class="mt-1 ml-3 text-capitalize">
                      {{
                        keywords.report ? `${keywords.report}s` : "Reports"
                      }}</span
                    >
                  </div>
                </div>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                exact-active-class="vm-link"
                class="nav-link nav-link2 ml-n2"
                to="/user/admin"
                v-if="role.toLowerCase() == 'admin'"
              >
                <div class="cont pl-1">
                  <div class="d-flex justify-content-start link-item">
                    <span>
                      <i class="bx bx-shield-quarter fa-2x"></i>
                    </span>
                    <span class="mt-1 ml-3">Admin</span>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </section>
      </nav>
    </div>
  </div>
</template>

<script>
import logo from "../../../assets/images/logo.png";
import avatar from "../../../assets/images/icons/user.png";
export default {
  name: "side-pane",
  components: {},
  data() {
    return {
      logo: logo,
      avatar: avatar,
      title: this.$store.state.manager.title,
      isActive: this.$store.state.manager.isActive,
      state: {
        loading: false,
      },
    };
  },
  watch: {
    isActive() {
      this.$store.dispatch("manager/setSidebarAction", true);
      this.isActive = this.$store.state.manager.isActive;
    },
  },
  computed: {
    keywords() {
      return this.$store.state.current_program
        ? this.$store.state.current_program.keyWords
        : null;
    },
    token() {
      return localStorage.getItem("token");
    },
    user(){
      return this.$store.state.user_data.user;
    },
    role(){
      return this.$store.state.user_data.role;
    },
  },
  beforeMount() {
    this.getCurrentProgram();
  },
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    sideMenuActions() {
      this.isActive = !this.isActive;
    },
    async getCurrentProgram() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getCurrentProgram", config)
        .then((response) => {
          this.state.loading = false;
          if (response.data) {
            this.$store.dispatch("setCurrentProgram", response.data[0]);
          }
        })
        .catch(() => {
          this.state.loading = false;
          // this.$notification["error"]({
          //   message: "ERROR!",
          //   description: `${error.errorMessage}`,
          //   duration: 5,
          // });
        });
    },
  },
};
</script>

<style>
.nav-link2 {
  color: #fff !important;
}

.vm-link .cont {
  border-left: 4px solid #0fa958 !important;
}

.vm-link {
  color: #0fa958 !important;
  border-radius: 0px !important;
}

.vm-link .link-item {
  color: #0fa958 !important;
  background: linear-gradient(
      0deg,
      rgba(165, 165, 165, 0.2),
      rgba(165, 165, 165, 0.2)
    ),
    linear-gradient(0deg, #031030, #031030);
  padding: 0.36rem 0.2rem !important;
  border-radius: 10px !important;
}

.logo {
  width: 50%;
  height: 90%;
}
.avatar {
  width: 50%;
  height: 50%;
}
.nav-top {
  background-color: #fff !important;
  width: 241px;
  height: 50px !important;
  margin: 0 !important;
  margin-left: -2rem !important;
  margin-top: -0.53rem !important;
  margin-bottom: -0.5rem !important;
}

.sidebar {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  z-index: 111;
  padding: 20px 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 15.625rem;
  border-bottom-right-radius: 10px !important;
  background-image: linear-gradient(180deg, white 8%, #031030 8%) !important;
}

.sidebar-hidden {
  position: fixed !important;
  top: 0;
  left: -100% !important;
  transition: 0.6s ease-out !important;
}

.sidebar .nav-item {
  width: 100%;
}

.right-icons {
  margin-left: 47rem !important;
}

@media (min-width: 250px) {
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (max-width: 600px) {
  .nav-top {
    width: 16.563rem !important;
  }
}

@media (min-width: 1280px) {
  .btn-humburger {
    display: none;
  }

  .nav-pills {
    margin-top: 3rem !important;
  }

  .sidebar-hidden {
    position: fixed !important;
    top: 0;
    left: 0 !important;
    transition: 0.4s ease-in !important;
  }
}
</style>