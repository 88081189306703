<template>
  <div>
    <adminTopBar :title="title" />
    <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-0">
      <div class="container-fluid ml-0 pl-0 mr-0 pr-0 main-container">
        <div class="jumbotron pl-0 pr-0 pt-0 mt-5 b-none">
          <b-row class="mt-0 row-admin mb-4">
            <div class="col-sm-4 col-xs-4 col-md-4 mt-0">
              <div class="card rounded mt-0 shadow rounded-10">
                <div class="card-body p-2 mt-2 ml-2">
                  <div class="d-flex justify-content-between">
                    <div class="text-left">
                      <h5 class="card-title text-dark">All roles</h5>
                    </div>
                    <div class="p-0">
                      <p>{{ rows }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-xs-4 col-md-4 mt-0">
              <div class="card rounded mt-0 shadow rounded-10">
                <div class="card-body p-2 mt-2 ml-2">
                  <div class="d-flex justify-content-between">
                    <div class="text-left">
                      <h5 class="card-title text-dark">All permissions</h5>
                    </div>
                    <div class="p-0">
                      <p>10</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-xs-4 col-md-4 mt-0">
              <div class="card rounded mt-0 shadow rounded-10">
                <div class="card-body p-2 mt-2 ml-2">
                  <div class="d-flex justify-content-between">
                    <div class="text-left">
                      <h5 class="card-title text-dark">All users</h5>
                    </div>
                    <div class="p-0">
                      <p>{{ all_numbers ? all_numbers.allusers : 0 }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-sm mb-4">
              <div class="card bg-gray rounded-20 card-main table-data">
                <div
                  class="card-header rounded-20 bg-gray card-main border-none"
                >
                  <div class="row d-flex justify-content-between">
                    <div class="text-left left-label col-xs">
                      <h6 class="p-2 mt-2">All Roles</h6>
                    </div>
                    <div class="p-2 col-xs">
                      <button
                        @click="showModal"
                        class="
                          btn btn-outline-primary btn-custom-primary
                          mr-4
                          btn-add
                        "
                      >
                        ADD NEW <i class="bx bx-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <b-row>
                    <div class="col-sm col-md"></div>
                    <div class="col-sm-4 col-md-4">
                      <form>
                        <div class="input-group mb-2">
                          <input
                            type="search"
                            v-model="filter"
                            class="form-control input-search rounded-left-10"
                            aria-label="Search"
                            placeholder="Search ..."
                          />
                          <div class="input-group-append">
                            <span class="input-group-text rounded-right-10">
                              <i class="bx bx-search text-success"></i>
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </b-row>
                  <b-row class="mb-2">
                    <div class="col-sm-9 col-md-9"></div>
                    <div class="col-sm col-md text-right">
                      <button
                        @click="refresh"
                        type="button"
                        class="btn btn-secondary btn-sm rounded-10 border-none"
                      >
                        <b-container v-if="state.loading && state.refresh">
                          <b-spinner small></b-spinner>
                          <span class="text-13 ml-2">Refreshing...</span>
                        </b-container>
                        <span v-else class="text text-13">Refresh</span>
                      </button>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-sm col-md">
                      <div class="table-responsive">
                        <b-container class="my-2" v-if="state.loading">
                          <a-skeleton
                            :loading="state.loading"
                            :paragraph="{ rows: 8 }"
                            active
                          ></a-skeleton>
                        </b-container>
                        <b-table
                          v-else
                          table-class="table-roles text-13"
                          striped
                          hover
                          thead-tr-class="tb-head text-left indent-20"
                          tbody-class="tb-body text-left indent-20"
                          :items="roles"
                          :filter="filter"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :fields="fields"
                          show-empty
                        >
                          <template v-slot:cell(edit)="data">
                            <button
                              @click="showEditRoleModal(data.item)"
                              class="btn btn-sm btn-outline-success"
                            >
                              <i class="bx bx-edit-alt"></i>
                            </button>
                          </template>

                          <!-- <template v-slot:cell(number_of_permissions)>
                              12
                          </template> -->

                          <template v-slot:cell(permissions)="data">
                            <span>{{ data.item.permission }}</span>
                          </template>

                          <template v-slot:cell(delete)="data">
                            <button
                              @click="showDeleteRoleModal(data.item)"
                              class="btn btn-sm btn-outline-danger"
                            >
                              <i class="bx bx-trash-alt"></i>
                            </button>
                          </template>
                          <template v-slot:cell(permission)>
                            <router-link :to="{ name: 'assign-roles' }">
                              <button class="btn btn-sm btn-outline-info">
                                <i class="bx bx-plus"></i>
                              </button>
                            </router-link>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-sm-8"></div>
                    <div class="col-sm">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                      ></b-pagination>
                    </div>
                  </b-row>
                </div>
              </div>
            </div>
          </b-row>
        </div>
      </div>
    </main>
    <a-modal
      v-model="visibleAddRole"
      :footer="null"
      title="Create new role"
      @ok="handleOk"
    >
      <form @submit.prevent="addRole">
        <div class="form-group">
          <label>Role name</label>
          <input
            placeholder="Enter role name"
            type="text"
            class="form-control form-input"
            v-model="role"
            required
          />
        </div>
        <div class="form-group">
          <label>Role description</label>
          <b-form-textarea
            id="desc"
            placeholder="Write description"
            rows="1"
            resize
            class="form-text-modal"
            v-model="description"
          ></b-form-textarea>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-success btn-block">
            <b-container v-if="this.state.saving">
              <b-spinner small></b-spinner>
              <span class="text-13 ml-2">Saving...</span>
            </b-container>
            <span v-if="!this.state.saving" class="text text-13"
              >Save Role</span
            >
          </button>
        </div>
      </form>
    </a-modal>
    <a-modal v-model="visibleEditRole" :footer="null" title="Update Role">
      <form @submit.prevent="updateRole(data)">
        <div class="form-group">
          <label>Role name</label>
          <input
            placeholder="Enter role name"
            type="text"
            class="form-control form-input"
            v-model="role"
            required
          />
        </div>
        <b-form-group
          label="Select permission"
          label-for="tags-component-select"
        >
          <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
          <b-form-tags
            id="tags-component-select"
            v-model="value"
            size="lg"
            class="mb-2 form-select-role bg-gray-white"
            add-on-change
            no-outer-focus
          >
            <template
              v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }"
            >
              <ul
                v-if="tags.length > 0"
                class="list-inline d-inline-block mb-2"
              >
                <li
                  v-for="tag in tags"
                  :key="tag"
                  class="list-inline-item mt-1"
                >
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag"
                    :disabled="disabled"
                    variant="success"
                  >
                    <span class="text-12 mr-2">{{ tag }}</span>
                  </b-form-tag>
                </li>
              </ul>
              <b-form-select
                v-bind="inputAttrs"
                v-on="inputHandlers"
                :disabled="disabled || availableOptions.length === 0"
                :options="availableOptions"
                class="select-modal custom-select rounded-10"
              >
                <template #first>
                  <!-- This is required to prevent bugs with Safari -->
                  <option disabled value="">Select permission</option>
                </template>
              </b-form-select>
            </template>
          </b-form-tags>
        </b-form-group>
        <div class="form-group">
          <label>Role description</label>
          <b-form-textarea
            id="desc"
            placeholder="Enter description"
            rows="1"
            resize
            class="form-text-modal"
            v-model="description"
          ></b-form-textarea>
        </div>
        <div class="form-group">
          <button
            :disabled="state.updating"
            type="submit"
            class="btn btn-success btn-block rounded-20 btn-sm"
          >
            <b-container v-if="state.updating">
              <b-spinner small></b-spinner>
              <span class="text-13 ml-2">Updating...</span>
            </b-container>
            <span v-if="!state.updating" class="text text-13">Update</span>
          </button>
        </div>
      </form>
    </a-modal>
    <!-- Delete role modal -->
    <a-modal
      v-model="visibleDeleteRole"
      :footer="null"
      title="Delete User"
      @ok="handleOk"
    >
      <b-row>
        <h6 class="p-3 text-14 pl-4 pr-4 mt-n3 pt-1 text-center">
          Are you sure you want to delete this information ?
        </h6>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <div class="inline-block pr-4">
          <a-popconfirm
            title="Are you sure delete this role ?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteRole(data)"
            @cancel="cancel"
          >
            <button
              type="button"
              class="btn btn-danger btn-block btn-confirm-delete"
            >
              <b-container v-if="state.loading">
                <b-spinner small></b-spinner>
                <span class="text-13 ml-2">Deleting...</span>
              </b-container>
              <span v-if="!state.loading" class="text text-13">DELETE</span>
            </button>
          </a-popconfirm>
        </div>
        <div class="inline-block">
          <button
            @click="handleDelete"
            type="button"
            class="btn btn-secondary btn-block btn-reject-delete"
          >
            CANCEL
          </button>
        </div>
      </b-row>
    </a-modal>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      active: true,
      visibleAddRole: false,
      visibleEditRole: false,
      visibleDeleteRole: false,
      options: [
        "View all users",
        "Edit all users",
        "Add all roles",
        "Edit all roles",
      ],
      value: ["View all users", "Edit all users"],
      fields: [
        "name",
        "permissions",
        "Description",
        "edit",
        "delete",
        "permission",
      ],
      perPage: 8,
      currentPage: 1,
      filter: "",
      title: "roles",
      roles: [],
      data: null,
      role: "",
      description: "",
      state: {
        loading: false,
        refresh: false,
        saving: false,
        updating: false,
      },
    };
  },
  computed: {
    availableOptions() {
      return this.options.filter((opt) => this.value.indexOf(opt) === -1);
    },
    rows() {
      return this.roles.length;
    },
    token() {
      return localStorage.getItem("token");
    },
    all_numbers() {
      return this.$store.state.numbers;
    },
  },
  beforeMount() {
    this.$title("ADMIN - Roles");
    this.getRoles();
  },
  methods: {
    async getRoles() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      this.state.loading = true;
      await this.axios
        .get("getRoles", config)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.roles = response.data;
          } else {
            this.state.loading = false;
            this.$notification["error"]({
              message: "ERROR!",
              description: `Something wrong when getting roles`,
              duration: 5,
            });
          }
        })
        .catch(() => {
          // this.$notification["error"]({
          //   message: "ERROR!",
          //   description: `${error.errorMessage}`,
          //   duration: 5,
          // });
          this.state.loading = false;
        });
    },
    async addRole() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        name: this.role,
        description: this.description,
      };
      this.state.saving = true;
      await this.axios
        .post("addRole", requestData, config)
        .then((response) => {
          if (response.data.message) {
            this.state.saving = false;
            this.$message.error(`${response.data.message}`, 4);
            this.visibleAddRole = false;
          } else {
            this.getRoles();
            this.state.saving = false;
            this.visibleAddRole = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Role added successfully.`,
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.saving = false;
        });
    },
    async deleteRole(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };

      this.state.loading = true;
      await this.axios
        .delete(`deteleRole/${param.id}`, config)
        .then((response) => {
          if (response) {
            this.state.loading = false;
            this.visibleDeleteRole = false;
            this.getRoles();
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Role  deleted successfully.`,
              duration: 5,
            });
          } else {
            this.state.loading = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.loading = false;
        });
    },
    async updateRole(param) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: "application/json",
        },
      };
      const requestData = {
        name: this.role,
        Description: this.description,
      };
      this.state.updating = true;
      await this.axios
        .put(`updateRole/${param.id}`, requestData, config)
        .then((response) => {
          if (response.data) {
            this.getRoles();
            this.state.updating = false;
            this.visibleEditRole = false;
            this.$notification["success"]({
              message: "SUCCESS!",
              description: `Role  has been changed successfully.`,
              duration: 5,
            });
          } else {
            this.state.updating = false;
          }
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "ERROR!",
            description: `${error.errorMessage}`,
            duration: 7,
          });
          this.state.updating = false;
        });
    },
    refresh() {
      this.state.refresh = true;
      this.getRoles();
    },
    setActiveTab() {},
    showModal() {
      this.visibleAddRole = true;
    },
    showDeleteRoleModal(data) {
      this.data = data;
      this.visibleDeleteRole = true;
    },
    showEditRoleModal(data) {
      this.data = data;
      this.role = data.name;
      this.description = data.Description;
      this.visibleEditRole = true;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    cancel(e) {
      console.log(e);
      this.visibleDeleteRole = false;
      this.$message.error("Delete canceled.");
    },
    handleChange(value) {
      console.log(`Selected: ${value}`);
    },
    popupScroll() {
      console.log("popupScroll");
    },
    handleDelete() {
      this.visibleDeleteRole = false;
    },
  },
};
</script>

<style scoped>
*,
html,
body {
  font-family: Ubuntu;
}

@media screen and (max-width: 578px) {
  .btn-add {
    margin-top: 1rem !important;
    margin-left: 2rem;
  }
  .card .left-label h6 {
    margin-left: 1rem !important;
  }
}

.card .left-label h6 {
  margin-left: 1rem;
}
</style>