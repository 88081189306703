<template>
  <div class="mb-4">
    <b-row>
      <div class="col-sm col-md text-right">
        <a-tooltip title="Refresh report data" placement="bottom">
          <button
            @click="refresh"
            type="button"
            class="
              btn
              mr-3
              mt-1
              btn-secondary
              float-right
              btn-sm
              rounded-20
              border-none
            "
          >
            <b-icon-arrow-repeat
              font-scale="1.6"
              :animation="state.refresh && state.loading ? 'spin' : ''"
              @click="refresh"
            />
          </button>
        </a-tooltip>
      </div>
    </b-row>
    <b-row class="mt-n3">
      <b-container v-if="state.loading">
        <a-skeleton
          active
          :loading="state.loading"
          :paragraph="{ rows: 8 }"
        ></a-skeleton>
      </b-container>
      <div v-else class="col-sm-9 col-md-9 mt-1 text-left col-lg-9">
        <form>
          <h2 class=""><strong style="text-capitalize">{{this.reportDetails.Title}}</strong></h2>
          <h4>Due Date: {{this.reportDetails.DeadLine}}</h4>
          <h4 class="text-13">Report on <strong style="text-capitalize">{{this.report.Type}}</strong></h4>
          <h4 class="text-13">Reporting milestone <strong>{{report.Milestone}}</strong> </h4>
          <div class="form-group">
            <b-row class="mt-2" v-for="(item, i) in inputs" :key="i">
              <div class="card w-100">
                <div class="card-body">
                  <b-row class="mt-3">
                    <div class="col-sm col-md">
                      <label>Indicator: <strong>{{item.indicatorname}}</strong></label>
                      <div class="clearfix"></div>
                      <label class="text-14"> Target:  {{item.target}} </label>
                      <hr v-if="item.aggregations.length"/>
                      <label v-if="item.aggregations.length" class="text-bold">Disaggregations:</label>
                      <hr v-if="item.aggregations.length">
                      <h4 v-for="(agg,k) in item.aggregations" :key="k">
                        <label>{{agg.Title}} </label>
                        <div class="clearfix"></div>
                        <input
                          placeholder="Aggregation Value"
                          type="number"
                          class="form-control form-input-login"
                          v-model="agg.aggValues"
                          @keyup="changesOnValues"
                        />
                         <label> Target:  <span class="text-black">{{ agg.target }}</span> </label>
                        <hr>
                      </h4>
                    </div>
                    <div class="col-sm col-md">
                      <input
                        placeholder="Value"
                        type="number"
                        class="form-control form-input-login"
                        v-model="item.value"
                      />
                    </div>
                  </b-row>
                </div>
              </div>
            </b-row>
            <b-row v-if="report.narativeReport" class="mt-2">
              <b-col cols="8">
                <div class="input-group ml-n3">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="inputGroupFile03"
                      aria-describedby="inputGroupFileAddon03"
                      ref="attachment"
                      @change="onchangeFile"
                      :required="report.narativeReport"
                    />
                    <label
                      class="custom-file-label bg-white"
                      for="inputGroupFile03"
                      >Narative report</label
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </form>
      </div>
    </b-row>
  </div>
</template>

<script>
import decode from "jwt-decode";
export default {
  props: ["store"],
  data() {
    return {
      email: "",
      reportDetails: {},
      state: {
        loading: true,
        refresh: false,
        adding: false,
      },
      indicators: [],
      inputs: [],
      inputValues: [],
      file: null,
      aggregations:[],
      aggTemp:[]
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    report() {
      const session = sessionStorage.getItem("report");
      var decoded = null;
      if (session) {
        decoded = decode(session);
      }
      return decoded;
    },
  },
  beforeMount() {
    if (this.report) {
      this.getReportData();
    }else{
      this.$router.push({name:'all-reports'})
    }
    this.$emit("draft", false);
  },
  methods: {
    onchangeFile(){
      this.file = this.$refs.attachment.files[0];
    },
    async getReportData() {
      this.state.loading = true;
      await this.axios
        .get(`getReport/${this.report.id}`)
        .then((response) => {
          if (response.data) {
            this.state.loading = false;
            this.reportDetails = response.data
            this.indicators = response.data.indicators;
            this.inputs = [];
            this.aggregations=[]
            this.aggTemp = []
            this.indicators.forEach((item) => {
              item.Aggregations.forEach((agg)=> {
                this.aggregations.push({
                  aggValues: null,
                  aggregationId: agg.id,
                  Title: agg.Title,
                  target: agg.target
                })
              })
              this.inputs.push({
                indicatorId: item.indicatorId,
                indicatorname: item.indicatorname,
                milestoneId: item.milestoneId,
                value: item.value,
                target: item.target,
                aggregations: item.Aggregations
              });

            });
          } else {
            this.state.loading = false;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    changesOnValues(){
      console.log("Inputted", this.inputs);
      console.log("Input aggregations", this.aggTemp);
    },
    nextStep(submissionType = 'final') {
      const formData = new FormData();
      formData.append("file", this.file);
      this.inputs.forEach((item,i) => {
        formData.append('values['+i+'][indicatorId]', parseInt(item.indicatorId));
        formData.append('values['+i+'][milestoneId]', parseInt(item.milestoneId));
        formData.append('values['+i+'][value]', item.value);
        item.aggregations.forEach((agg,index) =>{
          formData.append('values['+i+'][AggregationValues]['+index+'][aggregationId]', parseInt(agg.id));
          formData.append('values['+i+'][AggregationValues]['+index+'][aggValues]', parseInt(agg.aggValues));
        })
      });
      formData.append("reportId", parseInt(this.report.id))
      formData.append("Type", this.report.Type)
      formData.append("submissionType", submissionType)

      if (
          this.inputs.filter(
            (item) => item.value === null || item.value === ""
          ).length > 0
        ) {
          this.$message.error("Please fill all value fields corretly is required.", 5);
        }else if(this.report.narativeReport && !this.file){
          this.$message.error('Please choose narative report file!', 4)
        } else {
        this.state.adding = true;
        this.$emit("loading", true)
        this.axios
          .post(`AddreportVaues`, formData)
          .then((response) => {
            if (response.data) {
              this.state.adding = false;
              this.$emit("loading", false)
              this.inputs=[];
              this.$notification["success"]({
                message: "SUCCESS!",
                description: `Report submitted successful and now added to approval list.`,
                duration: 5,
              });
              this.$message.info("You will be redirected shortly.",5)
              sessionStorage.removeItem("report");
              setTimeout(()=> {
                this.$router.push({name:'all-reports'})
              }, 3000)

            } else {
              this.state.loading = false;
              this.$emit("loading", false)
              
            }
          })
          .catch((error) => {
            this.state.adding = false;
            this.$emit("loading", false)

            console.log(error)

            this.$notification["error"]({
              message: "Error!",
              description: error.errorMessage,
              duration: 30,
            });
          });
      }
    },
    saveDraft(){
      this.nextStep('draft')
    },
    refresh() {
      this.state.refresh = true;
      this.getReportData();
    },
  },
};
</script>

<style scoped>
  .main-wrapper .form-group label,
  .main-wrapper .form-group input::placeholder,
  .form-text,
  .form-text::placeholder,
  .form-text-label,
  .form-control,
  .custom-select {
    font-size: 13px !important;
  }
</style>
