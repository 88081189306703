var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('top-banner',{attrs:{"title":_vm.pageTitle}}),_c('main',{staticClass:"col-sm-9 ml-sm-auto col-md-10 pt-0 main-home mb-5",attrs:{"role":"main"}},[_c('div',{staticClass:"card card-main  card-0 mt-3 ml-0 mb-3"},[_c('div',{staticClass:"card-body pl-0 ml-0"},[_c('b-row',[_c('div',{staticClass:"col-sm col-md"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"\n                  btn btn-secondary\n                  rounded-20\n                  pt-1\n                  pb-1\n                  pl-5\n                  pr-5\n                  btn-filter"},[_vm._v(" Filter "),_c('i',{staticClass:"bx bx-filter-alt mt-2"})])])])]),_c('b-row',{staticClass:"mb-0 mt-2"},[_c('div',{staticClass:"col-sm-9 col-md-9"}),_c('div',{staticClass:"col-sm col-md text-right"},[_c('a-tooltip',{attrs:{"title":"Refresh data","placement":"bottom","color":'#87d068'}},[_c('button',{staticClass:"btn mr-3 mt-1 btn-secondary btn-sm rounded-20 border-none",attrs:{"type":"button"},on:{"click":_vm.refresh}},[_c('b-icon-arrow-repeat',{attrs:{"font-scale":"1.6","animation":_vm.state.refresh && _vm.state.loading ? 'spin' : ''}})],1)])],1)]),(_vm.state.loading || _vm.state.currentp)?_c('b-container',{staticClass:"my-5"},[_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 18 },"loading":_vm.state.loading || _vm.state.currentp}})],1):(_vm.pillarsLength > 0)?_c('b-row',{staticClass:"mb-4"},_vm._l((_vm.pillars),function(item,i){return _c('div',{key:i,staticClass:"col-sm-3 col-md-3 my-3"},[_c('div',{staticClass:"card pillar-1 ml-2 box cursor-pointer mt-2",class:i == 1
                  ? 'pillar-2'
                  : i == 2
                  ? 'pillar-3'
                  : i == 3
                  ? 'pillar-4'
                  : '',on:{"click":function($event){item.status == 'incomplete'
                  ? _vm.goToDraftedPillar(item)
                  : _vm.goToPillar(item)}}},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"text-left text-13"},[_c('strong',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(item.Title)+" ")])]),_c('div',{staticClass:"content pt-4 pb-4 mb-4"},[_c('div',{staticClass:"text-center"},[_c('h4',[_c('strong',[_vm._v(_vm._s(((item.peopleHelped.length > 0) + "%") ? item.peopleHelped : "0%"))])]),_c('span',[_c('strong',[_vm._v("People reached")])])])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"h6 text-13 text-capitalize"},[_vm._v(" "+_vm._s(_vm.keywords ? ((_vm.keywords.project) + "s") : "Projects")+": "),_c('big',{staticClass:"text-bold"},[_vm._v(_vm._s(item.projects ? item.projects : "0"))])],1),_c('span',{staticClass:"cursor-pointer"},[_c('i',{staticClass:"bx bx-dots-vertical-rounded bx-sm dotted"})])])])])])}),0):_c('b-container',{staticClass:"my-5"},[_c('i',{staticClass:"fas fa-exclamation-triangle text-red fa-3x"}),_c('span',{staticClass:"text-17 ml-2"},[_vm._v(" No "+_vm._s(_vm.getKeyword('pillar'))+" data found or you do not have access to any pillar ")])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }